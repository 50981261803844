import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { Subject } from "rxjs";

const PAGE_NAME: string = 'database';

@Component({
  selector: 'data-base-page',
  templateUrl: './DataBasePage.component.html'
})

export class DataBasePage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  private destroy = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });
  }

  public async ngOnInit(): Promise<void> {

  }

  public ngAfterViewInit(): void {

  }

  public ngOnDestroy(): void {

  }
}
