import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  MainPage,
  NotificationPage,
  LoginPage,
  DataBasePage,
  SettingPage,
  RepairPage,
  Test,
  ErrorPage,
  ChatPage,
  ProjectPage,
  HouseRegistrationPage,
  ResidentsPage,
  ConfigPage,
  ContentPage,
  DownPaymentPage,
  MessagePage,
  EmailMessagePage,
  SmsMessagePage,
  LogsMessagePage,
  AssessmentPage,
  CustomerServiceStatusPage,
  CustomerServiceItemPage,
  CustomerServiceAssessmentPage,
  RepairOutletPage,
  CustomerOutletPage,
  DocumentManualPage,
  RepairStatusPage,
  RepairTypePage,
  ManageUsersPage,
  AfterSalePage,
  ReportPage,
  ReportRepairPage,
  ReportCustomerServicePage
} from './components/components';
import { AuthGuard } from './services/auth.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'test',
    component: Test,
  },
  {
    path: LoginPage.PAGE_NAME,
    component: LoginPage
  },
  {
    path: '',
    redirectTo: RepairPage.PAGE_NAME,
    pathMatch: 'full'
  },
  {
    path: MainPage.PAGE_NAME,
    component: MainPage,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: DataBasePage.PAGE_NAME,
        component: DataBasePage,
        children: [
          {
            path: '',
            redirectTo: ProjectPage.PAGE_NAME,
            pathMatch: 'full'
          },
          {
            path: ProjectPage.PAGE_NAME,
            component: ProjectPage
          },
          {
            path: RepairOutletPage.PAGE_NAME,
            component: RepairOutletPage,
            children: [
              {
                path: RepairTypePage.PAGE_NAME,
                component: RepairTypePage
              },
              {
                path: AssessmentPage.PAGE_NAME,
                component: AssessmentPage
              },
              {
                path: RepairStatusPage.PAGE_NAME,
                component: RepairStatusPage
              },
              {
                path: '',
                redirectTo: RepairTypePage.PAGE_NAME,
                pathMatch: 'full'
              },
            ]
          },
          {
            path: CustomerOutletPage.PAGE_NAME,
            component: CustomerOutletPage,
            children: [
              {
                path: CustomerServiceStatusPage.PAGE_NAME,
                component: CustomerServiceStatusPage
              },
              {
                path: CustomerServiceItemPage.PAGE_NAME,
                component: CustomerServiceItemPage
              },
              {
                path: CustomerServiceAssessmentPage.PAGE_NAME,
                component: CustomerServiceAssessmentPage
              },
              {
                path: '',
                redirectTo: CustomerServiceItemPage.PAGE_NAME,
                pathMatch: 'full'
              },
            ]
          },
          {
            path: HouseRegistrationPage.PAGE_NAME,
            component: HouseRegistrationPage
          },
          {
            path: ResidentsPage.PAGE_NAME,
            component: ResidentsPage
          },
          {
            path: DocumentManualPage.PAGE_NAME,
            component: DocumentManualPage
          }
        ]
      },
      {
        path: DownPaymentPage.PAGE_NAME,
        component: DownPaymentPage,
      },
      {
        path: RepairPage.PAGE_NAME,
        component: RepairPage,
        children: [
          {
            path: ':id',
            component: RepairPage,
          }
        ]
      },
      {
        path: AfterSalePage.PAGE_NAME,
        component: AfterSalePage,
        children: [
          {
            path: ':id',
            component: AfterSalePage,
          }
        ]
      },
      {
        path: ContentPage.PAGE_NAME,
        component: ContentPage,
      },
      {
        path: NotificationPage.PAGE_NAME,
        component: NotificationPage,
      },
      {
        path: ChatPage.PAGE_NAME,
        component: ChatPage,
        children: [
          {
            path: ':id',
            component: DataBasePage,
          }
        ]
      },
      {
        path: SettingPage.PAGE_NAME,
        component: SettingPage,
        children: [
          {
            path: '',
            redirectTo: ManageUsersPage.PAGE_NAME,
            pathMatch: 'full'
          },
          {
            path: ManageUsersPage.PAGE_NAME,
            component: ManageUsersPage
          },
          {
            path: ConfigPage.PAGE_NAME,
            component: ConfigPage
          },
        ]
      },
      {
        path: MessagePage.PAGE_NAME,
        component: MessagePage,
        children: [
          {
            path: '',
            redirectTo: SmsMessagePage.PAGE_NAME,
            pathMatch: 'full'
          },
          {
            path: SmsMessagePage.PAGE_NAME,
            component: SmsMessagePage
          },
          {
            path: EmailMessagePage.PAGE_NAME,
            component: EmailMessagePage
          },
          {
            path: LogsMessagePage.PAGE_NAME,
            component: LogsMessagePage
          },
        ]
      },
      {
        path: ReportPage.PAGE_NAME,
        component: ReportPage,
        children: [
          {
            path: '',
            redirectTo: ReportRepairPage.PAGE_NAME,
            pathMatch: 'full'
          },
          {
            path: ReportRepairPage.PAGE_NAME,
            component: ReportRepairPage
          },
          {
            path: ReportCustomerServicePage.PAGE_NAME,
            component: ReportCustomerServicePage
          },
        ]
      },
      {
        path: ErrorPage.PAGE_NAME,
        component: ErrorPage
      },
    ]
  },
  {
    path: '**',
    redirectTo: ErrorPage.PAGE_NAME
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, {
    useHash: false,
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabledNonBlocking'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
