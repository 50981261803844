import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { PageFacade } from "../../../services/facade/PageFacade.service";
import { RepairTypeAdminFacade } from "../../../services/facade/RepairTypeAdminFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-repair-type',
    templateUrl: './SliderRepairType.component.html',
})
export class SliderRepairType implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public formGroup: UntypedFormGroup;
    public isSubmitted: boolean = false;

    public dataPage: any[] = [];
    public filteredListPage: any;

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private repairTypeAdminFacade: RepairTypeAdminFacade,
        private pageFacade: PageFacade,
        private formBuilder: UntypedFormBuilder,
        private checkTokenService: CheckTokenService,
    ) {
        this.formProjectId();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                this.getSelectPrefix();
                if (this.dataDetail.mode === 'edit') {
                    this.getProjectId(this.dataDetail.value);
                    setTimeout(() => {
                        this.formGroup.get('prefix')?.disable();
                    }, 10);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.formGroup.get('prefix')?.enable();
                    }, 10);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            if (this.formGroup.get('name')?.value !== this.dataJob.name || this.formGroup.get('prefix')?.value !== this.dataJob.page.prefix) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.getProjectId(this.dataDetail.value);
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (this.formGroup.get('name')?.value || this.formGroup.get('prefix')?.value) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.formGroup.reset();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            if (this.formGroup.get('name')?.value !== this.dataJob.name || this.formGroup.get('prefix')?.value !== this.dataJob.page.prefix) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.getProjectId(this.dataDetail.value);
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (this.formGroup.get('name')?.value || this.formGroup.get('prefix')?.value) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.formGroup.reset();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.formGroup.reset();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private getProjectId(data: any) {
        this.dataJob = data;
        this.formGroup.get('name')?.setValue(this.dataJob.name);
        this.formGroup.get('prefix')?.setValue(this.dataJob.page.prefix);
    }

    public formProjectId() {
        this.formGroup = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(254)]],
            prefix: ['', [Validators.required, Validators.pattern('[A-Z]{3}'), Validators.minLength(3), Validators.maxLength(3)]]
        });
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'edit') {
            if (this.formGroup.invalid || (this.formGroup.get('name')?.value === this.dataJob.name && this.formGroup.get('prefix')?.value === this.dataJob.page.prefix)) {
                return;
            }

            this.isSubmitted = true;
            this.repairTypeAdminFacade.updateData(this.dataDetail.value.id || this.dataDetail.value._id, this.formGroup.get('name')?.value
            ).then((res: any) => {
                if (res) {
                    this.getProjectId(res.data);
                    this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.getProjectId(this.dataDetail.value);
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                        }
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            if (this.formGroup.invalid) {
                return;
            }

            this.isSubmitted = true;
            const pageId: any = this._getPageId(this.formGroup.get('prefix')?.value);
            this.repairTypeAdminFacade.createData(this.formGroup.get('name')?.value, pageId
            ).then((res: any) => {
                if (res) {
                    this.dataJob = res.data;
                    this.createTableRow(this.dataJob, 'create');
                    this.isSubmitted = false;
                    this.formGroup.reset();
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                        }
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.name + '"'
            }).then((res: any) => {
                if (res) {
                    this.repairTypeAdminFacade.deleteData(this.dataDetail.value.id || this.dataDetail.value._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.formProjectId();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            }).then((res: any) => {
                                if (res) {

                                }
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    private _getPageId(prefix: string): any {
        let pages: any = this.dataPage.find(item => item.prefix === prefix);

        return pages?.id;
    }

    private getSelectPrefix() {
        this.pageFacade.searchData({ count: false, orderBy: { "createdDate": -1 } }).then((res: any) => {
            if (res) {
                this.dataPage = res.data;
                this.filteredListPage = this.dataPage.slice();
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.formGroup.controls;
    }
}

