import { BaseModel } from "./models";

export class ChatMessageModel extends BaseModel {
    public createdDate?: string;
    public createdBy?: string;
    public sender?: string;
    public senderType?: string;
    public message?: string;
    public messageType?: string;
    public room?: string;
    public fileId?: string;
    public filePath?: string;
    public videoURL?: string;
    public itemId?: string;
    public itemType?: string;
    public senderName?: string;
    public isRead?: boolean;
    public deleted?: boolean;
    public readers?: any[];
}