<ng-template cdk-portal>
  <notification></notification>
</ng-template>
<nav class="header-menu h-full relative flex border-r z-[101]" [ngClass]="{'active': isAcitonMenu}">
  <div class="menu-left h-full py-3 flex flex-col justify-between border-r bg-white" [ngClass]="{'pt-6': isAcitonMenu}">
    <div class="flex items-center justify-between" [ngClass]="{'pl-4 pr-1': isAcitonMenu}">
      <img
        [src]="isAcitonMenu ? '../../../../assets/images/logo-chanuntorn.svg' : '../../../../assets/images/menu/logo.svg'"
        alt="" class="logo cursor-pointer" [ngClass]="{'w-5/12 h-auto': isAcitonMenu , ' mx-auto' : !isAcitonMenu}"
        (click)="isAcitonMenu = false; isAcitonSubMenu = false; modeApp = ''; clickAcitonOut(false);"
        [routerLink]="'/'">
      <ng-container *ngFor="let item of menuList; let i = index">
        <card-list-menu [image]="item.image" [content]="''" [count]="0" [tooltip]="''" [isCount]="false"
          [isAciton]="false" *ngIf="isAcitonMenu && item.tag === 'app'" (event)="clickMenu(item.tag);">
        </card-list-menu>
      </ng-container>
    </div>
    <div class="menu-section-top flex flex-col flex-1 overflow-y-auto overflow-x-hidden py-2">
      <ng-container *ngFor="let item of menuList; let i = index">
        <card-list-menu [image]="item.image" [content]="item.text" [count]="0" [tooltip]="item.text" [isCount]="true"
          [isAciton]="isAcitonMenu" *ngIf="!isAcitonMenu && item.tag === 'app'" (event)="clickMenu(item.tag);">
        </card-list-menu>
      </ng-container>
      <ng-container *ngFor="let item_app of app; let app_i = index">
        <ng-container *ngIf="
        (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item_app.tag === 'database' : '') || 
        (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item_app.tag === 'down-payment' : '') || 
        (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item_app.tag === 'homecare' : '') || 
        (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (!userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item_app.tag === 'content' : '') ||
        (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (!userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item_app.tag === 'message' : '') ||
        (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (!userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item_app.tag === 'report' : '')
        ">
          <ng-container *ngIf="modeApp === item_app.tag">
            <div class="flex-none text-xl font-bold py-3 px-4" *ngIf="isAcitonMenu">
              {{item_app.text}}
            </div>
            <ng-container *ngFor="let list_item of item_app.list; let list_i = index">
              <card-list-menu [image]="list_item.image" [isTabActive]="isAcitonMenu"
                [isActive]="(listMenu['tag'] === list_item.tag) || (listMenu['tag'] === list_item.tag && urlActive[3])"
                [classAcitonImage]="'image-filter-blue'" [content]="list_item.text" [count]="0"
                [tooltip]="list_item.text" [isCount]="false" [isAciton]="isAcitonMenu"
                (event)="clickList(list_item.tag, null, (list_item.tag !== 'repair' && list_item.tag !== 'customer') ? true : false)"></card-list-menu>
              <ng-container *ngIf="listMenu['tag'] === list_item.tag && isAcitonMenu">
                <ng-container *ngFor="let item of list_item.list">
                  <card-list-menu [isTabActive]="isAcitonMenu"
                    [isActive]="(list_item.tag === urlActive[2]) && (item.tag === urlActive[3])" [content]="item.text"
                    [count]="0" [tooltip]="item.text" [isCount]="false" [isAciton]="isAcitonMenu" [isSubMenu]="true"
                    (event)="clickList(list_item.tag, item.tag);"></card-list-menu>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let menu of menuSetting; let app_i = index">
        <ng-container
          *ngIf="(userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) ? menu.tag === 'setting' : ''">
          <ng-container *ngIf="modeApp === menu.tag">
            <div class="flex-none text-xl font-bold py-3 px-4" *ngIf="isAcitonMenu">
              {{menu.text}}
            </div>
            <ng-container *ngFor="let list_item of menu.list; let list_i = index">
              <card-list-menu [image]="list_item.image" [isTabActive]="isAcitonMenu ? true : false"
                [isActive]="listMenu['tag'] === list_item.tag" [classAcitonImage]="'image-filter-blue'"
                [content]="list_item.text" [count]="1" [tooltip]="list_item.text" [isCount]="false"
                [isAciton]="isAcitonMenu" (event)="clickList(list_item.tag);"></card-list-menu>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="menu-section-bottom flex flex-col flex-none pt-2"
      [class.border-t]="((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing))">
      <div class="pb-2"
        *ngIf="((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing))">
        <ng-container *ngFor="let item of menuSetting; let i = index">
          <ng-container
            *ngIf="((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'chat' : ''">
            <card-list-menu [image]="modeApp !== item.tag ? item.image_1 : item.image_2" [content]="item.text"
              [count]="counReadChat ? counReadChat : 0" [tooltip]="item.text"
              [isCount]="counReadChat > 0 ? true : false" [isAciton]="isAcitonMenu" *ngIf="item.tag === 'chat'"
              (event)="modeSubMenu !== item.tag && modeApp !== item.tag ? clickMenu(item.tag) : '';"></card-list-menu>
          </ng-container>
        </ng-container>
      </div>
      <div class="border-t pt-3" [ngClass]="{'flex items-center justify-between px-2': isAcitonMenu}">
        <div [ngClass]="{'flex items-center flex-row-reverse': isAcitonMenu}">
          <ng-container *ngFor="let item of menuSetting; let i = index">
            <card-list-menu [image]="modeSubMenu !== item.tag ? item.image_1 : item.image_2" [content]="item.text"
              [count]="checkIsReadNoti()" [tooltip]="item.text" [isCount]="checkIsReadNoti() > 0 ? true : false"
              [isAciton]="false" *ngIf="item.tag === 'notification'" (event)="clickMenu(item.tag);"></card-list-menu>
            <ng-container
              *ngIf="(userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) ? item.tag === 'setting' : ''">
              <card-list-menu [image]="modeApp !== item.tag ? item.image_1 : item.image_2" [content]="item.text"
                [count]="0" [tooltip]="item.text" [isCount]="false" [isAciton]="false" *ngIf="item.tag === 'setting'"
                (event)="clickApp(item.tag);" [routerLink]="'/' + item.tag">
              </card-list-menu>
            </ng-container>
            <card-list-menu [image]="item.image_1" [content]="item.text" [count]="0" [tooltip]="item.text"
              [isCount]="false" [isAciton]="false" *ngIf="item.tag === 'logout'" (event)="clickLogout();">
            </card-list-menu>
          </ng-container>
        </div>
        <ng-container *ngFor="let item of menuSetting; let i = index">
          <card-list-menu [image]="!isAcitonMenu? item.image_1 : item.image_2" [content]="item.text"
            [tooltip]="item.text" [count]="0" [isCount]="false" [isAciton]="false" *ngIf="item.tag === 'slider'"
            (event)="clickAction(item.tag);"></card-list-menu>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="menu-right py-3 bg-white flex flex-col" [ngClass]="{'active': isAcitonSubMenu}">
    <ng-container *ngIf="isAcitonSubMenu">
      <div class="flex-none text-xl font-bold py-5 px-4 justify-between flex">
        {{modeSubMenu === 'notification' ? 'การแจ้งเตือน' : 'แอพพลิเคชั่น'}}
        <span class="text-sm font-normal text-gray-400 select-none group-hover:text-gray-800 cursor-pointer"
          *ngIf="modeSubMenu === 'notification'" (click)="checkReadAll() ? readAll() : null">อ่านทั้งหมด</span>
      </div>
      <div class=" flex-1 overflow-y-auto overflow-x-hidden" infinite-scroll [scrollWindow]="false"
        [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDownChatList()" *ngIf="notificationList">
        <ng-container *ngIf="modeSubMenu === 'app'">
          <div class="grid gap-4 grid-cols-3 px-4">
            <ng-container *ngFor="let item of app; let i = index">
              <ng-container *ngIf="
              (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'database' : '') || 
              (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'down-payment' : '') || 
              (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'homecare' : '') || 
              (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (!userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'content' : '') || 
              (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (!userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'aftersale' : '') || 
              (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (!userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'report' : '') || 
              (((userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && !userStatus?.user?.isMarketing) || (!userStatus?.user?.isAdmin && !userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing) || (userStatus?.user?.isAdmin && userStatus?.user?.isSubAdmin && userStatus?.user?.isMarketing)) ? item.tag === 'message' : '')
              ">
                <div class="group flex flex-col items-center select-none hover:cursor-pointer"
                  (click)="modeApp !== item.tag ? clickApp(item.tag) : null;"
                  [routerLink]="modeApp !== item.tag ? '/' + item.tag : null">
                  <div matRipple
                    class="button-app flex items-center justify-center rounded-lg select-none flex-none bg-[color:var(--blue-light)] group-hover:bg-[color:var(--blue-light)] group-hover:opacity-90"
                    [ngClass]="{'bg-gray-100 group-hover:bg-gray-200 group-hover:opacity-100': modeApp !== item.tag}">
                    <img class="w-6 h-6 min-w-6" [src]="modeApp !== item.tag ? item.image_1 : item.image_2" alt="">
                  </div>
                  <span class="w-full text-center mt-1 truncate text-sm font-medium leading-6 group-hover:opacity-100"
                    [ngClass]="{'opacity-50': modeApp !== item.tag , 'text-[color:var(--blue-light)]': modeApp === item.tag}">
                    {{item.text}}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="modeSubMenu === 'notification'">
          <ng-container *ngIf="notificationList.length > 0; else elseTemplateNotiList">
            <card-notification *ngFor="let item of notificationList let i = index"
              [image]="item.fromUser.imageURL ? image(item.fromUser.imageURL) : ''" [content]="(item.title)"
              [time]="setFormatDate(item.createdDate) | dateFormat" [read]="!item.isRead" (event)="openEvents(item,i)"
              [pulse]="true">
            </card-notification>
            <ng-container *ngIf="isLoadNotification">
              <card-notification class="opacity-100" [image]="''" [content]="''" [time]="''" [read]="true">
              </card-notification>
              <card-notification class="opacity-60" [image]="''" [content]="''" [time]="''" [read]="true">
              </card-notification>
              <card-notification class="opacity-20" [image]="''" [content]="''" [time]="''" [read]="true">
              </card-notification>
            </ng-container>
          </ng-container>
          <ng-template #elseTemplateNotiList>
            <ng-container *ngIf="isLoadNotification; else elseTemplateList">
              <card-chat [image]="''" [read]="true" [pulse]="false"
                *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]; let i = index"></card-chat>
            </ng-container>
            <ng-template #elseTemplateList>
              <div class="text-lg font-normal h-full flex items-center justify-center p-4">
                ไม่พบข้อมูล
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </div>
</nav>
<div class="fixed top-0 bottom-0 left-0 right-0 bg-stone-900/[.50] z-[100]" *ngIf="isAcitonMenu || isAcitonSubMenu"
  (click)="clickBackDrop()">
</div>