import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { CustomTable } from "../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../models/TableModel";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { ConfigAdminFacade } from "../../../../services/services";
import { AutocompleteChips } from "../../../shares/AutocompleteChips.component";


const PAGE_NAME: string = 'config';

@Component({
  selector: 'config-page',
  templateUrl: './ConfigPage.component.html'
})

export class ConfigPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private configAdminFacade: ConfigAdminFacade,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._getConfigAdminInfo(null, null, true, null, this._setNotConfig());
    this._getConfigAdminInfo(0, this.limitPage, false, { 'createdDate': -1 }, this._setNotConfig());
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'ชื่อค่าระบบ',
        tag: 'name',
        type: 'input'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  private _setNotConfig() {
    return {
      'name': {
        '$nin': ['after.mails', 'before.mails', 'after.users.id', 'before.users.id', 'utt.time', 'cmp.time', 'ass.time']
      }
    }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const whereConditions = this.getFilterWhereCondition();
    this._getConfigAdminInfo(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    if (!whereConditions['name']) {
      whereConditions['name'] = {};
    }

    whereConditions['name']['$nin'] = this._setNotConfig().name.$nin;
    return whereConditions;
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const whereConditions: any = this.getFilterWhereCondition();

    // search table
    this._getConfigAdminInfo(null, null, true, { 'createdDate': -1 }, whereConditions);
    this._getConfigAdminInfo(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event
      }
    });
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  private _getConfigAdminInfo(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any) {
    let val = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      whereConditions
    };

    this.configAdminFacade.searchData(val).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'name', header: 'ชื่อค่าระบบ', tag: 'text', display: true, cell: (element: any) => `${element.name ? element.name : '-'}` },
            { columnDef: 'type', header: 'ประเภทค่าระบบ', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.type ? this.getConfigTypeLabel(element.type) : '-'}` },
            { columnDef: 'value', header: 'ค่าระบบ', align: 'left', tag: 'text', display: true, cell: (element: any) => `${element.value ? element.value : '-'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }

  public clickActionTableDataTemplate() {
    let fileName = 'download.csv';
    let col: any[] = [];
    for (const columns of this.columns) {
      if (columns.display === true) {
        col.push(columns);
      }
    }

    let header = col.map(x => x.columnDef).join(',');
    let csv = header.replace('name,', '');
    csv += '\r\n';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });


    let link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private getConfigTypeLabel(type: string) {
    if (type === 'string') {
      return 'ข้อความ';
    } else if (type === 'boolean') {
      return 'ปิด/เปิด';
    } else if (type === 'number') {
      return 'ตัวเลข';
    } else if (type === 'date' || type === 'notification') {
      return 'เวลา';
    }

    return '-';
  }
}
