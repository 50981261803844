import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { PipeReadMore } from "../../../shares/pipes/PipeReadMore.pipe";
import { CustomTable } from "../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../models/TableModel";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { SmsMessageFacade } from "../../../../services/services";
import { environment } from "../../../../../environments/environment";
import { AutocompleteChips } from "../../../shares/AutocompleteChips.component";

const PAGE_NAME: string = 'sms';

@Component({
  selector: 'sms-message-page',
  templateUrl: './SmsMessagePage.component.html'
})

export class SmsMessagePage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;

  public environmentApi = environment;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private smsMessageFacade: SmsMessageFacade,
    private readMorePipe: PipeReadMore
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._getSmsMessage(null, null, true);
    this._getSmsMessage(0, this.limitPage, false, { 'createdDate': -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'รหัสโครงการ',
        tag: 'projectId',
        type: 'project_autocomp'
      },
      {
        name: 'ข้อความ',
        tag: 'text',
        type: 'input'
      },
      {
        name: 'สถานะ',
        tag: 'open',
        type: 'select',
        list: [
          {
            label: 'เผยแพร่',
            value: 'true'
          },
          {
            label: 'ไม่เผยแพร่',
            value: 'falue'
          }
        ]
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const whereConditions: any = this.getFilterWhereCondition();
    this._getSmsMessage(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    let regex: any;
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }

      if (filterResult['open']) {
        regex = filterResult[key] === 'true' ? true : false;
      } else {
        regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      }

      whereConditions[key] = regex;
    }

    return whereConditions;
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const whereConditions: any = this.getFilterWhereCondition();

    // search table
    this._getSmsMessage(null, null, true, { 'createdDate': -1 }, whereConditions);
    this._getSmsMessage(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        type: 'sms',
        index: event.index,
        value: event.event
      }
    });
  }

  private _getSmsMessage(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any) {
    let val = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      whereConditions
    };

    this.smsMessageFacade.searchData(val).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'text', header: 'ข้อความ', tag: 'text', display: true, cell: (element: any) => `${element.text ? this.readMorePipe.transform(element.text, 200) : '-'}` },
            { columnDef: 'projectId', header: 'รหัสโครงการ', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.projectId ? element.projectId : '-'}` },
            {
              columnDef: 'open', header: 'สถานะ', tag: 'text-color', display: true, cell: (element: any) => {
                return {
                  text: `${element.open ? 'เผยแพร่' : 'ไม่เผยแพร่'}`,
                  color: element.open ? '#18f318' : '#ff0000'
                }
              }
            }
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create',
        type: 'sms',
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }
}
