import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { CustomerServiceRequest } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class CustomerServiceFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/customerservice/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public searchData = (request?: CustomerServiceRequest) => this.post<MethodResponseInf<any>>('search', this.getDefaultHeader(), null, request);
    public getCustomerService = (id: string) => this.get<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), null);
    public exportData = (request: { customerServiceDate: Date }) => this.post<MethodResponseInf<any>>('foreman/generatepdf', this.getDefaultHeader(), null, request, 'blob');
    public exportDataById = (id: string) => this.get<MethodResponseInf<any>>(`${(id)}` + '/foreman/generatepdf', this.getDefaultHeader(), null, 'blob');
    public searchForemanData = (id: string, request?: CustomerServiceRequest) => this.post<MethodResponseInf<any>>(`${(id) + '/foreman/post/search'}`, this.getDefaultHeader(), null, request);
}