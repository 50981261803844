import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'card-gallery',
    templateUrl: './CardGallery.component.html',
})

export class CardGallery implements OnInit {
    @Input()
    public image: any;
    @Output()
    public eventClickImage: EventEmitter<any> = new EventEmitter();


    constructor() {
    }

    public ngOnInit() {

    }

    public checkTypeof(data: any) {
        if (typeof data === 'string') {
            return false;
        } else {
            return true;
        }
    }

    public clickEventImage(index: number, event: any) {
        let val = {
            index: index,
            data: event
        };
        this.eventClickImage.emit(val);
    }
}