<div
    class="autocomplete-chips-custom flex-none relative w-full h-14 border shadow-sm bg-white rounded-md flex items-center overflow-hidden">
    <span class="material-icons select-none flex-none ml-3" [ngClass]="class" (click)="clickOpenAutocomplete()">
        search
    </span>
    <mat-chip-list #chipList class="flex-1 ml-3 h-full flex items-center overflow-x-auto touch-pan-x"
        [class.mr-3]="values.length === data.length">
        <mat-chip class="flex-none text-sm font-normal py-[18px] px-3" [selectable]="true" [selected]="false"
            *ngFor="let value of values; let i = index" (click)="clickChipList(value);" (removed)="removeType(value)"
            [matTooltip]="value.name +': '+ getResultLabel(value)" [matTooltipPosition]="'below'"
            [matTooltipClass]="'text-sm max-w-[600px]'">
            <p class="truncate max-w-[200px]">{{value.name}}: {{getResultLabel(value)}}</p>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <div class="group h-full flex items-center sticky right-0 flex-none grow bg-white"
            (click)="clickOpenAutocomplete()" *ngIf="values.length < data.length">
            <mat-chip matRipple
                class="flex-none text-sm font-normal py-[18px] px-4 mr-2 bg-white opacity-100 cursor-pointer border border-solid border-transparent hover:text-blue-400 hover:border-blue-400 group-hover:text-blue-400 group-hover:border-blue-400"
                [disabled]="true" [selectable]="true" [selected]="false">
                {{textButtonFilter}}
            </mat-chip>
        </div>
        <input
            class="absolute top-0 left-0 bottom-0 right-0 z-[-1] w-full h-full m-0 p-2 outline-none border-transparent focus:outline-none focus:border-transparent"
            [placeholder]="textButtonFilter" type="text" name="search" #autoInput [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputAddOnBlur]="true"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addType($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="typeSelected($event)">
        <div class="sticky top-0 z-10 bg-white p-3">
            <label class="relative block">
                <span class="sr-only">Search</span>
                <span class="material-icons absolute inset-y-0 left-0 flex items-center pl-2 select-none">
                    search
                </span>
                <input
                    class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    #filterInput [formControl]="autoCtrl" [placeholder]="placeholderSearchFilter" type="text"
                    name="search" autocomplete="off" />
            </label>
        </div>
        <mat-option class="text-base font-normal" *ngFor="let value of filteredValues | async" [value]="value">
            {{value}}
        </mat-option>
    </mat-autocomplete>
</div>