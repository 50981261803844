<div class="card-gallery">
    <div matRipple class="aspect-[11/12] cursor-pointer hover:opacity-80" *ngIf="!checkTypeof(image)">
        <img [src]=" image" class="w-full h-full object-cover" alt="">
    </div>
    <div class="grid gap-2" [ngClass]="image.length >= 4 ? 'grid-cols-3' : 'grid-cols-2'" *ngIf="checkTypeof(image)">
        <ng-container *ngFor=" let item of image | slice:0:4; let i=index">
            <div matRipple class="relative cursor-pointer hover:opacity-80"
                [ngClass]="{'aspect-[11/12]': image.length <= 1, 'aspect-video': image.length >= 2 ,'col-span-2': (i === 0 && image.length <= 3) || (i === 1 && image.length === 2) , 'col-span-3': (i === 0 && image.length >= 4)}"
                (click)="clickEventImage(i , item)">
                <img [src]="item" class="w-full h-full object-cover" alt="">
                <div class="absolute top-0 left-0 bottom-0 right-0 bg-black/30 text-white text-2xl font-medium flex items-center justify-center"
                    *ngIf="i === 3 && image.length > 4">
                    + {{image.length - 4}}
                </div>
            </div>
        </ng-container>
    </div>
</div>