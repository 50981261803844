import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { PageFacade } from "../../../services/facade/PageFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { environment } from "../../../../environments/environment";
import { PageAdminFacade } from "../../../services/facade/PageAdminFacade.service";
import { DialogUpload } from "../dialog/DialogUpload.component";
import { UploadFileFacade } from "../../../services/facade/UploadFileFacade.service";
import { Editor, toDoc, toHTML, Toolbar } from "ngx-editor";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-content',
    templateUrl: './SliderContent.component.html',
})
export class SliderContent implements OnInit {
    @Input()
    public data: any[] = [];
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('username', { read: ElementRef, static: false })
    public myusername!: ElementRef;
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public groupPage: UntypedFormGroup;
    public isSubmitted: boolean = false;
    public contentTypes: any[] = [
        {
            lable: 'ข่าว',
            boolean: false
        },
        {
            lable: 'ประกาศ',
            boolean: true
        }
    ];

    public editor: Editor;
    public toolbar: Toolbar = [
        ['bold', 'italic'],
        ['align_left', 'align_center', 'align_right']
    ];
    private defaultVal = { "type": "doc", "content": [{ "type": "paragraph", "attrs": { "align": null } }] };

    public isFormContent: boolean;

    public imageDef = 'https://cdn-icons-png.flaticon.com/512/44/44289.png';

    public pageList: any;

    public environmentApi = environment;

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private formBuilder: UntypedFormBuilder,
        private pageAdminFacade: PageAdminFacade,
        private pageFacade: PageFacade,
        private uploadFileFacade: UploadFileFacade,
        private checkTokenService: CheckTokenService,
    ) {
        this.formPage();
    }

    public ngOnInit(): void {
        this.editor = new Editor();
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.isFormContent = false;
                this.clickOpen();
                this._getPage();
                if (this.dataDetail.mode === 'edit') {
                    this.getPage(this.dataDetail.value);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {

                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
        this.editor?.destroy();
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
        // setTimeout(() => {
        //     if (this.detailRight && this.detailRight.nativeElement) {
        //         this.topRight = this.detailRight.nativeElement.offsetTop;
        //     }
        // }, 100);
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    public formPage() {
        this.groupPage = this.formBuilder.group({
            type: [false, [Validators.required]],
            startDate: ['', [Validators.required, Validators.minLength(3)]],
            startDateTime: ['00:00', [Validators.required, Validators.minLength(3)]],
            endDate: [''],
            endDateTime: ['00:00'],
            isEndDate: [false],
            page: [''],
            coverImage: ['', [Validators.required]],
            title: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]],
            isHeadlinePattern: [true],
            story: ['', [Validators.required, Validators.minLength(1)]],
        });
    }

    private _setTimeout() {
        return {
            startHours: ((new Date(this.dataJob.startDateTime).getHours() < 10 ? '0' : '') + new Date(this.dataJob.startDateTime).getHours()).toString(),
            startMinutes: ((new Date(this.dataJob.startDateTime).getMinutes() < 10 ? '0' : '') + new Date(this.dataJob.startDateTime).getMinutes()).toString(),
            endHours: ((new Date(this.dataJob.endDateTime).getHours() < 10 ? '0' : '') + new Date(this.dataJob.endDateTime).getHours()).toString(),
            endMinutes: ((new Date(this.dataJob.endDateTime).getMinutes() < 10 ? '0' : '') + new Date(this.dataJob.endDateTime).getMinutes()).toString()
        }
    }

    public getPage(data: any) {
        this.dataJob = data;
        this.groupPage.get('type')?.setValue(this.dataJob.pinned);
        this.groupPage.get('startDate')?.setValue(this.dataJob.startDateTime);
        this.groupPage.get('startDateTime')?.setValue(this.dataJob.startDateTime ? (this._setTimeout().startHours + ':' + this._setTimeout().startMinutes) : '00:00');
        this.groupPage.get('endDate')?.setValue(this.dataJob.endDateTime);
        this.groupPage.get('endDateTime')?.setValue(this.dataJob.endDateTime ? (this._setTimeout().endHours + ':' + this._setTimeout().endMinutes) : '00:00');
        this.groupPage.get('isEndDate')?.setValue(this.dataJob.endDateTime ? true : false);
        this.groupPage.get('page')?.setValue(this.dataJob.page.id);
        this.groupPage.get('coverImage')?.setValue(this.dataJob.coverImage);
        this.groupPage.get('title')?.setValue(this.dataJob.title);
        this.groupPage.get('isHeadlinePattern')?.setValue(this.dataJob.isShowTitle);
        this.groupPage.get('story')?.setValue(this.dataJob.story);
        this.clickisEndDate(this.groupPage.get('isEndDate')?.value);
    }

    public checkCoverImage(): string {
        if (this.dataDetail.mode === 'edit' && this.groupPage.get('coverImage')?.value === this.dataJob.coverImage) {
            return this.image(this.dataJob.coverImage);
        }

        return this.groupPage.get('coverImage')?.value;
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.groupPage.get('type')?.value !== this.dataJob.pinned ||
                this.groupPage.get('startDate')?.value !== this.dataJob.startDateTime ||
                this.groupPage.get('startDateTime')?.value !== (this.dataJob.startDateTime ? (this._setTimeout().startHours + ':' + this._setTimeout().startMinutes) : '00:00') ||
                this.groupPage.get('endDate')?.value !== this.dataJob.endDateTime ||
                this.groupPage.get('endDateTime')?.value !== (this.dataJob.endDateTime ? (this._setTimeout().endHours + ':' + this._setTimeout().endMinutes) : '00:00') ||
                this.groupPage.get('isEndDate')?.value !== (this.dataJob.endDateTime ? true : false) ||
                this.groupPage.get('page')?.value !== this.dataJob.page.id ||
                this.groupPage.get('coverImage')?.value !== this.dataJob.coverImage ||
                this.groupPage.get('title')?.value !== this.dataJob.title ||
                this.groupPage.get('isHeadlinePattern')?.value !== this.dataJob.isShowTitle ||
                this._cutImageId(this.groupPage.get('story')?.value) !== this._cutImageId(this.dataJob.story)
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.getPage(this.dataDetail.value);
                        this.isSubmitted = false;
                        this.isFormContent = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupPage.get('type')?.value !== false ||
                this.groupPage.get('startDate')?.value ||
                this.groupPage.get('startDateTime')?.value !== '00:00' ||
                this.groupPage.get('endDate')?.value ||
                this.groupPage.get('endDateTime')?.value !== '00:00' ||
                this.groupPage.get('isEndDate')?.value !== false ||
                this.groupPage.get('page')?.value ||
                this.groupPage.get('coverImage')?.value ||
                this.groupPage.get('title')?.value ||
                this.groupPage.get('isHeadlinePattern')?.value !== true ||
                this.groupPage.get('story')?.value
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.groupPage.reset();
                        this.formPage();
                        this.isSubmitted = false;
                        this.isFormContent = false;
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
        this.isFormContent = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.groupPage.get('type')?.value !== this.dataJob.pinned ||
                this.groupPage.get('startDate')?.value !== this.dataJob.startDateTime ||
                this.groupPage.get('startDateTime')?.value !== (this.dataJob.startDateTime ? (this._setTimeout().startHours + ':' + this._setTimeout().startMinutes) : '00:00') ||
                this.groupPage.get('endDate')?.value !== this.dataJob.endDateTime ||
                this.groupPage.get('endDateTime')?.value !== (this.dataJob.endDateTime ? (this._setTimeout().endHours + ':' + this._setTimeout().endMinutes) : '00:00') ||
                this.groupPage.get('isEndDate')?.value !== (this.dataJob.endDateTime ? true : false) ||
                this.groupPage.get('page')?.value !== this.dataJob.page.id ||
                this.groupPage.get('coverImage')?.value !== this.dataJob.coverImage ||
                this.groupPage.get('title')?.value !== this.dataJob.title ||
                this.groupPage.get('isHeadlinePattern')?.value !== this.dataJob.isShowTitle ||
                this._cutImageId(this.groupPage.get('story')?.value) !== this._cutImageId(this.dataJob.story)
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                        this.isFormContent = false;
                        this.clearFormData();
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupPage.get('type')?.value !== false ||
                this.groupPage.get('startDate')?.value ||
                this.groupPage.get('startDateTime')?.value !== '00:00' ||
                this.groupPage.get('endDate')?.value ||
                this.groupPage.get('endDateTime')?.value !== '00:00' ||
                this.groupPage.get('isEndDate')?.value !== false ||
                this.groupPage.get('page')?.value ||
                this.groupPage.get('coverImage')?.value ||
                this.groupPage.get('title')?.value ||
                this.groupPage.get('isHeadlinePattern')?.value !== true ||
                this.groupPage.get('story')?.value
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                        this.isFormContent = false;
                        this.clearFormData();
                    }
                });

                return;
            }
        }

        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
        this.isFormContent = false;
        this.clearFormData();
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'edit') {
            if (
                this.groupPage.get('type')?.value === this.dataJob.pinned &&
                this.groupPage.get('startDate')?.value === this.dataJob.startDateTime &&
                this.groupPage.get('startDateTime')?.value === (this.dataJob.startDateTime ? (this._setTimeout().startHours + ':' + this._setTimeout().startMinutes) : '00:00') &&
                this.groupPage.get('endDate')?.value === this.dataJob.endDateTime &&
                this.groupPage.get('endDateTime')?.value === (this.dataJob.endDateTime ? (this._setTimeout().endHours + ':' + this._setTimeout().endMinutes) : '00:00') &&
                this.groupPage.get('isEndDate')?.value === (this.dataJob.endDateTime ? true : false) &&
                this.groupPage.get('page')?.value === this.dataJob.page.id &&
                this.groupPage.get('coverImage')?.value === this.dataJob.coverImage &&
                this.groupPage.get('title')?.value === this.dataJob.title &&
                this.groupPage.get('isHeadlinePattern')?.value === this.dataJob.isShowTitle &&
                this.groupPage.get('story')?.value === this._cutImageId(this.dataJob.story)
            ) {
                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (this.groupPage.invalid) {
                return;
            }
        }

        if (this.groupPage.get('isEndDate')?.value === true && !this.groupPage.get('endDate')?.value) {
            return;
        }

        this.isSubmitted = true;
        // start
        const timeStart = this.groupPage.get('startDateTime')?.value.split(':');
        const dateStart = new Date(this.groupPage.get('startDate')?.value);
        dateStart.setHours(timeStart[0], timeStart[1], 0);
        // end
        const timeEnd = this.groupPage.get('endDateTime')?.value.split(':');
        const dateEnd = new Date(this.groupPage.get('endDate')?.value);
        dateEnd.setHours(timeEnd[0], timeEnd[1], 0);
        // page
        const isOfficial = this.pageList.filter((res: any) => res.isOfficial === true);
        let pageId = this.groupPage.get('type')?.value === false ? (isOfficial[0].id || isOfficial[0]._id) : this.groupPage.get('page')?.value
        let val: any = {
            coverImage: this.groupPage.get('coverImage')?.value,
            story: await this._setImageId((this.groupPage.get('story')?.value === 'object' ? toHTML(this.groupPage.get('story')?.value) : this.groupPage.get('story')?.value)),
            isShowTitle: this.groupPage.get('isHeadlinePattern')?.value,
            pinned: this.groupPage.get('type')?.value,
            startDateTime: dateStart,
            endDateTime: ((this.groupPage.get('isEndDate')?.value && this.groupPage.get('endDate')?.value) ? dateEnd : null),
            title: this.groupPage.get('title')?.value,
            detail: ' '
        }

        if (this.dataDetail.mode === 'edit') {
            this.pageAdminFacade.updatePost(pageId, this.dataDetail.value.id || this.dataDetail.value._id, val).then((res: any) => {
                if (res) {
                    this.getPage(res.data);
                    this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    });
                }

            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.getPage(this.dataDetail.value);
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            this.pageAdminFacade.createPost(pageId, val).then((res: any) => {
                if (res) {
                    this.dataJob = res.data;
                    this.createTableRow(this.dataJob, 'create');
                    this.isSubmitted = false;
                    this.isFormContent = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    });
                    this.clearFormData();
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    });
                }
            });
        }
    }

    public clickisEndDate(data: boolean) {
        if (data === false) {
            this.groupPage.get('endDate')?.addValidators([Validators.required, Validators.minLength(3)]);
            this.groupPage.get('endDateTime')?.addValidators([Validators.required, Validators.minLength(3)]);
        } else {
            this.groupPage.get('endDate')?.clearValidators();
            this.groupPage.get('endDateTime')?.clearValidators();
        }

        this.groupPage.get('endDate')?.updateValueAndValidity();
        this.groupPage.get('endDateTime')?.updateValueAndValidity();
    }

    public selectType(data: boolean) {
        if (data === false) {
            this.groupPage.get('page')?.clearValidators();
        } else {
            this.groupPage.get('page')?.addValidators([Validators.required]);
        }

        this.groupPage.get('page')?.updateValueAndValidity();
    }

    public clearFormData(): void {
        this.groupPage.reset();
        this.formPage();
    }

    private _getPage() {
        this.pageFacade.searchData().then((res: any) => {
            if (res) {
                this.pageList = res.data;
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    private _cutImageId(data: string): string {
        const newDiv = document.createElement("div");
        newDiv.id = 'tag_editor';
        newDiv.innerHTML = data;

        Array.from(newDiv.querySelectorAll('img'))?.forEach((e: HTMLElement) => {
            e.removeAttribute('id');
        });

        const newElement = newDiv.innerHTML;
        return newElement;
    }

    private async _setImageId(data: string) {
        const newDiv = document.createElement("div");
        newDiv.id = 'tag_editor';
        newDiv.innerHTML = data;

        await Promise.all(Array.from(newDiv.querySelectorAll('img'))?.map(async (e: HTMLElement) => {
            if (e.getAttribute('src')?.indexOf('data:image') === 0) {
                let base64 = this._valTypeBase64(e.getAttribute('src'));
                let val = {
                    asset: {
                        data: base64.data.split(',')[1],
                        mimeType: base64.mimeType[0],
                        size: base64.size
                    }
                }
                const res: any = await this.uploadFileFacade.uploadData(val);
                e.setAttribute('id', '$$' + res.data.id);
                e.setAttribute('src', this.image('/file/' + res.data.id));
            } else {
                e.setAttribute('id', '$$' + e.getAttribute('src')?.split('/')[5]);
            }
        }));

        const newElement = newDiv.innerHTML;
        return newElement;
    }

    private _valTypeBase64(data: any): any {
        let stringLength = data.length - 'data:image/png;base64,'.length;
        let sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
        let sizeInKb = sizeInBytes / 1000;
        let mimeType = data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/g)

        return {
            data: data,
            mimeType: mimeType,
            size: sizeInKb
        }
    }

    public clickActionTableDataUpload() {
        const val = ['.jpg', '.jpeg'];
        const dialogRef = this.dialog.open(DialogUpload, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                header: 'อัพโหลดรูปภาพ',
                accept: val,
                type: 'image'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result[0].data.size > ((1024 * 1024) * 4)) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'การอัพโหลดล้มเหลว. ขนาดสูงสุดของไฟล์ JPG คือ 4 MB.',
                        cancel: false
                    });
                } else {
                    if (result[0].data.type === 'image/jpeg') {
                        this.groupPage.get('coverImage')?.setValue(result[0].base64);
                    } else {
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: 'รองรับ' + val + 'เท่านั้น',
                            cancel: false
                        });
                    }
                }
            }
        });
    }

    public onFileChange(file: any) {
        let page = toDoc(this.groupPage.get('story')?.value) ? toDoc(this.groupPage.get('story')?.value) : this.defaultVal;
        let fileMap = Object.keys(file).map((key: any) => file[key]);
        for (const imageList of fileMap) {
            if (imageList.type.indexOf('image') !== 0 && imageList.size > ((1024 * 1024) * 4)) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'การอัพโหลดล้มเหลว. ขนาดสูงสุดของไฟล์ JPG คือ 4 MB.',
                    cancel: false
                });

                return;
            }

            let reader = new FileReader();
            reader.readAsDataURL(imageList);
            reader.onload = () => {
                let list = {
                    type: "heading",
                    attrs: {
                        level: 3,
                        align: null
                    },
                    content: [
                        {
                            type: "image",
                            attrs: {
                                src: reader.result?.toString(),
                                width: "100%",
                            }
                        }
                    ]
                }

                page['content'].push(list);
                this.groupPage.get('story')?.setValue(page);
            };

            reader.onerror = function (error) {
                console.log(error);
            };
        }
    }

    public image(data: string): string {
        if (data !== '') {
            return (this.environmentApi.apiBaseURL + data + "/image").toString();
        } else {
            return this.imageDef;
        }
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            const isOfficial = this.pageList.filter((res: any) => res.isOfficial === true);
            let pageId = this.groupPage.get('type')?.value === false ? (isOfficial[0].id || isOfficial[0]._id) : this.groupPage.get('page')?.value
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.title + '"'
            }).then((res: any) => {
                if (res) {
                    this.pageAdminFacade.deletePost(pageId, this.dataDetail.value.id || this.dataDetail.value._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.clearFormData();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupPage.controls;
    }
}

