import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigModel } from '../../models/ConfigModel';
import { MethodResponseInf } from '../../interface/interface';
import { SearchFilter, ConfigRequest } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class ConfigAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/config/');
    }

    public createData = (request: ConfigRequest) => this.post<MethodResponseInf<ConfigModel>>('', this.getDefaultHeader(), null, request);
    public updateData = (id: string, request: any) => this.put<MethodResponseInf<ConfigModel>>(`${(id)}`, this.getDefaultHeader(), null, request);
    public deleteData = (id: string) => this.delete<MethodResponseInf>(`${(id)}`, this.getDefaultHeader(), null);
    public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<any[]>>(`search`, this.getDefaultHeader(), null, request);
    public getData = (id: string, request?: SearchFilter) => this.get<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), request);
}