<div class="chat-page h-screen flex flex-col" (window:resize)="onResize($event)">
    <div class="h-[75px] px-6 flex items-center border-b flex-none">
        <div class="text-xl font-bold flex-1">
            {{menu && menu.text}}
        </div>
    </div>
    <div class="flex-1 p-6 flex overflow-hidden" [class.gap-5]="isShowing">
        <div class="flex-1 flex flex-col gap-5">
            <autocomplete-chips #searchText [data]="autoCompList" (eventChip)="clickChip($event);"
                (filterConfirm)="filterTextComfirm($event);" (closeChip)="closeChip($event);"></autocomplete-chips>
            <div class="flex-1 card-detail flex overflow-hidden">
                <div class="flex-none w-[266px] xl:w-[306px] 2xl:w-[346px] flex flex-col">
                    <div class="flex-none flex items-center px-4 h-[65px]">
                        <button mat-button [matMenuTriggerFor]="filterChat" #trigger="matMenuTrigger"
                            class="bg-[color:var(--bg-F2)] pr-2">
                            <div class="flex items-center justify-between">
                                {{selected.text}}
                                <span class="material-icons-outlined ml-2">
                                    {{trigger.menuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}
                                </span>
                            </div>
                        </button>
                        <mat-menu #filterChat="matMenu">
                            <button mat-menu-item *ngFor="let item of filterChatList"
                                (click)="selected.tag !== item.tag ? clickSelect(item) : ''">
                                <span class="text-base font-normal">{{item.text}}</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div #listchat class="flex-1 overflow-x-hidden border-y overflow-y-auto" infinite-scroll
                        [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
                        [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
                        (scrolled)="onScrollDownChatList()" [class.overflow-y-hidden]="chatList.length === 0">
                        <ng-container *ngIf="chatList.length > 0; else elseTemplateChatList">
                            <ng-container *ngFor="let item of chatList; let i = index">
                                <ng-container *ngIf="selected.tag === 'all'">
                                    <card-chat
                                        [image]="item.user.imageURL ? (environmentApi.apiBaseURL + item.user.imageURL + '/image') : ''"
                                        [name]="(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' ' + item.user.lastName : '') : '') || (item.user.lastName ? (item.user.firstName ? item.user.firstName + ' ' : '') + item.user.lastName : '') || (item.user.firstName && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName : (item.user.displayName ? item.user.displayName : '-'))"
                                        [content]="item.chatMessage ? (item.chatMessage.message ? (item.chatMessage.senderType === 'ADMIN' ? 'คุณ: ' : '') + item.chatMessage.message : 'ยังไม่มีการพูดคุย...' || item.chatMessage.filePath ? (item.chatMessage.senderType === 'ADMIN' ? 'คุณส่ง: ' : '') + 'รูปภาพ' : 'ยังไม่มีการพูดคุย...') : 'ยังไม่มีการพูดคุย...'"
                                        [time]="item.chatMessage ? (setFormatDate(item.chatMessage.createdDate) | dateFormat) : ''"
                                        [tagged]="[]" [active]="rla.isActive ? true : false"
                                        [read]="checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath))"
                                        [pulse]="true" [routerLink]="item.id" routerLinkActive #rla="routerLinkActive"
                                        (click)="paramsId !== item.id || checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath)) ? clickChatList() : null">
                                    </card-chat>
                                </ng-container>
                                <ng-container
                                    *ngIf="selected.tag === 'read' && !checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath))">
                                    <card-chat
                                        [image]="item.user.imageURL ? (environmentApi.apiBaseURL + item.user.imageURL + '/image') : ''"
                                        [name]="(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' ' + item.user.lastName : '') : '') || (item.user.lastName ? (item.user.firstName ? item.user.firstName + ' ' : '') + item.user.lastName : '') || (item.user.firstName && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName : (item.user.displayName ? item.user.displayName : '-'))"
                                        [content]="item.chatMessage ? (item.chatMessage.message ? (item.chatMessage.senderType === 'ADMIN' ? 'คุณ: ' : '') + item.chatMessage.message : 'ยังไม่มีการพูดคุย...' || item.chatMessage.filePath ? (item.chatMessage.senderType === 'ADMIN' ? 'คุณส่ง: ' : '') + 'รูปภาพ' : 'ยังไม่มีการพูดคุย...') : 'ยังไม่มีการพูดคุย...'"
                                        [time]="item.chatMessage ? (setFormatDate(item.chatMessage.createdDate) | dateFormat) : ''"
                                        [tagged]="[]" [active]="rla.isActive ? true : false"
                                        [read]="checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath))"
                                        [pulse]="true" [routerLink]="item.id" routerLinkActive #rla="routerLinkActive"
                                        (click)="paramsId !== item.id || checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath)) ? clickChatList() : null">
                                    </card-chat>
                                </ng-container>
                                <ng-container
                                    *ngIf="selected.tag === 'not_read' && checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath))">
                                    <card-chat
                                        [image]="item.user.imageURL ? (environmentApi.apiBaseURL + item.user.imageURL + '/image') : ''"
                                        [name]="(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' ' + item.user.lastName : '') : '') || (item.user.lastName ? (item.user.firstName ? item.user.firstName + ' ' : '') + item.user.lastName : '') || (item.user.firstName && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName : (item.user.displayName ? item.user.displayName : '-'))"
                                        [content]="item.chatMessage ? (item.chatMessage.message ? (item.chatMessage.senderType === 'ADMIN' ? 'คุณ: ' : '') + item.chatMessage.message : 'ยังไม่มีการพูดคุย...' || item.chatMessage.filePath ? (item.chatMessage.senderType === 'ADMIN' ? 'คุณส่ง: ' : '') + 'รูปภาพ' : 'ยังไม่มีการพูดคุย...') : 'ยังไม่มีการพูดคุย...'"
                                        [time]="item.chatMessage ? (setFormatDate(item.chatMessage.createdDate) | dateFormat) : ''"
                                        [tagged]="[]" [active]="rla.isActive ? true : false"
                                        [read]="checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath))"
                                        [pulse]="true" [routerLink]="item.id" routerLinkActive #rla="routerLinkActive"
                                        (click)="paramsId !== item.id || checkReaders(item.chatMessage && item.chatMessage.readers) && (item.chatMessage && (item.chatMessage.message || item.chatMessage.filePath)) ? clickChatList() : null">
                                    </card-chat>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="isLoadchatList">
                                <card-chat class="opacity-100" [image]="''" [content]="''" [read]="true"
                                    [pulse]="false">
                                </card-chat>
                                <card-chat class="opacity-60" [image]="''" [content]="''" [read]="true" [pulse]="false">
                                </card-chat>
                                <card-chat class="opacity-20" [image]="''" [content]="''" [read]="true" [pulse]="false">
                                </card-chat>
                            </ng-container>
                        </ng-container>
                        <ng-template #elseTemplateChatList>
                            <ng-container *ngIf="isLoadchatList; else elseTemplateList">
                                <card-chat [image]="''" [read]="true" [pulse]="false"
                                    *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]; let i = index"></card-chat>
                            </ng-container>
                            <ng-template #elseTemplateList>
                                <div class="text-lg font-normal h-full flex items-center justify-center p-4">
                                    ไม่พบข้อมูล
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                    <div class="flex-none h-[60px]">

                    </div>
                </div>
                <div class="flex-1 border-l flex flex-col" [ngClass]="{'items-center justify-center': !isRoomInvalid}">
                    <ng-container *ngIf="isRoomInvalid; else elseTemplateisRoomInvalid">
                        <div class="flex-none flex items-center px-4 h-[65px]">
                            <div class="flex-1 flex items-center">
                                <ng-container *ngFor="let item of chatList; let i = index">
                                    <ng-container *ngIf="paramsId === item.id">
                                        <ng-container *ngIf="item.user.imageURL; else elseTemplate1">
                                            <img loading="eager" class="flex-none h-10 w-10 object-cover rounded-full"
                                                [src]="item.user.imageURL ? (environmentApi.apiBaseURL + item.user.imageURL + '/image') : ''"
                                                alt="" />
                                        </ng-container>
                                        <ng-template #elseTemplate1>
                                            <div class="flex-none h-10 w-10 object-cover rounded-full bg-gray-300">
                                            </div>
                                        </ng-template>

                                        <div class="flex-1 ml-3">
                                            <div class="text-base font-normal line-clamp-1">
                                                {{(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' '
                                                +
                                                item.user.lastName : '') : '') || (item.user.lastName ?
                                                (item.user.firstName
                                                ?
                                                item.user.firstName + ' ' : '') + item.user.lastName : '') ||
                                                (item.user.firstName
                                                && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName :
                                                (item.user.displayName ? item.user.displayName : '-'))}}
                                            </div>
                                            <div class="text-xs font-normal line-clamp-2 mt-1 text-gray-400">
                                                บ้านเลขที่ {{item.realesateUnit.no ? item.realesateUnit.no : '-'}}
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <button mat-icon-button class="flex-none icon-ripper-center"
                                (click)="drawer.toggle(); clickSliderInfo();">
                                <mat-icon class="material-icons-outlined">info</mat-icon>
                            </button>
                        </div>
                        <div class="flex-1 relative overflow-hidden flex flex-col items-center"
                            (click)="paramsId ? checkIsRead(paramsId) : ''">
                            <mat-spinner [diameter]="34" class="flex-none absolute top-2" *ngIf="isLoadchatRoom">
                            </mat-spinner>
                            <div #scrollRoom
                                class="flex-1 w-full h-full border-t overflow-x-hidden overflow-y-auto flex flex-col"
                                infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
                                [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
                                (scrolledUp)="onScrollDownChatRoom()" (scroll)="onScroll($event)"
                                [class.overflow-y-hidden]="chatRoom.length <= 0">
                                <ng-container *ngFor="let item of chatRoom; let i = index">
                                    <ng-container
                                        *ngIf="item.chatMessage.senderType === 'USER'; else elseTemplatesenderType">
                                        <div class="dox-chat-messenger flex max-w-[90%] xl:max-w-[85%] p-4 mr-auto">
                                            <ng-container *ngIf="item.senderImage; else elseTemplateUser">
                                                <img loading="eager" class="flex-none h-8 w-8 object-cover rounded-full"
                                                    [src]="item.senderImage ? (environmentApi.apiBaseURL + item.senderImage + '/image') : ''"
                                                    alt="" />
                                            </ng-container>
                                            <ng-template #elseTemplateUser>
                                                <div class="flex-none h-8 w-8 object-cover rounded-full bg-gray-300">
                                                </div>
                                            </ng-template>
                                            <div class="relative ml-5 flex items-end justify-start left-box">
                                                <div class="bg-[color:var(--bg-F5)] p-3 shadow rounded-lg text-sm font-normal  min-h-[46px] flex items-center"
                                                    [ngClass]="{'max-w-[45%] min-w-[320px] cursor-pointer': item.chatMessage.filePath}"
                                                    (click)="item.chatMessage.filePath ? clickDetailExamine(null, null, environmentApi.apiBaseURL + item.chatMessage.filePath + '/image', false) : ''">
                                                    <ng-container
                                                        *ngIf="item.chatMessage.filePath; else elseTemplateImageLeft">
                                                        <img loading="eager" class="w-full"
                                                            [src]="item.chatMessage.filePath ? (environmentApi.apiBaseURL + item.chatMessage.filePath + '/image') : ''"
                                                            alt="">
                                                    </ng-container>
                                                    <ng-template #elseTemplateImageLeft>
                                                        {{item.chatMessage.message}}
                                                    </ng-template>
                                                </div>
                                                <span class="flex-none ml-3 text-sm font-normal text-gray-400">
                                                    {{setFormatDate(item.chatMessage.createdDate) | dateFormat}}
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplatesenderType>
                                        <div class="dox-chat-messenger max-w-[90%] xl:max-w-[85%] p-4 ml-auto">
                                            <div class="flex flex-col items-end mr-5">
                                                <div class="relative flex items-end justify-end right-box">
                                                    <div
                                                        class="flex-none mr-3 text-sm font-normal text-gray-400 flex flex-col items-end justify-center gap-1">
                                                        <p
                                                            *ngIf="!checkReaderSenderType(item.chatMessage.readers, 'user')">
                                                            อ่านแล้ว</p>
                                                        <p>{{setFormatDate(item.chatMessage.createdDate) | dateFormat}}
                                                        </p>
                                                    </div>
                                                    <div class="bg-[color:var(--bg-box-chat-messenger)] text-white p-3 shadow rounded-lg text-sm font-normal min-h-[46px] flex items-center"
                                                        [ngClass]="{'max-w-[45%] min-w-[320px] cursor-pointer': item.chatMessage.filePath}"
                                                        (click)="item.chatMessage.filePath ? clickDetailExamine(null, null, environmentApi.apiBaseURL + item.chatMessage.filePath + '/image', false) : ''">
                                                        <ng-container
                                                            *ngIf="item.chatMessage.filePath; else elseTemplateImageRight">
                                                            <img loading="eager" class="w-full"
                                                                [src]="item.chatMessage.filePath ? (environmentApi.apiBaseURL + item.chatMessage.filePath + '/image') : ''"
                                                                alt="">
                                                        </ng-container>
                                                        <ng-template #elseTemplateImageRight>
                                                            {{item.chatMessage.message}}
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="mt-2 inline-block text-xs font-normal text-gray-400">โดย
                                                    <span
                                                        class="text-[color:var(--bg-box-chat-messenger)]">{{item.chatMessage.senderName
                                                        ? item.chatMessage.senderName :
                                                        item.chatMessage.senderType}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <div class="h-0" id="chat-bottom"></div>
                            </div>
                            <button mat-mini-fab
                                class="flex-none absolute bottom-2 bg-white text-[color:var(--blue-light)] border icon-ripper-center"
                                (click)="scrollChatRoomToBottom()" *ngIf="isScrollLast">
                                <mat-icon>south</mat-icon>
                            </button>
                        </div>
                        <div class="flex-none p-4 flex items-center" (click)="paramsId ? checkIsRead(paramsId) : ''">
                            <button mat-icon-button class="bg-white h-12 w-12 p-3 mr-2" (click)="clickAddImage()">
                                <img loading="eager" class="w-full h-full image-filter-blue"
                                    src="../../../../assets/images/photo_add.svg" alt="">
                            </button>
                            <div class="flex-1 relative flex items-center">
                                <label for="boxinput"
                                    class="h-8 w-8 object-cover rounded-full overflow-hidden absolute left-2">
                                    <ng-container *ngFor="let item of chatList; let i = index">
                                        <ng-container *ngIf="paramsId === item.id">
                                            <ng-container
                                                *ngIf="item.realesateUnit.page.coverURL; else elseTemplate1Mess">
                                                <img loading="eager" class="w-full h-full"
                                                    [src]="item.realesateUnit.page.coverURL ? (environmentApi.apiBaseURL + item.realesateUnit.page.coverURL + '/image') : ''"
                                                    alt="" />
                                            </ng-container>
                                            <ng-template #elseTemplate1Mess>
                                                <div class="w-full h-full bg-gray-300"></div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </label>
                                <input #input type="text" id="boxinput" placeholder="พิมพ์ข้อความ..." autocomplete="off"
                                    class="w-full input-custom mt-0 pl-12" [formControl]="myMessage"
                                    (keyup.enter)="myMessage.value && myMessage.invalid ? clikSendMess(myMessage.value , '') : ''">
                                <button mat-stroked-button class="bg-red-cdg button-cdg ml-3"
                                    [ngClass]="{'pointer-events-none opacity-50': !myMessage.value || !myMessage.invalid}"
                                    (click)="myMessage.value && myMessage.invalid ? clikSendMess(myMessage.value , '') : ''">
                                    ส่ง
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateisRoomInvalid>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:svgjs="http://svgjs.com/svgjs" width="256" height="256" x="0" y="0"
                            viewBox="0 0 30.743 30.744" xml:space="preserve" class="w-1/3 h-1/3">
                            <g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M28.585,9.67h-0.842v9.255c0,1.441-0.839,2.744-2.521,2.744H8.743v0.44c0,1.274,1.449,2.56,2.937,2.56h12.599l4.82,2.834   L28.4,24.669h0.185c1.487,0,2.158-1.283,2.158-2.56V11.867C30.743,10.593,30.072,9.67,28.585,9.67z"
                                        fill="#f2f2f2" class=""></path>
                                    <path
                                        d="M22.762,3.24H3.622C1.938,3.24,0,4.736,0,6.178v11.6c0,1.328,1.642,2.287,3.217,2.435l-1.025,3.891L8.76,20.24h14.002   c1.684,0,3.238-1.021,3.238-2.462V8.393V6.178C26,4.736,24.445,3.24,22.762,3.24z M6.542,13.032c-0.955,0-1.729-0.774-1.729-1.729   s0.774-1.729,1.729-1.729c0.954,0,1.729,0.774,1.729,1.729S7.496,13.032,6.542,13.032z M13,13.032   c-0.955,0-1.729-0.774-1.729-1.729S12.045,9.574,13,9.574s1.729,0.774,1.729,1.729S13.955,13.032,13,13.032z M19.459,13.032   c-0.955,0-1.73-0.774-1.73-1.729s0.775-1.729,1.73-1.729c0.953,0,1.729,0.774,1.729,1.729S20.412,13.032,19.459,13.032z"
                                        fill="#f2f2f2" class=""></path>
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                            </g>
                        </svg>
                    </ng-template>
                </div>
            </div>
        </div>
        <mat-sidenav-container [hasBackdrop]="isInfo ? true : false" class="bg-transparent"
            [ngClass]="{'static': isInfo , 'w-[326px]' : isShowing}">
            <mat-sidenav #drawer autoFocus="false" [mode]="isInfo ? 'over' : 'side'" [position]="'end'"
                [fixedInViewport]="isInfo ? true : false"
                class="w-[326px] lg:w-full overflow-y-visible lg:bg-transparent border-l-0">
                <button mat-mini-fab
                    class="absolute top-2/4 -left-5 z-[1] bg-white text-[color:var(--blue-light)] border icon-ripper-center"
                    *ngIf="isInfo" (click)="drawer.toggle(); isShowing = false; clickCloseSliderInfo();">
                    <mat-icon>east</mat-icon>
                </button>
                <div class="flex flex-col">
                    <div class="card-detail rounded-t-none lg:rounded-t-md rounded-b-none sticky top-0 z-[1]">
                        <div class="flex items-center min-h-[80px] border-b p-4">
                            <div class="flex-1">
                                <span class="text-lg font-bold">
                                    โน๊ต
                                </span>
                            </div>
                            <div class="text-base font-normal text-[color:var(--blue-light)] cursor-pointer hover:underline select-none ml-3 flex-none"
                                (click)="isCreate = true;">
                                +เพิ่มโน๊ต
                            </div>
                        </div>
                        <div class="px-4 pt-4 pb-2 min-h-[56px]" *ngIf="isCreate">
                            <textarea
                                class="leading-normal text-base font-normal px-4 py-2 w-full border rounded-lg resize-none min-h-[100px]"
                                placeholder="ช่องสำหรับใส่โน๊ต..." [formControl]="myNote"></textarea>
                            <div class="my-1.5 grid grid-cols-2 gap-2">
                                <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancelCreateNote()">
                                    ยกเลิก
                                </button>
                                <button mat-button class="bg-bule-light button-cdg" (click)="clickCreateNote()">
                                    สร้าง
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-detail rounded-t-none rounded-b-none lg:rounded-b-md">
                        <ul class="divide-y border-b">
                            <ng-container [formGroup]="groupNote">
                                <ng-container formArrayName="items" *ngFor="let item of noteItems; let i = index;">
                                    <li class="px-4 pt-4 pb-2 min-h-[56px]" [formGroupName]="i">
                                        <ng-container *ngIf="item.get('isEdit').value === false; else elseTemplateEdit">
                                            <div class="text-base font-normal mb-1.5 pt-1 pb-4 min-h-[80px] break-all"
                                                [ngClass]="{'flex items-center': item.get('message').value.length <= 90}">
                                                {{(item.get('message').value.length > 90) ?
                                                ((!item.get('isReadMore').value) ? ((item.get('message').value |
                                                slice:0:90) + '...') : item.get('message').value) :
                                                item.get('message').value}}
                                                <span
                                                    class="ml-1 font-medium cursor-pointer hover:underline select-none "
                                                    *ngIf="item.get('message').value.length > 90"
                                                    (click)="item.get('isReadMore').value = !item.get('isReadMore').value">
                                                    แสดง{{!item.get('isReadMore').value ? 'เพิ่มเติม' : 'น้อยลง'}}
                                                </span>
                                            </div>
                                            <div class="flex items-center mt-2 pt-2 border-t">
                                                <div class="flex-1 mr-2">
                                                    <div class="text-sm font-normal opacity-30">
                                                        {{item.get('createdDate').value}}
                                                    </div>
                                                    <div class="text-sm font-normal opacity-50">
                                                        โดย {{item.get('createUser').value}}
                                                    </div>
                                                </div>
                                                <div class="flex-none">
                                                    <button mat-icon-button
                                                        class="group icon-ripper-center w-9 h-9 leading-9"
                                                        (click)="item.get('isEdit').setValue(true);">
                                                        <mat-icon class="text-xl opacity-50 group-hover:opacity-100">
                                                            edit
                                                        </mat-icon>
                                                    </button>
                                                    <button mat-icon-button
                                                        class="group icon-ripper-center w-9 h-9 leading-9"
                                                        (click)="clickDeleteNote(i)">
                                                        <mat-icon class="text-xl opacity-50 group-hover:opacity-100">
                                                            delete
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplateEdit>
                                            <textarea
                                                class="leading-normal text-base font-normal px-4 py-2 w-full border rounded-lg resize-none min-h-[100px]"
                                                placeholder="ช่องสำหรับใส่โน๊ต..." formControlName="message"></textarea>
                                            <div class="my-1.5 grid grid-cols-2 gap-2">
                                                <button mat-button class="bg-gray-100 button-cdg"
                                                    (click)="clickEditCancel(i)">
                                                    ยกเลิก
                                                </button>
                                                <button mat-button class="bg-bule-light button-cdg"
                                                    (click)="clickEditNote(i)">
                                                    บันทึก
                                                </button>
                                            </div>
                                        </ng-template>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </mat-sidenav>
        </mat-sidenav-container>
    </div>
</div>