<div class="h-screen flex flex-col">
    <div class="h-[75px] px-6 flex items-center border-b flex-none">
        <div class="text-xl font-bold flex-1">
            {{menu && menu.text}}
        </div>
    </div>
    <div class="flex-1 p-6 flex flex-col overflow-hidden">
        <autocomplete-chips #searchText [data]="autoCompList" (eventChip)="clickChip($event);"
            (filterConfirm)="filterTextComfirm($event);" (closeChip)="closeChip($event);"></autocomplete-chips>
        <div class="flex-1 mt-6 border shadow-sm bg-white rounded-md flex flex-col overflow-hidden">
            <div class="h-[64px] flex-none flex items-center justify-between px-6">
                <div class="flex-none flex items-center">
                    <button mat-stroked-button class="bg-red-cdg button-cdg" (click)="clickCreate()">
                        สร้าง
                    </button>
                </div>
                <div class="flex-none">
                    <button mat-button class="button-action leading-none" [matMenuTriggerFor]="beforeMenu">
                        <span class="material-icons-outlined">
                            more_vert
                        </span>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="mat-menu-item-submenu-trigger flex items-center"
                            [matMenuTriggerFor]="ColoumTable">
                            <mat-icon>add</mat-icon>
                            <span class="text-base font-normal">ตั้งค่าการแสดงตาราง</span>
                        </button>
                    </mat-menu>
                    <mat-menu #ColoumTable="matMenu">
                        <ng-container *ngFor="let item of columns; let i = index">
                            <button mat-menu-item class="mat-menu-item-submenu-trigger flex items-center px-0"
                                (click)="$event.stopPropagation(); clickDisplayTable(i)">
                                <mat-checkbox class="custom-checkbox" [checked]="true">
                                    <span class="text-base font-normal">{{item.header}}</span>
                                </mat-checkbox>
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
            </div>
            <custom-table #table class="flex-1 flex flex-col overflow-hidden border-t" [columns]="columns" [data]="data"
                [length]="lengthDataTable" [tag]="''" [pageSize]="limitTable" [isCheckBoxAll]="false"
                [isCheckBox]="false" [isShowFirstLastButtons]="true" [isHidePageSize]="true"
                (event)="clickTableRow($event)" (eventPaginator)="clickPaginator($event)">
            </custom-table>
        </div>
    </div>
    <slider-assessment [hasBackdrop]="true" [mode]="'over'" [position]="'end'" [fixedInViewport]="true">
    </slider-assessment>
</div>