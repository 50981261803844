import { SocketIoManager } from './../../services/services';
import { SocketIoFacade } from './facade';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenManager } from '../services';
import { MessageInf, TypingDataInf, ReadedInf } from '../../interface/interface';

/**
 * @author plzdontcry19
 */

@Injectable()
export class ChatFacade extends SocketIoFacade {

    constructor(http: HttpClient,
        authenManager: AuthenManager,
        socketIoManager: SocketIoManager) {
        super(http, authenManager, socketIoManager, '/chat/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    // public create = (request: Pick<ChatRoom, 'type' | 'realesateUnitId' | 'typeId'>) => this.post<MethodResponseInf<ChatRoom>>('', this.getDefaultHeader(), null, request);

    // subscript chat 
    public subChat() {
        return this.listen<MessageInf>('message');
    }

    // subscript typing
    public subTyping() {
        return this.listen<TypingDataInf>('typing');
    }

    // subscript readed
    public subReaded() {
        return this.listen<ReadedInf>('readed');
    }

    // emit chat message
    public sendChat<MessageInf>(data: Partial<MessageInf> | MessageInf, isVolatile: boolean = false): void {

        if (isVolatile) {
            this.volatileEmit('message', data);
        } else {
            this.emit('message', data);
        }
    }

    // emit type
    public typing<TypingDataInf>(data: Partial<TypingDataInf> | TypingDataInf, isVolatile: boolean = false): void {

        if (isVolatile) {
            this.volatileEmit('typing', data);
        } else {
            this.emit('typing', data);
        }
    }

    // emit readed
    public readed<ReadedInf>(data: Partial<ReadedInf> | ReadedInf, isVolatile: boolean = false): void {

        if (isVolatile) {
            this.volatileEmit('readed', data);
        } else {
            this.emit('readed', data);
        }
    }
}
