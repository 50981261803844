import { SelectionModel } from "@angular/cdk/collections";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { TableColumnModel } from "../../../models/TableModel";
import { ObservableManager } from "../../../services/ObservableManager.service";

@Component({
    selector: 'custom-table',
    templateUrl: './CustomTable.component.html',
})
export class CustomTable implements OnInit {
    @Input()
    public columns: TableColumnModel[] = [];
    @Input()
    public data: any[] = [];
    @Input()
    public pagination: number[] = [];
    @Input()
    public length: number;
    @Input()
    public tag: string;
    @Input()
    public pageSize: number = 100;
    @Input()
    public isShowFirstLastButtons: boolean = false;
    @Input()
    public isHidePageSize: boolean = false;
    @Input()
    public isCheckBoxAll: boolean = false;
    @Input()
    public isCheckBox: boolean = false;
    @Input()
    public textNoData: string = 'ไม่พบข้อมูล';

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];

    public selection = new SelectionModel<[]>(true, []);

    public isLoadingResults = true;

    @ViewChild(MatPaginator, { static: false })
    public paginator: MatPaginator;
    @ViewChild(MatSort, { static: false })
    public sort: MatSort;
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @Output()
    public event: EventEmitter<any> = new EventEmitter();
    @Output()
    public eventPaginator: EventEmitter<any> = new EventEmitter();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private observManager: ObservableManager
    ) {

    }

    public ngOnInit(): void {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.data) {
            if (changes["data"]) {
                this.dataSource = new MatTableDataSource(this.data);
                this.dataSource.sort = this.sort;
                // this.dataSource.paginator = this.paginator;
                if (this.columns) {
                    this.displayedColumns = [...this.columns.map(c => c.columnDef)];
                    if (this.isCheckBox) this.displayedColumns = ['select', ...this.displayedColumns];
                    if (this.wrapper && this.wrapper.nativeElement) this.wrapper.nativeElement.scrollTop = 0;
                }
            }
            if (this.data.length >= 0) {
                setTimeout(() => {
                    this.isLoadingResults = false;
                }, 1000);
            }
        }
    }
    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    public clickEvent(index: number, tag: any, event: any) {
        this.event.emit({ index, tag, event });
    }

    public clickPaginator(event: any) {
        this.isLoadingResults = true;
        this.eventPaginator.emit(event);
    }

    public editRow(value: any) {
        this.dataSource.data[value.index] = value.value;
        this.dataSource.sort = this.sort;
    }

    public createRow(value: any, length?: number) {
        // this.dataSource.data.push(value.data.value);
        this.dataSource.data.unshift(value);
        this.dataSource.sort = this.sort;
        this.length += length ? length : 1;
    }

    public deleteRow(index: number) {
        this.dataSource.data.splice(index, 1);
        this.dataSource.sort = this.sort;
        this.length -= 1;
    }

    public resetTable() {
        this.dataSource.data = [];
        this.displayedColumns = [];
        this.dataSource.sort = this.sort;
    }
}