import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { MethodResponseInf } from '../../interface/interface';
import { methodType } from '../../type/type';
import { AuthenManager, SocketIoManager } from '../services';
import { AbstractBaseFacade } from './AbstractBaseFacade';

/**
 * @author plzdontcry19
 */

export class MethodFacade extends AbstractBaseFacade<MethodResponseInf<any>, any> {

    constructor(http: HttpClient, authMgr: AuthenManager, subPath: string, timeout?: number, private socketIoManager?: SocketIoManager) {
        super(http, authMgr, subPath, timeout);
    }

    protected async get<RES, HEADER = any, QUERY_PARAM = any>
        (path: string = '', header: HEADER, queryParam?: QUERY_PARAM, responseType?: any): Promise<RES> {
        return await this.request(this._setConfig('GET', path, header, queryParam, null, responseType));
    }

    protected async post<RES, HEADER = any, QUERY_PARAM = any>
        (path: string = '', header: HEADER, queryParam?: QUERY_PARAM, body: any = {}, responseType?: any): Promise<RES> {
        return await this.request(this._setConfig('POST', path, header, queryParam, body, responseType));
    }

    protected async put<RES, HEADER = any, QUERY_PARAM = any>
        (path: string = '', header?: HEADER, queryParam?: QUERY_PARAM, body: any = {}, responseType?: any): Promise<RES> {
        return await this.request(this._setConfig('PUT', path, header, queryParam, body, responseType));
    }

    protected async delete<RES, HEADER = any, QUERY_PARAM = any>
        (path: string = '', header?: HEADER, queryParam?: QUERY_PARAM, responseType?: any): Promise<RES> {
        return await this.request(this._setConfig('DELETE', path, header, queryParam, null, responseType));
    }

    protected handleApiError(error: HttpErrorResponse): any {
        if (error.status === 401) {
            this.authMgr.clearStorage();
            this.socketIoManager?.disconnect();
            this.authMgr.dialogSystem('', 'เกิดข้อผิดพลาดบางอย่าง', false
            ).then((res: any) => {
                if (res) {

                }
            });
        }

        return null;
    }

    protected handleAnyError(error: any): any {
        return null;
    }
    private _setConfig(method: methodType, path: string, header?: any, queryParam?: any, body: any = {}, responseType?: any): HttpRequest<any> {
        const config = new HttpRequest(method, this.routPath + this.subPath + path, body, {
            headers: header,
            reportProgress: true,
            params: queryParam,
            responseType: responseType ? responseType : 'json'
        })
        return config;
    }
}
