import { Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { environment } from "../../../../../environments/environment";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { RepairTypeFacade } from "../../../../services/facade/RepairTypeFacade.service";
import { PageFacade } from "src/app/services/facade/PageFacade.service";
import { RepairStatusAdminFacade } from "src/app/services/facade/RepairStatusAdminFacade.service";
import { ReportRepairAdminFacade } from "src/app/services/facade/ReportRepairAdminFacade.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatTableDataSource } from "@angular/material/table";
import { animate, state, style, transition, trigger } from "@angular/animations";
import * as moment from 'moment';
import { AuthenManager } from "src/app/services/AuthenManager.service";

const PAGE_NAME: string = 'repair';
const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'report-repair-page',
  templateUrl: './ReportRepairPage.component.html',
  animations: [
    trigger('heightGrow', [
      state('closed', style({
        height: '45%',
      })),
      state('open', style({
        height: '100vh'
      })),
      transition('* => *', animate(300))
    ]),
    trigger('hide', [
      state('closed', style({
        display: 'unset',
      })),
      state('open', style({
        display: 'none',
      })),
      transition('* => *', animate(300))
    ]),
    trigger('arrow', [
      state('closed', style({
        rotate: '0deg',
      })),
      state('open', style({
        rotate: '-180deg',
      })),
      transition('* => *', animate(300))
    ]),
  ]
})

export class ReportRepairPage implements OnInit {
  clickSelect(_t11: any, arg1: string) {
    throw new Error('Method not implemented.');
  }
  public static readonly PAGE_NAME: string = PAGE_NAME;

  private destroy = new Subject<void>();

  @Input() dataTable: any[];

  displayedColumns: string[] = [
    'no',
    'pageName',
    'userOwnerName',
    'repairName',
    'repairUnitNo',
    'repairType',
    'repairStatus',
    'description',
    'createdDate',
    'userDisplayName',
    'walkinDateTime',
    'repairDateTime',
    'repairForemanDateTime',
    'repairStatusHasDate',
    'bookingDateTime',
    // 'slaDay',
    'successRepairForemanDateTime',
    'foremanRepairCustomerDay',
    // 'successRepairDay',
    'sinceCreatetoSuccessDay',
    'foremanRepairAllDay',
    'questionnaireAvg',
    'repairForemanPostUser',
    'approvedDisplayName',
    'approvedDateTime',
    'totalRepair'
  ];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  // Page
  public dataPage: any[] = [];
  public filteredListPage: any[] = [];

  // Type
  public dataType: any[] = [];
  public filteredType: any[] = [];

  // Status
  public dataStatus: any[] = [];
  public filteredListStatus: any[] = [];

  public isGuarantee = false;
  public isNotGuarantee = false;

  public menu: any;
  public state = "closed";

  public environmentApi = environment;
  public groupRepair: any;

  public repairType: any;

  public isAllSelectedStatus = false;
  public isAllSelectedPage = false;
  public isAllSelectedType = false;

  constructor(
    private router: Router,
    private observManager: ObservableManager,
    private repairTypeFacade: RepairTypeFacade,
    private pageFacade: PageFacade,
    private formBuilder: UntypedFormBuilder,
    private repairStatusAdminFacade: RepairStatusAdminFacade,
    private reportRepairAdminFacade: ReportRepairAdminFacade,
    private authenManager: AuthenManager
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });

    this.formRepair();
    this._getPage();
    this._getStatusList();
    this._searchRepairType();
  }

  public async ngOnInit(): Promise<void> {
  }

  public transformData(jsonData: any[]): any[] {
    return jsonData.map(data => {
      if (data.type === 'D') {
        return {
          header: {
            no: '',
            pageName: '',
            userOwnerName: '',
            totalRepair: ''
          },
          repairName: data.repair?.name,
          repairUnitNo: data.repair?.realesateUnit?.no,
          repairType: data.repair?.repairType?.name,
          repairStatus: data.repair?.repairStatus?.name,
          description: data.repair?.description,
          createdDate: data.repair?.createdDate,
          userDisplayName: data.repair?.user?.displayName,
          walkinDateTime: data.repair?.walkinDateTime,
          bookingDateTime: data.repair?.bookingDateTime,
          repairDateTime: data.repair?.repairDateTime,
          repairStatusHasDate: data.repair?.repairStatusHasDate?.createdDate,
          // slaDay: data.slaDay,
          repairForemanDateTime: data.repair?.repairForemanDateTime,
          successRepairForemanDateTime: data.repair?.successRepairForemanDateTime,
          foremanRepairCustomerDay: data.foremanRepairCustomerDay,
          // successRepairDay: data.successRepairDay,
          sinceCreatetoSuccessDay: data.sinceCreatetoSuccessDay,
          foremanRepairAllDay: data.foremanRepairAllDay,
          questionnaireAvg: this._setQuestionnaireAvg(data.repair?.questionnaireAvg),
          repairForemanPostUser: this._setForemanFullName(data.repair?.repairForemanPostUser),
          approvedDisplayName: data.repair?.approvedDisplayName,
          approvedDateTime: data.repair?.approvedDateTime,
          totalSumRepair: data.totalSumRepair
        };
      } else if (data.type === 'H') {
        return {
          header: {
            no: data?.no,
            pageName: data?.pageName,
            userOwnerName: data?.userOwnerName,
            totalRepair: data?.totalRepair
          },
          repairName: '',
          repairUnitNo: '',
          repairType: '',
          repairStatus: '',
          description: '',
          createdDate: '',
          userDisplayName: '',
          walkinDateTime: '',
          bookingDateTime: '',
          repairDateTime: '',
          repairStatusHasDate: '',
          // slaDay: '',
          repairForemanDateTime: '',
          successRepairForemanDateTime: '',
          foremanRepairCustomerDay: '',
          // successRepairDay: '',
          sinceCreatetoSuccessDay: '',
          foremanRepairAllDay: '',
          questionnaireAvg: '',
          repairForemanPostUser: '',
          approvedDisplayName: '',
          approvedDateTime: '',
          totalSumRepair: ''
        };
      } else {
        return null;
      }
    }).filter(Boolean);
  }

  private _setQuestionnaireAvg(data: number) {
    if (!data) return '';
    return data.toFixed(2);
  }

  private _setForemanFullName(data: any) {
    if (!data || !Array.isArray(data)) {
      return '';
    }
    return data.map((item: any) => item.displayName).join(',');
  }

  public getTotalSumRepair() {
    return this.dataSource.data[this.dataSource.data.length - 1]?.totalSumRepair || 0;
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
  }

  public formRepair() {
    this.groupRepair = this.formBuilder.group({
      page: ['', [Validators.required, Validators.minLength(1)]],
      status: [''],
      type: [''],
      foremanFullName: [''],
      adminFullName: [''],
      userFullName: [''],
      no: [''],
      startWalkinDateTime: [''],
      endWalkinDateTime: [''],
      startRepairDateTime: [''],
      endRepairDateTime: [''],
      startApproveDateTime: [''],
      endApproveDateTime: [''],
      startDateTime: [''],
      endDateTime: [''],
      isOverGuarantee: false,
    });
  }

  private _getPage() {
    this.pageFacade.searchData().then((res: any) => {
      if (res) {
        this.dataPage = res.data;
        this.filteredListPage = this.dataPage.slice();
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  private async _searchRepairType(): Promise<any> {
    this.repairTypeFacade.searchData().then((res: any) => {
      if (res) {
        this.repairType = res.data;
        this._setRepairType(this.repairType);
      }
    });
  }

  private _setRepairType(data: any) {
    let result: any[] = [];

    data.forEach((item: any) => {
      const existing = result.find(entry => entry.name === item.name);
      if (existing) {
        existing.id.push(item.id);
      } else {
        result.push({ name: item.name, id: [item.id] });
      }
    });

    this.dataType = result;
    this.filteredType = this.dataType.slice();
  }

  private _getStatusList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.repairStatusAdminFacade.searchData().then((res: any) => {
        if (res && res.data) {
          const groupedData = res.data.reduce((acc: { [x: string]: any[] }, item: { name: string; _id: any }) => {
            if (!acc[item.name]) {
              acc[item.name] = [];
            }
            acc[item.name].push(item._id);
            return acc;
          }, {});

          const transformedData = Object.keys(groupedData).map(key => ({
            name: key,
            id: groupedData[key]
          }));

          this.dataStatus = transformedData;
          this.filteredListStatus = this.dataStatus.slice();
        }
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public createTable() {
    let data: any = {};
    const adjustDateTime = (dateTimeString: string, type: 'start' | 'end'): string => {
      if (!dateTimeString) {
        return '';
      }
      let date = moment(dateTimeString);
      if (type === 'start') {
        date = date.startOf('day');
      } else if (type === 'end') {
        date = date.endOf('day');
      }
      date = date.add(7, 'hours');
      return date.toISOString();
    };

    const addIfExists = (key: string, value: any, type: 'start' | 'end' | null = null) => {
      if (value !== null && value !== undefined && value !== '') {
        if (type) {
          data[key] = adjustDateTime(value, type);
        } else {
          data[key] = value;
        }
      }
    };

    addIfExists('pageIds', this.groupRepair.value.page);
    addIfExists('repairStatusIds', this._setStatus(this.groupRepair.value.status));
    addIfExists('repairTypeIds', this._setType(this.groupRepair.value.type));
    addIfExists('foremanFullName', this.groupRepair.value.foremanFullName);
    addIfExists('adminFullName', this.groupRepair.value.adminFullName);
    addIfExists('userFullName', this.groupRepair.value.userFullName);
    addIfExists('no', this.groupRepair.value.no);
    addIfExists('startWalkinDateTime', this.groupRepair.value.startWalkinDateTime, 'start');
    addIfExists('endWalkinDateTime', this.groupRepair.value.endWalkinDateTime, 'end');
    addIfExists('startRepairDateTime', this.groupRepair.value.startRepairDateTime, 'start');
    addIfExists('endRepairDateTime', this.groupRepair.value.endRepairDateTime, 'end');
    addIfExists('startApproveDateTime', this.groupRepair.value.startApproveDateTime, 'start');
    addIfExists('endApproveDateTime', this.groupRepair.value.endApproveDateTime, 'end');
    addIfExists('startDateTime', this.groupRepair.value.startDateTime, 'start');
    addIfExists('endDateTime', this.groupRepair.value.endDateTime, 'end');

    if (this.isGuarantee || this.isNotGuarantee) {
      addIfExists('isOverGuarantee', this.isGuarantee ? false : (this.isNotGuarantee ? true : null));
    }

    this.reportRepairAdminFacade.searchData(data).then((res: any) => {
      if (res) {
        this.dataTable = res.data;
        this.dataSource.data = this.transformData(this.dataTable);
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }


  private _setType(data: any) {
    if (!Array.isArray(data)) {
      return [];
    }
    const flatArray = data.flat();
    return flatArray;
  }

  private _setStatus(data: any) {
    if (!Array.isArray(data)) {
      return [];
    }
    const flatArray = data.flat();
    return flatArray;
  }

  public checkGuarantee(value: boolean, event: MatCheckboxChange) {
    if (value) {
      this.isGuarantee = event.checked;
      if (event.checked) {
        this.isNotGuarantee = false;
      }
    } else {
      this.isNotGuarantee = event.checked;
      if (event.checked) {
        this.isGuarantee = false;
      }
    }
  }

  public changeState(): void {
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
  }

  public clickActionTableDataDownload() {
    // Map the data excluding totalSumRepair
    const dataToExport = this.dataSource.data.map(item => ({
      'บ้านเลขที่': item.header?.no || '',
      'โครงการ': item.header?.pageName || '',
      'ชื่อเจ้าของบ้าน': item.header?.userOwnerName || '',
      'รหัส': item.repairName || '',
      'บ้านเลขที่ ': item.repairUnitNo || '',
      'ประเภทงาน': item.repairType || '',
      'สถานะงาน': item.repairStatus || '',
      'รายละเอียด': item.description || '',
      'ว/ด/ป ที่ลูกค้าแจ้ง': this.dateAddHours(item.createdDate, true) || '',
      'ชื่อผู้แจ้ง': item.userDisplayName || '',
      'นัดประเมิน': this.dateAddHours(item.walkinDateTime, true) || '',
      'นัดเข้าซ่อม': this.dateAddHours(item.repairDateTime, true) || '',
      'วันเข้าซ่อมจริง': this.dateAddHours(item.repairForemanDateTime, true) || '',
      'ว/ด/ป ที่เสร็จงาน': this.dateAddHours(item.repairStatusHasDate, true) || '',
      'วันที่ลูกค้าสะดวกให้เข้าซ่อม': this.dateAddHours(item.bookingDateTime, true) || '',
      // 'ระยะเวลาซ่อม (SLA)': item.slaDay || '',
      'เสร็จงานจริง (FM)': this.dateAddHours(item.successRepairForemanDateTime, true) || '',
      // 'M-O เข้าซ่อมจริง-ลูกค้าสะดวก (รวมวันนัดคิว)': item.foremanRepairDay || '',
      'M-O เข้าซ่อมจริง-ลูกค้าสะดวก (รวมวันนัดคิว)': item.foremanRepairCustomerDay || '',
      // 'ผลสำเร็จของงาน (ใน 30 วัน)': item.successRepairDay || '',
      'P-I ซ่อมเรียบร้อย-แจ้งซ่อม (รวมตั้งแต่แจ้งซ่อมถึงเสร็จ)': item.sinceCreatetoSuccessDay || '',
      'M-P เข้าซ่อมจริง-ซ่อมเรียบร้อย (รวมวันซ่อม)': item.foremanRepairAllDay || '',
      'ผลประเมินความพึงพอใจ': item.questionnaireAvg || '',
      'ชื่อช่าง': item.repairForemanPostUser || '',
      'ชื่อ admin ที่ approve/reject': item.approvedDisplayName || '',
      'วันที่ approve/reject': this.dateAddHours(item.approvedDateTime, true) || '',
      'จำนวนงานทั้งหมด': item.header?.totalRepair || ''
    }));

    // Find the row with totalSumRepair and append it as the last row
    const totalSumRepairRow = this.dataSource.data.find(item => !!item.totalSumRepair);
    if (totalSumRepairRow) {
      dataToExport.push({
        'บ้านเลขที่': '',
        'โครงการ': '',
        'ชื่อเจ้าของบ้าน': '',
        'รหัส': '',
        'บ้านเลขที่ ': '',
        'ประเภทงาน': '',
        'สถานะงาน': '',
        'รายละเอียด': '',
        'ว/ด/ป ที่ลูกค้าแจ้ง': '',
        'ชื่อผู้แจ้ง': '',
        'นัดประเมิน': '',
        'นัดเข้าซ่อม': '',
        'วันเข้าซ่อมจริง': '',
        'ว/ด/ป ที่เสร็จงาน': '',
        'วันที่ลูกค้าสะดวกให้เข้าซ่อม': '',
        // 'ระยะเวลาซ่อม (SLA)': '',
        'เสร็จงานจริง (FM)': '',
        'M-O เข้าซ่อมจริง-ลูกค้าสะดวก (รวมวันนัดคิว)': '',
        // 'ผลสำเร็จของงาน (ใน 30 วัน)': '',
        'P-I ซ่อมเรียบร้อย-แจ้งซ่อม (รวมตั้งแต่แจ้งซ่อมถึงเสร็จ)': '',
        'M-P เข้าซ่อมจริง-ซ่อมเรียบร้อย (รวมวันซ่อม)': '',
        'ผลประเมินความพึงพอใจ': '',
        'ชื่อช่าง': '',
        'ชื่อ admin ที่ approve/reject': '',
        'วันที่ approve/reject': 'รวมงานซ่อม',
        'จำนวนงานทั้งหมด': totalSumRepairRow.totalSumRepair || '',
      });
    }

    const currentDate = new Date();
    const formattedDate = this._formatDate(currentDate);
    const csvData = this._convertToCsv(dataToExport);
    const blob = new Blob(["\ufeff" + csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `RepairData-${formattedDate}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private _convertToCsv(data: any[]) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  private _formatDate(date: any) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return `${day}-${month}-${year}`;
  }

  public dateAddHours(dateTime: string, isCreateCsv?: boolean): any {
    if (!dateTime) {
      return '';
    }

    return moment(dateTime).add(7, 'hours').format('D/M/YYYY');
    // if (isCreateCsv) return moment(dateTime).add(7, 'hours').toISOString();
    // if (!isCreateCsv) return moment(dateTime).add(7, 'hours').format('D/M/YYYY');
  }

  public clickReset() {
    this.authenManager.clickDialogAlert({
      header: '',
      content: 'ต้องการที่จะคืนค่า'
    }).then((res: any) => {
      if (res) {
        this.groupRepair.reset();
        this.isGuarantee = false;
        this.isNotGuarantee = false;
        this.dataSource.data = [];
        this.dataTable = [];
        this.state = "closed";
      }
    });
  }

  public toggleSelectAll(type: string) {
    if (type === 'status') {
      this.isAllSelectedStatus = !this.isAllSelectedStatus;
      if (this.isAllSelectedStatus) {
        const allStatusIds = this.filteredListStatus.map(item => item.id);
        this.groupRepair.get('status').setValue(allStatusIds);
      } else {
        this.groupRepair.get('status').setValue([]);
      }
    } else if (type === 'page') {
      this.isAllSelectedPage = !this.isAllSelectedPage;
      if (this.isAllSelectedPage) {
        const allPageIds = this.filteredListPage.map(item => item.id);
        this.groupRepair.get('page').setValue(allPageIds);
      } else {
        this.groupRepair.get('page').setValue([]);
      }
    } else {
      this.isAllSelectedType = !this.isAllSelectedType;
      if (this.isAllSelectedType) {
        const allItemIds = this.filteredType.map(item => item.id);
        this.groupRepair.get('type').setValue(allItemIds);
      } else {
        this.groupRepair.get('type').setValue([]);
      }
    }
  }
}