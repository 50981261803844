import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { QuestionnaireFacade } from "../../../services/facade/QuestionnaireFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-assessment',
    templateUrl: './SliderAssessment.component.html',
})
export class SliderAssessment implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public formGroup: UntypedFormGroup;
    public isSubmitted: boolean = false;

    public dataPage: any[] = [];
    public filteredListPage: any;

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private questionnaireFacade: QuestionnaireFacade,
        private formBuilder: UntypedFormBuilder,
        private checkTokenService: CheckTokenService,
    ) {
        this.formAssessment();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                this.formAssessment();
                if (this.dataDetail.mode === 'edit') {
                    this.setAssessment(this.dataDetail.value);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    this._newItem();
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    private _checkValueQuestionnaires(): any {
        const group = this.formGroup;
        const array = (<UntypedFormArray>group.get('questionnaires'));
        if (this.dataDetail.mode === 'edit') {
            if (
                group.get('name')?.value !== this.dataJob.name ||
                group.get('isActive')?.value !== this.dataJob.isActive ||
                group.get('description')?.value !== this.dataJob.description ||
                group.get('thankyouDescription')?.value !== this.dataJob.thankyouDescription
            ) {
                return false;
            }

            for (const [index, questionnaires] of array.value.entries()) {
                if (array.value.length === this.dataJob!.questionnaires.length) {
                    if (array.at(index).get('name')?.value !== this.dataJob.questionnaires[index].name) {
                        return false;
                    }
                } else {
                    return false;
                }
            }

            return true;
        } else if (this.dataDetail.mode === 'create') {
            if (
                group.get('name')?.value ||
                group.get('isActive')?.value ||
                group.get('description')?.value ||
                group.get('thankyouDescription')?.value
            ) {
                return false;
            }

            for (const [index, questionnaires] of array.value.entries()) {
                if (!!array.at(index).get('name')?.value) {
                    return false;
                }
            }

            return true;
        }
    }

    public clickCancel(): void {
        const group = this.formGroup;
        const array = (<UntypedFormArray>group.get('questionnaires'));
        if (this.dataDetail.mode === 'edit') {
            if (!this._checkValueQuestionnaires()) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.formAssessment();
                        this.setAssessment(this.dataDetail.value);
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (!this._checkValueQuestionnaires()) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.formAssessment();
                        this._newItem();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            if (!this._checkValueQuestionnaires()) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.sidenav.close();
                        this.formAssessment();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (!this._checkValueQuestionnaires()) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.sidenav.close();
                        this.formAssessment();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.sidenav.close();
        this.formAssessment();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    public formAssessment() {
        this.formGroup = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3)]],
            isActive: [false],
            description: [''],
            thankyouDescription: ['', [Validators.required, Validators.minLength(3)]],
            questionnaires: this.formBuilder.array([])
        });
    }

    private _createGroupsArray(): UntypedFormGroup {
        return this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(1)]],
            point: [0, [Validators.required]],
        });
    }

    private _newItem() {
        (<UntypedFormArray>this.formGroup.get('questionnaires')).push(this._createGroupsArray());
    }

    get questionnaires() {
        return (<UntypedFormArray>this.formGroup.get('questionnaires'));
    }

    public clickNewItem($event: Event) {
        this._newItem();
    }

    private setAssessment(data: any) {
        this.dataJob = data;
        const group = this.formGroup;
        const array = (<UntypedFormArray>group.get('questionnaires'));
        group.get('name')?.setValue(this.dataJob.name);
        group.get('isActive')?.setValue(this.dataJob.isActive);
        group.get('description')?.setValue(this.dataJob.description);
        group.get('thankyouDescription')?.setValue(this.dataJob.thankyouDescription);
        if (!!this.dataJob!.questionnaires) {
            for (const questionnaires of this.dataJob!.questionnaires) {
                array.push(this.formBuilder.group({
                    name: questionnaires.name,
                    point: questionnaires.point
                }));
            }
        }
    }

    public clickDeleteItem(index: number) {
        const group = this.formGroup;
        const array = (<UntypedFormArray>group.get('questionnaires'));
        if (array.at(index).get('name')?.value) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบคำถามที่ ' + (index + 1)
            }).then((res: any) => {
                if (res) {
                    array.removeAt(index);
                    if (array.value.length <= 0) this._newItem();
                }
            });

            return;
        }

        array.removeAt(index);
        if (array.value.length <= 0) this._newItem();
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        const group = this.formGroup;
        const array = (<UntypedFormArray>group.get('questionnaires'));
        let val = {
            name: group.get('name')?.value,
            questionnaires: array.value,
            isActive: group.get('isActive')?.value,
            description: group.get('description')?.value,
            thankyouDescription: group.get('thankyouDescription')?.value
        }

        if (this.dataDetail.mode === 'edit') {
            if (this.formGroup.invalid || this._checkValueQuestionnaires()) {
                return;
            }

            this.isSubmitted = true;
            this.questionnaireFacade.updateData(this.dataDetail.value.id || this.dataDetail.value._id, val
            ).then((res: any) => {
                if (res) {
                    this.formAssessment();
                    this.setAssessment(res.data);
                    this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                        }
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            if (this.formGroup.invalid) {
                return;
            }

            this.isSubmitted = true;
            this.questionnaireFacade.createData(val).then((res: any) => {
                if (res) {
                    this.dataJob = res.data;
                    this.createTableRow(this.dataJob, 'create');
                    this.isSubmitted = false;
                    this.formAssessment();
                    this._newItem();
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.name + '"'
            }).then((res: any) => {
                if (res) {
                    this.questionnaireFacade.deleteData(this.dataDetail.value.id || this.dataDetail.value._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.formAssessment();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            }).then((res: any) => {
                                if (res) {

                                }
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    get f(): { [key: string]: AbstractControl } {
        return this.formGroup.controls;
    }

    public drop(event: CdkDragDrop<unknown>) {
        const group = this.formGroup;
        const array = (<UntypedFormArray>group.get('questionnaires'));
        moveItemInArray(array.value, event.previousIndex, event.currentIndex);
        array.setValue(array.value);
    }
}

