import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatRoomModel } from '../../models/ChatRoomModel';
import { SearchFilter } from '../../request/SearchFilterRequest';
import { ChatMessageResInf, MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class NoteFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/note/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public createData = (request: Pick<ChatRoomModel, 'type' | 'realesateUnitId' | 'typeId'>) => this.post<MethodResponseInf<ChatRoomModel>>('', this.getDefaultHeader(), null, request);
    public getList = (offset: number = 0, limit: number = 20) => this.get<MethodResponseInf<ChatRoomModel[]>>('list', this.getDefaultHeader(), `limit=${limit}&offset=${offset}`);
    public getRoomChatMessage = (id: string, request?: SearchFilter) => this.get<MethodResponseInf<ChatMessageResInf[]>>(`${(id)}/message`, this.getDefaultHeader(), `limit=${request?.limit}&offset=${request?.offset}&orderBy=${request?.orderBy}`);
    public updateIsRead = (id: string) => this.put<any>(`${(id)}/read`, this.getDefaultHeader(), null);
    public check_unread = (request: Pick<ChatRoomModel, 'type' | 'realesateUnitId' | 'typeId'>) => this.post<MethodResponseInf<ChatRoomModel>>('', this.getDefaultHeader(), null, request);
}