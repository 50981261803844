<ng-container *ngIf="pulse; else elseTemplateData">
    <div class="card-chat card-detail cursor-pointer flex items-start min-h-[80px] border-b relative px-4 py-3"
        [ngClass]="{'bg-[color:var(--blue-choose)]': active , 'hover:bg-gray-100' : !active}"
        (click)="clickEvent($event)">
        <ng-container *ngIf="image; else elseTemplateImg">
            <img class="h-12 w-12 object-cover mt-1 rounded-full float-none" [src]="image" alt="" />
        </ng-container>
        <ng-template #elseTemplateImg>
            <div class="h-12 w-12 object-cover mt-1 rounded-full bg-gray-300 flex-none"></div>
        </ng-template>
        <div class="ml-3 flex-1">
            <div class="flex items-center">
                <span class="text-base font-normal flex-1 mr-3 line-clamp-1">
                    {{name}}
                </span>
                <span class="text-sm text-gray-400 flex-none" [class.text-[color:var(--blue-light)]]="read">
                    {{time}}
                </span>
            </div>
            <div class="text-xs font-normal line-clamp-2 mt-1 text-gray-400">
                {{content}}
            </div>
            <div class="mt-3 flex items-center flex-wrap gap-2" *ngIf="tagged">
                <ng-container *ngFor="let item of tagged; let i = index">
                    <div
                        class="flex-none flex items-center pl-2 pr-3 py-2 bg-[color:var(--bg-FA)] rounded-md select-none border border-inherit">
                        <span class="flex-none h-3 w-3 rounded-full bg-gray-300 mr-1" [ngClass]="item.color"></span>
                        <span class="flex-none text-xs text-gray-400">{{item.text}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <span class="h-2 w-2 bg-[color:var(--blue-light)] rounded-full overflow-hidden absolute bottom-2 right-2"
            *ngIf="read"></span>
    </div>
</ng-container>
<ng-template #elseTemplateData>
    <div class="card-chat card-detail animate-pulse flex items-center p-4 cursor-pointer hover:bg-gray-100"
        [ngClass]="class">
        <div class="h-12 w-12 object-cover rounded-full bg-gray-300 flex-none"></div>
        <div class="ml-3 overflow-hidden flex-1 space-y-2">
            <p class="col-span-1 h-2 bg-gray-300 rounded mb-4"></p>
            <div class="grid grid-cols-3 gap-4">
                <p class="col-span-2 h-2 bg-gray-300 rounded"></p>
                <p class="col-span-1 h-2 bg-gray-300 rounded"></p>
            </div>
            <p class="col-span-1 h-2 bg-gray-300 rounded"></p>
        </div>
    </div>
</ng-template>