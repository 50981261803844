import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import * as moment from 'moment';
import { ActivateInfoAdminFacade } from "../../../services/facade/ActivateInfoAdminFacade.service";
import { PageFacade } from "../../../services/facade/PageFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { MessageResUtils } from "../../../utils/MessageResUtils";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-house-registration',
    templateUrl: './SliderHouseRegistration.component.html',
})
export class SliderHouseRegistration implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public groupActivateInfo: UntypedFormGroup;
    public dataPage: any[] = [];
    public filteredListPage: any;
    public isSubmitted: boolean = false;

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private pageFacade: PageFacade,
        private activateInfoAdminFacade: ActivateInfoAdminFacade,
        private formBuilder: UntypedFormBuilder,
        private checkTokenService: CheckTokenService,
    ) {
        this.formActivateInfo();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                this.getSelectPrefix();
                if (this.dataDetail.mode === 'edit') {
                    this.getActivateInfo(this.dataDetail.value);
                    setTimeout(() => {
                        this.groupActivateInfo.get('prefix')?.disable();
                        if (this.dataDetail.value.activateInfo) {
                            this.groupActivateInfo.get('absUnitId')?.disable();

                            if (!!this.dataDetail.value.absLeadId) {
                                this.groupActivateInfo.get('absLeadId')?.disable();
                            } else {
                                this.groupActivateInfo.get('absLeadId')?.enable();
                            }

                            if (!!this.dataDetail.value.activateInfo.userId) {
                                this.groupActivateInfo.get('citizenId')?.disable();
                                this.groupActivateInfo.get('mobileNo')?.disable();
                            } else {
                                this.groupActivateInfo.get('citizenId')?.enable();
                                this.groupActivateInfo.get('mobileNo')?.enable();
                            }
                        }
                    }, 10);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.groupActivateInfo.get('prefix')?.enable();
                        this.groupActivateInfo.get('absUnitId')?.enable();
                        this.groupActivateInfo.get('absLeadId')?.enable();
                        this.groupActivateInfo.get('citizenId')?.enable();
                        this.groupActivateInfo.get('mobileNo')?.enable();
                    }, 10);

                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {

    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            let s_start = this.groupActivateInfo.get('guaranteeDate')?.value ? moment(this.groupActivateInfo.get('guaranteeDate')?.value).format('YYYY/MM/DD') : null;
            let s_end = this.dataJob && this.dataJob.guaranteeDate ? moment(this.dataJob.guaranteeDate).format('YYYY/MM/DD') : null;
            let e_start = this.groupActivateInfo.get('guaranteeEndDate')?.value ? moment(this.groupActivateInfo.get('guaranteeEndDate')?.value).format('YYYY/MM/DD') : null;
            let e_end = this.dataJob && this.dataJob.guaranteeEndDate ? moment(this.dataJob.guaranteeEndDate).format('YYYY/MM/DD') : null;
            if (
                this.groupActivateInfo.get('prefix')?.value !== this.dataJob.page.prefix ||
                this.groupActivateInfo.get('absUnitId')?.value !== this.dataJob.absUnitId ||
                this.groupActivateInfo.get('modelName')?.value !== this.dataJob.modelName ||
                this.groupActivateInfo.get('absLeadId')?.value !== this.dataJob.absLeadId ||
                this.groupActivateInfo.get('fullName')?.value !== this.dataJob.activateInfo.fullName ||
                this.groupActivateInfo.get('no')?.value !== this.dataJob.no ||
                this.groupActivateInfo.get('citizenId')?.value !== this.dataJob.activateInfo.citizenId ||
                this.groupActivateInfo.get('mobileNo')?.value !== (this.dataJob.activateInfo.mobileNo ? this.dataJob.activateInfo.mobileNo.replace('-', '') : this.dataJob.activateInfo.mobileNo) ||
                (s_start !== s_end) ||
                (e_start !== e_end)
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.getActivateInfo(this.dataDetail.value);
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupActivateInfo.get('prefix')?.value ||
                this.groupActivateInfo.get('absUnitId')?.value ||
                this.groupActivateInfo.get('modelName')?.value ||
                this.groupActivateInfo.get('absLeadId')?.value ||
                this.groupActivateInfo.get('fullName')?.value ||
                this.groupActivateInfo.get('no')?.value ||
                this.groupActivateInfo.get('citizenId')?.value ||
                this.groupActivateInfo.get('mobileNo')?.value ||
                this.groupActivateInfo.get('guaranteeDate')?.value ||
                this.groupActivateInfo.get('guaranteeEndDate')?.value ||
                this.groupActivateInfo.get('transferOwnershipDate')?.value
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.groupActivateInfo.reset();
                        this.formActivateInfo();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            let s_start = this.groupActivateInfo.get('guaranteeDate')?.value ? moment(this.groupActivateInfo.get('guaranteeDate')?.value).format('YYYY/MM/DD') : null;
            let s_end = this.dataJob && this.dataJob.guaranteeDate ? moment(this.dataJob.guaranteeDate).format('YYYY/MM/DD') : null;
            let e_start = this.groupActivateInfo.get('guaranteeEndDate')?.value ? moment(this.groupActivateInfo.get('guaranteeEndDate')?.value).format('YYYY/MM/DD') : null;
            let e_end = this.dataJob && this.dataJob.guaranteeEndDate ? moment(this.dataJob.guaranteeEndDate).format('YYYY/MM/DD') : null;
            let o_start = this.groupActivateInfo.get('transferOwnershipDate')?.value ? moment(this.groupActivateInfo.get('transferOwnershipDate')?.value).format('YYYY/MM/DD') : null;
            let o_end = this.dataJob && this.dataJob.transferOwnershipDate ? moment(this.dataJob.transferOwnershipDate).format('YYYY/MM/DD') : null;
            if (
                this.groupActivateInfo.get('prefix')?.value !== this.dataJob.page.prefix ||
                this.groupActivateInfo.get('absUnitId')?.value !== this.dataJob.absUnitId ||
                this.groupActivateInfo.get('modelName')?.value !== this.dataJob.modelName ||
                this.groupActivateInfo.get('absLeadId')?.value !== this.dataJob.absLeadId ||
                this.groupActivateInfo.get('fullName')?.value !== this.dataJob.activateInfo.fullName ||
                this.groupActivateInfo.get('no')?.value !== this.dataJob.no ||
                this.groupActivateInfo.get('citizenId')?.value !== this.dataJob.activateInfo.citizenId ||
                this.groupActivateInfo.get('mobileNo')?.value !== (this.dataJob.activateInfo.mobileNo ? this.dataJob.activateInfo.mobileNo.replace('-', '') : this.dataJob.activateInfo.mobileNo) ||
                (s_start !== s_end) ||
                (e_start !== e_end) ||
                (o_start !== o_end)
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.getActivateInfo(this.dataDetail.value);
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.formActivateInfo();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupActivateInfo.get('prefix')?.value ||
                this.groupActivateInfo.get('absUnitId')?.value ||
                this.groupActivateInfo.get('modelName')?.value ||
                this.groupActivateInfo.get('absLeadId')?.value ||
                this.groupActivateInfo.get('fullName')?.value ||
                this.groupActivateInfo.get('no')?.value ||
                this.groupActivateInfo.get('citizenId')?.value ||
                this.groupActivateInfo.get('mobileNo')?.value ||
                this.groupActivateInfo.get('guaranteeDate')?.value ||
                this.groupActivateInfo.get('guaranteeEndDate')?.value ||
                this.groupActivateInfo.get('transferOwnershipDate')?.value
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.groupActivateInfo.reset();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.formActivateInfo();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.groupActivateInfo.reset();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.formActivateInfo();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private getActivateInfo(data: any) {
        this.dataJob = data;
        this.groupActivateInfo.get('prefix')?.setValue(this.dataJob.page.prefix);
        this.groupActivateInfo.get('absUnitId')?.setValue(this.dataJob.absUnitId);
        this.groupActivateInfo.get('modelName')?.setValue(this.dataJob.modelName);
        this.groupActivateInfo.get('absLeadId')?.setValue(this.dataJob.absLeadId);
        this.groupActivateInfo.get('fullName')?.setValue(this.dataJob.activateInfo.fullName);
        this.groupActivateInfo.get('no')?.setValue(this.dataJob.no);
        this.groupActivateInfo.get('citizenId')?.setValue(this.dataJob.activateInfo.citizenId ? this.dataJob.activateInfo.citizenId.toUpperCase() : this.dataJob.activateInfo.citizenId);
        this.groupActivateInfo.get('mobileNo')?.setValue((this.dataJob.activateInfo.mobileNo ? this.dataJob.activateInfo.mobileNo.replace('-', '') : this.dataJob.activateInfo.mobileNo));
        this.groupActivateInfo.get('guaranteeDate')?.setValue(this.dataJob.guaranteeDate ? new Date(moment(this.dataJob.guaranteeDate).format('YYYY/MM/DD')) : '');
        this.groupActivateInfo.get('guaranteeEndDate')?.setValue(this.dataJob.guaranteeEndDate ? new Date(moment(this.dataJob.guaranteeEndDate).format('YYYY/MM/DD')) : '');
        this.groupActivateInfo.get('transferOwnershipDate')?.setValue(this.dataJob.transferOwnershipDate ? new Date(moment(this.dataJob.transferOwnershipDate).format('YYYY/MM/DD')) : '');
    }

    private formActivateInfo() {
        this.groupActivateInfo = this.formBuilder.group({
            prefix: ['', [Validators.required, Validators.pattern('[A-Z]{3}'), Validators.minLength(3), Validators.maxLength(3)]],
            absUnitId: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
            modelName: ['', [Validators.required, Validators.minLength(3)]],
            absLeadId: ['', [Validators.minLength(11), Validators.maxLength(11)]],
            fullName: ['', [Validators.pattern('[a-zA-Z0-9ก-๙ \.(){}t_-]+$'), Validators.minLength(3)]],
            no: ['', [Validators.required, Validators.minLength(1)]],
            citizenId: ['', [Validators.pattern('^[a-zA-Z0-9]+$'), Validators.maxLength(13)]],
            mobileNo: ['', [Validators.pattern('^[0-9]+$'), Validators.minLength(9)]],
            guaranteeDate: [{ value: '', disabled: true }],
            guaranteeEndDate: [{ value: '', disabled: true }],
            transferOwnershipDate: [{ value: '', disabled: true }],
        });
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'edit') {
            if (this.groupActivateInfo.invalid) {
                return;
            }
            // if (this.groupActivateInfo.invalid || !this._checkActivateInfo()) {
            //     return;
            // }

            let s_start = this.groupActivateInfo.get('guaranteeDate')?.value ? moment(this.groupActivateInfo.get('guaranteeDate')?.value).format('YYYY/MM/DD') : null;
            let s_end = this.dataJob && this.dataJob.guaranteeDate ? moment(this.dataJob.guaranteeDate).format('YYYY/MM/DD') : null;
            let e_start = this.groupActivateInfo.get('guaranteeEndDate')?.value ? moment(this.groupActivateInfo.get('guaranteeEndDate')?.value).format('YYYY/MM/DD') : null;
            let e_end = this.dataJob && this.dataJob.guaranteeEndDate ? moment(this.dataJob.guaranteeEndDate).format('YYYY/MM/DD') : null;
            let o_start = this.groupActivateInfo.get('transferOwnershipDate')?.value ? moment(this.groupActivateInfo.get('transferOwnershipDate')?.value).format('YYYY/MM/DD') : null;
            let o_end = this.dataJob && this.dataJob.transferOwnershipDate ? moment(this.dataJob.transferOwnershipDate).format('YYYY/MM/DD') : null;
            if (
                this.groupActivateInfo.get('prefix')?.value === this.dataJob.page.prefix &&
                this.groupActivateInfo.get('absUnitId')?.value === this.dataJob.absUnitId &&
                this.groupActivateInfo.get('modelName')?.value === this.dataJob.modelName &&
                (this.groupActivateInfo.get('absLeadId')?.value === this.dataJob.absLeadId || this.groupActivateInfo.get('absLeadId')?.invalid) &&
                this.groupActivateInfo.get('fullName')?.value === this.dataJob.activateInfo.fullName &&
                this.groupActivateInfo.get('no')?.value === this.dataJob.no &&
                (this.groupActivateInfo.get('citizenId')?.value ? this.groupActivateInfo.get('citizenId')?.value.toUpperCase() : this.groupActivateInfo.get('citizenId')?.value) === (this.dataJob.activateInfo.citizenId ? this.dataJob.activateInfo.citizenId.toUpperCase() : this.dataJob.activateInfo.citizenId) &&
                this.groupActivateInfo.get('mobileNo')?.value === this.dataJob.activateInfo.mobileNo &&
                (s_start === s_end) &&
                (e_start === e_end) &&
                (o_start === o_end)
            ) {
                return;
            }
            this.isSubmitted = true;
            this.activateInfoAdminFacade.updateData({
                prefixPage: String(this.groupActivateInfo.get('prefix')?.value).toUpperCase(),
                realesateUnitId: this.dataDetail.value.id || this.dataDetail.value._id,
                absLeadId: this.groupActivateInfo.get('absLeadId')?.value,
                absUnitId: this.groupActivateInfo.get('absUnitId')?.value,
                no: this.groupActivateInfo.get('no')?.value,
                modelName: this.groupActivateInfo.get('modelName')?.value,
                guaranteeDate: new Date(moment(this.groupActivateInfo.get('guaranteeDate')?.value).format('YYYY-MM-DD')),
                guaranteeEndDate: new Date(moment(this.groupActivateInfo.get('guaranteeEndDate')?.value).format('YYYY-MM-DD')),
                transferOwnershipDate: new Date(moment(this.groupActivateInfo.get('transferOwnershipDate')?.value).format('YYYY-MM-DD')),
                name: this.dataDetail.value.name,
                address: this.dataDetail.value.address,
                activateInfoId: this.dataDetail.value.activateInfo.id || this.dataDetail.value.activateInfo._id,
                citizenId: (this.groupActivateInfo.get('citizenId')?.value ? this.groupActivateInfo.get('citizenId')?.value.toUpperCase() : this.groupActivateInfo.get('citizenId')?.value),
                fullName: this.groupActivateInfo.get('fullName')?.value,
                mobileNo: this.groupActivateInfo.get('mobileNo')?.value,
            }).then((res: any) => {
                if (res) {
                    let val = {
                        absLeadId: res.data.realesateUnit.absLeadId,
                        absUnitId: res.data.realesateUnit.absUnitId,
                        activateInfo: res.data.activateInfo,
                        address: res.data.realesateUnit.address,
                        createdDate: res.data.realesateUnit.createdDate,
                        guaranteeDate: res.data.realesateUnit.guaranteeDate,
                        guaranteeEndDate: res.data.realesateUnit.guaranteeEndDate,
                        transferOwnershipDate: res.data.realesateUnit.transferOwnershipDate,
                        id: res.data.realesateUnit.id,
                        modelName: res.data.realesateUnit.modelName,
                        name: res.data.realesateUnit.name,
                        no: res.data.realesateUnit.no,
                        page: res.data.page
                    }

                    this.getActivateInfo(val);
                    this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.formActivateInfo();
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: MessageResUtils.messageRes(err.error.message),
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                        }
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            if (this.groupActivateInfo.invalid) {
                return;
            }
            // if (this.groupActivateInfo.invalid || !this._checkActivateInfo()) {
            //     return;
            // }

            this.isSubmitted = true;
            this.activateInfoAdminFacade.createData({
                prefixPage: String(this.groupActivateInfo.get('prefix')?.value).toUpperCase(),
                absUnitId: this.groupActivateInfo.get('absUnitId')?.value,
                modelName: this.groupActivateInfo.get('modelName')?.value,
                absLeadId: this.groupActivateInfo.get('absLeadId')?.value,
                fullName: this.groupActivateInfo.get('fullName')?.value,
                no: this.groupActivateInfo.get('no')?.value,
                citizenId: (this.groupActivateInfo.get('citizenId')?.value ? this.groupActivateInfo.get('citizenId')?.value.toUpperCase() : this.groupActivateInfo.get('citizenId')?.value),
                mobileNo: this.groupActivateInfo.get('mobileNo')?.value,
                guaranteeDate: this.groupActivateInfo.get('guaranteeDate')?.value,
                guaranteeEndDate: this.groupActivateInfo.get('guaranteeEndDate')?.value,
                transferOwnershipDate: this.groupActivateInfo.get('transferOwnershipDate')?.value
            }).then((res: any) => {
                if (res) {
                    let val = {
                        absLeadId: res.data.realesateUnit.absLeadId,
                        absUnitId: res.data.realesateUnit.absUnitId,
                        activateInfo: res.data.activateInfo,
                        address: res.data.realesateUnit.address,
                        createdDate: res.data.realesateUnit.createdDate,
                        guaranteeDate: res.data.realesateUnit.guaranteeDate,
                        guaranteeEndDate: res.data.realesateUnit.guaranteeEndDate,
                        transferOwnershipDate: res.data.realesateUnit.transferOwnershipDate,
                        id: res.data.realesateUnit.id,
                        modelName: res.data.realesateUnit.modelName,
                        name: res.data.realesateUnit.name,
                        no: res.data.realesateUnit.no,
                        page: res.data.page
                    }

                    this.dataJob = val;
                    this.createTableRow(this.dataJob, 'create');
                    this.formActivateInfo();
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    console.log(err);
                    this.formActivateInfo();
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: MessageResUtils.messageRes(err.error.message),
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                        }
                    });
                }
            });
        }
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupActivateInfo.controls;
    }

    // private _checkActivateInfo(): boolean {
    //     if (this.groupActivateInfo.get('fullName')?.value &&
    //         (this.groupActivateInfo.get('citizenId')?.value ? this.groupActivateInfo.get('citizenId')?.value.toUpperCase() : this.groupActivateInfo.get('citizenId')?.value) &&
    //         this.groupActivateInfo.get('mobileNo')?.value) {
    //         this.groupActivateInfo.get('fullName')?.removeValidators(Validators.required);
    //         this.groupActivateInfo.get('citizenId')?.removeValidators(Validators.required);
    //         this.groupActivateInfo.get('mobileNo')?.removeValidators(Validators.required);
    //         this.groupActivateInfo.get('fullName')?.updateValueAndValidity();
    //         this.groupActivateInfo.get('citizenId')?.updateValueAndValidity();
    //         this.groupActivateInfo.get('mobileNo')?.updateValueAndValidity();
    //         return true;
    //     } else {
    //         this.groupActivateInfo.get('fullName')?.addValidators(Validators.required);
    //         this.groupActivateInfo.get('citizenId')?.addValidators(Validators.required);
    //         this.groupActivateInfo.get('mobileNo')?.addValidators(Validators.required);
    //         this.groupActivateInfo.get('fullName')?.updateValueAndValidity();
    //         this.groupActivateInfo.get('citizenId')?.updateValueAndValidity();
    //         this.groupActivateInfo.get('mobileNo')?.updateValueAndValidity();
    //         return false;
    //     }
    // }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.absUnitId + '"'
            }).then((res: any) => {
                if (res) {
                    this.activateInfoAdminFacade.deleteData(this.dataDetail.value.activateInfo.id || this.dataDetail.value.activateInfo._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.formActivateInfo();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            }).then((res: any) => {
                                if (res) {

                                }
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.formActivateInfo();
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    private getSelectPrefix() {
        this.pageFacade.searchData({ count: false, orderBy: { "createdDate": -1 } }).then((res: any) => {
            if (res) {
                this.dataPage = res.data;
                this.filteredListPage = this.dataPage.slice();
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    public getProjectData() {
        const project = this.dataPage.find(element => element.prefix === this.groupActivateInfo.get('prefix')?.value);
        if (this.groupActivateInfo.get('prefix')?.value && this.groupActivateInfo.get('guaranteeDate')?.value) {
            this.groupActivateInfo.get('guaranteeEndDate')?.setValue(this.groupActivateInfo.get('guaranteeDate')?.value ? new Date(moment(this.groupActivateInfo.get('guaranteeDate')?.value).add(project.periodGuaranteeDay, 'days').format('YYYY/MM/DD')) : '');
        }
    }

    public selectProject(event: any) {
        this.getProjectData();
    }
}

