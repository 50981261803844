import { Injectable } from '@angular/core';
import {
    HOUSE_PROJECT, REPAIR_TYPE, REPAIR_STATUS, INSURANCE_STATUS, HOUSE_NUMBER,
    REPAIR_NUMBER, REPAIR_PERSON
} from '../../constants/TypeFilters';
@Injectable()
export class TypeFilterListService {

    constructor() { }

    public getTypeFilterList() {
        return [
            HOUSE_PROJECT, REPAIR_TYPE, REPAIR_STATUS, 
            INSURANCE_STATUS, HOUSE_NUMBER,
            REPAIR_NUMBER, REPAIR_PERSON
        ];
    }
}