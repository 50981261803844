import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageModel } from '../../models/PageModel';
import { DownPaymentSearchRequest } from '../../request/DownPaymentSearchRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class DownPaymentFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/down_payment/');
    }

    public searchData = (request?: DownPaymentSearchRequest) => this.post<MethodResponseInf<PageModel>>('search', this.getDefaultHeader(), null, request);
    public getInstallments = (downPaymentId: string) => this.get<MethodResponseInf<any>>(`${(downPaymentId) + '/installment'}`, this.getDefaultHeader(), null);
}