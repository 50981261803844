import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as moment from "moment";
import { filter, Subject, takeUntil } from "rxjs";
import { PipeReadMore } from "../../../shares/pipes/PipeReadMore.pipe";
import { CustomTable } from "../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../models/TableModel";
import { LogsMessageRequest } from "../../../../request/LogsMessageRequest";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { environment } from "../../../../../environments/environment";
import { LogsMessageFacade } from "../../../../services/facade/LogsMessageFacade.service";
import { CheckTokenService } from "../../../../services/CheckToken.service";
import { AutocompleteChips } from "../../../shares/AutocompleteChips.component";

const PAGE_NAME: string = 'logs';

@Component({
  selector: 'logs-message-page',
  templateUrl: './LogsMessagePage.component.html'
})

export class LogsMessagePage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;
  public campaign: UntypedFormGroup;

  private logsMessageRequest = new LogsMessageRequest;
  public environmentApi = environment;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private formBuilder: UntypedFormBuilder,
    private logsMessageFacade: LogsMessageFacade,
    private checkTokenService: CheckTokenService,
    private readMorePipe: PipeReadMore
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._campaign();
    this.logsMessageRequest.startDateTime = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
    this.logsMessageRequest.endDateTime = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
    this._getLogsMessage(null, null, true);
    this._getLogsMessage(0, this.limitPage, false, { 'createdDate': -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'โหมด',
        tag: 'mode',
        type: 'input'
      },
      {
        name: 'ข้อความ',
        tag: 'text',
        type: 'input'
      },
      {
        name: 'รหัสโครงการ',
        tag: 'projectId',
        type: 'project_autocomp'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const whereConditions: any = this.getFilterWhereCondition();
    this._getLogsMessage(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    return whereConditions;
  }

  private _campaign() {
    this.campaign = this.formBuilder.group({
      start: new UntypedFormControl(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))),
      end: new UntypedFormControl(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)))
    });
  }

  public async searchDate() {
    await this.checkTokenService.checkStatus();
    let startDateTime: any = (this.campaign.get('start')?.value ? moment(this.campaign.get('start')?.value).format('YYYY-MM-DD') : new Date('2019-01-16')) + 'T00:00:00.000Z';
    let endDateTime: any = (this.campaign.get('end')?.value ? moment(this.campaign.get('end')?.value).format('YYYY-MM-DD') : (moment((new Date().getFullYear()) + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate())).format('YYYY-MM-DD'))) + 'T23:59:59.000Z';
    this.logsMessageRequest.startDateTime = startDateTime;
    this.logsMessageRequest.endDateTime = endDateTime;
    this.refreshTable();
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const whereConditions: any = this.getFilterWhereCondition();

    // search table
    this._getLogsMessage(null, null, true, { 'createdDate': -1 }, whereConditions);
    this._getLogsMessage(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {

  }

  private _getLogsMessage(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any) {
    this.logsMessageRequest.offset = offset;
    this.logsMessageRequest.limit = limit;
    this.logsMessageRequest.count = count;
    this.logsMessageRequest.orderBy = orderBy;
    this.logsMessageRequest.whereConditions = whereConditions;

    this.logsMessageFacade.searchData(this.logsMessageRequest).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'mode', header: 'โหมด', tag: 'text', display: true, cell: (element: any) => `${element.mode ? element.mode : '-'}` },
            { columnDef: 'message', header: 'ข้อความ', tag: 'text', display: true, cell: (element: any) => `${element.message ? this.readMorePipe.transform(element.message, 200) : '-'}` },
            { columnDef: 'projectId', header: 'รหัสโครงการ', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.projectId ? element.projectId : '-'}` },
            { columnDef: 'sendDateTime', header: 'วัน/เวลาที่ส่ง', tag: 'text', display: true, cell: (element: any) => `${element.sendDateTime ? moment(element.sendDateTime).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
            { columnDef: 'fullName', header: 'ชื่อ-นามสกุล', tag: 'text', display: true, cell: (element: any) => `${element.fullName ? element.fullName : '-'}` },
            { columnDef: 'email', header: 'Email', tag: 'text', display: true, cell: (element: any) => `${element.email ? element.email : '-'}` },
            { columnDef: 'mobileNo', header: 'เบอร์โทร', tag: 'text', display: true, cell: (element: any) => `${element.mobileNo ? element.mobileNo : '-'}` },
            { columnDef: 'status', header: 'สถานะ', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.status ? element.status : '-'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }
}
