import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { ValidateResponse } from '../../response/response';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class ActivateFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/activate/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public validate = (citizenId: string) => this.post<MethodResponseInf<ValidateResponse>>('validate', this.getDefaultHeader(), null, { citizenId });
}