import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';
import { RepairTypeModel } from '../../models/RepairTypeModel';
import { RepairTypeRequest } from '../../request/RepairTypeRequest';

@Injectable()
export class RepairTypeFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/repair_type/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    // public createData = (name: string) => this.post<MethodResponseInf<RepairTypeModel>>('', this.getDefaultHeader(), null, { name });
    // public updateData = (id: string, name: string) => this.put<MethodResponseInf<RepairTypeModel>>(`${(id)}`, this.getDefaultHeader(), null, { name });
    // public deleteData = (id: string) => this.delete<MethodResponseInf<DeleteResponse>>(`${(id)}`, this.getDefaultHeader(), null);
    public searchData = (request?: RepairTypeRequest) => this.post<MethodResponseInf<RepairTypeModel[]>>(`search`, this.getDefaultHeader(), null, request);
}