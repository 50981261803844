import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NoteAdminCreateRequest, NoteAdminEditRequest, NoteAdminRequest } from '../../request/NoteAdminRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class NoteAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/note/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);

    public searchData = (request: NoteAdminRequest) => this.post<MethodResponseInf<any>>('search', this.getDefaultHeader(), null, request);
    public createData = (request: NoteAdminCreateRequest) => this.post<MethodResponseInf<any>>('', this.getDefaultHeader(), null, request);
    public updateData = (id: string, request: NoteAdminEditRequest) => this.put<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), null, request);
    public deleteData = (id: string) => this.delete<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), null);
}