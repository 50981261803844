import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject, timer } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { environment } from "../../../../environments/environment";
import { NotificationFacade } from "../../../services/facade/NotificationFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { ChatFacade } from "../../../services/facade/ChatFacade.service";
import { ReadChatFacade } from "../../../services/facade/ReadChatFacade.service";
import { DialogAlert } from "../../shares/dialog/DialogAlert.component";
import { getMessaging, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { NotificationService } from "../../../services/Notification.service";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { Portal, TemplatePortalDirective } from "@angular/cdk/portal";
import { SocketIoManager } from "../../../services/SocketIoManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import * as moment from "moment";

@Component({
  selector: 'header-page',
  templateUrl: './Header.component.html'
})
export class Header implements OnInit {
  public menuList = [
    {
      image: '../../../../assets/images/menu/menu.svg',
      text: 'แอพ',
      tag: 'app'
    },
  ];

  public menuSetting = [
    {
      image_1: '../../../../assets/images/menu/box.svg',
      image_2: '../../../../assets/images/menu/box-active.svg',
      text: 'แชท',
      tag: 'chat'
    },
    {
      image_1: '../../../../assets/images/menu/bell.svg',
      image_2: '../../../../assets/images/menu/bell-active.svg',
      text: 'แจ้งเตือน',
      tag: 'notification'
    },
    {
      image_1: '../../../../assets/images/menu/setting.svg',
      image_2: '../../../../assets/images/menu/setting-active.svg',
      text: 'ตั้งค่า',
      tag: 'setting',
      url: '/setting',
      list: [
        {
          image: '../../../../assets/images/app/owner.svg',
          text: 'จัดการผู้ใช้',
          tag: 'manage-users'
        },
        {
          image: '../../../../assets/images/app/config.svg',
          text: 'ตั้งค่าระบบ',
          tag: 'config'
        },
      ]
    },
    {
      image_1: '../../../../assets/images/menu/logout.svg',
      image_2: '../../../../assets/images/menu/logout.svg',
      text: 'ออกจากระบบ',
      tag: 'logout'
    },
    {
      image_1: '../../../../assets/images/menu/sliderIn.svg',
      image_2: '../../../../assets/images/menu/sliderOut.svg',
      text: 'เปิด / ปิด',
      tag: 'slider'
    }
  ];

  public app = [
    {
      image_1: '../../../../assets/images/app/homecare.svg',
      image_2: '../../../../assets/images/app/homecare-active.svg',
      text: 'แจ้งซ่อม',
      tag: 'homecare',
    },
    {
      image_1: '../../../../assets/images/app/down-payment.svg',
      image_2: '../../../../assets/images/app/down-payment-active.svg',
      text: 'ผ่อนดาวน์',
      tag: 'down-payment',
    },
    {
      image_1: '../../../../assets/images/app/database.svg',
      image_2: '../../../../assets/images/app/database-active.svg',
      text: 'ฐานข้อมูล',
      tag: 'database',
      list: [
        {
          image: '../../../../assets/images/app/project.svg',
          text: 'โครงการ',
          tag: 'project'
        },
        {
          image: '../../../../assets/images/app/house-registration.svg',
          text: 'ทะเบียนบ้าน',
          tag: 'house-registration'
        },
        {
          image: '../../../../assets/images/app/residents.svg',
          text: 'ผู้อยู่อาศัย',
          tag: 'residents'
        },
        {
          image: '../../../../assets/images/app/fixed.svg',
          text: 'งานซ่อม',
          tag: 'repair',
          list: [
            {
              text: 'ประเภทงานซ่อม',
              tag: 'repair-type'
            },
            {
              text: 'สถานะงานซ่อม',
              tag: 'repair-status'
            },
            {
              text: 'ประเมินงานซ่อม',
              tag: 'assessment'
            },
          ]
        },
        {
          image: '../../../../assets/images/app/customerservice.svg',
          text: 'ร้องเรียน',
          tag: 'customer',
          list: [
            {
              text: 'ประเภทงานร้องเรียน',
              tag: 'service-item'
            },
            {
              text: 'สถานะงานร้องเรียน',
              tag: 'service-status'
            },
            {
              text: 'ประเมินงานร้องเรียน',
              tag: 'assessment'
            },
          ]
        },
        {
          image: '../../../../assets/images/app/manual.svg',
          text: 'คู่มือ',
          tag: 'document-manual'
        },
      ]
    },
    {
      image_1: '../../../../assets/images/app/content.svg',
      image_2: '../../../../assets/images/app/content-active.svg',
      text: 'ข่าวสาร',
      tag: 'content',
    },
    {
      image_1: '../../../../assets/images/app/message.svg',
      image_2: '../../../../assets/images/app/message-active.svg',
      text: 'ระบบส่งข้อความหลังการลงทะเบียน',
      tag: 'message',
      list: [
        {
          image: '../../../../assets/images/app/message_sms.svg',
          text: 'sms หลังการลงทะเบียน',
          tag: 'sms'
        },
        {
          image: '../../../../assets/images/app/message_email.svg',
          text: 'email หลังการลงทะเบียน',
          tag: 'email'
        },
        {
          image: '../../../../assets/images/app/message_log.svg',
          text: 'ประวัติลงทะเบียน',
          tag: 'logs'
        }
      ]
    },
    {
      image_1: '../../../../assets/images/app/aftersale.svg',
      image_2: '../../../../assets/images/app/aftersale-active.svg',
      text: 'บริการหลังการขาย',
      tag: 'aftersale',
    },
    {
      image_1: '../../../../assets/images/app/report.svg',
      image_2: '../../../../assets/images/app/report-active.svg',
      text: 'รายงาน',
      tag: 'report',
      list: [
        {
          image: '../../../../assets/images/app/fixed.svg',
          text: 'report งานซ่อม',
          tag: 'repair'
        },
        {
          image: '../../../../assets/images/app/customerservice.svg',
          text: 'report บริการหลังการขาย',
          tag: 'aftersale'
        },
      ]
    },
  ];

  public isAcitonMenu: boolean = false;
  @Output()
  public openMenu: EventEmitter<any> = new EventEmitter();
  public isAcitonSubMenu: boolean = false;
  public modeSubMenu: string;
  public modeSubMenu2: string;
  public modeApp: string;
  public urlActive: any;
  public listMenu: any = {};

  public environmentApi = environment;

  private destroy = new Subject<void>();

  // Start --- Scroll load ---
  public throttle = 50;
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  // End --- Scroll load ---

  public counReadChat: number = 0;
  public userStatus: any;

  // Notification
  public notificationList: any[] = [];
  public isLoadNotification: boolean = false;
  public limitNotification = 30;
  public sumNotification = 30;

  public overlayRef: OverlayRef;
  @ViewChildren(TemplatePortalDirective)
  public templatePortals: QueryList<Portal<any>>;

  constructor(
    private router: Router,
    private observManager: ObservableManager,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authenManager: AuthenManager,
    private notificationFacade: NotificationFacade,
    private chatFacade: ChatFacade,
    private readChatFacade: ReadChatFacade,
    protected _notificationSvc: NotificationService,
    private socketIoManager: SocketIoManager,
    private checkTokenService: CheckTokenService,
    public overlay: Overlay,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe(async (event: NavigationEnd) => {
        this.userStatus = await this.checkTokenService.checkStatus();
        this.urlActive = this.router.url.split('/');
        if (
          this.urlActive[1] === 'database' ||
          this.urlActive[1] === 'setting' ||
          this.urlActive[1] === 'chat' ||
          this.urlActive[1] === 'homecare' ||
          this.urlActive[1] === 'content' ||
          this.urlActive[1] === 'down-payment' ||
          this.urlActive[1] === 'message' ||
          this.urlActive[1] === 'aftersale' ||
          this.urlActive[1] === 'report'
        ) {
          this.modeApp = this.urlActive[1];
          this.listMenu['tag'] = this.urlActive[2];
        } else {
          this.modeApp = this.urlActive[0];
        }

        this.observManager.createSubject('menu');
        const app = this.app.find((res: any) => this.urlActive[1] === res.tag);
        if (app) {
          if (app?.list) {
            const list = app?.list.find((res: any) => this.urlActive[2] === res.tag);
            if (list?.list) {
              const sub_list = list?.list.find((res: any) => this.urlActive[3] === res.tag);
              if (sub_list) {
                this.observManager.publish('menu', {
                  data: sub_list
                });
              }
            } else {
              this.observManager.publish('menu', {
                data: list
              });
            }
          } else {
            this.observManager.publish('menu', {
              data: app
            });
          }
        }

        const setting = this.menuSetting.find((res: any) => this.urlActive[1] === res.tag);
        if (setting) {
          if (setting?.list) {
            const list = setting?.list.find((res: any) => this.urlActive[2] === res.tag);
            if (list) {
              this.observManager.publish('menu', {
                data: list
              });
            }
          } else {
            this.observManager.publish('menu', {
              data: app
            });
          }
        }
      });
  }

  public ngOnInit(): void {
    this._playAudio();
    this.countMess();
    // timer(0, (100000 * this.environmentApi.timerReFaceChat)).subscribe(n => {
    //   if (n) {
    //     this.counReadChat = 0;
    //     this.countMess();
    //     this.observManager.createSubject('refaceChat');
    //     this.observManager.publish('refaceChat', {
    //       data: true
    //     });
    //   }
    // });
    this.notification(0, this.limitNotification, false, { "createdDate": -1 }, 'push');
    this.observManager.subscribe('chat', (res: any) => {
      if (res) {
        this.counReadChat = 0;
        this.countMess();
      }
    });
  }

  public ngAfterViewInit(): void {

  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete('chat');
    this.observManager.complete('refaceChat');
  }

  public clickAction(tag: string) {
    if (tag === 'slider' && !this.isAcitonMenu) {
      this.isAcitonMenu = true;
      this.isAcitonSubMenu = false;
      this.clickAcitonOut(true);
    } else if (this.isAcitonMenu) {
      this.isAcitonMenu = false;
      this.isAcitonSubMenu = false;
      this.clickAcitonOut(false);
    }
  }

  public clickBackDrop() {
    this.isAcitonMenu = false;
    this.isAcitonSubMenu = false;
    this.clickAcitonOut(false);
    if (this.modeSubMenu === 'notification') {
      this.modeSubMenu = '';
    }
  }

  public clickMenu(tag: string) {
    this.modeSubMenu = tag;
    if (tag === 'app') {
      this.act();
      this.modeSubMenu2 = this.modeSubMenu;
    } else if (tag === 'chat') {
      this.modeApp = tag;
      this.router.navigate(['/chat']);
      this.isAcitonMenu = false;
      this.isAcitonSubMenu = false;
      this.clickAcitonOut(false);
    } else if (tag === 'notification') {
      this.act();
      this.modeSubMenu2 = this.modeSubMenu;
    } else if (tag === 'setting') {

    }

    this.modeSubMenu2 = this.modeSubMenu;
  }

  public act() {
    if (this.modeSubMenu === this.modeSubMenu2) {
      if (this.isAcitonSubMenu) {
        this.isAcitonSubMenu = !this.isAcitonSubMenu;
        if (this.modeSubMenu === 'notification') {
          this.modeSubMenu = '';
          this.isAcitonMenu = false;
          this.isAcitonSubMenu = false;
        }
      } else {
        this.isAcitonSubMenu = !this.isAcitonSubMenu;
      }
    } else {
      this.isAcitonSubMenu = true;
    }
  }

  public clickApp(tag: string) {
    this.modeApp = tag;
    this.isAcitonSubMenu = false;
    if (tag === 'database' || tag === 'setting' || tag === 'message' || tag === 'report') {
      this.isAcitonMenu = true;
    } else {
      this.isAcitonMenu = false;
    }
    this.clickAcitonOut(false);
  }

  public clickAcitonOut(val: boolean) {
    this.openMenu.emit(val);
  }

  public clickList(tag: string, menu?: string | null, isCloseMenu?: boolean) {
    this.listMenu['tag'] = tag;
    if (tag !== 'repair' && tag !== 'customer') {
      this.listMenu['menu'] = menu;
      this.router.navigate(['', this.modeApp, tag]);
    } else {
      if (!!menu) {
        this.listMenu['menu'] = menu;
        this.router.navigate(['', this.modeApp, tag, menu]);
      } else {
        if (isCloseMenu) {
          this.isAcitonMenu = false;
        } else {
          this.isAcitonMenu = true;
        }
        if (tag !== this.urlActive[2]) {
          this.router.navigate(['', this.modeApp, tag]);
        }
      }
    }

    this.isAcitonSubMenu = false;
    this.clickAcitonOut(false);
  }

  public openEvents(event: any, index: number) {
    let id: string = event.data.id ? event.data.id : event.data[0].id;
    if (this.notificationList[index].isRead === false) {
      this.notificationFacade.readNotification(event.id).then((res: any) => {
        if (res) {
          this.notificationList[index].isRead = true;
          this.clickMenu("notification");
          if (!this._checkType(event.type)) {
            this._showAlert(event.data.name);
            return;
          }

          if (event.type.includes('CUSTOMER_SERVICE') && event.title.substring(0, 2) === 'CS') {
            this.router.navigate(['/aftersale', id]);
          } else {
            this.router.navigate(['/homecare', id]);
          }
        }
      }).catch((err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      this.clickMenu("notification");
      if (!this._checkType(event.type)) {
        this._showAlert(event.data.name);
        return;
      }

      if (event.type.includes('CUSTOMER_SERVICE') && event.title.substring(0, 2) === 'CS') {
        this.router.navigate(['/aftersale', id]);
      } else {
        this.router.navigate(['/homecare', id]);
      }
    }
  }

  private _checkType(data: any): boolean {
    if (data === "DELETE_REPAIR") {
      return false;
    } else {
      return true;
    }
  }

  private _showAlert(data: string): any {
    const dialogRef = this.dialog.open(DialogAlert, {
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: '',
        content: 'งานแจ้งซ่อมรหัสงาน "' + data + ' "ถูกลบ',
        cancel: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        return result;
      }
    });
  }

  public readAll() {
    this.notificationFacade.readAllNotification().then((res: any) => {
      if (res) {
        for (let index = 0; index < this.notificationList.length; index++) {
          this.notificationList[index].isRead = true;
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public checkReadAll(): boolean {
    const noti = this.notificationList.filter((res: any) => res.isRead === false);
    if (noti.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private _playAudio() {
    this.chatFacade.subChat().subscribe(async data => {
      if (data) {
        if (data.chatMessage?.senderType === 'USER') {
          this.counReadChat += 1;
          await this._setMelody('../../../../assets/notification/mixkit-software-interface-start-2574.wav');
          if (this.urlActive[1] !== 'chat') this._showNotification(data.chatMessage);
        }
      }
    });
  }

  public countMess() {
    this.readChatFacade.getCount().then((res: any) => {
      if (res) {
        for (const data of res.data) {
          this.counReadChat += data.count;
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickLogout() {
    const dialogRef = this.dialog.open(DialogAlert, {
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: '',
        content: 'ต้องการออกจากระบบ',
        cancel: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authenManager.logout();
      }
    });
  }

  private notification(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, arrange?: 'unshift' | 'push', whereConditions?: any, optionalFields?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let val: any = {
        offset: offset,
        limit: limit,
        count: count,
        orderBy: orderBy,
        whereConditions
      };

      this.notificationFacade.searchNotification(val).then((res: any) => {
        if (res) {
          for (const list of res.data) {
            if (arrange === 'unshift') {
              this.notificationList.unshift(list);
            } else {
              this.notificationList.push(list);
            }
          }

          this.isLoadNotification = true;
          setTimeout(() => {
            this.isLoadNotification = false;
          }, 3000);

          resolve(true);
          this.getNewNotification();
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public image(data: string): string {
    if (data !== '') {
      return (this.environmentApi.apiBaseURL + data + '/image').toString();
    } else {
      return '';
    }
  }

  private getNewNotification() {
    // Initialize Firebase
    const app = initializeApp(this.environmentApi.firebase);
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app);
    onMessage(messaging, async (payload) => {
      this.setOverlay();
      if (await this.notification(0, 1, false, { "createdDate": -1 }, 'unshift', {
        id: payload.data!['notificationId']
      })) {
        const list = this.notificationList.filter((res: any) => res.id === payload.data!['notificationId']);
        if (list.length > 0) {
          await this._setMelody('../../../../assets/notification/mixkit-correct-answer-tone-2870.wav');
          this._notificationSvc.showNotification(list[0].data.id ? list[0].data.id : list[0].data[0].id, this.image(list[0].fromUser.imageURL), list[0].title! + ' (' + list[0].data[0].description + ')', list[0].createdDate, 10000);
        }
      }
    });
  }

  private setOverlay() {
    if (!this.overlayRef) {
      let config = new OverlayConfig();
      config.positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .bottom('0px')
        .right('0px');
      this.overlayRef = this.overlay.create(config);
      this.overlayRef.attach(this.templatePortals.first);
    }
  }

  public checkIsReadNoti(): number {
    if (this.notificationList.filter((res: any) => res.isRead === false).length > 0) {
      return 1;
    } else {
      return 0;
    }
  }

  public onScrollDownChatList() {
    if (this.notificationList.length >= this.sumNotification) {
      const start = this.sumNotification;
      this.sumNotification += this.limitNotification;
      this.notification(start, this.limitNotification, false, { "createdDate": -1 }, 'push');
    }
  }

  private async _setMelody(melody: string) {
    let audio = new Audio();
    audio.src = melody;
    audio?.load();
    try {
      await audio?.play();
    } catch (err) {
      console.log(err);
      audio?.pause();
    }
  }

  private _showNotification(data: any) {
    if (Notification.permission === 'granted') {
      this._notification(data);
    } else if (Notification.permission === 'denied') {
      alert('ผู้ใช้บล็อกการแจ้งเตือน');
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this._notification(data);
        }
      });
    }
  }

  private _notification(data: any) {
    const notification = new Notification('Chanuntorn', {
      body: 'message: ' + (data.message ? data.message : 'รูปภาพ'),
      icon: '../../../../assets/images/chanuntorn.png',
      timestamp: Math.floor(Date.now())
    })

    notification.onclick = (e) => {
      window.location.href = 'chat/' + data.room;
    };
  }

  public setFormatDate(data: any) {
    return moment(data).add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
  }
}
