<div class="dialog-custom dialog-custom-alert">
  <div class="mat-dialog-title flex items-center">
    <span class="text-base font-bold flex-1">
      {{ data && data.header ? data.header : "แจ้งเตือน !" }}
    </span>
    <button mat-icon-button class="flex-none icon-ripper-center" (click)="clickClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="overflow-x-hidden break-all border-y p-[24px] text-center"
    [ngClass]="{'flex flex-col': (data && !!data.subcontent)}">
    <span>{{ data && data.content }}</span>
    <span *ngIf="data && !!data.subcontent">{{ data.subcontent }}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="bg-gray-100 button-cdg" *ngIf="data && data.cancel !== false" (click)="clickClose()">
      ยกเลิก
    </button>
    <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
      ยืนยัน
    </button>
  </mat-dialog-actions>
</div>