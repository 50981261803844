export * from './ProjectPage.component';
export * from './HouseRegistrationPage.component';
export * from './ResidentsPage.component';
export * from './repair/RepairStatusPage.component';
export * from './repair/RepairTypePage.component';
export * from './repair/AssessmentPage.component';
export * from './RepairOutletPage.component';
export * from './repair/repairOutlet';
export * from './CustomerOutletPage.component';
export * from './customer/customerOutlet';
export * from './DocumentManualPage.component';
