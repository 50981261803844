import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { CustomTable } from "../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../models/TableModel";
import { RealesateUnitFacade } from "../../../../services/facade/ReslesateUnitFacade.service";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { AutocompleteChips } from "../../../shares/AutocompleteChips.component";

const PAGE_NAME: string = 'residents';

@Component({
  selector: 'residents-page',
  templateUrl: './ResidentsPage.component.html'
})

export class ResidentsPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private realesateUnitFacade: RealesateUnitFacade
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._getRealesateUnit(null, null, true);
    this._getRealesateUnit(0, this.limitPage, false, { "createdDate": -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          if (res.data.value.page.prefix) res.data.value['pagePrefix'] = res.data.value.page.prefix;
          if (res.data.value.activateInfo.fullName) res.data.value['activateInfoFullName'] = res.data.value.activateInfo.fullName;
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          if (res.data.value.page.prefix) res.data.value['pagePrefix'] = res.data.value.page.prefix;
          if (res.data.value.activateInfo.fullName) res.data.value['activateInfoFullName'] = res.data.value.activateInfo.fullName;
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public loadData(value: any) {
    this.data = value;
    this.data.map((res, index) => {
      if (!!res!.page!.prefix) this.data[index]['pagePrefix'] = res!.page!.prefix;
      if (!!res!.activateInfo!.fullName) this.data[index]['activateInfoFullName'] = res!.activateInfo!.fullName;
    });
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'รหัสยูนิต',
        tag: 'absUnitId',
        type: 'input'
      },
      {
        name: 'รหัสประจำตัวประชาชน',
        tag: 'citizenId',
        type: 'input'
      },
      {
        name: 'หมายเลขโทรศัพท์',
        tag: 'mobileNo',
        type: 'input'
      },
      {
        name: 'ชื่อผู้อยู่อาศัย',
        tag: 'fullName',
        type: 'input'
      },
      {
        name: 'โครงการบ้าน',
        tag: 'prefix',
        type: 'project_autocomp'
      },
      {
        name: 'แบบบ้าน',
        tag: 'modelName',
        type: 'input'
      }
    ];
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;
    this._getRealesateUnit(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      if (key === 'citizenId' || key === 'mobileNo' || key === 'fullName' || key === 'prefix') {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    const optionalFields: any = {};
    if (filterResult['fullName'] !== undefined && filterResult['fullName'] !== '') {
      optionalFields.fullName = { $regex: '.*' + filterResult['fullName'] + '.*', $options: 'si' };
    }
    if (filterResult['citizenId'] !== undefined && filterResult['citizenId'] !== '') {
      optionalFields.citizenId = { $regex: '.*' + filterResult['citizenId'] + '.*', $options: 'si' };
    }
    if (filterResult['mobileNo'] !== undefined && filterResult['mobileNo'] !== '') {
      optionalFields.mobileNo = { $regex: '.*' + filterResult['mobileNo'] + '.*', $options: 'si' };
    }
    if (filterResult['prefix'] !== undefined && filterResult['prefix'] !== '') {
      optionalFields.pageIds = [];
      optionalFields.pageIds.push(filterResult['prefix']);
    }

    return {
      whereConditions,
      optionalFields
    };
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const conditionObj: any = this.getFilterWhereCondition();

    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;

    // search table
    this._getRealesateUnit(null, null, true, { 'createdDate': -1 }, whereConditions, optionalFields);
    this._getRealesateUnit(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event
      }
    });
  }

  private _getRealesateUnit(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any, optionalFields?: any) {
    let val: any = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      whereConditions
    };

    if (optionalFields !== undefined) {
      for (const key in optionalFields) {
        val[key] = optionalFields[key];
      }
    }

    this.realesateUnitFacade.searchData(val).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'activateInfoFullName', header: 'ชื่อ', tag: 'text', display: true, cell: (element: any) => `${element.activateInfoFullName ? element.activateInfoFullName : '-'}` },
            { columnDef: 'pagePrefix', header: 'รหัสโครงการ', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.pagePrefix ? element.pagePrefix : '-'}` },
            { columnDef: 'id', header: 'ชื่อโครงการ', tag: 'text', display: true, cell: (element: any) => `${element.page.name ? element.page.name : '-'}` },
            { columnDef: 'no', header: 'บ้านเลขที่', tag: 'text', display: true, cell: (element: any) => `${element.no ? element.no : '-'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }

  public clickActionTableDataTemplate() {
    let fileName = 'download.csv';
    let col: any[] = [];
    for (const columns of this.columns) {
      if (columns.display === true) {
        col.push(columns);
      }
    }

    let header = col.map(x => x.columnDef).join(',');
    let csv = header.replace('name,', '');
    csv += '\r\n';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });


    let link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // // public clickActionTableDataDownload() {
  // //   let rows = [];
  // //   for (const row of this.columns) {
  // //     console.log(row.cell.toString().split('element => `${element.'));
  // //     // console.log(row.cell.toString().split('.'));
  // //     rows.push(row.columnDef);
  // //   }
  // //   // this.download(rows);
  // // }

  // // public download(arr: any[]) {
  // //   let fileName = 'download.csv';
  // //   let columnNames = arr;
  // //   let header = columnNames.join(',');

  // //   let csv = header;
  // //   csv += '\r\n';

  // //   this.data.map(c => {
  // //     let x = [];
  // //     for (const val of arr) {
  // //       x.push([c[val]]);
  // //     }
  // //     csv += x.join(',');
  // //     csv += '\r\n';
  // //   })

  // //   var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  // //   var link = document.createElement('a');
  // //   if (link.download !== undefined) {
  // //     var url = URL.createObjectURL(blob);
  // //     link.setAttribute('href', url);
  // //     link.setAttribute('download', fileName);
  // //     document.body.appendChild(link);
  // //     link.click();
  // //     document.body.removeChild(link);
  // //   }
  // // }
}
