import { SearchFilter } from "./SearchFilterRequest";

export class RepairRequest extends SearchFilter {
    public pageIds?: string[];
    public repairType?: string[];
    public repairStatus?: string[];
    public repairIds?: string[];
    public startDateTime?: Date;
    public endDateTime?: Date;
    public isComplete?: Boolean;
    public absUnitId?: any;
    public no?: any;
    public absLeadId?: any;
    public citizenId?: any;
    public mobileNo?: any;
    public pinned?: boolean;
    public showPublic?: boolean;
    public pageId?: string;
    public displayName?: any;
    public isActive?: boolean;
    public title?: string;
    // aftersale
    public customerServiceStatus?: string[];
}