import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { toHTML } from "ngx-editor";

@Pipe({
    name: 'safeHtml',
    pure: false
})

export class PipeSafeHtml implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) { }
    transform(value: any): any {
        return toHTML(value);
    }
}
