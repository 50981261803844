import { Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

/**
 * @author plzdontcry19
 */

@Injectable()
export class SocketIoManager {

  // socketIo
  private socket: Socket;

  // subscription for connection 
  public isConnect = new ReplaySubject<boolean>();

  constructor(private router: Router) {

    // create socketIo
    this.socket = io(environment.socketIoURL, {
      transports: ['websocket', 'polling', 'flashsocket'],
      autoConnect: true,
      reconnection: true
    });

    // _connction method handle about connection 
    this._connction();
  }

  // subscription by event name
  // LISTEN_TYPE is return type of data return
  // Partial is some field of LISTEN_TYPE
  public listen<LISTEN_TYPE>(eventName: string): Observable<Partial<LISTEN_TYPE> | LISTEN_TYPE> {
    return new Observable(subscriber => {
      this.socket.on(eventName, (data: Partial<LISTEN_TYPE> | LISTEN_TYPE) => {
        subscriber.next(data);
      })
    });
  }

  // emit to event 
  // EMIT_TYPE is input type
  public emit<EMIT_TYPE>(eventName: string, data: Partial<EMIT_TYPE> | EMIT_TYPE): void {
    this.socket.emit(eventName, data);
  }

  // volatile emit to event
  // volatile events are events that will not be sent if the underlying connection is not ready
  // EMIT_TYPE is input type
  public volatileEmit<EMIT_TYPE>(eventName: string, data: Partial<EMIT_TYPE> | EMIT_TYPE): void {
    this.socket.volatile.emit(eventName, data);
  }

  // subscription by isConnect
  public listenConnection() {
    return this.isConnect.asObservable();
  }

  // set socket's auth 
  public setAuth<TYPE = string>(key: string, value: TYPE): boolean {
    try {

      this.socket.auth = {};
      this.socket.auth[key] = value;
      return true;

    } catch (error) {
      return false;
    }
  }

  // navigate to ... rout
  public navigate(key: string): void {
    this.router.navigate([key]);
  }

  // connect socketIo server
  public connect(): void {
    this.socket.connect();
  }

  // disconnect socketIo server
  public disconnect(): void {
    this.socket.disconnect();
  }

  // method handle about connection
  private _connction() {
    // when connect server
    this.socket.on('connect', () => {
      // isConnect emit true
      this.isConnect.next(true);
    });

    // when lose connect server
    this.socket.on('connect_error', (err) => {
      console.log('error: ', err);
      // isConnect emit false 
      this.isConnect.next(false);
    });
  }
}
