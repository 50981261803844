import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageModel } from '../../models/PageModel';
import { SearchFilter } from '../../request/SearchFilterRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class PageFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/page/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);

    public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<PageModel>>('search', this.getDefaultHeader(), null, request);
    public searchPostData = (pageId: string) => this.get<MethodResponseInf<PageModel>>(`${(pageId)}` + '/poat', this.getDefaultHeader(), null);
    public getPageId = (id: string) => this.get<MethodResponseInf>(`${(id)}`, this.getDefaultHeader(), null);
}