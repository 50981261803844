import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as moment from 'moment';
import { filter, Subject, takeUntil } from "rxjs";
import { DialogAlert } from "../../../shares/dialog/DialogAlert.component";
import { DialogUpload } from "../../../shares/dialog/DialogUpload.component";
import { CustomTable } from "../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../models/TableModel";
import { ActivateInfoAdminFacade } from "../../../../services/facade/ActivateInfoAdminFacade.service";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { AutocompleteChips } from "../../../shares/AutocompleteChips.component";


const PAGE_NAME: string = 'house-registration';

@Component({
  selector: 'house-registration-page',
  templateUrl: './HouseRegistrationPage.component.html'
})

export class HouseRegistrationPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private activateInfoAdminFacade: ActivateInfoAdminFacade,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public ngOnInit(): void {
    this.getAutocompleteChip();
    this._getActivateInfo(null, null, true);
    this._getActivateInfo(0, this.limitPage, false, { "createdDate": -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          if (res.data.value.page.prefix) res.data.value['pagePrefix'] = res.data.value.page.prefix;
          if (res.data.value.page.name) res.data.value['pageName'] = res.data.value.page.name;
          if (res.data.value.activateInfo.fullName) res.data.value['activateInfoFullName'] = res.data.value.activateInfo.fullName;
          if (res.data.value.activateInfo.citizenId) res.data.value['activateInfoCitizenId'] = res.data.value.activateInfo.citizenId;
          if (res.data.value.activateInfo.mobileNo) res.data.value['activateInfoMobileNo'] = res.data.value.activateInfo.mobileNo;
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          if (res.data.value.page.prefix) res.data.value['pagePrefix'] = res.data.value.page.prefix;
          if (res.data.value.page.name) res.data.value['pageName'] = res.data.value.page.name;
          if (res.data.value.activateInfo.fullName) res.data.value['activateInfoFullName'] = res.data.value.activateInfo.fullName;
          if (res.data.value.activateInfo.citizenId) res.data.value['activateInfoCitizenId'] = res.data.value.activateInfo.citizenId;
          if (res.data.value.activateInfo.mobileNo) res.data.value['activateInfoMobileNo'] = res.data.value.activateInfo.mobileNo;
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'รหัสยูนิต',
        tag: 'absUnitId',
        type: 'input'
      },
      {
        name: 'รหัสติดตามการขาย',
        tag: 'absLeadId',
        type: 'input'
      },
      {
        name: 'ชื่อเจ้าบ้าน (ชื่อ-นามสกุล)',
        tag: 'fullName',
        type: 'input'
      },
      {
        name: 'รหัสประจำตัวประชาชน',
        tag: 'citizenId',
        type: 'input'
      },
      {
        name: 'บ้านเลขที่',
        tag: 'no',
        type: 'input'
      },
      {
        name: 'หมายเลขโทรศัพท์',
        tag: 'mobileNo',
        type: 'input'
      },
      {
        name: 'รหัสโครงการ',
        tag: 'prefix',
        type: 'project_autocomp'
      },
      {
        name: 'ชื่อแบบบ้าน',
        tag: 'modelName',
        type: 'input'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    this.data.map((res, index) => {
      if (!!res!.page!.prefix) this.data[index]['pagePrefix'] = res!.page!.prefix;
      if (!!res!.page!.name) this.data[index]['pageName'] = res!.page!.name;
      if (!!res!.activateInfo!.fullName) this.data[index]['activateInfoFullName'] = res!.activateInfo!.fullName;
      if (!!res!.activateInfo!.citizenId) this.data[index]['activateInfoCitizenId'] = res!.activateInfo!.citizenId;
      if (!!res!.activateInfo!.mobileNo) this.data[index]['activateInfoMobileNo'] = res!.activateInfo!.mobileNo;
    });
    // let newObj = Object.assign({}, ...Object.entries(this.data).map(([key, val]) => ({ [key]: val })));
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;
    this._getActivateInfo(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      if (key === 'fullName' || key === 'citizenId' || key === 'mobileNo' || key === 'prefix') {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    const optionalFields: any = {};
    if (filterResult['fullName'] !== undefined && filterResult['fullName'] !== '') {
      optionalFields.fullName = { $regex: '.*' + filterResult['fullName'] + '.*', $options: 'si' };
    }
    if (filterResult['citizenId'] !== undefined && filterResult['citizenId'] !== '') {
      optionalFields.citizenId = { $regex: '.*' + filterResult['citizenId'] + '.*', $options: 'si' };
    }
    if (filterResult['mobileNo'] !== undefined && filterResult['mobileNo'] !== '') {
      optionalFields.mobileNo = { $regex: '.*' + filterResult['mobileNo'] + '.*', $options: 'si' };
    }
    if (filterResult['prefix'] !== undefined && filterResult['prefix'] !== '') {
      optionalFields.pageIds = [];
      optionalFields.pageIds.push(filterResult['prefix']);
    }

    return {
      whereConditions,
      optionalFields
    };
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;

    // search table
    this._getActivateInfo(null, null, true, { 'createdDate': -1 }, whereConditions, optionalFields);
    this._getActivateInfo(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event
      }
    });
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  private _getActivateInfo(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any, optionalFields?: any) {
    let val: any = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      whereConditions
    };

    if (optionalFields !== undefined) {
      for (const key in optionalFields) {
        val[key] = optionalFields[key];
      }
    }

    this.activateInfoAdminFacade.searchData(val).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'pagePrefix', header: 'รหัสโครงการ', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.pagePrefix ? element.pagePrefix : '-'}` },
            { columnDef: 'pageName', header: 'โครงการ', tag: 'text', display: true, cell: (element: any) => `${element.pageName ? element.pageName : '-'}` },
            { columnDef: 'absUnitId', header: 'รหัสยูนิต', tag: 'text', display: true, cell: (element: any) => `${element.absUnitId ? element.absUnitId : '-'}` },
            { columnDef: 'modelName', header: 'ชื่อแบบบ้าน', tag: 'text', display: true, cell: (element: any) => `${element.modelName ? element.modelName : '-'}` },
            { columnDef: 'absLeadId', header: 'รหัสติดตามการขาย', tag: 'text', display: true, cell: (element: any) => `${element.absLeadId ? element.absLeadId : '-'}` },
            { columnDef: 'activateInfoFullName', header: 'ชื่อเจ้าบ้าน', tag: 'text', display: true, cell: (element: any) => `${element.activateInfoFullName ? element.activateInfoFullName : '-'}` },
            { columnDef: 'no', header: 'บ้านเลขที่', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.no ? element.no : '-'}` },
            { columnDef: 'activateInfoCitizenId', header: 'รหัสประจำตัวประชาชน', tag: 'text', display: true, cell: (element: any) => `${element.activateInfoCitizenId ? element.activateInfoCitizenId : '-'}` },
            { columnDef: 'activateInfoMobileNo', header: 'หมายเลขโทรศัพท์', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.activateInfoMobileNo ? element.activateInfoMobileNo.replace('-', '') : '-'}` },
            { columnDef: 'guaranteeDate', header: 'วันที่เริ่มรับประกันบ้าน', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.guaranteeDate ? moment(element.guaranteeDate).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
            { columnDef: 'guaranteeEndDate', header: 'วันที่สิ้นสุดรับประกันบ้าน', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.guaranteeEndDate ? moment(element.guaranteeEndDate).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
            // {
            //   columnDef: 'guaranteeDate', header: 'วันที่เริ่มรับประกันบ้าน', color: 'green', align: 'center', tag: 'text-color', display: true, cell: (element: any) => {
            //     return {
            //       text: `${element.guaranteeDate ? moment(element.guaranteeDate).add(543, 'year').format('DD/MM/YYYY') : '-'}`,
            //       color: `red`
            //     }
            //   }
            // }
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }

  public clickActionTableDataUpload() {
    let val = ['.csv', '.xlsx'];
    const dialogRef = this.dialog.open(DialogUpload, {
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'นำเข้าข้อมูล',
        accept: val
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const formData = new FormData();
        formData.append('file', result.data);
        this.activateInfoAdminFacade.uploadData(formData).then((res: any) => {
          if (res) {
            for (const success of res.data.data.success) {
              let val = {
                absLeadId: success.data.realesateUnit.absLeadId ? success.data.realesateUnit.absLeadId : '',
                absUnitId: success.data.realesateUnit.absUnitId ? success.data.realesateUnit.absUnitId : '',
                activateInfo: success.data.activateInfo ? success.data.activateInfo : '',
                address: success.data.realesateUnit.address ? success.data.realesateUnit.address : '',
                createdDate: success.data.realesateUnit.createdDate ? success.data.realesateUnit.createdDate : '',
                guaranteeDate: success.data.realesateUnit.guaranteeDate ? success.data.realesateUnit.guaranteeDate : '',
                guaranteeEndDate: success.data.realesateUnit.guaranteeEndDate ? success.data.realesateUnit.guaranteeEndDate : '',
                id: success.data.realesateUnit.id ? success.data.realesateUnit.id : '',
                modelName: success.data.realesateUnit.modelName ? success.data.realesateUnit.modelName : '',
                name: success.data.realesateUnit.name ? success.data.realesateUnit.name : '',
                no: success.data.realesateUnit.no ? success.data.realesateUnit.no : '',
                page: success.data.page ? success.data.page : ''
              }

              this.tableRow && this.tableRow.createRow(val);
            }

            let dataList = {
              header: 'รายละเอียดการอัพโหลด',
              list: {
                success: {
                  head: 'ข้อมูลอัพโหลดสำเร็จ',
                  array: res.data.data.success
                },
                errors: {
                  head: 'ข้อมูลอัพโหลดไม่สำเร็จ',
                  array: res.data.data.unSuccess
                },
              }
            }

            const dialogRef = this.dialog.open(DialogUpload, {
              autoFocus: false,
              data: dataList
            });
          }
        }).catch((err: any) => {
          if (err) {
            const dialogRef = this.dialog.open(DialogAlert, {
              autoFocus: false,
              restoreFocus: false,
              data: {
                header: '',
                content: err.error.message,
                cancel: false
              }
            });

            dialogRef.afterClosed().subscribe(result => {

            });
          }
        });
      }
    });
  }

  public clickActionTableDataTemplate() {
    let fileName = 'download.csv';
    let col: any[] = [];
    for (const columns of this.columns) {
      if (columns.display === true) {
        col.push(columns);
      }
    }

    let header = col.map(x => x.columnDef).join(',');
    let csv = header.replace('name,', '');
    csv += '\r\n';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });


    let link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // // public clickActionTableDataDownload() {
  // //   let rows = [];
  // //   for (const row of this.columns) {
  // //     console.log(row.cell.toString().split('element => `${element.'));
  // //     // console.log(row.cell.toString().split('.'));
  // //     rows.push(row.columnDef);
  // //   }
  // //   // this.download(rows);
  // // }

  // // public download(arr: any[]) {
  // //   let fileName = 'download.csv';
  // //   let columnNames = arr;
  // //   let header = columnNames.join(',');

  // //   let csv = header;
  // //   csv += '\r\n';

  // //   this.data.map(c => {
  // //     let x = [];
  // //     for (const val of arr) {
  // //       x.push([c[val]]);
  // //     }
  // //     csv += x.join(',');
  // //     csv += '\r\n';
  // //   })

  // //   var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  // //   var link = document.createElement('a');
  // //   if (link.download !== undefined) {
  // //     var url = URL.createObjectURL(blob);
  // //     link.setAttribute('href', url);
  // //     link.setAttribute('download', fileName);
  // //     document.body.appendChild(link);
  // //     link.click();
  // //     document.body.removeChild(link);
  // //   }
  // // }
}
