import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { PageAdminFacade } from "../../../services/facade/PageAdminFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { AuthenManager } from "../../../services/AuthenManager.service";
import { RealesateUnitInstallmentFacade } from "../../../services/facade/RealesateUnitInstallmentFacade.service";
import { DownPaymentFacade } from "../../../services/facade/DownPaymentFacade.service";

@Component({
    selector: 'slider-down-payment',
    templateUrl: './SliderDownPayment.component.html',
})
export class SliderDownPayment implements OnInit {
    protected routPath: string = environment.apiBaseURL;
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public isSubmitted: boolean = false;
    public installments: any[] = [];

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private pageAdminFacade: PageAdminFacade,
        private installmentFacade: RealesateUnitInstallmentFacade,
        private downPaymentFacade: DownPaymentFacade
    ) {

    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                if (this.dataDetail.mode === 'edit') {
                    this.getInstallments(this.dataDetail.value);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {

                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    public clickCancel(): void {
        this.isSubmitted = false;
    }

    public clickClose(): void {
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    public parseDate(value: any): any {
        if (value === undefined || value === null || value === '') {
            return undefined;
        }

        let date = undefined;
        if (typeof value === 'object') {
            if (typeof value.getMonth === 'function') {
                date = value;
            }
        } else if (typeof value === 'string') {
            try {
                date = new Date(value);
            } catch (error) {
            }
        }

        return date;
    }

    private getInstallments(data: any) {
        this.dataJob = data;
        this.installments = [];
        if (this.dataJob.realesateUnitId !== undefined && this.dataJob.realesateUnitId !== null && this.dataJob.realesateUnitId !== '') {
            this.installmentFacade.getInstallments(this.dataJob.realesateUnitId, this.dataJob.absLeadId).then((result: any) => {
                const installments = result.data === undefined ? undefined : result.data.installments;

                if (installments !== undefined) {
                    this.installments = installments;
                }
            }).catch((err) => {
                console.log(err);
            });
        } else if (this.dataJob.id !== undefined && this.dataJob.id !== null && this.dataJob.id !== '') {
            this.downPaymentFacade.getInstallments(this.dataJob.id).then((result: any) => {
                const installments = result.data === undefined ? undefined : result.data.installments;

                if (installments !== undefined) {
                    this.installments = installments;
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    private clickSubmitPage() {
        this.isSubmitted = true;
    }

    public clearFormData(): void {
        this.installments = [];
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.name + '"'
            }).then((res: any) => {
                if (res) {
                    this.pageAdminFacade.deleteData(this.dataDetail.value.id || this.dataDetail.value._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.clearFormData();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            }).then((res: any) => {
                                if (res) {

                                }
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }

                this.clearFormData();
            });
        }
    }

    public setYear(val: any, count: string): any {
        if (val) {
            return moment(val).locale('th').add(count, 'year').format('LL');
        }
    }
}

