import { HttpClient } from '@angular/common/http';
import { AuthenManager, SocketIoManager } from '../../services/services';
import { MethodFacade } from './facade';
import { Observable } from 'rxjs';

/**
 * @author plzdontcry19
 */

export class SocketIoFacade extends MethodFacade {

    protected socketMgr: SocketIoManager;
    constructor(
        http: HttpClient,
        authenManager: AuthenManager,
        socketIoManager: SocketIoManager,
        subPath: string) {

        super(http, authenManager, subPath);
        this.socketMgr = socketIoManager;
        this.authMgr = authenManager;
    }

    // subscription isConnect
    public subIsConnect() {
        return this.socketMgr.listenConnection();
    }

    // disconnect server
    public disconnect(): void {
        this.socketMgr.disconnect();
    }

    // emit to event 
    // EMIT_TYPE is input type
    protected emit<EMIT_TYPE>(eventName: string, data: Partial<EMIT_TYPE> | EMIT_TYPE, isAuth: boolean = true): void {
        this._checkAuth(isAuth);
        // emit
        this.socketMgr.emit<EMIT_TYPE>(eventName, data);
    }

    // volatile emit to event 
    // EMIT_TYPE is input type
    protected volatileEmit<EMIT_TYPE>(eventName: string, data: Partial<EMIT_TYPE> | EMIT_TYPE, isAuth: boolean = true): void {
        this._checkAuth(isAuth);
        // emit
        this.socketMgr.volatileEmit<EMIT_TYPE>(eventName, data);
    }

    // subscription by event name
    // LISTEN_TYPE is return type of data return
    // Partial is some field of LISTEN_TYPE
    protected listen<EMIT_TYPE>(eventName: string, isAuth: boolean = true) {
        if (isAuth) {
            // set auth
            this.socketMgr.setAuth('token', this.authMgr.getUserToken());
        }

        return new Observable<Partial<EMIT_TYPE> | EMIT_TYPE>(subscriber => {
            this.socketMgr.listen<EMIT_TYPE>(eventName).subscribe(data => {

                // if not token
                // clearstorage, navigate to login, emit isConnect to false

                if (!this.authMgr.getUserToken()) {
                    this.authMgr.clearStorage();
                    this.socketMgr.navigate('/login');
                    this.socketMgr.isConnect.next(false);
                    subscriber.error(new Error('Not found token'));
                }
                subscriber.next(data);
            })
        });
    }

    private _checkAuth(isAuth: boolean): void {
        if (isAuth) {
            // set auth
            this.socketMgr.setAuth('token', this.authMgr.getUserToken());
        }
        // if not token
        // clearstorage, navigate to login, emit isConnect to false
        if (!this.authMgr.getUserToken()) {
            this.authMgr.clearStorage();
            this.socketMgr.navigate('/login');
            this.socketMgr.isConnect.next(false);
            throw new Error('Not found token');
        }
    }
}
