import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: 'readMore',
    pure: false
})

export class PipeReadMore implements PipeTransform {
    private isMore: boolean;

    constructor(protected sanitizer: DomSanitizer) { }
    transform(text: any, length: number = 30, showAll: boolean = false, suffix: string = '...'): any {
        if (showAll) {
            return text;
        }

        if (text.length > length) {
            return text.substring(0, length) + suffix;
        }

        return text;
    }
}
