import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { UserModel } from "../../../models/UserModel";
import { UserAdminFacade } from "../../../services/facade/UserAdminFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-manage-users',
    templateUrl: './SliderManageUsers.component.html',
})
export class SliderManageUsers implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public groupUserAdmin: UntypedFormGroup;
    public isSubmitted: boolean = false;
    public hidePassword: boolean;
    public hideConfirmPassword: boolean;

    public dataPage: any[] = [];
    public filteredListPage: any;

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private userAdminFacade: UserAdminFacade,
        private formBuilder: UntypedFormBuilder,
        private checkTokenService: CheckTokenService,
    ) {
        this.formProjectId();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.hidePassword = false;
                this.hideConfirmPassword = false;
                this.clickOpen();
                if (this.dataDetail.mode === 'edit') {
                    this.setData(this.dataDetail.value);
                    setTimeout(() => {
                        this.groupUserAdmin.get('email')?.disable();
                        this.groupUserAdmin.get('password')?.disable();
                        this.groupUserAdmin.get('confirmpassword')?.disable();
                    }, 10);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.groupUserAdmin.get('email')?.enable();
                        this.groupUserAdmin.get('password')?.enable();
                        this.groupUserAdmin.get('confirmpassword')?.enable();
                    }, 10);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                (this.groupUserAdmin.get('citizenId')?.value ? this.groupUserAdmin.get('citizenId')?.value.toUpperCase() : this.groupUserAdmin.get('citizenId')?.value) !== (this.dataJob.citizenId ? this.dataJob.citizenId.toUpperCase() : this.dataJob.citizenId)
                || this.groupUserAdmin.get('mobileNo')?.value !== (!!this.dataJob.mobileNo ? this.dataJob.mobileNo.replace('-', '') : this.dataJob.mobileNo)
                || this.groupUserAdmin.get('displayName')?.value !== this.dataJob.displayName
                || this.groupUserAdmin.get('isAdmin')?.value !== this.dataJob.isAdmin
                || this.groupUserAdmin.get('isSubAdmin')?.value !== this.dataJob.isSubAdmin
                || this.groupUserAdmin.get('isMarketing')?.value !== this.dataJob.isMarketing
                || this.groupUserAdmin.get('isForeman')?.value !== this.dataJob.isForeman
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.setData(this.dataDetail.value);
                        this.hidePassword = false;
                        this.hideConfirmPassword = false;
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupUserAdmin.get('email')?.value
                || this.groupUserAdmin.get('password')?.value
                || this.groupUserAdmin.get('confirmpassword')?.value
                || (this.groupUserAdmin.get('citizenId')?.value ? this.groupUserAdmin.get('citizenId')?.value.toUpperCase() : this.groupUserAdmin.get('citizenId')?.value)
                || this.groupUserAdmin.get('mobileNo')?.value
                || this.groupUserAdmin.get('displayName')?.value
                || this.groupUserAdmin.get('isAdmin')?.value !== true
                || this.groupUserAdmin.get('isSubAdmin')?.value !== false
                || this.groupUserAdmin.get('isMarketing')?.value !== false
                || this.groupUserAdmin.get('isForeman')?.value !== false
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.formProjectId();
                        this.hidePassword = false;
                        this.hideConfirmPassword = false;
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.hidePassword = false;
        this.hideConfirmPassword = false;
        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                (this.groupUserAdmin.get('citizenId')?.value ? this.groupUserAdmin.get('citizenId')?.value.toUpperCase() : this.groupUserAdmin.get('citizenId')?.value) !== (this.dataJob.citizenId ? this.dataJob.citizenId.toUpperCase() : this.dataJob.citizenId)
                || this.groupUserAdmin.get('mobileNo')?.value !== (!!this.dataJob.mobileNo ? this.dataJob.mobileNo.replace('-', '') : this.dataJob.mobileNo)
                || this.groupUserAdmin.get('displayName')?.value !== this.dataJob.displayName
                || this.groupUserAdmin.get('isAdmin')?.value !== this.dataJob.isAdmin
                || this.groupUserAdmin.get('isSubAdmin')?.value !== this.dataJob.isSubAdmin
                || this.groupUserAdmin.get('isMarketing')?.value !== this.dataJob.isMarketing
                || this.groupUserAdmin.get('isForeman')?.value !== this.dataJob.isForeman
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.formProjectId();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.hidePassword = false;
                        this.hideConfirmPassword = false;
                        this.isSubmitted = false;
                    }
                });

                return;
            }

        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupUserAdmin.get('email')?.value
                || this.groupUserAdmin.get('password')?.value
                || this.groupUserAdmin.get('confirmpassword')?.value
                || (this.groupUserAdmin.get('citizenId')?.value ? this.groupUserAdmin.get('citizenId')?.value.toUpperCase() : this.groupUserAdmin.get('citizenId')?.value)
                || this.groupUserAdmin.get('mobileNo')?.value
                || this.groupUserAdmin.get('displayName')?.value
                || this.groupUserAdmin.get('isAdmin')?.value !== true
                || this.groupUserAdmin.get('isSubAdmin')?.value !== false
                || this.groupUserAdmin.get('isMarketing')?.value !== false
                || this.groupUserAdmin.get('isForeman')?.value !== false
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.formProjectId();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.hidePassword = false;
                        this.hideConfirmPassword = false;
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.formProjectId();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.hidePassword = false;
        this.hideConfirmPassword = false;
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private setData(data: any) {
        this.dataJob = data;
        this.groupUserAdmin.get('email')?.setValue(this.dataJob.email);
        this.groupUserAdmin.get('password')?.setValue('●●●●●●');
        this.groupUserAdmin.get('confirmpassword')?.setValue('●●●●●●');
        this.groupUserAdmin.get('citizenId')?.setValue((this.dataJob.citizenId ? this.dataJob.citizenId.toUpperCase() : this.dataJob.citizenId));
        this.groupUserAdmin.get('mobileNo')?.setValue((!!this.dataJob.mobileNo ? this.dataJob.mobileNo.replace('-', '') : this.dataJob.mobileNo));
        this.groupUserAdmin.get('displayName')?.setValue(this.dataJob.displayName);
        this.groupUserAdmin.get('isAdmin')?.setValue(this.dataJob.isAdmin);
        this.groupUserAdmin.get('isSubAdmin')?.setValue(this.dataJob.isSubAdmin);
        this.groupUserAdmin.get('isMarketing')?.setValue(this.dataJob.isMarketing);
        this.groupUserAdmin.get('isForeman')?.setValue(this.dataJob.isForeman);
    }

    public formProjectId() {
        this.groupUserAdmin = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.maxLength(254)]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(254)]],
            confirmpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(254)]],
            citizenId: ['', [Validators.pattern('^[a-zA-Z0-9]+$'), Validators.required, Validators.maxLength(13)]],
            mobileNo: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(254)]],
            displayName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(254)]],
            isAdmin: [true],
            isSubAdmin: [false],
            isMarketing: [false],
            isForeman: [false]
        });
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'edit') {
            if (!!this.groupUserAdmin.get('citizenId')?.value && (this.groupUserAdmin.get('citizenId')?.value !== this.dataJob.citizenId)) {
                const citizen = await this.userAdminFacade.searchData({
                    count: true,
                    whereConditions: {
                        citizenId: {
                            $options: 'si',
                            $regex: '.*' + this.groupUserAdmin.get('email')?.value + '.*'
                        }
                    }
                });

                if (citizen.data >= 1) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'เลขบัตรประชาชนมีผู้ใช้แล้ว',
                        cancel: false
                    });

                    this.groupUserAdmin.get('citizenId')?.setValue(this.dataJob.citizenId);
                    return;
                }
            }

            if (this.groupUserAdmin.invalid ||
                (
                    this.groupUserAdmin.get('email')?.value === this.dataJob.email &&
                    (this.groupUserAdmin.get('citizenId')?.value ? this.groupUserAdmin.get('citizenId')?.value.toUpperCase() : this.groupUserAdmin.get('citizenId')?.value) === (this.dataJob.citizenId ? this.dataJob.citizenId.toUpperCase() : this.dataJob.citizenId) &&
                    this.groupUserAdmin.get('mobileNo')?.value === this.dataJob.mobileNo &&
                    this.groupUserAdmin.get('displayName')?.value === this.dataJob.displayName &&
                    this.groupUserAdmin.get('isAdmin')?.value === this.dataJob.isAdmin &&
                    this.groupUserAdmin.get('isSubAdmin')?.value === this.dataJob.isSubAdmin &&
                    this.groupUserAdmin.get('isMarketing')?.value === this.dataJob.isMarketing &&
                    this.groupUserAdmin.get('isForeman')?.value === this.dataJob.isForeman ||
                    (this.groupUserAdmin.get('isAdmin')?.value === false &&
                        this.groupUserAdmin.get('isSubAdmin')?.value === false &&
                        this.groupUserAdmin.get('isMarketing')?.value === false &&
                        this.groupUserAdmin.get('isForeman')?.value === false)
                )) {
                return;
            }

            this.isSubmitted = true;
            const userModel = new UserModel();
            userModel.citizenId = this.groupUserAdmin.get('citizenId')?.value;
            userModel.mobileNo = this.groupUserAdmin.get('mobileNo')?.value;
            userModel.displayName = this.groupUserAdmin.get('displayName')?.value;
            userModel.isAdmin = ((this.groupUserAdmin.get('isSubAdmin')?.value && this.groupUserAdmin.get('isMarketing')?.value) || (this.groupUserAdmin.get('isSubAdmin')?.value && !this.groupUserAdmin.get('isMarketing')?.value)) ? true : this.groupUserAdmin.get('isAdmin')?.value;
            userModel.isSubAdmin = this.groupUserAdmin.get('isSubAdmin')?.value;
            userModel.isMarketing = this.groupUserAdmin.get('isMarketing')?.value;
            userModel.isForeman = this.groupUserAdmin.get('isForeman')?.value;

            this.userAdminFacade.updateData(this.dataDetail.value.id || this.dataDetail.value._id,
                userModel
            ).then((res: any) => {
                if (res) {
                    this.setData(res.data);
                    this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                    this.hidePassword = false;
                    this.hideConfirmPassword = false;
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.hidePassword = false;
                    this.hideConfirmPassword = false;
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            if (!!this.groupUserAdmin.get('email')?.value) {
                const citizen = await this.userAdminFacade.searchData({
                    count: true,
                    whereConditions: {
                        email: {
                            $options: 'si',
                            $regex: '.*' + this.groupUserAdmin.get('email')?.value + '.*'
                        }
                    }
                });

                if (citizen.data >= 1) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'อีเมลมีผู้ใช้แล้ว',
                        cancel: false
                    });

                    this.groupUserAdmin.get('email')?.reset();
                    return;
                }
            }

            if (!!this.groupUserAdmin.get('citizenId')?.value) {
                const citizen = await this.userAdminFacade.searchData({
                    count: true,
                    whereConditions: {
                        citizenId: {
                            $options: 'si',
                            $regex: '.*' + this.groupUserAdmin.get('citizenId')?.value + '.*'
                        }
                    }
                });

                if (citizen.data >= 1) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'เลขบัตรประชาชนมีผู้ใช้แล้ว',
                        cancel: false
                    });

                    this.groupUserAdmin.get('citizenId')?.reset();
                    return;
                }
            }

            if (
                this.groupUserAdmin.invalid ||
                this.groupUserAdmin.get('password')?.value !== this.groupUserAdmin.get('confirmpassword')?.value ||
                (
                    !this.groupUserAdmin.get('isAdmin')?.value &&
                    !this.groupUserAdmin.get('isSubAdmin')?.value &&
                    !this.groupUserAdmin.get('isMarketing')?.value &&
                    !this.groupUserAdmin.get('isForeman')?.value
                )
            ) {
                return;
            }

            this.isSubmitted = true;
            const userModel = new UserModel();
            userModel.email = (this.groupUserAdmin.get('email')?.value).trim();
            userModel.password = this.groupUserAdmin.get('password')?.value;
            userModel.citizenId = (this.groupUserAdmin.get('citizenId')?.value ? this.groupUserAdmin.get('citizenId')?.value.toUpperCase() : this.groupUserAdmin.get('citizenId')?.value);
            userModel.mobileNo = this.groupUserAdmin.get('mobileNo')?.value;
            userModel.displayName = this.groupUserAdmin.get('displayName')?.value;
            userModel.isAdmin = ((this.groupUserAdmin.get('isSubAdmin')?.value && this.groupUserAdmin.get('isMarketing')?.value) || (this.groupUserAdmin.get('isSubAdmin')?.value && !this.groupUserAdmin.get('isMarketing')?.value)) ? true : this.groupUserAdmin.get('isAdmin')?.value;
            userModel.isSubAdmin = this.groupUserAdmin.get('isSubAdmin')?.value;
            userModel.isMarketing = this.groupUserAdmin.get('isMarketing')?.value;
            userModel.isForeman = this.groupUserAdmin.get('isForeman')?.value;

            this.userAdminFacade.createData(userModel
            ).then((res: any) => {
                if (res) {
                    this.dataJob = res.data;
                    this.createTableRow(this.dataJob, 'create');
                    this.formProjectId();
                    this.hidePassword = false;
                    this.hideConfirmPassword = false;
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.hidePassword = false;
                    this.hideConfirmPassword = false;
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                        }
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.displayName + '"'
            }).then((res: any) => {
                if (res) {
                    const userModel = new UserModel();

                    userModel.banned = true;
                    this.userAdminFacade.deleteData(this.dataDetail.value.id || this.dataDetail.value._id, userModel).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.formProjectId();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            }).then((res: any) => {
                                if (res) {

                                }
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupUserAdmin.controls;
    }
}

