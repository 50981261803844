import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ChatRoomAdminFacade } from "../../../services/facade/ChatRoomAdminFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";

const PAGE_NAME: string = 'noti';

@Component({
  selector: 'noti-page',
  templateUrl: './NotificationPage.component.html'
})

export class NotificationPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  private destroy = new Subject<void>();
  public menu: any;
  public filterChatList = [
    {
      text: 'ทั้งหมด',
      tag: 'all'
    },
    {
      text: 'อ่านแล้ว',
      tag: 'read'
    },
    {
      text: 'ยังไม่อ่าน',
      tag: 'not_read'
    },
  ];
  public selected = this.filterChatList[0];

  public taggeds = [
    {
      color: 'chat-tag-not',
      text: 'กำลังซ่อม'
    },
    {
      color: 'chat-tag-suss',
      text: 'มีปัญหา มีปัญหา มีปัญหา'
    },
  ];

  public chatList: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private chatRoomAdminFacade: ChatRoomAdminFacade,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public ngOnInit(): void {
    this.getChatList();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
  }

  private getChatList() {
    this.chatRoomAdminFacade.searchData().then((res: any) => {
      if (res) {
        this.chatList = res.data;
      }
    }).catch((err: any) => {
      if (err) {
        console.log(err);
      }
    });
  }
}