import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { NotificationModel } from "../../../models/NotificationModel";
import { NotificationService } from "../../../services/Notification.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import * as moment from "moment";

@Component({
    selector: 'notification',
    templateUrl: './Notification.component.html',
})
export class notification implements OnInit {
    public notifications: NotificationModel[] = [];
    private _subscription: Subscription;

    constructor(
        private router: Router,
        private _notificationSvc: NotificationService,
        private observManager: ObservableManager,
    ) {

    }

    public ngOnInit() {
        this._subscription = this._notificationSvc.getObservable().subscribe(notification => this._addNotification(notification));
    }

    public ngOnDestroy() {
        this._subscription.unsubscribe();
        this.observManager.complete('noneNotification');
    }

    private _addNotification(notification: NotificationModel) {
        this.notifications.push(notification);
        if (notification.timeout !== 0) {
            setTimeout(() => {
                this.close(notification);
            }, notification.timeout);
        }
    }

    public close(notification: NotificationModel) {
        this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
    }

    public clickRouter(data: string) {
        if (data) {
            this.router.navigate(['homecare', data]);
        }
    }

    public setFormatDate(data: any) {
        return moment(data).add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
    }
}