import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { CustomerServiceRequest } from '../../request/CustomerServiceRequest';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class CustomerServiceAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/customerservice/');
    }

    // [@/PUT] admin/repair/:id/repair_status /: repairStatusId / change_status

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public approve = (id: string, description: any) => this.put<MethodResponseInf<CustomerServiceRequest>>(`${(id) + '/approve'}`, this.getDefaultHeader(), null, description);
    // public updateGuaranteeDate = (id: string, date: GuaranteeDateRequest) => this.put<MethodResponseInf<CustomerServiceRequest>>(`${(id) + '/guarantee_date'}`, this.getDefaultHeader(), null, date);
    public unapprove = (id: string, description: any) => this.put<MethodResponseInf<CustomerServiceRequest>>(`${(id) + '/unapprove'}`, this.getDefaultHeader(), null, description);
    public cancelapprove = (id: string, description: any) => this.put<MethodResponseInf<CustomerServiceRequest>>(`${(id) + '/cancel_approve'}`, this.getDefaultHeader(), null, description);
    public upDateStatus = (customerServiceId: string, customerServiceStatusId: string) => this.put<MethodResponseInf<any>>(`${(customerServiceId) + '/customerservice_status/'}${(customerServiceStatusId) + '/change_status'}`, this.getDefaultHeader(), null, null);
    // public searchLogQueuedate = (repairId: string, data: LogQueueDateRequest) => this.post<MethodResponseInf<any>>(`${(repairId) + '/queuedate/logs/search'}`, this.getDefaultHeader(), null, data);
    // public createLogQueuedate = (repairId: string, data: LogQueueDateRequest) => this.post<MethodResponseInf<any>>(`${(repairId) + '/queuedate/logs'}`, this.getDefaultHeader(), null, data);
    public completeStatus = (reUnitId: string, statusId: string, description?: string) => this.put<MethodResponseInf<any>>(`${(reUnitId) + '/customerservice_status/'}${(statusId) + '/change_status/'}`, this.getDefaultHeader(), null, { completeAdminDescription: description });
}