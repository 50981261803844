export const HOUSE_PROJECT = {
    name: "โครงการบ้าน",
    tag: "house_project",
    type: "input"
};

export const REPAIR_TYPE = {
    name: "ประเภทการซ่อม",
    tag: "repair_type",
    type: "input"
};

export const REPAIR_STATUS = {
    name: "สถานะการซ่อม",
    tag: "repair_status",
    type: "input"
};

export const INSURANCE_STATUS = {
    name: "สถานะประกัน",
    tag: "insurance_status",
    type: "input"
};

export const HOUSE_NUMBER = {
    name: "บ้านเลขที่",
    tag: "house_number",
    type: "input"
};

export const REPAIR_NUMBER = {
    name: "หมายเลขแจ้งซ่อม",
    tag: "repair_number",
    type: "input"
};

export const REPAIR_PERSON = {
    name: "ผู้แจ้งซ่อม",
    tag: "repair_person",
    type: "input"
};