import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class ReportRepairAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/report/');
    }

    public searchData = (request?: any) => this.post<MethodResponseInf<any[]>>(`repair/search`, this.getDefaultHeader(), null, request);
}