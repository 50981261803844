import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'read-more',
    templateUrl: './ReadMore.component.html',
})

export class ReadMore implements OnInit {
    @Input()
    public content: string;
    @Input()
    public limit: number;
    public isContentToggled: boolean;

    constructor() {

    }

    public ngOnInit() {

    }

    public toggleContent() {
        this.isContentToggled = !this.isContentToggled;
    }
}