<div class="chat-page h-screen flex flex-col">
    <div class="h-[75px] px-6 flex items-center border-b flex-none">
        <div class="text-xl font-bold flex-1">
            {{menu && menu.text}}
        </div>
    </div>
    <div class="flex-1 p-6 flex overflow-hidden  gap-5">
        <div class="flex-none bg-white w-[100%] xl:w-[100%] 2xl:w-[100%]">

        </div>
    </div>
</div>