<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
  <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
    [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
    [fixedBottomGap]="fixedBottomGap" disableClose="true">
    <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
      *ngIf="isSubmitted">
      <mat-spinner></mat-spinner>
    </div>
    <div #wrapper class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
      <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
        <div class="flex-1 flex items-center mr-4">
          <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose();">
            <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
          </button>
          <div class="flex-1 flex flex-col">
            <span class="text-lg font-bold flex items-center">
              <img class="flex-none w-6 h-6 min-w-6 mr-2" [src]="'../../../../assets/images/app/project.svg'" alt="">
              <span class="flex-1 line-clamp-1">
                {{groupProject.get('name')?.value ? groupProject.get('name')?.value :
                'หัวเรื่อง'}}
              </span>
            </span>
          </div>
        </div>
        <div class="flex-none flex items-center">
          <div class="grid grid-cols-2 gap-2">
            <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
              คืนค่า
            </button>
            <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
              <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">บันทึก</ng-container>
              <ng-container *ngIf="dataDetail && dataDetail.mode === 'create'">สร้าง</ng-container>
            </button>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
            class="ml-3 flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
            [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
              <mat-icon>delete</mat-icon>
              <span>ลบข้อมูล</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="flex-1 flex items-start">
        <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
          <div class="w-full md:max-w-[500px] xl:max-w-[560px] grid grid-cols-1 gap-6">
            <div class="card-detail">
              <div class="flex items-center min-h-[80px] border-b p-4">
                <div class="flex-1 flex flex-col">
                  <span class="text-lg font-bold">
                    ข้อมูลโครงการ
                  </span>
                  <span class="text-sm font-normal opacity-50 mt-1">
                    ข้อมูลทั่วไปของโครงการ
                  </span>
                </div>
              </div>
              <div class="px-4 py-6 grid grid-cols-1 gap-4" [formGroup]="groupProject">
                <div>
                  <span class="text-base font-bold opacity-50">ชื่อโครงการ</span>
                  <input class="input-custom" type="text" placeholder="" autocomplete="off" formControlName="name"
                    [class.border-error]="(isSubmitted || groupProject.get('name')?.touched) && f['name'].errors">
                  <mat-error class="text-sm font-medium"
                    *ngIf="(isSubmitted || groupProject.get('name')?.touched) && f['name'].errors">
                    ชื่อโครงการกรอกอย่างน้อย 3 หลัก
                  </mat-error>
                </div>
                <div>
                  <span class="text-base font-bold opacity-50">รหัสโครงการ</span>
                  <input class="input-custom" type="text" placeholder="" autocomplete="off" formControlName="prefix"
                    [class.border-error]="(isSubmitted || groupProject.get('prefix')?.touched) && f['prefix'].errors">
                  <mat-error class="text-sm font-medium"
                    *ngIf="(isSubmitted || groupProject.get('prefix')?.touched) && f['prefix'].errors">
                    รหัสโครงการต้องเป็น 3 หลัก
                  </mat-error>
                </div>
                <div>
                  <span class="text-base font-bold opacity-50">เบอร์โทรศัพท์</span>
                  <input class="input-custom" type="text" placeholder="" autocomplete="off" formControlName="mobileNo"
                    [class.border-error]="(isSubmitted || groupProject.get('mobileNo')?.touched) && f['mobileNo'].errors">
                  <mat-error class="text-sm font-medium"
                    *ngIf="(isSubmitted || groupProject.get('mobileNo')?.touched) && f['mobileNo'].errors">
                    เบอร์โทรศัพท์ต้องกรอกอย่างน้อย 9 หลัก
                  </mat-error>
                </div>
                <div>
                  <span class="text-base font-bold opacity-50">ละติจูด</span>
                  <input class="input-custom" type="text" placeholder="" autocomplete="off" formControlName="latitude"
                    [class.border-error]="(isSubmitted || groupProject.get('latitude')?.touched) && f['latitude'].errors">
                  <mat-error class="text-sm font-medium"
                    *ngIf="(isSubmitted || groupProject.get('latitude')?.touched) && f['latitude'].errors">
                    กรุณากรอกละติจูด
                  </mat-error>
                </div>
                <div>
                  <span class="text-base font-bold opacity-50">ลองจิจูด</span>
                  <input class="input-custom" type="text" placeholder="" autocomplete="off" formControlName="longitude"
                    [class.border-error]="(isSubmitted || groupProject.get('longitude')?.touched) && f['longitude'].errors">
                  <mat-error class="text-sm font-medium"
                    *ngIf="(isSubmitted || groupProject.get('longitude')?.touched) && f['longitude'].errors">
                    กรุณากรอกลองจิจูด
                  </mat-error>
                </div>
                <div>
                  <span class="text-base font-bold opacity-50">ระยะเวลารับประกัน</span>
                  <input class="input-custom" type="text" placeholder="" autocomplete="off"
                    formControlName="periodGuaranteeDay"
                    [class.border-error]="(isSubmitted || groupProject.get('periodGuaranteeDay')?.touched) && f['periodGuaranteeDay'].errors">
                  <mat-error class="text-sm font-medium"
                    *ngIf="(isSubmitted || groupProject.get('periodGuaranteeDay')?.touched) && f['periodGuaranteeDay'].errors">
                    ระยะเวลารับประกันกรอกอย่างน้อย 1 หลัก
                  </mat-error>
                </div>
                <div>
                  <span class="text-base font-bold opacity-50">รูปภาพ</span>
                  <div class="mt-2">
                    <card-upload-image [isAction]="true" [image]="images[0]"
                      (event)="images && images.length <= 0 ? clickActionTableDataUpload() : ''">
                      <button (click)="clickActionTableDataUpload()" mat-button class="bg-gray-100 button-cdg">
                        <div class="flex items-center">
                          <mat-icon class="mr-2">add_photo_alternate</mat-icon>
                          เปลี่ยนรูปภาพ
                        </div>
                      </button>
                    </card-upload-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>