import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as moment from "moment";
import { filter, Subject, takeUntil } from "rxjs";
import { AutocompleteChipsModel } from "../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../models/TableModel";
import { PostRequest } from "../../../request/PostRequest";
import { PostFacade } from "../../../services/facade/PostFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { environment } from "../../../../environments/environment";
import { AutocompleteChips } from "../../shares/AutocompleteChips.component";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { CustomTable } from "../../shares/table/CustomTable.component";

const PAGE_NAME: string = 'content';

@Component({
  selector: 'content-page',
  templateUrl: './ContentPage.component.html'
})

export class ContentPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;
  public campaign: UntypedFormGroup;

  private postRequestval = new PostRequest;
  public environmentApi = environment;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private observManager: ObservableManager,
    private postFacade: PostFacade,
    private checkTokenService: CheckTokenService,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._campaign();
    this.postRequestval.startCreatedDateTime = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
    this.postRequestval.endCreatedDateTime = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
    this._getPost(null, null, true);
    this._getPost(0, this.limitPage, false, { 'createdDate': -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          if (res.data.value.page.name) res.data.value['pageName'] = res.data.value.page.name;
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          if (res.data.value.page.name) res.data.value['pageName'] = res.data.value.page.name;
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'รหัสโครงการ',
        tag: 'pageId',
        type: 'project_autocomp'
      },
      {
        name: 'ประเภท',
        tag: 'pinned',
        type: 'select',
        list: [
          {
            label: '(ข่าว)',
            value: 'false'
          },
          {
            label: '(ประกาศ)',
            value: 'true'
          }
        ]
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    this.data.map((res, index) => {
      if (!!res!.page!.name) this.data[index]['pageName'] = res!.page!.name;
    });
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const whereConditions: any = this.getFilterWhereCondition();
    this._getPost(count, this.limitPage, false, { 'createdDate': -1 }, this.postRequestval.whereConditions);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    const whereConditions: any = {};
    if (!filterResult) {
      return {};
    }

    if (!!filterResult!.pinned) {
      for (const key in filterResult) {
        if (!filterResult[key]) {
          continue;
        }
        const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
        whereConditions[key] = regex;
      }

      return whereConditions;
    } else {
      return filterResult;
    }
  }

  private _getPost(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any, optionalFields?: any) {
    this.postRequestval.count = count;
    this.postRequestval.limit = limit;
    this.postRequestval.offset = offset;
    this.postRequestval.orderBy = orderBy;
    this.postRequestval.whereConditions = whereConditions;

    if (optionalFields !== undefined) {
      if (optionalFields.postIds !== undefined) {
        this.postRequestval.postIds = optionalFields.postIds;
      }
      if (optionalFields.postType !== undefined) {
        this.postRequestval.postType = optionalFields.postType;
      }
      if (optionalFields.postStatus !== undefined) {
        this.postRequestval.postStatus = optionalFields.postStatus;
      }
    }

    this.postFacade.searchData(this.postRequestval).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'coverImage', header: 'รูป', tag: 'image', display: true, align: 'center', cell: (element: any) => `${!!element!.coverImage ? this.environmentApi.apiBaseURL + element.coverImage + '/image' : ''}` },
            { columnDef: 'title', header: 'รายการประกาศ', tag: 'text', display: true, cell: (element: any) => `${element.title ? element.title : 'ไม่มีผาดหัว'}` },
            { columnDef: 'pageName', header: 'โครงการ', tag: 'text', display: true, cell: (element: any) => `${element.pageName ? element.pageName : '-'}` },
            {
              columnDef: 'status', header: 'สถานะ', tag: 'text-color', display: true, cell: (element: any) => {
                return {
                  text: `${element.hidden ? 'ไม่เผยแพร่' : 'เผยแพร่'}`,
                  color: element.hidden ? '#ff0000' : '#18f318'
                }
              }
            },
            { columnDef: 'startDateTime', header: 'วันที่เผยแพร่', tag: 'text', display: true, cell: (element: any) => `${element.startDateTime ? moment(element.startDateTime).add(543, 'year').format('DD/MM/YYYY') : 'ไม่กำหนด'}` },
            { columnDef: 'endDateTime', header: 'วันที่สิ้นสุด', tag: 'text', display: true, cell: (element: any) => `${element.endDateTime ? moment(element.endDateTime).add(543, 'year').format('DD/MM/YYYY') : 'ไม่กำหนด'}` },
            { columnDef: 'pinned', header: 'ประเภท', tag: 'text', display: true, cell: (element: any) => `${element.pinned ? 'ประกาศ' : 'ข่าว'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickChip(event: any) {

  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  private _campaign() {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaign = this.formBuilder.group({
      start: new UntypedFormControl(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))),
      end: new UntypedFormControl(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)))
    });
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const whereConditions: any = this.getFilterWhereCondition();

    // search table
    if (!!whereConditions!.pageId) {
      this.postRequestval.pageId = filterResult.pageId;
    } else {
      this.postRequestval.pageId = undefined;
    }

    if (!!whereConditions!.pinned) {
      this.postRequestval.whereConditions = { pinned: (filterResult.pinned === 'true' ? true : false) };
    } else {
      this.postRequestval.whereConditions = undefined;
    }

    // search table
    this._getPost(null, null, true, { 'createdDate': -1 }, this.postRequestval.whereConditions);
    this._getPost(0, this.limitPage, false, { 'createdDate': -1 }, this.postRequestval.whereConditions);
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event
      }
    });
  }

  public async searchDate() {
    await this.checkTokenService.checkStatus();
    let startCreatedDateTime: any = (this.campaign.get('start')?.value ? moment(this.campaign.get('start')?.value).format('YYYY-MM-DD') : new Date('2019-01-16')) + 'T00:00:00.000Z';
    let endDateTime: any = (this.campaign.get('end')?.value ? moment(this.campaign.get('end')?.value).format('YYYY-MM-DD') : (moment((new Date().getFullYear()) + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate())).format('YYYY-MM-DD'))) + 'T23:59:59.000Z';
    this.postRequestval.startCreatedDateTime = startCreatedDateTime;
    this.postRequestval.endCreatedDateTime = endDateTime;
    this.refreshTable();
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }
}