import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { RepairRequest } from '../../request/RepairRequest';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';
import { GuaranteeDateRequest } from '../../request/GuaranteeDateRequest';
import { LogQueueDateRequest } from '../../request/LogQueueDateRequest';

@Injectable()
export class RepairAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/repair/');
    }

    // [@/PUT] admin/repair/:id/repair_status /: repairStatusId / change_status

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public approve = (id: string) => this.put<MethodResponseInf<RepairRequest>>(`${(id) + '/approve'}`, this.getDefaultHeader(), null, null);
    public updateGuaranteeDate = (id: string, date: GuaranteeDateRequest) => this.put<MethodResponseInf<RepairRequest>>(`${(id) + '/guarantee_date'}`, this.getDefaultHeader(), null, date);
    public unapprove = (id: string, description: any) => this.put<MethodResponseInf<RepairRequest>>(`${(id) + '/unapprove'}`, this.getDefaultHeader(), null, description);
    public cancelapprove = (id: string) => this.put<MethodResponseInf<RepairRequest>>(`${(id) + '/cancel_approve'}`, this.getDefaultHeader(), null, null);
    public upDateStatus = (repairId: string, repairStatusId: string) => this.put<MethodResponseInf<any>>(`${(repairId) + '/repair_status/'}${(repairStatusId) + '/change_status'}`, this.getDefaultHeader(), null, null);
    public searchLogQueuedate = (repairId: string, data: LogQueueDateRequest) => this.post<MethodResponseInf<any>>(`${(repairId) + '/queuedate/logs/search'}`, this.getDefaultHeader(), null, data);
    public createLogQueuedate = (repairId: string, data: LogQueueDateRequest) => this.post<MethodResponseInf<any>>(`${(repairId) + '/queuedate/logs'}`, this.getDefaultHeader(), null, data);
    public searchForemanData = (id: string, request?: RepairRequest) => this.post<MethodResponseInf<any>>(`${(id) + '/foreman/post/search'}`, this.getDefaultHeader(), null, request);
    public updateGuarantee = (id: string, isOverGuarantee: boolean) => this.put<MethodResponseInf<any>>(`${(id)}` + '/over_guarantee', this.getDefaultHeader(), null, { isOverGuarantee: isOverGuarantee });
    public getValidateQueue = () => this.get<MethodResponseInf<any[]>>('validate/queuedate', this.getDefaultHeader());
    public foremanDate = (id: string, request?: any) => this.put<MethodResponseInf<RepairRequest>>(`${(id) + '/repair_foreman_date'}`, this.getDefaultHeader(), null, request);
    public successForemanDate = (id: string, request?: any) => this.put<MethodResponseInf<RepairRequest>>(`${(id) + '/success_repair_foreman_date'}`, this.getDefaultHeader(), null, request);
    public successWalkinDateTime = (id: string, request?: any) => this.put<MethodResponseInf<RepairRequest>>(`${(id)}` + '/success_walkin_date', this.getDefaultHeader(), null, request);
    public successRepairDateTime = (id: string, request?: any) => this.put<MethodResponseInf<RepairRequest>>(`${(id) + '/success_repair_date'}`, this.getDefaultHeader(), null, request);
}