import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenManager } from "./AuthenManager.service";
import { SocketIoManager } from "./SocketIoManager.service";

const PATH_REDIRECT: string = 'path';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authenManager: AuthenManager,
        private socketIoManager: SocketIoManager) {

    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let url: string = decodeURIComponent(state.url);
        localStorage.setItem(PATH_REDIRECT, url);
        sessionStorage.setItem(PATH_REDIRECT, url);
        if (this.authenManager.getUserToken() && this.authenManager.getCurrentUser()) {
            this.socketIoManager.setAuth('token', this.authenManager.getUserToken());
            this.socketIoManager.connect();
            return true;
        }

        this.authenManager.clearStorage();
        return false;
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(route, state);
    }
}