import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { CustomTable } from "../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../models/TableModel";
import { PageFacade } from "../../../../services/facade/PageFacade.service";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { environment } from "../../../../../environments/environment";
import { AutocompleteChips } from "../../../shares/AutocompleteChips.component";

const PAGE_NAME: string = 'project';

@Component({
  selector: 'project-page',
  templateUrl: './ProjectPage.component.html'
})

export class ProjectPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;

  public environmentApi = environment;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private pageFacade: PageFacade,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._getPageProject(null, null, true);
    this._getPageProject(0, this.limitPage, false, { 'createdDate': -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'รหัสโครงการ',
        tag: 'prefix',
        type: 'input'
      },
      {
        name: 'ชื่อโครงการ',
        tag: 'name',
        type: 'input'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const whereConditions: any = this.getFilterWhereCondition();
    this._getPageProject(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    return whereConditions;
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const whereConditions: any = this.getFilterWhereCondition();

    // search table
    this._getPageProject(null, null, true, { 'createdDate': -1 }, whereConditions);
    this._getPageProject(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event
      }
    });
  }

  private _getPageProject(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any) {
    let val = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      whereConditions
    };

    this.pageFacade.searchData(val).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'coverURL', header: 'รูป', tag: 'image', display: true, align: 'center', cell: (element: any) => `${element.coverURL !== '' && element.coverURL !== null && element.coverURL !== undefined ? this.environmentApi.apiBaseURL + element.coverURL + '/image' : ''}` },
            { columnDef: 'prefix', header: 'รหัสโครงการ', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.prefix ? element.prefix : '-'}` },
            { columnDef: 'id', header: 'ชื่อโครงการ', tag: 'text', display: true, cell: (element: any) => `${element.name ? element.name : '-'}` },
            { columnDef: 'mobileNo', header: 'เบอร์โทรศัพท์', tag: 'text', display: true, cell: (element: any) => `${element.mobileNo ? element.mobileNo : '-'}` },
            { columnDef: 'latitude', header: 'ละติจูด', tag: 'text', display: true, cell: (element: any) => `${element.latitude ? element.latitude : '-'}` },
            { columnDef: 'longitude', header: 'ลองจิจูด', tag: 'text', display: true, cell: (element: any) => `${element.longitude ? element.longitude : '-'}` },
            { columnDef: 'periodGuaranteeDay', header: 'ระยะเวลารับประกัน', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.periodGuaranteeDay ? element.periodGuaranteeDay : '-'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true
    }
  }
}
