import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';
import { DocumentManualRequest } from 'src/app/request/DocumentManualRequest';
import { SearchFilter } from 'src/app/request/SearchFilterRequest';

const TOKEN_KEY: string = 'token';

@Injectable()
export class DocumentManualFacade extends MethodFacade {
    // protected routPath: string = environment.apiBaseURL;

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public createData = (request: DocumentManualRequest) => this.post<MethodResponseInf<any>>(`admin/document_manual/upload`, this.getDefaultHeader(), null, request);
    public updateData = (id: string, request: DocumentManualRequest) => this.put<MethodResponseInf<any>>(`admin/document_manual/${(id)}`, this.getDefaultHeader(), null, request);
    public deleteData = (id: string) => this.delete<MethodResponseInf>(`admin/document_manual/${(id)}`, this.getDefaultHeader(), null);
    public getData = (id: string) => this.get<MethodResponseInf<any>>(`admin/document_manual/download/${(id)}`, this.getDefaultHeader(), null);
    public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<any[]>>(`document_manual/search`, this.getDefaultHeader(), null, request);
}