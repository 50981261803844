import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { PageAdminFacade } from "../../../services/facade/PageAdminFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { DialogUpload } from "../dialog/DialogUpload.component";

@Component({
    selector: 'slider-project',
    templateUrl: './SliderProject.component.html',
})
export class SliderProject implements OnInit {
    protected routPath: string = environment.apiBaseURL;
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public groupProject: UntypedFormGroup;
    public isSubmitted: boolean = false;
    public images: any[] = [];
    public isImageChange = false;

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private pageAdminFacade: PageAdminFacade,
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient,
        private checkTokenService: CheckTokenService,
    ) {
        this.formProject();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                if (this.dataDetail.mode === 'edit') {
                    this.getProject(this.dataDetail.value);
                    setTimeout(() => {
                        this.groupProject.get('prefix')?.disable();
                    }, 10);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.groupProject.get('prefix')?.enable();
                    }, 10);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.groupProject.get('name')?.value !== this.dataJob.name ||
                this.groupProject.get('prefix')?.value !== this.dataJob.prefix ||
                this.groupProject.get('mobileNo')?.value !== this.dataJob.mobileNo ||
                this.groupProject.get('latitude')?.value !== this.dataJob.latitude ||
                this.groupProject.get('longitude')?.value !== this.dataJob.longitude ||
                this.groupProject.get('periodGuaranteeDay')?.value !== this.dataJob.periodGuaranteeDay ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.getProject(this.dataDetail.value);
                        this.isSubmitted = false;
                        this.isImageChange = false;
                        this.images = [];
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupProject.get('name')?.value ||
                this.groupProject.get('prefix')?.value ||
                this.groupProject.get('mobileNo')?.value ||
                this.groupProject.get('latitude')?.value ||
                this.groupProject.get('longitude')?.value ||
                this.groupProject.get('periodGuaranteeDay')?.value ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.groupProject.reset();
                        this.isSubmitted = false;
                        this.isImageChange = false;
                        this.images = [];
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
        this.isImageChange = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.groupProject.get('name')?.value !== this.dataJob.name ||
                this.groupProject.get('prefix')?.value !== this.dataJob.prefix ||
                this.groupProject.get('mobileNo')?.value !== this.dataJob.mobileNo ||
                this.groupProject.get('latitude')?.value !== this.dataJob.latitude ||
                this.groupProject.get('longitude')?.value !== this.dataJob.longitude ||
                this.groupProject.get('periodGuaranteeDay')?.value !== this.dataJob.periodGuaranteeDay ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                        this.clearFormData();
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupProject.get('name')?.value ||
                this.groupProject.get('prefix')?.value ||
                this.groupProject.get('mobileNo')?.value ||
                this.groupProject.get('latitude')?.value ||
                this.groupProject.get('longitude')?.value ||
                this.groupProject.get('periodGuaranteeDay')?.value ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.clearFormData();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.clearFormData();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private getProject(data: any) {
        this.dataJob = data;
        this.groupProject.get('name')?.setValue(this.dataJob.name);
        this.groupProject.get('prefix')?.setValue(this.dataJob.prefix);
        this.groupProject.get('mobileNo')?.setValue(this.dataJob.mobileNo);
        this.groupProject.get('latitude')?.setValue(this.dataJob.latitude);
        this.groupProject.get('longitude')?.setValue(this.dataJob.longitude);
        this.groupProject.get('periodGuaranteeDay')?.setValue(this.dataJob.periodGuaranteeDay);
        this.images = [];
        if (this.dataJob.coverURL !== undefined && this.dataJob.coverURL !== null && this.dataJob.coverURL !== '') {
            // load base64 to image
            this.httpClient.get(this.routPath + this.dataJob.coverURL).toPromise().then((result: any) => {
                this.images.push(result.data);
            }).catch((err) => {
                // error
                console.log(err);
            });
        }
    }

    public formProject() {
        this.groupProject = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(254)]],
            prefix: ['', [Validators.required, Validators.pattern('[A-Z]{3}'), Validators.minLength(3), Validators.maxLength(3)]],
            mobileNo: ['', [Validators.pattern('^[0-9]+$'), Validators.minLength(4)]],
            latitude: ['', [Validators.pattern('^([0-9.-]+).+?([0-9.-]+)$')]],
            longitude: ['', [Validators.pattern('^([0-9.-]+).+?([0-9.-]+)$')]],
            periodGuaranteeDay: ['', [Validators.required, Validators.pattern('^[0-9]+$')]]
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupProject.controls;
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'edit') {
            if (this.groupProject.invalid || (this.groupProject.get('name')?.value === this.dataJob.name && this.groupProject.get('prefix')?.value === this.dataJob.prefix
                && this.groupProject.get('mobileNo')?.value === this.dataJob.mobileNo && this.groupProject.get('latitude')?.value === this.dataJob.latitude
                && this.groupProject.get('longitude')?.value === this.dataJob.longitude && this.groupProject.get('periodGuaranteeDay')?.value === this.dataJob.periodGuaranteeDay) && !this.isImageChange) {
                return;
            }

            this.isSubmitted = true;
            let image = undefined;
            if (this.images.length > 0) {
                image = this.images[0];
            }

            this.pageAdminFacade.updateData(this.dataDetail.value.id || this.dataDetail.value._id, {
                name: this.groupProject.get('name')?.value,
                prefix: String(this.groupProject.get('prefix')?.value).toUpperCase(),
                mobileNo: this.groupProject.get('mobileNo')?.value,
                latitude: this.groupProject.get('latitude')?.value,
                longitude: this.groupProject.get('longitude')?.value,
                periodGuaranteeDay: this.groupProject.get('periodGuaranteeDay')?.value,
                coverURL: image,
                isOfficial: false
            }).then((res: any) => {
                if (res) {
                    this.getProject(res.data);
                    this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.getProject(this.dataDetail.value);
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            if (this.groupProject.invalid) {
                return;
            }

            this.isSubmitted = true;
            let image = undefined;
            if (this.images.length > 0) {
                image = this.images[0];
            }

            this.pageAdminFacade.createData({
                name: this.groupProject.get('name')?.value,
                prefix: String(this.groupProject.get('prefix')?.value).toUpperCase(),
                mobileNo: this.groupProject.get('mobileNo')?.value,
                latitude: this.groupProject.get('latitude')?.value,
                longitude: this.groupProject.get('longitude')?.value,
                periodGuaranteeDay: this.groupProject.get('periodGuaranteeDay')?.value,
                coverURL: image,
                isOfficial: false
            }).then((res: any) => {
                if (res) {
                    this.dataJob = res.data;
                    this.createTableRow(this.dataJob, 'create');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                    // clear form
                    this.clearFormData();
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            });
        }
    }

    public clearFormData(): void {
        this.images = [];
        this.isImageChange = false;
        this.groupProject.reset();
        this.formProject();
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.name + '"'
            }).then((res: any) => {
                if (res) {
                    this.pageAdminFacade.deleteData(this.dataDetail.value.id || this.dataDetail.value._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.clearFormData();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            }).then((res: any) => {
                                if (res) {

                                }
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }

            });
        }
    }

    public clickActionTableDataUpload() {
        let val = ['.jpg', '.jpeg'];
        if (this.images.length > 1) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'แนบรูปภาพสูงสุดไม่เกิน 1 รูปเท่านั้น',
                cancel: false
            });

            return;
        }
        const dialogRef = this.dialog.open(DialogUpload, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                header: 'อัพโหลดรูปภาพ',
                accept: val,
                type: 'image'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result[0].data.size > ((1024 * 1024) * 4)) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'การอัพโหลดล้มเหลว. ขนาดสูงสุดของไฟล์ JPG คือ 4 MB.',
                        cancel: false
                    });
                } else {
                    if (result[0].data.type === 'image/jpeg') {
                        this.images = [];
                        this.images.push((result[0].base64));
                        this.isImageChange = true;
                    } else {
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: 'รองรับ' + val + 'เท่านั้น',
                            cancel: false
                        });
                    }
                }
            }
        });
    }
}

