<div class="dialog-custom dialog-custom-detail flex lg:flex-row flex-col justify-start lg:h-full h-auto">
    <div class="wrapper-image lg:flex-1 flex-none lg:h-screen h-[65vh] bg-black relative flex items-center justify-center overflow-hidden select-none"
        [class.!h-screen]="isFullscreen">
        <div class="absolute top-2 left-3 z-[1]">
            <button mat-icon-button class="icon-ripper-center text-white bg-black/30" matTooltip="กดปุ่ม Esc เพื่อปิด"
                (click)="clickClose()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="absolute top-2 right-3 z-[1] flex items-center gap-2">
            <button mat-icon-button class="icon-ripper-center text-white bg-black/30" [disabled]="countZoom === 2"
                [ngClass]="{'opacity-50 cursor-not-allowed': countZoom === 2}" (click)="clickZoom('zoomIn')">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button mat-icon-button class="icon-ripper-center text-white bg-black/30" [disabled]="countZoom === 1"
                [ngClass]="{'opacity-50 cursor-not-allowed': countZoom === 1}" (click)="clickZoom('zoomOut')">
                <mat-icon>zoom_out</mat-icon>
            </button>
            <button mat-icon-button class=" icon-ripper-center text-white bg-black/30"
                matTooltip="{{isFullscreen ? 'ออกจาก' : 'เข้าสู่'}}โหมดเต็มจอ" (click)="isFullscreen = !isFullscreen"
                *ngIf="data.isContent">
                <mat-icon>{{isFullscreen ? 'close_fullscreen' : 'open_in_full'}}</mat-icon>
            </button>
        </div>
        <button mat-icon-button
            class="absolute left-3 z-[1] w-12 h-12 icon-ripper-center bg-white/50 hover:bg-white hover:scale-110 transition-all"
            (click)="clickNavigateBefore()"
            *ngIf="data.index > 0 && data.gallery.length > 1 && checkIsArray(data.gallery)">
            <mat-icon class="w-10 h-10 text-[40px] leading-[40px]">navigate_before</mat-icon>
        </button>
        <img id="image" loading="lazy" class="max-h-full"
            [src]="checkIsArray(data.gallery) ? (data.gallery[data.index].coverURLs ? data.gallery[data.index].coverURLs : data.gallery[data.index]) : data.gallery"
            [ngStyle]="{'transform': 'translate(' + positionImage.xPercent + 'px, ' + positionImage.yPercent + 'px) scale(' + countZoom + ')'}"
            alt="">
        <button mat-icon-button
            class="absolute right-3 z-[1] w-12 h-12 icon-ripper-center bg-white/50 hover:bg-white hover:scale-110 transition-all"
            (click)="clickNavigateNext()"
            *ngIf="(data.index + 1) < data.gallery.length && data.gallery.length > 1 && checkIsArray(data.gallery)">
            <mat-icon class="w-10 h-10 text-[40px] leading-[40px]">navigate_next</mat-icon>
        </button>
    </div>
    <div class="lg:flex-none flex-1 lg:w-[360px] w-full flex flex-col overflow-x-hidden overflow-y-auto"
        *ngIf="!isFullscreen && data.isContent">
        <div class="shrink-0 p-4 border-b">
            <p class="text-lg font-bold">{{data.detail.title}}</p>
            <p class="text-sm font-normal opacity-50 mt-1 flex gap-4">
                <span class="flex-none">อัพโหลดเมื่อวันที่ {{dateFormat(data.detail.createdDate , 'DD-MM-YYYY')}}</span>
                <span class="flex-1 truncate">โดย {{data.detail.user.displayName}}</span>
            </p>
        </div>
        <div class="grow p-4 break-all">
            <read-more #readmore [content]="data.gallery[data.index].description" [limit]="160"></read-more>
        </div>
    </div>
</div>