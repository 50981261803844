import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as moment from "moment";
import { filter, Subject, takeUntil } from "rxjs";
import { CustomTable } from "../../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../../models/TableModel";
import { QuestionnaireFacade } from "../../../../../services/facade/QuestionnaireFacade.service";
import { ObservableManager } from "../../../../../services/ObservableManager.service";
import { AutocompleteChips } from "../../../../shares/AutocompleteChips.component";

const PAGE_NAME: string = 'assessment';

@Component({
    selector: 'assessment-page',
    templateUrl: './AssessmentPage.component.html'
})

export class AssessmentPage implements OnInit {
    public static readonly PAGE_NAME: string = PAGE_NAME;
    // Start search filter -----------------------
    public autoCompList: AutocompleteChipsModel[] = [];
    @ViewChild('searchText') searchText: AutocompleteChips;
    // End search filter -----------------------

    // Start table
    @ViewChild('table') tableRow: CustomTable;
    public columns: TableColumnModel[];
    public data: any[] = [];
    public limitTable = 100;
    public limitPage = 101;
    public lengthDataTable: number;
    // End table

    private destroy = new Subject<void>();

    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';

    public menu: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private observManager: ObservableManager,
        private questionnaireFacade: QuestionnaireFacade
    ) {
        this.router.events
            .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
            .subscribe((event: NavigationEnd) => {

            });

        this.observManager.subscribe('menu', (res: any) => {
            if (res) {
                this.menu = res.data;
            }
        });
    }

    public async ngOnInit(): Promise<void> {
        this.getAutocompleteChip();
        this._getSearch(null, null, true);
        this._getSearch(0, this.limitPage, false, { 'createdDate': -1 });
    }

    public ngAfterViewInit(): void {
        this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
            if (res) {
                if (res.data.mode === 'edit') {
                    if (res.data.value.isActive) {
                        this.data.map((res, index) => {
                            res['isActive'] = false;
                        });
                    }
                    this.tableRow && this.tableRow.editRow(res.data);
                } else if (res.data.mode === 'create') {
                    if (res.data.value.isActive) {
                        this.data.map((res, index) => {
                            res['isActive'] = false;
                        });
                    }
                    this.tableRow && this.tableRow.createRow(res.data.value);
                } else if (res.data.mode === 'delete') {
                    this.tableRow && this.tableRow.deleteRow(res.data.index);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
        this.observManager.complete('menu');
        this.observManager.complete(this.textSucceedDetail);
    }

    public getAutocompleteChip() {
        this.autoCompList = [
            {
                name: 'ชื่อแบบประเมิน',
                tag: 'name',
                type: 'input'
            },
            {
                name: 'สถานะ',
                tag: 'isActive',
                type: 'select',
                list: [
                    {
                        label: 'ใช้งาน',
                        value: 'true'
                    },
                    {
                        label: 'ไม่ใช้งาน',
                        value: 'false'
                    }
                ]
            }
        ];
    }

    public loadData(value: any) {
        this.data = value;
        // if (this.data !== undefined && this.data.length < this.limitPage) {
        //   this.lengthDataTable = this.data ? this.data.length : 0;
        // }
    }

    public clickPaginator(event: any) {
        let count: number = this.limitPage * event.pageIndex;
        const conditionObj: any = this.getFilterWhereCondition();
        const whereConditions: any = conditionObj.whereConditions;
        const optionalFields: any = conditionObj.optionalFields;
        this._getSearch(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
    }

    public getFilterWhereCondition() {
        const filterResult = this.searchText.getFilterResults();
        if (!filterResult) {
            return {};
        }

        const whereConditions: any = {};
        for (const key in filterResult) {
            if (!filterResult[key]) {
                continue;
            }

            if (key === 'isActive') {
                whereConditions[key] = filterResult[key] === 'true' ? true : false;
            } else {
                const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
                whereConditions[key] = regex;
            }
        }

        const optionalFields: any = {};
        return {
            whereConditions,
            optionalFields
        };
    }

    public refreshTable() {
        const filterResult = this.searchText.getFilterResults();
        if (!filterResult) {
            return;
        }

        const conditionObj: any = this.getFilterWhereCondition();
        const whereConditions: any = conditionObj.whereConditions;
        const optionalFields: any = conditionObj.optionalFields;
        // search table
        this._getSearch(null, null, true, { 'createdDate': -1 }, whereConditions, optionalFields);
        this._getSearch(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
    }

    public clickChip(event: any) {
    }

    public closeChip(event: any) {
        this.refreshTable();
    }

    public filterTextComfirm(event: any) {
        this.refreshTable();
    }

    public clickTableRow(event: any) {
        this.observManager.createSubject(this.textSliderDetail);
        this.observManager.publish(this.textSliderDetail, {
            data: {
                mode: 'edit',
                index: event.index,
                value: event.event
            }
        });
    }

    public clickCreate() {
        this.observManager.createSubject(this.textSliderDetail);
        this.observManager.publish(this.textSliderDetail, {
            data: {
                mode: 'create'
            }
        });
    }

    private _getSearch(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any, optionalFields?: any) {
        let val: any = {
            offset: offset,
            limit: limit,
            count: count,
            orderBy: orderBy,
            whereConditions,
            type: 'repair'
        };

        if (optionalFields !== undefined) {
            for (const key in optionalFields) {
                val[key] = optionalFields[key];
            }
        }

        this.questionnaireFacade.searchData(val).then((res: any) => {
            if (res) {
                if (count === true) {
                    this.lengthDataTable = res.data;
                } else {
                    this.loadData(res.data);
                    this.columns = [
                        { columnDef: 'name', header: 'ชื่อแบบประเมิน', align: 'left', tag: 'text', display: true, cell: (element: any) => `${element.name ? element.name : '-'}` },
                        { columnDef: 'questionnaires', header: 'จำนวนคำถาม', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.questionnaires ? element.questionnaires.length : '-'}` },
                        { columnDef: 'createdDate', header: 'วันที่สร้าง', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.createdDate ? moment(element.createdDate).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
                        { columnDef: 'isActive', header: 'สถานะ', align: 'left', tag: 'text', display: true, cell: (element: any) => `${element.isActive ? 'ใช้งาน' : 'ไม่ใช้งาน'}` },
                    ];
                }
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    public clickDisplayTable(index: number) {
        if (this.columns[index].display === true) {
            this.columns[index].display = false;
        } else {
            this.columns[index].display = true;
        }
    }
}
