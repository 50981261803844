import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { map, Observable, startWith } from "rxjs";
import { AutocompleteChipsModel } from "../../models/AutocompleteChipsModel";
import { ObservableManager } from "../../services/ObservableManager.service";
import { DialogComplaint } from "./shares";

@Component({
    selector: 'autocomplete-chips',
    templateUrl: './AutocompleteChips.component.html',
})
export class AutocompleteChips implements OnInit {
    // Start search filter -----------------------
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public autoCtrl = new UntypedFormControl();
    public filteredValues: Observable<any[]>;
    public values: any[] = [];
    @Input()
    public data: AutocompleteChipsModel[] = [];
    @ViewChild('autoInput')
    public autoInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto')
    public matAutocomplete: MatAutocomplete;
    @ViewChild('filterInput')
    public filterInput: ElementRef<HTMLInputElement>;
    // End search filter -----------------------

    @Input()
    public textButtonFilter: string = 'ตัวกรอง';
    @Input()
    public placeholderSearchFilter: string = 'ค้นหาตัวกรอง...';
    @Input()
    public class: string | [string];
    @Output()
    public event: EventEmitter<any> = new EventEmitter();
    @Output()
    public eventChip: EventEmitter<any> = new EventEmitter();
    @Output()
    public closeChip: EventEmitter<any> = new EventEmitter();
    @Output('filterConfirm')
    public filterDialogConfirmEvent: EventEmitter<any> = new EventEmitter();
    @Output('filterCancel')
    public filterDialogCancelEvent: EventEmitter<any> = new EventEmitter();

    private filterResults: any = {};
    private filterFullResults: any = {};

    constructor(
        private dialog: MatDialog,
        private observManager: ObservableManager
    ) {
        this.filteredValues = this.autoCtrl.valueChanges.pipe(
            startWith(null),
            map(engineerName => this.filterOnValueChange(engineerName))
        );
    }

    public ngOnInit() {
        this.onScrollX();
    }

    public clickEvent(event: any) {
        this.event.emit(event);
    }

    public addType(event: MatChipInputEvent): void {
        if (this.matAutocomplete.isOpen) {
            return;
        }

        const value = event.value;
        if ((value || '').trim()) {
            this.selectTypeByName(value.trim());
        }

        this.resetInputs();
    }

    public removeType(value: any): void {
        const index = this.values.indexOf(value);
        if (index >= 0) {
            this.values.splice(index, 1);
            this.resetInputs();
            this.clickEvent(this.values);
        }

        if (value) {
            if (this.filterResults[value.tag] !== undefined) {
                delete this.filterResults[value.tag];
            }
            if (this.filterFullResults[value.tag] !== undefined) {
                delete this.filterFullResults[value.tag];
            }
        }

        this.closeChip.emit(value);
    }

    public typeSelected(event: MatAutocompleteSelectedEvent): void {
        this.selectTypeByName(event.option.value);
        this.resetInputs();
    }

    private resetInputs() {
        // clear input element
        this.autoInput.nativeElement.value = '';
        this.autoCtrl.setValue(null);
    }

    private filterOnValueChange(value: string | null): String[] {
        let result: String[] = [];
        let lessSelected = this.data.filter(type => this.values.indexOf(type) < 0);
        if (value) {
            result = this.filterType(lessSelected, value);
        } else {
            result = lessSelected.map(type => type.name);
        }
        return result;
    }

    private filterType(typeList: any[], value: String): String[] {
        let filteredTypeList: any[] = [];
        const filterValue = value.toLowerCase();
        let typeMatching = typeList.filter(type => type.name.toLowerCase().indexOf(filterValue) === 0);
        if (typeMatching.length) {
            filteredTypeList = typeMatching;
        } else {
            filteredTypeList = typeList;
        }

        return filteredTypeList.map(type => type.name);
    }

    private selectTypeByName(value: any) {
        let found = this.data.filter(type => type.name == value);
        if (found.length) {
            this.values.push(found[0]);
            this.clickEvent(this.values);
            this.showDialog(found[0]);
        }
    }

    public clickOpenAutocomplete() {
        this.autoInput.nativeElement.focus();
        if (this.matAutocomplete.isOpen) {
            setTimeout(() => {
                this.filterInput.nativeElement.focus();
            }, 100);
        }
    }

    public onScrollX() {
        let scrollContainer = document.querySelector(".mat-chip-list");

        // Setup our function to run on various events
        let someFunction = function (event: any) {
            if (event) {
                // event.preventDefault();
                scrollContainer!.scrollLeft += event.deltaY;
            }
        };

        // // Add our event listeners
        // window.addEventListener('scroll', someFunction, false);
        scrollContainer!.addEventListener('wheel', someFunction, true);
    }

    public clickChipList(value: any) {
        this.eventChip.emit(value);
        this.showDialog(value);
    }

    public showDialog(value: any) {
        const currentData = value.tag === 'repairStatus' ? this.filterFullResults[value.tag] : this.filterResults[value.tag];

        const dialogRef = this.dialog.open(DialogComplaint, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                header: value.name,
                type: value.type,
                tag: value.tag,
                list: value.list,
                value: currentData
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.action === 'confirm') {
                this.filterResults[value.tag] = result.value;
                this.filterFullResults[value.tag] = result;
                this.filterDialogConfirmEvent.emit({
                    name: value.name,
                    type: value.type,
                    tag: value.tag,
                    value: result.value,
                    label: result.label
                });
            } else if (result.action === 'close') {
                this.filterDialogCancelEvent.emit(
                    {
                        name: value.name,
                        type: value.type,
                        tag: value.tag
                    }
                );
            }
        });
    }

    public getFilterResults() {
        return this.filterResults;
    }

    public getResultLabel(value: any): string {
        if (!value) {
            return '-';
        }

        let label = this.filterResults[value.tag] ? this.filterResults[value.tag] : '-';
        if (this.filterFullResults[value.tag] !== undefined) {
            label = this.filterFullResults[value.tag].label ? this.filterFullResults[value.tag].label : '-';
        }

        return label;
    }
}