<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper class="wrapper-slider-detail bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center"
                        (click)="clickCancel('close')">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                </div>
                <div class="flex-none flex items-center">
                    <div class="grid grid-cols-2 gap-2">
                        <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel('reset')">
                            คืนค่า
                        </button>
                        <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                            บันทึก
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-1 flex items-start">
                <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
                    <div [formGroup]="formGroup" class="w-full md:w-[700px] xl:w-[760px] grid grid-cols-1 gap-6">
                        <div class="card-detail">
                            <div class="flex items-center min-h-[80px] border-b p-4">
                                <div class="flex-1 flex flex-col">
                                    <span class="text-lg font-bold">
                                        การแจ้งเตือนล่วงหน้า
                                    </span>
                                    <span class="text-sm font-normal opacity-50 mt-1">
                                        ตั้งค่าการแจ้งเตือนล่วงหน้า
                                    </span>
                                </div>
                            </div>
                            <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                <div>
                                    <div class="flex gap-4">
                                        <img class="flex-none w-6 h-6 mt-4"
                                            src="../../../../assets/images/app/add-user.svg" alt="">
                                        <mat-form-field appearance="fill" class="flex-1 custom">
                                            <mat-label class="text-base select-none"
                                                [ngClass]="{'text-red-500': (stateBefore.invalid && stateBefore.touched) || isErrorBefore}">แจ้งเตือนไปยัง</mat-label>
                                            <input #inputBefore matInput type="text" class="leading-normal"
                                                placeholder="aaa@email.com" [matAutocomplete]="autoBefore"
                                                [formControl]="stateBefore" (keyup)="onChangeEmail($event, 'before')"
                                                (keypress)="onChangeEmail($event, 'before')"
                                                (keyup.enter)="stateBefore.valid ? onEnter($event, 'before') : null">
                                            <mat-autocomplete #autoBefore="matAutocomplete" [displayWith]="displayFn">
                                                <div class="max-h-full overflow-y-auto" infinite-scroll
                                                    [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
                                                    [infiniteScrollUpDistance]="scrollUpDistance"
                                                    [infiniteScrollThrottle]="throttle" (scrolled)="onScrollUser()">
                                                    <mat-option
                                                        *ngFor="let item of filteredBeforeUserAdmins | async; let i=index"
                                                        [value]="item" (click)="clickAddUser(item, 'before')">
                                                        <span>{{ item.email }}</span> |
                                                        <small>name: {{item.displayName}}</small>
                                                    </mat-option>
                                                </div>
                                            </mat-autocomplete>
                                            <mat-hint class="text-sm font-medium text-red-500"
                                                *ngIf="(stateBefore.invalid && stateBefore.touched) || isErrorBefore">
                                                กรอกในรูปแบบอีเมล
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <ul role="list" class="ml-10 divide-y divide-slate-200">
                                            <ng-container formArrayName="before"
                                                *ngFor="let item of before.controls; let i=index">
                                                <li [formGroupName]="i"
                                                    class="flex items-center gap-2 py-2 first:pt-0 last:pb-0">
                                                    <ng-container
                                                        *ngIf="item.get('imageURL')?.value; else elseTemplateImageBeforeUsers">
                                                        <img class="h-10 w-10 rounded-full border"
                                                            [src]="environmentApi.apiBaseURL + item.get('imageURL')?.value + '/image'"
                                                            alt="" />
                                                    </ng-container>
                                                    <ng-template #elseTemplateImageBeforeUsers>
                                                        <div
                                                            class="flex-none h-10 w-10 rounded-full border flex justify-center items-center text-white font-bold text-xl bg-[color:var(--red-cdg)]">
                                                            {{item.get('email')?.value ?
                                                            item.get('email')?.value.charAt(0).toUpperCase() :
                                                            item.get('displayName')?.value.charAt(0).toUpperCase()}}
                                                        </div>
                                                    </ng-template>
                                                    <p class="flex-1 text-base font-medium text-slate-900 line-clamp-1">
                                                        {{item.get('email')?.value}}
                                                    </p>
                                                    <div class="flex-none flex items-center justify-center gap-3">
                                                        <mat-select class="select-custom mt-0"
                                                            [disableOptionCentering]="true" [placeholder]="'แจ้งเตือน'"
                                                            formControlName="typeNoti"
                                                            [class.border-error]="(isSubmitted || item.get('typeNoti')?.touched) && item.get('typeNoti')?.errors"
                                                            [disabled]="!!item.get('typeNoti')?.value">
                                                            <ng-container
                                                                *ngIf="item.get('id')?.value; else elseTemplateBeforeType">
                                                                <ng-container
                                                                    *ngFor="let type of notiTypes; let i=index">
                                                                    <mat-option class="font-medium"
                                                                        [value]="type.value">
                                                                        {{type.text}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-template #elseTemplateBeforeType>
                                                                <mat-option class="font-medium" [value]="'email'">
                                                                    Email
                                                                </mat-option>
                                                            </ng-template>
                                                        </mat-select>
                                                        <button mat-icon-button
                                                            class="flex-none group icon-ripper-center"
                                                            (click)="clickDeleteUser(i, item.get('email')?.value, 'before')">
                                                            <mat-icon
                                                                class="opacity-50 group-hover:opacity-100">close</mat-icon>
                                                        </button>
                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 py-6 grid grid-cols-1 gap-4 border-t">
                                <div class="flex gap-4">
                                    <img class="flex-none w-6 h-6"
                                        [ngClass]="dateData.controls.length >= 1 ? 'mt-4' : 'mt-3.5'"
                                        src="../../../../assets/images/menu/bell.svg" alt="">
                                    <div class="flex-1 flex flex-col gap-2">
                                        <ng-container formArrayName="dateData"
                                            *ngFor="let item of dateData.controls; let i=index">
                                            <div [formGroupName]="i" class="flex items-center gap-4">
                                                <mat-select formControlName="type" class="select-custom mt-0 min-w-56"
                                                    [disableOptionCentering]="true" [placeholder]="'ประเภท'"
                                                    [class.border-error]="(isSubmitted || item.get('type')?.touched) && item.get('type')?.errors">
                                                    <ng-container *ngFor="let category of typeCategorys; let i=index">
                                                        <mat-option class="font-medium" [value]="category.id">
                                                            {{category.name}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-select formControlName="date" class="select-custom mt-0"
                                                    [disableOptionCentering]="true" [placeholder]="'จำนวนวัน'"
                                                    [class.border-error]="(isSubmitted || item.get('date')?.touched) && item.get('date')?.errors">
                                                    <ng-container *ngFor="let day of days; let i=index">
                                                        <mat-option class="font-medium" [value]="day">
                                                            ก่อน {{day}} วัน
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-select formControlName="time" class="select-custom mt-0"
                                                    [disableOptionCentering]="true" [placeholder]="'เวลา'"
                                                    [class.border-error]="(isSubmitted || item.get('time')?.touched) && item.get('time')?.errors">
                                                    <ng-container *ngFor="let time of times; let i=index">
                                                        <mat-option class="font-medium" [value]="time">
                                                            เวลา {{time}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <button mat-icon-button class="flex-none group icon-ripper-center"
                                                    (click)="clickDeleteDate(i)">
                                                    <mat-icon
                                                        class="opacity-50 group-hover:opacity-100">close</mat-icon>
                                                </button>
                                            </div>
                                        </ng-container>
                                        <button mat-button class="button-cdg mt-1"
                                            [ngClass]="dateData.controls.length === 5 ? 'bg-gray-100' : 'bg-bule-light'"
                                            [disabled]="dateData.controls.length === 5 ? true : false"
                                            (click)="dateData.controls.length <= 4 ? clickAddDate() : null">
                                            <mat-icon>add</mat-icon>
                                            เพิ่มวัน
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-detail">
                            <div class="flex items-center min-h-[80px] border-b p-4">
                                <div class="flex-1 flex flex-col">
                                    <span class="text-lg font-bold">
                                        การแจ้งเตือนเมื่อเลยกำหนด
                                    </span>
                                    <span class="text-sm font-normal opacity-50 mt-1">
                                        ตั้งค่าการแจ้งเตือนล่วงหน้า
                                    </span>
                                </div>
                            </div>
                            <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                <div>
                                    <div class="flex gap-4">
                                        <img class="flex-none w-6 h-6 mt-4"
                                            src="../../../../assets/images/app/add-user.svg" alt="">
                                        <mat-form-field appearance="fill" class="flex-1 custom">
                                            <mat-label class="text-base select-none"
                                                [ngClass]="{'text-red-500': (stateAfter.invalid && stateAfter.touched) || isErrorAfter}">แจ้งเตือนไปยัง</mat-label>
                                            <input #inputAfter matInput type="text" class="leading-normal"
                                                placeholder="aaa@email.com" [matAutocomplete]="autoAfter"
                                                [formControl]="stateAfter" (keyup)="onChangeEmail($event, 'after')"
                                                (keypress)="onChangeEmail($event, 'after')"
                                                (keyup.enter)="stateAfter.valid ? onEnter($event, 'after') : null">
                                            <mat-autocomplete #autoAfter="matAutocomplete" [displayWith]="displayFn">
                                                <div class="max-h-full overflow-y-auto" infinite-scroll
                                                    [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
                                                    [infiniteScrollUpDistance]="scrollUpDistance"
                                                    [infiniteScrollThrottle]="throttle" (scrolled)="onScrollUser()">
                                                    <mat-option
                                                        *ngFor="let item of filteredAfterUserAdmins | async; let i=index"
                                                        [value]="item" (click)="clickAddUser(item, 'after')">
                                                        <span>{{ item.email }}</span> |
                                                        <small>name: {{item.displayName}}</small>
                                                    </mat-option>
                                                </div>
                                            </mat-autocomplete>
                                            <mat-hint class="text-sm font-medium text-red-500"
                                                *ngIf="(stateAfter.invalid && stateAfter.touched) || isErrorAfter">
                                                กรอกในรูปแบบอีเมล
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <ul role="list" class="ml-10 divide-y divide-slate-200">
                                            <ng-container formArrayName="after"
                                                *ngFor="let item of after.controls; let i=index">
                                                <li [formGroupName]="i"
                                                    class="flex items-center gap-2 py-2 first:pt-0 last:pb-0">
                                                    <ng-container
                                                        *ngIf="item.get('imageURL')?.value; else elseTemplateImageAfterUsers">
                                                        <img class="h-10 w-10 rounded-full border"
                                                            [src]="environmentApi.apiBaseURL + item.get('imageURL')?.value + '/image'"
                                                            alt="" />
                                                    </ng-container>
                                                    <ng-template #elseTemplateImageAfterUsers>
                                                        <div
                                                            class="flex-none h-10 w-10 rounded-full border flex justify-center items-center text-white font-bold text-xl bg-[color:var(--red-cdg)]">
                                                            {{item.get('email')?.value ?
                                                            item.get('email')?.value.charAt(0).toUpperCase() :
                                                            item.get('displayName')?.value.charAt(0).toUpperCase()}}
                                                        </div>
                                                    </ng-template>
                                                    <p class="flex-1 text-base font-medium text-slate-900 line-clamp-1">
                                                        {{item.get('email')?.value}}
                                                    </p>
                                                    <div class="flex-none flex items-center justify-center gap-3">
                                                        <mat-select class="select-custom mt-0"
                                                            [disableOptionCentering]="true" [placeholder]="'แจ้งเตือน'"
                                                            formControlName="typeNoti"
                                                            [class.border-error]="(isSubmitted || item.get('typeNoti')?.touched) && item.get('typeNoti')?.errors"
                                                            [disabled]="!!item.get('typeNoti')?.value">
                                                            <ng-container
                                                                *ngIf="item.get('id')?.value; else elseTemplateAfterType">
                                                                <ng-container
                                                                    *ngFor="let item of notiTypes; let i=index">
                                                                    <mat-option class="font-medium"
                                                                        [value]="item.value">
                                                                        {{item.text}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-template #elseTemplateAfterType>
                                                                <mat-option class="font-medium" [value]="'email'">
                                                                    Email
                                                                </mat-option>
                                                            </ng-template>
                                                        </mat-select>
                                                        <button mat-icon-button
                                                            class="flex-none group icon-ripper-center"
                                                            (click)="clickDeleteUser(i, item.get('email')?.value, 'after')">
                                                            <mat-icon
                                                                class="opacity-50 group-hover:opacity-100">close</mat-icon>
                                                        </button>
                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>