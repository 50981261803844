import { Injectable } from "@angular/core";
import { CheckStatusFacade } from "./facade/CheckStatusFacade.service";
import { AuthenManager } from "./AuthenManager.service";
import { SocketIoManager } from "./SocketIoManager.service";

@Injectable()
export class CheckTokenService {
    constructor(
        private authenManager: AuthenManager,
        private checkStatusFacade: CheckStatusFacade,
        private socketIoManager: SocketIoManager
    ) { }

    public checkStatus(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkStatusFacade.searchData(this.authenManager.getUserToken()!).then((res: any) => {
                if (res) {
                    resolve(res.data);
                }
            }).catch((err) => {
                if (err) {
                    reject(err);
                    if (err.error.status === 0) {
                        this.authenManager.clearStorage();
                        this.socketIoManager.disconnect();
                        this.authenManager.dialogSystem('', 'โทเคนหมดอายุ', false
                        ).then((res: any) => {
                            if (res) {

                            }
                        });
                    } else {
                        console.log(err);
                    }
                }
            });
        })
    }
}