<div class="dialog-custom dialog-custom-upload">
    <div class="mat-dialog-title flex items-center">
        <span class="text-base font-bold flex-1">
            {{data && data.header ? data.header : 'แจ้งเตือน !'}}
        </span>
        <button mat-icon-button class="flex-none icon-ripper-center" (click)="clickClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="overflow-x-hidden break-all border-y p-[24px] min-h-[240px] bg-[color:var(--bg-F2)]"
        [ngClass]="{'flex items-center justify-center': !data.list && !isCheckUploadFile}">
        <input type="file" class="hidden" name="file" id="file" [multiple]="data.multiple ? data.multiple : false"
            [accept]="data.accept ? data.accept : ''" (change)="onFileChange('input', $event)">
        <ng-container *ngIf="!data.list; else elseTemplatelist">
            <ng-container *ngIf="!isCheckUploadFile; else elseTemplateIsCheckUploadFile">
                <label for="file" appDragDrop
                    class="cursor-pointer flex flex-col items-center justify-center w-full h-full"
                    (filesChangeEmiter)="onFileChange('dragdrop', $event)">
                    <label for="file" class="cursor-pointer">
                        <img [src]="data.type === 'image' ? '../../../../assets/images/image-gallery.svg' : '../../../../assets/images/add_file.svg'"
                            class="w-[120px]" alt="">
                    </label>
                    <span class="text-base font-normal flex-none mt-3 opacity-50">
                        {{data.type === 'image' ? 'ลากรูปภาพมาวางในช่องนี้' : 'ลากไฟล์ข้อมูลมาวางในช่องนี้'}}
                    </span>
                </label>
            </ng-container>
            <ng-template #elseTemplateIsCheckUploadFile>
                <ng-container *ngIf="data.type === 'image'; else elseTemplateTypeImage">
                    <div appDragDrop class="grid gap-2" [ngClass]="images.length >= 4 ? 'grid-cols-3' : 'grid-cols-2'"
                        (filesChangeEmiter)="onFileChange('dragdrop', $event)">
                        <ng-container *ngFor="let item of images | slice:0:4; let i = index">
                            <div class="relative"
                                [ngClass]="{'col-span-2': (i === 0 && images.length <= 3) || (i === 1 && images.length === 2) , 'col-span-3': (i === 0 && images.length >= 4)}">
                                <button mat-raised-button
                                    class="absolute top-2 left-2 bg-white hover:bg-white button-cdg"
                                    *ngIf="i === 0 && data.multiple" (click)="clickOpenInputImage()">
                                    <div class="flex items-center">
                                        <mat-icon class="mr-2">add_photo_alternate</mat-icon>
                                        เพิ่มรูปภาพ
                                    </div>
                                </button>
                                <img *ngIf="item.base64" [src]="item.base64" alt="" class="w-full object-cover">
                                <div class="absolute top-0 left-0 bottom-0 right-0 bg-black/30 text-white text-2xl font-medium flex items-center justify-center"
                                    *ngIf="i === 3 && images.length > 4">
                                    + {{images.length - 4}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #elseTemplateTypeImage>
                    <div class="flex flex-col items-center justify-center">
                        <span
                            class="material-icons-outlined text-[120px] border-4 rounded-full border-green-500 text-green-500">
                            check
                        </span>
                        <span class="text-3xl font-bold mt-3">
                            Success
                        </span>
                        <div class="text-base font-normal mt-1">
                            {{files && files.data && files.data.name}}
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ng-container>
        <ng-template #elseTemplatelist>
            <div class="mx-auto">
                <ng-container *ngIf="data.list.success.array.length">
                    <div class="flex items-center">
                        <span class="text-base font-medium line-clamp-1">{{data.list.success.head}}</span>
                        <span class="text-base font-semibold mx-2 flex-none">({{data.list.success.array.length}})</span>
                    </div>
                    <ul>
                        <li class="text-base font-light" *ngFor="let item of data.list.success.array">
                            {{item.message}}
                        </li>
                    </ul>
                    <mat-divider *ngIf="data.list.errors.array.length"></mat-divider>
                </ng-container>
                <ng-container *ngIf="data.list.errors.array.length">
                    <div class="flex items-center">
                        <span class="text-base font-medium line-clamp-1">{{data.list.errors.head}}</span>
                        <span class="text-base font-semibold mx-2 flex-none">({{data.list.errors.array.length}})</span>
                    </div>
                    <ul>
                        <li class="text-base font-light" *ngFor="let item of data.list.errors.array">
                            {{item.message}}
                        </li>
                    </ul>
                </ng-container>
            </div>
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <ng-container *ngIf="!data.list; else elseTemplateEnd">
            <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                ยกเลิก
            </button>
            <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                อัพโหลด
            </button>
        </ng-container>
        <ng-template #elseTemplateEnd>
            <button mat-button class="bg-bule-light button-cdg" (click)="clickCancel()">
                ปิด
            </button>
        </ng-template>
    </mat-dialog-actions>
</div>