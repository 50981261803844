import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'card-upload-image',
    templateUrl: './CardUploadImage.component.html',
})
export class CardUploadImage implements OnInit {
    @Input()
    public class!: string | [string];
    @Input()
    public image: any;
    @Input()
    public isAction: boolean;
    @Input()
    public isDelete: boolean;
    @Output()
    public event: EventEmitter<any> = new EventEmitter();
    @Output()
    public eventClickImage: EventEmitter<any> = new EventEmitter();
    @Output()
    public eventClickDelete: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    public ngOnInit() {

    }

    public clickEvent(event: any) {
        this.event.emit(event);
        if (!this.image || this.image.length === 0) {
            this.isDelete = false;
        }
    }

    public checkTypeof(data: any) {
        if (typeof data === 'string') {
            return false;
        } else {
            return true;
        }
    }

    public clickEventImage(index: number, event: any) {
        let val = {
            index: index,
            data: event
        };
        this.eventClickImage.emit(val);
    }

    public clickEventDelete(index: number, event: any) {
        let val = {
            index: index,
            data: event
        };

        this.eventClickDelete.emit(val);
    }
}