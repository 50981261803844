import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenManager } from './services/AuthenManager.service';
import { ObservableManager } from './services/ObservableManager.service';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isLoading!: boolean;

  constructor(
    private router: Router,
    private observManager: ObservableManager,
    private dialog: MatDialog,
    private authenManager: AuthenManager,
    public loaderService: LoaderService
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.authenManager.checkLogin();
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 3000);
      });

    this.observManager.subscribe('loading.get', (res: any) => {
      if (res) {
        if (res.data) {
          this.isLoading = res.data;
        } else {
          setTimeout(() => {
            this.isLoading = res.data;
          }, 3000);
        }
      }
    });
  }

  public ngOnInit(): void {

  }

  public ngOnDestroy(): void {
    this.observManager.complete('loading.get');
  }
}
