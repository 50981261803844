import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ConfigAdminFacade } from "../../../services/facade/ConfigAdminFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { CUSTOMER_SERVICE_CATEGORY } from '../../../constants/General';
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-config',
    templateUrl: './SliderConfig.component.html',
})
export class SliderConfig implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public isSubmitted: boolean = false;
    public groupConfigForm: UntypedFormGroup;
    public filteredListPage: any[] = [{ label: 'ข้อความ', type: 'string' }, { label: 'ตัวเลข', type: 'number' }, { label: 'ปิด/เปิด', type: 'boolean' }, { label: 'เวลา', type: 'notification' }];
    public dataPage: any[] = this.filteredListPage;

    // configDate

    public typeCategory = CUSTOMER_SERVICE_CATEGORY;
    public days_utt: number[];
    public days_ass: number[];
    public days_cmp: number[];
    public times: string[] = Array.from({ length: 24 }, (_, index) => { const hours = index < 10 ? `0${index}` : `${index}`; return `${hours}:00`; });

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private formBuilder: UntypedFormBuilder,
        private configAdminFacade: ConfigAdminFacade,
        private checkTokenService: CheckTokenService,
    ) {
        this.initialConfigForm();
        this._setTypeDate();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                if (this.dataDetail.mode === 'edit') {
                    this.setRepairStatusInfo(this.dataDetail.value);
                    setTimeout(() => {
                        this.groupConfigForm.get('name')?.disable();
                        this.setValidatorsValue();
                    }, 10);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.groupConfigForm.get('name')?.enable();
                    }, 10);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    private _setTypeDate() {
        for (let data of CUSTOMER_SERVICE_CATEGORY) {
            if (data.id === 'UTT') this.days_utt = Array.from({ length: data.day }, (_, index) => index + 1);
            if (data.id === 'ASS') this.days_ass = Array.from({ length: data.day }, (_, index) => index + 1);
            if (data.id === 'CMP') this.days_cmp = Array.from({ length: data.day }, (_, index) => index + 1);
        }
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            if (this.groupConfigForm.get('name')?.value !== this.dataJob.name ||
                this.groupConfigForm.get('type')?.value !== this.dataJob.type ||
                (this.groupConfigForm.get('type')?.value !== 'notification' && this.groupConfigForm.get('value')?.value !== this.dataJob.value)) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.setRepairStatusInfo(this.dataDetail.value);
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupConfigForm.get('name')?.value ||
                this.groupConfigForm.get('type')?.value !== 'string' ||
                (this.groupConfigForm.get('type')?.value !== 'notification' && this.groupConfigForm.get('value')?.value)
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.initialConfigForm();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.groupConfigForm.get('name')?.value !== this.dataJob.name ||
                this.groupConfigForm.get('type')?.value !== this.dataJob.type ||
                (this.groupConfigForm.get('type')?.value !== 'notification' && this.groupConfigForm.get('value')?.value !== this.dataJob.value)
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.initialConfigForm();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupConfigForm.get('name')?.value ||
                this.groupConfigForm.get('type')?.value !== 'string' ||
                (this.groupConfigForm.get('type')?.value !== 'notification' && this.groupConfigForm.get('value')?.value)
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.initialConfigForm();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.initialConfigForm();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private getFormData(): any {
        let val;
        if (this.groupConfigForm.get('type')?.value === 'number') {
            val = parseInt(this.groupConfigForm.get('value')?.value);
        } else if (this.groupConfigForm.get('type')?.value === 'notification') {
            let data = this.groupConfigForm.get('configDateData')?.value;
            const transformed = data.map((item: { type: any; day: any; time: any; }) => `${item.type}, ${item.day}, ${item.time}`);
            val = transformed;
        } else {
            val = this.groupConfigForm.get('value')?.value;
        }

        const editObj = {
            name: this.groupConfigForm.get('name')?.value,
            type: this.groupConfigForm.get('type')?.value,
            value: val
        };

        return editObj;
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'edit') {
            if (this.groupConfigForm.invalid || (
                this.groupConfigForm.get('name')?.value === this.dataJob.name &&
                this.groupConfigForm.get('type')?.value === this.dataJob.type &&
                this.groupConfigForm.get('value')?.value === this.dataJob.value
            )) {
                return;
            }

            this.isSubmitted = true;
            this.configAdminFacade.updateData(this.dataDetail.value.name, this.getFormData()).then((res: any) => {
                if (res) {
                    this.setRepairStatusInfo(res.data, true);
                    this.editTableRow(this.dataDetail.index, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            if (this.groupConfigForm.invalid || (
                !this.groupConfigForm.get('name')?.value ||
                !this.groupConfigForm.get('type')?.value ||
                !this.groupConfigForm.get('value')?.value
            )) {
                return;
            }

            this.configAdminFacade.createData(this.getFormData()).then((res: any) => {
                if (res) {
                    this.createTableRow(res.data, 'create');
                    this.initialConfigForm();
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.name + '"'
            }).then((res: any) => {
                if (res) {
                    this.configAdminFacade.deleteData(this.dataDetail.value.name).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.initialConfigForm();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    private setRepairStatusInfo(data: any, isEdit?: boolean) {
        this.dataJob = data;
        if (this.dataJob.type === 'notification') {
            this.groupConfigForm.get('name')?.setValue(this.dataJob.name);
            this.groupConfigForm.get('type')?.setValue(this.dataJob.type);
            this.groupConfigForm.get('value')?.setValue('');
            const data = this.groupConfigForm.get('configDateData') as UntypedFormArray;
            if (!isEdit) {
                for (let i = 0; i < this.dataJob.value.length; i++) {
                    const item = this.dataJob.value[i];
                    const [type, day, time] = item.split(',').map((part: string) => part.trim());
                    const configFormGroup = this.formBuilder.group({
                        type: [type, Validators.required],
                        day: [parseInt(day, 10), Validators.required],
                        time: [time, Validators.required],
                    });
                    data.push(configFormGroup);
                }
            }
        } else {
            this.groupConfigForm.get('name')?.setValue(this.dataJob.name);
            this.groupConfigForm.get('type')?.setValue(this.dataJob.type);
            this.groupConfigForm.get('value')?.setValue(this.dataJob.value);
        }
    }

    private initialConfigForm() {
        this.groupConfigForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
            type: ['string', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
            value: [],
            configDateData: this.formBuilder.array([])
        });

        this.setValidatorsValue();
    }

    private _createConfigData(): UntypedFormGroup {
        return this.formBuilder.group({
            type: [''],
            day: [''],
            time: ['']
        });
    }

    private setValidatorsValue(isSet?: boolean) {
        if (this.groupConfigForm.get('type')?.value === 'string') {
            this.groupConfigForm.get('value')?.setValidators([Validators.minLength(1), Validators.maxLength(255)]);
            this.groupConfigForm.get('value')?.updateValueAndValidity();
            if (this.dataDetail && this.dataDetail.mode === 'create' || isSet === true) {
                this.groupConfigForm.get('value')?.setValue('');
            }
        } else if (this.groupConfigForm.get('type')?.value === 'number') {
            this.groupConfigForm.get('value')?.setValidators([Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(1), Validators.maxLength(255)]);
            this.groupConfigForm.get('value')?.updateValueAndValidity();
            if (this.dataDetail && this.dataDetail.mode === 'create' || isSet === true) {
                this.groupConfigForm.get('value')?.setValue(0);
            }
        } else if (this.groupConfigForm.get('type')?.value === 'boolean') {
            this.groupConfigForm.get('value')?.setValidators([Validators.required]);
            this.groupConfigForm.get('value')?.updateValueAndValidity();
            if (this.dataDetail && this.dataDetail.mode === 'create' || isSet === true) {
                this.groupConfigForm.get('value')?.setValue(false);
            }
        } else if (this.groupConfigForm.get('type')?.value === 'notification') {
            this.groupConfigForm.get('value')?.clearValidators();
            this.groupConfigForm.get('value')?.updateValueAndValidity();
            if (this.dataDetail && this.dataDetail.mode === 'create' || isSet === true) {
                this.groupConfigForm.get('value')?.setValue('');
            }
        }
    }

    public selectChange(event: any) {
        this.setValidatorsValue(true);
    }

    get configData(): any {
        return (this.groupConfigForm?.get('configDateData') as UntypedFormArray);
    }

    public isMaxConfig() {
        const data = this.groupConfigForm?.get('configDateData') as UntypedFormArray;
        return data?.length === 5;
    }

    public clickAdd() {
        const data = this.groupConfigForm.get('configDateData') as UntypedFormArray;
        data.push(this._createConfigData());
    }

    public clickRemove(index: number): void {
        const data = this.groupConfigForm.get('configDateData') as UntypedFormArray;
        if (data.at(index).get('type')?.value || data.at(index).get('day')?.value || data.at(index).get('time')?.value) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบข้อมูล'
            }).then((res: any) => {
                if (res) {
                    data.removeAt(index);
                }
            });
        } else {
            data.removeAt(index);
        }
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupConfigForm.controls;
    }
}

