import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivateInfoModel } from '../../models/ActivateInfoModel';
import { ActivateInfoCreateRequest, ActivateInfoEditRequest } from '../../request/ActivateInfoRequest';
import { SearchFilter } from '../../request/SearchFilterRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class ActivateInfoAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/activate_info/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public createData = (request: ActivateInfoCreateRequest) => this.post<MethodResponseInf<ActivateInfoModel>>('', this.getDefaultHeader(), null, request);
    public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<ActivateInfoModel>>('search', this.getDefaultHeader(), null, request);
    public updateData = (request: ActivateInfoEditRequest) => this.put<ActivateInfoModel>('', this.getDefaultHeader(), null, request);
    public deleteData = (id: string) => this.delete<MethodResponseInf>(`${(id)}`, this.getDefaultHeader(), null);
    public uploadData = (request: any) => this.post<MethodResponseInf>('upload', this.getDefaultHeader(), null, request);
}