import { modeType } from "../type/type";

export class LoginRequest {
    public username?: string;
    public password?: string;
    public mode: modeType = 'EMAIL';

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }
}