import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { SearchFilter } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';
import { UserModel } from '../../models/UserModel';

@Injectable()
export class UserAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/user/');
    }

    public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<any>>(`search`, this.getDefaultHeader(), null, request);
    public createData = (request: UserModel) => this.post<MethodResponseInf<UserModel>>(`register`, this.getDefaultHeader(), null, request);
    public updateData = (id: string, request: UserModel) => this.put<MethodResponseInf<UserModel>>(`${(id)}`, this.getDefaultHeader(), null, request);
    public deleteData = (id: string, request: UserModel) => this.post<MethodResponseInf<UserModel>>(`${(id)}/ban`, this.getDefaultHeader(), null, request);
}