import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ObservableManager } from "../../../services/ObservableManager.service";

@Component({
    selector: 'card-detail',
    templateUrl: './CardDetail.component.html',
})
export class CardDetail implements OnInit {
    @Input()
    public header: any = 'content';
    @Input()
    public isAciton: boolean = true;
    @Input()
    public isShow: boolean = true;
    @Input()
    public isFooter: boolean = false;
    @Input()
    public maxHeight: number | 'unset' = 400;
    @ViewChild('detail', { read: ElementRef, static: false })
    public detail!: ElementRef;
    @Output()
    public event: EventEmitter<any> = new EventEmitter();
    private textSub: string = 'card-detail';
    public isShowActive: boolean = this.isShow;

    constructor(
        private observManager: ObservableManager,
    ) {

    }


    public ngOnInit() {
        this.scrollTop();
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        this.observManager.subscribe(this.textSub, (res: any) => {
            if (res) {
                this.scrollTop();
                this.isShowActive = this.isShow;
            }
        });
    }

    public clickEvent(event: any) {
        this.event.emit(event);
    }

    public clickShow() {
        this.isShowActive = !this.isShowActive;
        this.scrollTop();
    }

    public scrollTop() {
        try {
            this.detail.nativeElement.scrollTop = 0;
        } catch (err) {

        }
    }
}