export class RepairCreateRequest {
    public repairs?: any[];
    public ownerUser?: string;
    public parentRepair?: any;

    constructor(repairs?: any[], ownerUser?: string, parentRepair?: any) {
        this.repairs = repairs;
        this.ownerUser = ownerUser;
        this.parentRepair = parentRepair;
    }
}