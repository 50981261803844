<div class="dialog-custom dialog-custom-table">
  <div class="mat-dialog-title flex items-center">
    <span class="text-base font-bold flex-1">
      {{ data && data.header ? data.header : "แจ้งเตือน !" }}
    </span>
    <button mat-icon-button class="flex-none icon-ripper-center" (click)="clickClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="overflow-hidden border-y p-[0px]">
    <custom-table #table class="flex-1 flex flex-col overflow-hidden h-[65vh]" [columns]="dataTable.columns"
      [data]="dataTable.data" [length]="dataTable.lengthDataTable" [tag]="''" [pageSize]="dataTable.limitTable"
      [isCheckBoxAll]="dataTable.isCheckBoxAll ? true : false" [isCheckBox]="dataTable.isCheckBox ? true : false"
      [isShowFirstLastButtons]="dataTable.isShowFirstLastButtons ? true : false" [isHidePageSize]="true" (event)="''"
      (eventPaginator)="clickPaginator($event)">
    </custom-table>
  </mat-dialog-content>
</div>