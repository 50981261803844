import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router"
import { ObservableManager } from '../../services/ObservableManager.service';
export * from './main.internal/main';

const PAGE_NAME: string = '';

@Component({
  selector: 'main-page',
  templateUrl: './MainPage.component.html',
})
export class MainPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  public isMenuOpen: boolean;
  public isFullScreen: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
  ) {
    this.checkFullScreen();
  }

  public ngOnInit() {

  }

  public setMenuOpen(event: boolean) {
    this.isMenuOpen = event;
  }

  private checkFullScreen() {
    if (window.innerWidth < 900) {
      this.isFullScreen = false;
    } else {
      this.isFullScreen = true;
    }
  }

  public onResize(event: any) {
    this.checkFullScreen();
  }
}