import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: 'dateFormat',
    pure: false
})

export class PipeDatetime implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) { }
    transform(value: any): any {
        if (value) {
            const chatDate = new Date(value);
            let h = new Date();
            h.setDate(chatDate.getUTCDate());
            h.setMonth(chatDate.getUTCMonth());
            h.setFullYear(chatDate.getUTCFullYear());
            h.setMinutes(chatDate.getUTCMinutes());
            h.setHours(chatDate.getUTCHours());
            h.setSeconds(chatDate.getUTCSeconds());
            const seconds = Math.floor((+new Date() - +h) / 1000);
            if (seconds < 29) {
                return 'เมื่อสักครู่';
            }

            const intervals: any = {
                'ปี': 31536000,
                'เดือน': 2592000,
                'วัน': 86400,
                'ชั่วโมง': 3600,
                'นาที': 60,
                'วินาที': 1
            };

            let counter;

            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    if (counter === 1) {
                        return counter + ' ' + i + 'ที่แล้ว'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 'ที่แล้ว'; // plural (2 days ago)
                    }
                }
            }
        }

        return value;
    }
}
