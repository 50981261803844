import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

interface patternTag {
    color: any;
    text: string;
}

@Component({
    selector: 'card-chat',
    templateUrl: './CardChat.component.html',
})
export class CardChat implements OnInit {
    @Input()
    public class!: string | [string];
    @Input()
    public image: any = '';
    @Input()
    public name: any = 'ชื่อ นามสกุล';
    @Input()
    public content: any = 'ตัวอย่างการแจ้งเตือน ยกตัวอย่าง เช่น แจ้งเตื่อนเมื่อปิดงานซ่อม';
    @Input()
    public time: any = '15 นาทีที่แล้ว';
    @Input()
    public tagged: patternTag[];
    @Input()
    public active: boolean = false;
    @Input()
    public read: boolean = false;
    @Input()
    public pulse: boolean = false;
    @Output()
    public event: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    public ngOnInit() {
    }

    public clickEvent(event: any) {
        this.event.emit(event);
    }
}