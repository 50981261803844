import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { UploadFileRequest } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class UploadFileFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/file/');
    }

    public uploadData = (request?: UploadFileRequest) => this.post<MethodResponseInf<any>>(`temp`, this.getDefaultHeader(), null, request);
}