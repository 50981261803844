import { Component, OnInit } from "@angular/core";

const PAGE_NAME: string = 'customer';

@Component({
  selector: 'customer-outlet-page',
  templateUrl: './CustomerOutletPage.component.html'
})

export class CustomerOutletPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;

  constructor() {

  }

  public ngOnInit(): void {

  }
}
