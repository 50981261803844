import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/MethodResponseInf';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';


@Injectable()
export class CheckStatusFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/check_status/');
    }

    public searchData = (token: string) => this.get<MethodResponseInf<any>>('', this.getDefaultHeader(), `token=${token}`);
    // public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<any>>(`search`, this.getDefaultHeader(), null, request);
    // public createData = (request: UserModel) => this.post<MethodResponseInf<UserModel>>(`register`, this.getDefaultHeader(), null, request);
    // public updateData = (id: string, request: UserModel) => this.put<MethodResponseInf<UserModel>>(`${(id)}`, this.getDefaultHeader(), null, request);
    // public deleteData = (id: string, request: UserModel) => this.post<MethodResponseInf<UserModel>>(`${(id)}/ban`, this.getDefaultHeader(), null, request);
}