import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { RepairRequest } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class RepairFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/repair/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public searchData = (request?: RepairRequest) => this.post<MethodResponseInf<any>>('search', this.getDefaultHeader(), null, request);
    public getRepair = (id: string) => this.get<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), null);
    public exportData = (request: { repairDate: Date }) => this.post<MethodResponseInf<any>>('foreman/generatepdf', this.getDefaultHeader(), null, request, 'blob');
    public exportDataById = (id: string) => this.get<MethodResponseInf<any>>(`${(id)}` + '/foreman/generatepdf', this.getDefaultHeader(), null, 'blob');
}