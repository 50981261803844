import { DataUtil } from './../utils/DataUtil';
import { MethodResponseInf } from './../interface/interface';
import { ChangePasswordRequest, LoginRequest, RegisterRequest } from '../request/request';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginResponse } from '../response/response';
import { empty, userType } from '../type/type';
import { ActivatedRoute, Router } from '@angular/router';
import { ObservableManager } from './ObservableManager.service';
import { UserModel } from '../models/UserModel';
import { MatDialog } from '@angular/material/dialog';
import { DialogAlert } from '../components/shares/dialog/DialogAlert.component';
import { SocketIoManager } from './SocketIoManager.service';

const TOKEN_KEY: string = 'token';
const USER_KEY: string = 'user';

// only page user can login
@Injectable()
export class AuthenManager {

  protected baseURL: string;
  protected http: HttpClient;
  protected token: string | empty;
  protected user: UserModel | empty;

  public isLoading: boolean;

  private _notis: any;
  get notis(): [] {
    return this._notis;
  }
  set notis(value: any) {
    this._notis = value;
  }

  constructor(
    http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private activatedRoute: ActivatedRoute,
    private socketIoManager: SocketIoManager
  ) {
    this.http = http;
    this.baseURL = environment.apiBaseURL;
    this.observManager.createSubject('loading.get');
  }

  // login
  public async login(request: LoginRequest, isSavePass: boolean, tokenFCM: string): Promise<MethodResponseInf<LoginResponse>> {
    return new Promise((resolve, reject) => {
      let url: string = this.baseURL + '/login';
      let body: any = {
        'username': request.username,
        'password': request.password,
        'tokenFCM': tokenFCM,
      };

      let headers = new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Request-Method': 'GET,PUT,OPTIONS,POST',
        'Access-Control-Allow-Origin': '*',
        'mode': request.mode
      });

      let httpOptions = {
        headers: headers
      };

      this.http.post(url, body, httpOptions).toPromise().then((response: any) => {
        let result: any = {
          token: response.data.token,
          user: response.data.user
        };

        if (isSavePass) {
          // when close browser, session is clear but local is alive! 
          localStorage.setItem(TOKEN_KEY, result.token);
          localStorage.setItem(USER_KEY, JSON.stringify(result.user));
          sessionStorage.setItem(TOKEN_KEY, result.token);
          sessionStorage.setItem(USER_KEY, JSON.stringify(result.user));
          this.token = result.token;
          this.user = result.user;
        } else {
          // when close browser, session is clear 
          sessionStorage.setItem(TOKEN_KEY, result.token);
          sessionStorage.setItem(USER_KEY, JSON.stringify(result.user));
          this.token = result.token;
          this.user = result.user;
        }

        const user = this.getCurrentUser();
        if (!user.isAdmin && !user.isSubAdmin && user.isMarketing) {
          this.router.navigate(['/content']);
        } else if ((!user.isAdmin && !user.isSubAdmin && !user.isMarketing && !user.isForeman) || (!user.isAdmin && !user.isSubAdmin && !user.isMarketing && user.isForeman)) {
          this.logout();
          this.dialogSystem('', 'สิทธิในการใช้งานไม่ถึง', false
          ).then((res: any) => {
            if (res) {

            }
          });
        } else {
          this.router.navigate(['/']);
        }
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  // forgot password
  public async forgotPassword(email: string): Promise<MethodResponseInf<string>> {
    return new Promise((resolve, reject) => {
      let url: string = this.baseURL + '/forgot';
      let body: any = {
        'username': email,
      };

      let headers = new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Request-Method': 'GET,PUT,OPTIONS,POST',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      });

      let httpOptions = {
        headers: headers
      };
      this.http.post(url, body, httpOptions).toPromise().then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  // register
  public async register(req: RegisterRequest): Promise<MethodResponseInf<LoginResponse>> {
    return new Promise((resolve, reject) => {
      let url: string = this.baseURL + '/admin/register';
      let headers = new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Request-Method': 'GET,PUT,OPTIONS,POST',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      });

      let httpOptions = {
        headers: headers
      };

      this.http.post(url, req, httpOptions).toPromise().then((response: any) => {
        let result: any = {
          token: response.data.token,
          user: response.data.user
        };

        sessionStorage.setItem(TOKEN_KEY, result.token);
        sessionStorage.setItem(USER_KEY, result.user);
        this.token = result.token;
        this.user = result.user;
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  // change password
  public async changePassword(req: ChangePasswordRequest): Promise<MethodResponseInf<string>> {
    return new Promise((resolve, reject) => {
      let url: string = this.baseURL + '/change_password';

      let headers = new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Request-Method': 'GET,PUT,OPTIONS,POST',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      });

      let httpOptions = {
        headers: headers
      };

      this.http.post(url, req, httpOptions).toPromise().then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  // logout
  public async logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      // !implement logout from API
      const token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY) : localStorage.getItem(TOKEN_KEY);
      const headers = new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Request-Method': 'GET,PUT,OPTIONS,POST',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + token,
        'mode': 'all'
      });

      const url: string = this.baseURL + '/user/logout';
      const httpOptions = {
        headers: headers
      };
      this.http.post(url, {}, httpOptions).toPromise().then((response: any) => {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(USER_KEY);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_KEY);
        this.token = undefined;
        this.user = undefined;
        this.socketIoManager.disconnect();
        this.router.navigate(['/login']);
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  public clearStorage(): void {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    this.token = undefined;
    this.user = undefined;
    this.router.navigate(['/login']);
  }

  // !impl
  public async createUserWithEmailAndPassword(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // resolve();
    });
  }
  // ! implement token check
  public isTokenValid(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  // return current login
  public getCurrentUser(): any {
    const stringObject: any = this._getStorage(USER_KEY);
    if (DataUtil.isEmpty(stringObject)) {
      return undefined;
    }
    return JSON.parse(stringObject);
  }

  // return current login
  public isCurrentUserType(): userType | empty {
    if (this.user === undefined || this.user === null) {
      return undefined;
    }

    if (this.user.isAdmin) {
      return 'rootAdmin';
    }

    if (this.user.isSubAdmin) {
      return 'subAdmin';
    }

    if (this.user.isMarketing) {
      return 'marketing';
    }

    return undefined;
  }

  // return isRootAdmin
  public isRootAdmin(): boolean | empty {
    if (this.user === undefined || this.user === null) {
      return undefined;
    }

    if (this.user.isAdmin) {
      return true;
    }

    return false;
  }

  // return isSubAdmin
  public isSubAdmin(): boolean | empty {

    if (this.user === undefined || this.user === null) {
      return undefined;
    }

    if (this.user.isSubAdmin) {
      return true;
    }

    return false;
  }

  // return isMarketingAdmin
  public isMarketing(): boolean | empty {
    if (this.user === undefined || this.user === null) {
      return undefined;
    }

    if (this.user.isMarketing) {
      return true;
    }

    return false;
  }

  // return user
  public getUserToken(): string | empty {
    this._handleStorage();
    return this._getStorage(TOKEN_KEY);
  }

  private _handleStorage(): void {
    const isLocalToken = DataUtil.isEmpty(localStorage.getItem(TOKEN_KEY)) ? false : true;
    const isSessionToken = DataUtil.isEmpty(sessionStorage.getItem(TOKEN_KEY)) ? false : true;
    const isLocalUser = DataUtil.isEmpty(localStorage.getItem(USER_KEY)) ? false : true;
    const isSessionUser = DataUtil.isEmpty(sessionStorage.getItem(USER_KEY)) ? false : true;

    // if local's found,session not found, set local to session
    if (isLocalToken && !isSessionToken) {
      if (localStorage.getItem(TOKEN_KEY) !== null) {
        const tokenKey: any = localStorage.getItem(TOKEN_KEY);
        sessionStorage.setItem(TOKEN_KEY, tokenKey);
      }
    }

    if (isLocalUser && !isSessionUser) {
      if (localStorage.getItem(USER_KEY) !== null) {
        const userKey: any = localStorage.getItem(USER_KEY);
        sessionStorage.setItem(USER_KEY, userKey);
      }
    }
  }

  private _getStorage(key: string): any {
    if (DataUtil.isEmpty(key)) {
      return undefined;
    }

    const localkey: any = localStorage.getItem(key);
    const sessionkey: any = sessionStorage.getItem(key);

    if (DataUtil.isEmpty(localkey) && DataUtil.isEmpty(sessionkey)) {
      return undefined;
    }

    if (!DataUtil.isEmpty(localkey)) {
      return localkey;
    }

    if (!DataUtil.isEmpty(sessionkey)) {
      return sessionkey;
    }

    return undefined;
  }

  public checkLogin() {
    let url: string;
    if (this.router.url === '/login') {
      if (this.getUserToken() && this.getCurrentUser()) {
        url = localStorage.getItem('path')!;
        this.router.navigateByUrl(decodeURIComponent(url));
        return;
      }
    } else {
      if (this.getUserToken() && this.getCurrentUser()) {
        this.checkStatus(this.getCurrentUser());
        return;
      }
    }
  }

  private checkStatus(val: any) {
    if (val) {
      const url = this.router.url.split('/');
      if (
        (((val?.isAdmin && val?.isSubAdmin && !val?.isMarketing) || (!val?.isAdmin && !val?.isSubAdmin && val?.isMarketing) || (val?.isAdmin && val?.isSubAdmin && val?.isMarketing)) && (url[1] === 'setting')) ||
        ((val?.isAdmin && val?.isSubAdmin && !val?.isMarketing) && ((url[1] === 'content') || (url[1] === 'message'))) ||
        ((!val?.isAdmin && !val?.isSubAdmin && val?.isMarketing) && ((url[1] === 'database') || (url[1] === 'homecare') || (url[1] === 'down-payment') || (url[1] === 'chat')))
      ) {
        this.router.navigate(['/404']);
      } else if (!val?.isAdmin && !val?.isSubAdmin && !val?.isMarketing && val?.isForeman) {
        this.logout();
        this.dialogSystem('', 'สิทธิในการใช้งานไม่ถึง', false
        ).then((res: any) => {
          if (res) {

          }
        });
      }
    }
  }

  public setLoading(val: boolean) {
    this.isLoading = val;
    this.obsLoading();
  }

  public obsLoading() {
    this.observManager.publish('loading.get', {
      data: this.isLoading
    });
  }

  public clickDialogAlert(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const dialogRef = this.dialog.open(DialogAlert, {
        autoFocus: false,
        restoreFocus: false,
        data: {
          header: data.header,
          content: data.content,
          cancel: data.cancel
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(result);
        }
      });
    });
  }

  public dialogSystem(header: string, content: string, cancel: boolean): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const dialogRef = this.dialog.open(DialogAlert, {
        autoFocus: false,
        restoreFocus: false,
        data: {
          header: header,
          content: content,
          cancel: cancel
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(result);
        }
      });
    });
  }
}
