import { Component, HostListener, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { ReadMore } from "../ReadMore.component";

@Component({
    selector: 'dialog-detail',
    templateUrl: './DialogDetail.component.html',
})
export class DialogDetail implements OnInit {
    public isFullscreen: boolean = false;
    public countZoom: number = 1;
    public positionImage: any = {
        xPercent: 0,
        yPercent: 0
    };
    @ViewChild('readmore') public readmore: ReadMore;

    constructor(
        public dialogRef: MatDialogRef<DialogDetail>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }

    public ngOnInit(): void {
        this.dialogRef.addPanelClass(['overlay-dialog-detail', 'active']);
        this._setImageMouse();
    }

    public clickClose(): void {
        this.dialogRef.close(false);
        if (this.data !== undefined && this.data !== null && this.data.cancelClickedEvent !== undefined) {
            this.data.cancelClickedEvent.emit(false);
        }
    }

    // public clickSubmit(): void {
    //     this.dialogRef.close(true);
    //     if (this.data !== undefined && this.data !== null && this.data.confirmClickedEvent !== undefined) {
    //         this.data.confirmClickedEvent.emit(true);
    //     }
    // }

    public clickNavigateBefore() {
        if (this.data.index > 0) {
            this.data.index -= 1;
            this.countZoom = 1;
            if (this.data.isContent) this.readmore.isContentToggled = false;
            this.setPositionDefault();
            this._setImageMouse();
        }
    }
    public clickNavigateNext() {
        if ((this.data.index + 1) < this.data.gallery.length) {
            this.data.index += 1;
            this.countZoom = 1;
            if (this.data.isContent) this.readmore.isContentToggled = false;
            this.setPositionDefault();
            this._setImageMouse();
        }
    }

    @HostListener("window:keydown", ['$event'])
    private _onKeydown(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft') {
            this.clickNavigateBefore();
        } else if (event.key === 'ArrowRight') {
            this.clickNavigateNext();
        }
    }

    public clickZoom(zoom: 'zoomIn' | 'zoomOut') {
        const wrapper = this._getDom('.wrapper-image');
        const image = this._getDom('#image');
        if (zoom === 'zoomIn') {
            if (this.countZoom < 2) {
                this.countZoom += 0.25;
                this._setImageMouse();
            }
        } else if (zoom === 'zoomOut') {
            if (this.countZoom > 1) {
                this.countZoom -= 0.25;
                this._setImageMouse();
                if (this.countZoom === 1) this.setPositionDefault();
                if (wrapper.getBoundingClientRect().width <= image.getBoundingClientRect().width) {
                    this.positionImage['xPercent'] = 0;
                }

                if (wrapper.getBoundingClientRect().height <= image.getBoundingClientRect().height) {
                    this.positionImage['yPercent'] = 0;
                }
            }
        }
    }

    public dateFormat(data: any, format: string) {
        return moment(data).add(543, 'year').format(format);
    }

    private _getDom(selectors: string): any {
        return document.querySelector(selectors);
    }

    private setPositionDefault() {
        this.positionImage = {
            xPercent: 0,
            yPercent: 0
        };
    }

    private _setImageMouse() {
        const wrapper = this._getDom('.wrapper-image');
        const image = this._getDom('#image');
        let position = this.positionImage;

        image.onmousedown = (event: any) => {
            if (event.buttons === 2) {
                return;
            }

            let shiftX = (position['xPercent'] ? event.clientX - position['xPercent'] : event.clientX);
            let shiftY = (position['yPercent'] ? event.clientY - position['yPercent'] : event.clientY);
            let differenceX = (image.getBoundingClientRect().width - wrapper.getBoundingClientRect().width) / 2;
            let differenceY = (image.getBoundingClientRect().height - wrapper.getBoundingClientRect().height) / 2;

            if (wrapper.getBoundingClientRect().width < image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height >= image.getBoundingClientRect().height) {
                moveAt(event.pageX, 0);
            } else if (wrapper.getBoundingClientRect().width >= image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height < image.getBoundingClientRect().height) {
                moveAt(0, event.pageY);
            } else if (wrapper.getBoundingClientRect().width < image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height < image.getBoundingClientRect().height) {
                moveAt(event.pageX, event.pageY);
            }

            // moves the image at (pageX, pageY) coordinates
            // taking initial shifts into account
            function moveAt(pageX: any, pageY: any) {
                if (wrapper.getBoundingClientRect().width < image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height >= image.getBoundingClientRect().height) {
                    position['xPercent'] = (pageX - shiftX);
                    position['yPercent'] = 0;
                } else if (wrapper.getBoundingClientRect().width >= image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height < image.getBoundingClientRect().height) {
                    position['xPercent'] = 0;
                    position['yPercent'] = (pageY - shiftY);
                } else if (wrapper.getBoundingClientRect().width < image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height < image.getBoundingClientRect().height) {
                    position['xPercent'] = (pageX - shiftX);
                    position['yPercent'] = (pageY - shiftY);
                }
            }

            function onMouseMove(event: any) {
                if ((wrapper.getBoundingClientRect().width < image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height >= image.getBoundingClientRect().height) && Math.abs(differenceX) >= Math.abs(event.pageX - shiftX)) {
                    moveAt(event.pageX, 0);
                } else if ((wrapper.getBoundingClientRect().width >= image.getBoundingClientRect().width && wrapper.getBoundingClientRect().height < image.getBoundingClientRect().height) && Math.abs(differenceY) >= Math.abs(event.pageY - shiftY)) {
                    moveAt(0, event.pageY);
                } else if ((wrapper.getBoundingClientRect().width < image.getBoundingClientRect().width) && (Math.abs(differenceX) >= Math.abs(event.pageX - shiftX)) && (wrapper.getBoundingClientRect().height < image.getBoundingClientRect().height) && (Math.abs(differenceY) >= Math.abs(event.pageY - shiftY))) {
                    moveAt(event.pageX, event.pageY);
                }
            }

            // move the image on mousemove
            document.addEventListener('mousemove', onMouseMove);

            // drop the image, remove unneeded handlers
            image.onmouseup = () => {
                document.removeEventListener('mousemove', onMouseMove);
                image.onmouseup = null;
            };

            image.onmouseout = () => {
                document.removeEventListener('mousemove', onMouseMove);
                image.onmouseup = null;
            };
        };

        image.ondragstart = () => {
            return false;
        };
    }

    public checkIsArray(data: any) {
        return Array.isArray(data);
    }
}