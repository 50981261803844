<div class="login-page min-h-screen flex items-center justify-center p-8"
    [ngClass]="{'step-2': workMode === 'Forgot' , 'step-3': workMode === 'Change' , 'step-4': workMode === 'Succ'}">
    <div class="wrapper-login flex max-w-full overflow-hidden rounded-xl mx-auto drop-shadow-xl">
        <div class="wrapper-left hidden md:flex items-center justify-center select-none p-8">
            <img src="https://s3.ap-southeast-1.amazonaws.com/chanuntorn.com/Frame.png" alt=""
                class="transition-all hover:scale-125">
        </div>
        <div class="wrapper-right bg-white p-8 pb-0 flex flex-col overflow-x-hidden overflow-y-auto">
            <div class="wrapper-right-top grow pb-8" [ngClass]="{'flex flex-col justify-evenly': workMode === 'Succ'}">
                <ng-container *ngIf="workMode !== 'Succ'">
                    <div class="text-2xl md:text-3xl font-bold">
                        <ng-container *ngIf="workMode === 'Login'">
                            ลงชื่อเข้าใช้
                        </ng-container>
                        <ng-container *ngIf="workMode === 'Forgot'">
                            ลืมรหัสผ่าน ?
                        </ng-container>
                        <ng-container *ngIf="workMode === 'Change'">
                            เปลี่ยนรหัสผ่าน
                        </ng-container>
                    </div>
                    <div class="text-sm font-normal text-gray-400 mt-3"
                        *ngIf="workMode === 'Forgot' || workMode === 'Change'">
                        <ng-container *ngIf="workMode === 'Forgot'">
                            ป้อนอีเมลแอดเดรสของคุณด้านล่าง<br>
                            และเราจะส่งลิงก์ให้คุณเปลี่ยนรหัสผ่านของคุณ
                        </ng-container>
                        <ng-container *ngIf="workMode === 'Change'">
                            ตั้งรหัสผ่านของคุณใหม่อีกครั้ง แล้วบัญชีของคุณ<br>
                            ก็จะกลับมาพร้อมใช้งานอีกครั้ง
                        </ng-container>
                    </div>
                </ng-container>
                <form class="mt-8 mb-6" autocomplete="on" [formGroup]="groupLogin">
                    <mat-form-field appearance="fill" class="custom w-full"
                        *ngIf="workMode !== 'Change' && workMode !== 'Succ'">
                        <mat-label class="text-base select-none">อีเมล</mat-label>
                        <input matInput class="leading-normal" type="text" placeholder="" formControlName="email"
                            autocomplete="on" (keydown)="resetMessage()">
                        <mat-hint class="text-sm font-medium text-red-500"
                            *ngIf="workMode === 'Forgot' && groupLogin.value.message">
                            {{groupLogin.value.message}}
                        </mat-hint>
                    </mat-form-field>
                    <ng-otp-input #ngOtpInput class="block mb-8" [config]="otpInputConfig"
                        (onInputChange)="onOtpChange($event)" *ngIf="workMode === 'Change'">
                    </ng-otp-input>
                    <mat-form-field appearance="fill" class="custom w-full"
                        *ngIf="workMode !== 'Forgot' && workMode !== 'Succ'">
                        <mat-label class="text-base select-none">รหัสผ่าน</mat-label>
                        <input matInput [type]="isHidePass ? 'password' : 'text'" class="leading-normal" placeholder=""
                            formControlName="password" autocomplete="off"
                            (keyup.enter)="workMode === 'Login' && (groupLogin.value.email && groupLogin.value.password) ? clickLogin() : ''"
                            (keydown)="resetMessage()">
                        <button mat-icon-button matSuffix (click)="isHidePass = !isHidePass" type="button">
                            <mat-icon>{{isHidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-hint class="text-sm font-medium text-red-500"
                            *ngIf="workMode === 'Login' && groupLogin.value.message">
                            {{groupLogin.value.message}}
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="custom w-full" *ngIf="workMode === 'Change'">
                        <mat-label class="text-base select-none">ยืนยันรหัสผ่านอีกครั้ง</mat-label>
                        <input matInput [type]="isHideConPass ? 'password' : 'text'" class="leading-normal"
                            placeholder="" formControlName="conpassword" autocomplete="off" (keydown)="resetMessage()">
                        <button mat-icon-button matSuffix (click)="isHideConPass = !isHideConPass" type="button">
                            <mat-icon>{{isHideConPass ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-hint class="text-sm font-medium text-red-500"
                            *ngIf="workMode === 'Change' && groupLogin.value.message">
                            {{groupLogin.value.message}}
                        </mat-hint>
                    </mat-form-field>
                    <ng-container *ngIf="workMode === 'Succ'">
                        <img class="w-40 mx-auto" src="../../../../assets/images/success_border_radius.svg" alt="">
                        <div class="text-2xl font-bold text-center mt-4">
                            {{groupLogin.value.message}}
                        </div>
                    </ng-container>
                </form>
                <button mat-stroked-button class="w-full text-white button-cdg"
                    [disabled]="(!groupLogin.value.email || !groupLogin.value.password) || isSubmitted"
                    [ngClass]="(!groupLogin.value.email || !groupLogin.value.password) || isSubmitted ? 'bg-stone-400 cursor-not-allowed' : 'bg-red-cdg'"
                    *ngIf="workMode === 'Login'"
                    (click)="(groupLogin.value.email && groupLogin.value.password) ? clickLogin() : ''">
                    ลงชื่อเข้าใช้
                </button>
                <button mat-stroked-button class="w-full text-white button-cdg"
                    [disabled]="!groupLogin.value.email || isSubmitted"
                    [ngClass]="!groupLogin.value.email || isSubmitted ? 'bg-stone-400 cursor-not-allowed' : 'bg-red-cdg'"
                    *ngIf="workMode === 'Forgot'" (click)="groupLogin.value.email ? clickForgotPassword() : ''">
                    ส่งลิงค์เปลี่ยนรหัสผ่าน
                </button>
                <button mat-stroked-button class="w-full text-white button-cdg"
                    [disabled]="(f['password'].errors?.['minlength'] || !groupLogin.value.password || !groupLogin.value.conpassword || groupLogin.value.otp.length !== 6) || (groupLogin.value.password !== groupLogin.value.conpassword) || isSubmitted"
                    [ngClass]="(f['password'].errors?.['minlength'] || !groupLogin.value.password || !groupLogin.value.conpassword || groupLogin.value.otp.length !== 6) || (groupLogin.value.password !== groupLogin.value.conpassword) || isSubmitted ? 'bg-stone-400 cursor-not-allowed' : 'bg-red-cdg'"
                    *ngIf="workMode === 'Change'"
                    (click)="(groupLogin.value.password || groupLogin.value.conpassword || groupLogin.value.otp.length === 6) || (groupLogin.value.password === groupLogin.value.conpassword) ? clickChangePassword() : ''">
                    บันทึกรหัสผ่านใหม่
                </button>
                <button mat-stroked-button class="w-full bg-red-cdg button-cdg" *ngIf="workMode === 'Succ'"
                    (click)="workMode = 'Login'; validatorsLogin(); resetMessage();">
                    กลับไปหน้าล็อกอิน
                </button>
            </div>
            <div class="wrapper-right-bottom shrink-0 py-8 border-t flex items-center justify-between sticky bottom-0 bg-white"
                *ngIf="workMode !== 'Succ'">
                <div class="wrapper-right-bottom-left flex items-center cursor-pointer select-none"
                    (click)="clickMemorizePassword()" *ngIf="workMode === 'Login'">
                    <div class="rounded-full border-2 w-6 h-6 mr-2 flex items-center justify-center"
                        [class.bg-[color:var(--blue-light)]]="isMemorizePassword">
                        <span class="material-icons-outlined text-white text-sm" *ngIf="isMemorizePassword">
                            done
                        </span>
                    </div>
                    <span class="text-base font-normal">จำรหัสผ่าน</span>
                </div>
                <div class="wrapper-right-bottom-right">
                    <ng-container *ngIf="workMode === 'Login'">
                        <p class="text-base font-normal text-[color:var(--blue-light)] cursor-pointer hover:underline select-none"
                            (click)="clickMode('Forgot');">
                            ลืมรหัสผ่าน
                        </p>
                    </ng-container>
                    <ng-container *ngIf="workMode === 'Forgot' || workMode === 'Change'">
                        <p class="text-base font-normal text-[color:var(--blue-light)] cursor-pointer hover:underline select-none"
                            (click)="clickMode('Login');">
                            กลับไปหน้าลงชื่อเข้าใช้
                        </p>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>