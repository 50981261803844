import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerServiceStatusAdminFacade } from "../../../services/facade/CustomerServiceStatusAdminFacade.service";
import { PageFacade } from "../../../services/facade/PageFacade.service";
import { RepairStatusAdminFacade } from "../../../services/facade/RepairStatusAdminFacade.service";

@Component({
    selector: 'dialog-complaint',
    templateUrl: './DialogComplaint.component.html',
})
export class DialogComplaint implements OnInit {

    public dialogFormGroup: UntypedFormGroup;
    public filteredListPage: any;
    public filteredListPageStatus: any;
    public dataPage: any[] = [];
    public dataPageStatus: any[] = [];
    public filteredListSelect: any;
    public dataSelect: any[] = [];
    public groupDataStatus: any[] = [];
    @ViewChild('filterInput')
    public filterInput: ElementRef<HTMLInputElement>;

    constructor(
        public dialogRef: MatDialogRef<DialogComplaint>,
        public dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private pageFacade: PageFacade,
        private repairStatusAdminFacade: RepairStatusAdminFacade,
        private customerServiceStatusAdminFacade: CustomerServiceStatusAdminFacade,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
    }

    public ngOnInit(): void {
        this.getStatus();
        this.dialogRef.addPanelClass('overlay-dialog-complaint');
        this.dialogFormGroup = this.formBuilder.group({
            value: [''],
            project_value: [''],
            select: [''],
            status: ['']
        });

        if (this.data !== undefined) {
            if (this.data.type === 'input' || this.data.type === 'textarea') {
                setTimeout(() => {
                    this.filterInput.nativeElement.focus();
                }, 100);
                this.dialogFormGroup.get('value')?.setValue(this.data?.value);
            } else if (this.data.type === 'project_autocomp') {
                // set date to auto comp
                this.getProjectList().then((result) => {
                    if (result) {
                        this.dialogFormGroup.get('value')?.setValue(this.data?.value);
                    }
                });
            } else if (this.data.type === 'select') {
                this.dataSelect = this.data?.list;
                this.filteredListSelect = this.dataSelect?.slice();
                this.dialogFormGroup.get('value')?.setValue(this.data?.value);
            }
        }
    }

    private _setValueCustomerSerivce(data: any) {
        if (data.length > 0) {
            const value = this.dataPageStatus.filter(item1 => data.some((item2: any) => item1._id === item2));
            this.dialogFormGroup.get('status')?.setValue(value);
        }
    }

    private _setValueRepairStatus(data: any) {
        if (data?.status && Array.isArray(data.status)) {
            const groupedData = data.status.map((item: any) => ({
                name: item.name,
                id: item.id.map((id: any) => id.toString()) // Convert ids to strings
            }));
            this.dialogFormGroup.get('status')?.setValue(groupedData);
        }
    }




    private getProjectList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.dataPage = [];
            this.filteredListPage = [];
            this.pageFacade.searchData({ count: false, orderBy: { "createdDate": -1 } }).then((res: any) => {
                if (res) {
                    this.dataPage = res.data;
                    this.filteredListPage = this.dataPage.slice();
                }
                resolve(res);
            }).catch((err) => {
                if (err) {
                    reject(err);
                }
            });
        });
    }

    private getProject(id: string): any {
        if (this.dataPage === undefined) {
            return undefined;
        }

        for (const selectItem of this.dataPage) {
            if (selectItem.id === id) {
                return selectItem;
            }
        }

        return undefined;
    }

    public async getStatus() {
        if (this.data.tag === 'customerServiceStatus') {
            this._getCustomerServiceStatusList();
        } else if (this.data.tag === 'repairStatus') {
            await this._getStatusList();
        }
    }

    private _getStatusList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.dataPageStatus = [];
            this.filteredListPageStatus = [];
            this.repairStatusAdminFacade.searchData().then((res: any) => {
                if (res && res.data) {
                    const groupedData = res.data.reduce((acc: { [x: string]: any[] }, item: { name: string; _id: any }) => {
                        if (!acc[item.name]) {
                            acc[item.name] = [];
                        }
                        acc[item.name].push(item._id);
                        return acc;
                    }, {});

                    const transformedData = Object.keys(groupedData).map(key => ({
                        name: key,
                        id: groupedData[key]
                    }));

                    this.dataPageStatus = transformedData;
                    this.filteredListPageStatus = this.dataPageStatus.slice();

                    if (this.data.value !== undefined) {
                        this._setValueRepairStatus(this.data.value);
                    }
                }
                resolve(res);
            }).catch((err) => {
                reject(err);
            });

        });
    }

    private _getCustomerServiceStatusList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.dataPageStatus = [];
            this.filteredListPageStatus = [];
            this.customerServiceStatusAdminFacade.searchData().then((res: any) => {
                if (res) {
                    this.dataPageStatus = res.data;
                    this.filteredListPageStatus = this.dataPageStatus.slice();
                    if (this.data.value !== undefined) {
                        this._setValueCustomerSerivce(this.data?.value);
                    }
                }
                resolve(res);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    public clickClose(): void {
        this.dialogRef.close({ action: 'close', value: false });
        if (this.data !== undefined && this.data !== null && this.data.cancelClickedEvent !== undefined) {
            this.data.cancelClickedEvent.emit(false);
        }
    }

    public clickSubmit(): void {
        let value: any = '';
        let label = '';
        if (this.data.type === 'input' || this.data.type === 'textarea') {
            value = this.dialogFormGroup.get('value')?.value;
            label = value;
        } else if (this.data.type === 'project_autocomp') {
            value = this.dialogFormGroup.get('value')?.value;
            const project = this.getProject(value);
            label = project ? ('(' + project.prefix + ') ' + project.name) : '';
        } else if (this.data.type === 'multi_select') {
            let array = this.dialogFormGroup.get('status')?.value;
            if (this.data.tag === 'repairStatus') {
                const formattedArray = array.map((item: { name: any }) => item.name);

                const selectedIds = this.filteredListPageStatus
                    .filter((item: { name: any }) => formattedArray.includes(item.name))
                    .reduce((acc: any[], item: { id: any[] }) => acc.concat(item.id), []);
                value = selectedIds;
                label = formattedArray.join(' | ');
            } else {
                const filter = this.dataPageStatus.filter(item1 => array.some((item2: { _id: any; }) => item1._id === item2._id));
                const data = filter.map((item: { _id: any; }) => item._id);
                const formattedArray = array.map((item: { name: any; label: any; }) => `${!!item.name ? item.name : item.label}`);
                value = data;
                label = formattedArray.join(' | ');
            }
        } else if (this.data.type === 'select') {
            const list = this.data?.list.find((element: any) => element.value === this.dialogFormGroup.get('value')?.value);
            value = list?.value;
            label = list?.label;
        }

        this.dialogRef.close({ action: 'confirm', value, type: this.data.type, label });
        if (this.data !== undefined && this.data !== null && this.data.confirmClickedEvent !== undefined) {
            this.data.confirmClickedEvent.emit(true);
        }
    }

    public clickAdd(): void {
        let array = [];
        for (const item of this.dialogFormGroup.get('status')?.value) {
            array.push({ id: item._id, name: item.name })
        }
        const statusText = array.map(obj => obj.name).join(',');
        let status = {
            label: statusText,
            project: this.dialogFormGroup.get('value')?.value.id,
            value: array
        }

        let value = {
            id: this.dialogFormGroup.get('value')?.value.id,
            prefix: this.dialogFormGroup.get('value')?.value.prefix,
        }

        this.groupDataStatus.push({
            status: status,
            value: value
        });

        this.dialogFormGroup.reset();
    }

    public checkSelectValue(): void {
        const splitArray = this.filteredListPage.filter((item: any) =>
            !this.groupDataStatus.some(mainItem => mainItem?.value?.id === item?.id)
        );

        this.filteredListPage = splitArray;
    }

    public isCheckButton(): boolean {
        let data = this.dialogFormGroup.get('status')?.value;
        if (data === '' || data.length <= 0) {
            return false;
        } else {
            return true;
        }
    }

    public removeStatus(item: any, index: number): void {
        this.groupDataStatus.splice(index, 1);
    }
}