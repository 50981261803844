import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ObservableManager } from "../../../services/ObservableManager.service";

@Component({
    selector: 'card-list-menu',
    templateUrl: './CardListMenu.component.html',
})
export class CardListMenu implements OnInit {
    @Input()
    public image: any = '';
    @Input()
    public content: any = 'content';
    @Input()
    public tooltip: any = 'tooltip';
    @Input()
    public count: number = 0;
    @Input()
    public isCount: boolean;
    @Input()
    public isAciton: boolean;
    @Input()
    public isActive: boolean;
    @Input()
    public isTabActive: boolean;
    @Input()
    public isSubMenu: boolean;
    @Input()
    public classAcitonImage: string | [string];
    @Output()
    public event: EventEmitter<any> = new EventEmitter();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private observManager: ObservableManager
    ) {

    }

    public ngOnInit() {

    }

    public clickEvent(event: any) {
        this.event.emit(event);
    }
}