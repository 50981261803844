<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper class="wrapper-slider-detail bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto"
            (scroll)="scrollingTo($event)">
            <div #content class="flex flex-col min-h-screen">
                <div class="flex-none sticky top-0 z-20 bg-white">
                    <div class="flex items-center h-[80px] px-6" [class.shadow-md]="!isActiveMenu">
                        <div class="flex-1 flex items-center mr-4">
                            <button mat-icon-button class="group mr-3 flex-none icon-ripper-center"
                                (click)="clickClose()">
                                <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                            </button>
                            <div class="flex-1 flex flex-col">
                                <span class="text-lg font-bold flex items-center">
                                    <span class="line-clamp-1">
                                        {{groupCustomerService.get('name')?.value ?
                                        groupCustomerService.get('name')?.value :
                                        'สร้างข้อมูลหลังการขาย'}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="flex-none flex items-center">
                            <ng-container *ngIf="dataDetail && dataDetail.mode !== 'edit'; else elseTemplateAction">
                                <div class="grid grid-cols-2 gap-2" *ngIf="tabActive === 'information'">
                                    <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                                        คืนค่า
                                    </button>
                                    <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                                        {{dataDetail && dataDetail.mode === 'create' ? 'สร้าง' : 'บันทึก'}}
                                    </button>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateAction>
                                <button mat-stroked-button class="relative flex-none button-cdg bg-white text-black"
                                    [disabled]="approveStatus(dataDetail?.value?.status) ? false : true"
                                    [ngClass]="{'opacity-25': !approveStatus(dataDetail?.value?.status)}"
                                    (click)="approveStatus(dataDetail?.value?.status) ? clickDowload() : null">
                                    <span class="material-symbols-outlined">
                                        download
                                    </span>
                                    ออกเอกสาร
                                </button>
                            </ng-template>
                        </div>
                    </div>
                    <div class="absolute h-[57px] left-0 right-0 flex items-center justify-center gap-1 border-t px-6 bg-white shadow-md"
                        [ngClass]="isActiveMenu ? 'visible' : 'invisible'">
                        <button mat-button class="button-cdg" *ngFor="let item of menuTab; let i=index"
                            [ngClass]="{'text-[color:var(--blue-light)] bg-[color:var(--blue-choose)]': tabActive === item.tab}"
                            (click)="tabActive !== item.tab ? scrollTop() : null; tabActive = item.tab;">
                            <div class="flex items-center justify-center gap-1">
                                <img class="w-[25px] h-[25px] object-cover"
                                    [class.image-filter-blue]="tabActive === item.tab"
                                    [src]="item.icon + (tabActive === item.tab ? '-active.svg' : '.svg')" alt="">
                                <span>{{item.text}}</span>
                            </div>
                        </button>
                        <button mat-icon-button class="butt-refresh absolute right-2 group flex-none icon-ripper-center"
                            (click)="clickRefresh(tabActive)">
                            <mat-icon class="opacity-50 group-hover:opacity-100">refresh</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="flex-1 mt-[57px] p-8 flex items-start gap-6 md:gap-5" [ngSwitch]="tabActive"
                    [ngClass]="{'justify-between': tabActive === 'information' , 'justify-center': tabActive !== 'information' , 'items-center': ((tabActive === 'examine' && dataForeman.length <= 0 && (dataDetail && dataDetail.mode === 'edit') || (tabActive === 'examine') && (dataDetail && dataDetail.mode !== 'edit'))) || (tabActive === 'assessment' && (dataDetail && dataDetail.mode !== 'edit'))}">
                    <ng-container *ngSwitchCase="'information'" [formGroup]="groupCustomerService">
                        <div class="w-full md:w-[500px] xl:w-[560px] grid grid-cols-1 gap-6">
                            <card-detail [header]="'ข้อมูลหลังการขาย'" [isAciton]="true" [isShow]="true"
                                [maxHeight]="'unset'">
                                <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <ng-container *ngIf="dataDetail && dataDetail.mode==='edit'">
                                        <div>
                                            <span class="text-base font-bold opacity-50">ชื่อรายการร้องเรียน</span>
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="name"
                                                [class.border-error]="(isSubmitted || groupCustomerService.get('name')?.touched) && f['name'].errors">
                                            <mat-error class="text-sm font-medium"
                                                *ngIf="(isSubmitted || groupCustomerService.get('name')?.touched) && f['name'].errors">
                                                ชื่อรายการร้องเรียน ต้องไม่เป็นค่าว่าง
                                            </mat-error>
                                        </div>
                                        <div>
                                            <span class="text-base font-bold opacity-50">รหัสยูนิต</span>
                                            <input class="input-custom" formControlName="realesateUnitName" type="text"
                                                placeholder="" autocomplete="off">
                                        </div>
                                    </ng-container>
                                    <div>
                                        <span class="text-base font-bold opacity-50">โครงการ</span>
                                        <mat-select #select class="select-custom" [disableOptionCentering]="true"
                                            [placeholder]="'เลือก'" autocomplete="off" formControlName="page"
                                            [class.border-error]="(isSubmitted || groupCustomerService.get('page')?.touched) && f['page'].errors">
                                            <mat-select-filter class="select-filter" [displayMember]="'prefix'"
                                                [array]="dataPage" [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                                                (filteredReturn)="filteredListPage = $event" *ngIf="select.focused">
                                            </mat-select-filter>
                                            <ng-container *ngFor="let item of filteredListPage">
                                                <mat-option class="font-medium" [value]="item.prefix"
                                                    (click)="clickSelect(item , 'page')">
                                                    ({{item.prefix}}) {{item.name}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupCustomerService.get('page')?.touched) && f['page'].errors">
                                            กรุณาเลือก โครงการ
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">ยูนิต</span>
                                        <mat-select #select2 class="select-custom" [disableOptionCentering]="true"
                                            [placeholder]="'เลือก'" autocomplete="off" formControlName="unit"
                                            [class.border-error]="(isSubmitted || groupCustomerService.get('unit')?.touched) && f['unit'].errors && groupCustomerService.get('page')?.value">
                                            <mat-select-filter class="select-filter" [displayMember]="'no'"
                                                [array]="realesateUnit" [placeholder]="'ค้นหา...'"
                                                [noResultsMessage]="'-'" (filteredReturn)="filteredListUnit = $event"
                                                *ngIf="select2.focused">
                                            </mat-select-filter>
                                            <ng-container *ngFor="let item of filteredListUnit">
                                                <mat-option class="font-medium" [value]="item.no"
                                                    (click)="clickSelect(item , 'unit')">
                                                    {{item.no}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupCustomerService.get('unit')?.touched) && f['unit'].errors && groupCustomerService.get('page')?.value">
                                            กรุณาเลือก ยูนิต
                                        </mat-error>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="groupCustomerService.get('page')?.valid && filteredListUnit && filteredListUnit.length === 0">
                                            ไม่พบยูนิตในโครงการที่เลือก
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">ยูสเชอร์</span>
                                        <mat-select #select5 class="select-custom" [disableOptionCentering]="true"
                                            [placeholder]="'เลือก'" autocomplete="off" formControlName="user"
                                            [class.border-error]="(isSubmitted || groupCustomerService.get('user')?.touched) && f['user'].errors && groupCustomerService.get('page')?.value && groupCustomerService.get('unit')?.value">
                                            <mat-select-filter class="select-filter"
                                                [displayMember]="'user.displayName'" [array]="realesateUser"
                                                [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                                                (filteredReturn)="filteredListUser = $event" *ngIf="select5.focused">
                                            </mat-select-filter>
                                            <ng-container *ngFor="let item of filteredListUser">
                                                <mat-option class="font-medium"
                                                    [value]="item.user.displayName ? item.user.displayName : (item.user.firstName ? item.user.firstName : (item.user.email ? item.user.email : item.user.username))"
                                                    (click)="clickSelect(item , 'user')">
                                                    {{item.user.displayName ? item.user.displayName :
                                                    (item.user.firstName ? item.user.firstName : (item.user.email ?
                                                    item.user.email : item.user.username))}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class=" text-sm font-medium"
                                            *ngIf="(isSubmitted || groupCustomerService.get('user')?.touched) && filteredListUser.length >= 1 && f['user'].errors && groupCustomerService.get('page')?.value && groupCustomerService.get('unit')?.value">
                                            กรุณาเลือก ยูสเชอร์
                                        </mat-error>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="groupCustomerService?.get('unit')?.valid && filteredListUser && filteredListUser.length === 0">
                                            ไม่พบยูสเชอร์ในยูนิตที่เลือก
                                        </mat-error>
                                    </div>
                                </div>
                            </card-detail>
                            <div class="card-detail">
                                <div class="flex items-center min-h-[80px] border-b p-4">
                                    <div class="flex-1 flex flex-col">
                                        <span class="text-lg font-bold">
                                            ประเภทร้องเรียน
                                        </span>
                                    </div>
                                </div>
                                <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <div>
                                        <span class="text-base font-bold opacity-50">ประเภทร้องเรียน</span>
                                        <mat-select class="select-custom" [disableOptionCentering]="true"
                                            [placeholder]="'เลือก ประเภทร้องเรียน'" autocomplete="off"
                                            formControlName="category"
                                            [class.border-error]="(isSubmitted || groupCustomerService.get('category')?.touched) && f['category'].errors && groupCustomerService.get('page')?.value && groupCustomerService.get('unit')?.value">
                                            <ng-container *ngFor="let item of dataCustomerServiceCategory">
                                                <mat-option class="font-medium" [value]="item.id"
                                                    (click)="clickSelect(item , 'category')">
                                                    {{item.name}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class=" text-sm font-medium"
                                            *ngIf="(isSubmitted || groupCustomerService.get('category')?.touched) && f['category'].errors">
                                            กรุณาเลือก ประเภทร้องเรียน
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">ประเภทการร้องเรียน</span>
                                        <mat-select #select1 class="select-custom" [disableOptionCentering]="true"
                                            [placeholder]="'เลือก ประเภทการร้องเรียน'" autocomplete="off"
                                            formControlName="type"
                                            [class.border-error]="(isSubmitted || groupCustomerService.get('type')?.touched) && f['type'].errors && groupCustomerService.get('category')?.value">
                                            <mat-select-filter class="select-filter" [displayMember]="'name'"
                                                [array]="customerItemType" [placeholder]="'ค้นหา...'"
                                                [noResultsMessage]="'-'" (filteredReturn)="filteredListType = $event"
                                                *ngIf="select1.focused">
                                            </mat-select-filter>
                                            <ng-container *ngFor="let item of filteredListType">
                                                <mat-option class="font-medium" [value]="item._id"
                                                    (click)="clickSelect(item , 'type')">
                                                    {{item.name}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class=" text-sm font-medium"
                                            *ngIf="(isSubmitted || groupCustomerService.get('type')?.touched) && f['type'].errors">
                                            กรุณาเลือก ประเภทการร้องเรียน
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="card-detail">
                                <div class="flex items-center min-h-[80px] border-b p-4">
                                    <div class="flex-1 flex flex-col">
                                        <span class="text-lg font-bold">
                                            รายละเอียด
                                        </span>
                                        <span class="text-sm font-normal opacity-50 mt-1">
                                            คุณสามารถเพิ่มรายละเอียดการร้องเรียนด้วยกันกดปุ่มสีฟ้าทางขวา
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <card-upload-image
                                        [isAction]="dataDetail && dataDetail.mode === 'create' ? true : false"
                                        [image]="images ? images : []" [isDelete]="isDelete"
                                        (event)="dataDetail && dataDetail.mode === 'create' ? clickActionTableDataUpload() : null"
                                        (eventClickImage)="clickDetailExamine($event, null, images , false);"
                                        (eventClickDelete)="clickRemoveImage($event.index, $event.data)">
                                        <button (click)="clickActionTableDataUpload()" mat-button
                                            class="bg-gray-100 button-cdg w-[150px]" [class.w-auto]="isDelete">
                                            <div class="flex items-center">
                                                <mat-icon class="mr-2">add_photo_alternate</mat-icon>
                                                เพิ่มรูปภาพ
                                            </div>
                                        </button>
                                        <button *ngIf="!getIndexNull(images)" (click)="isDelete = !isDelete" mat-button
                                            class="bg-gray-100 button-cdg w-[150px]" [class.w-auto]="isDelete">
                                            <div class="flex items-center">
                                                <mat-icon class="mr-2">add_photo_alternate</mat-icon>
                                                {{isDelete ? 'แก้ไขเสร็จสิ้น' : 'แก้ไขรูปภาพ'}}
                                            </div>
                                        </button>
                                    </card-upload-image>
                                    <div class="px-4">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="isSubmitted && getIndexNull(images)">
                                            กรุณาอัพโหลดรูปภาพ
                                        </mat-error>
                                    </div>
                                    <div class="p-4">
                                        <textarea formControlName="detail"
                                            class="leading-normal text-base font-normal px-4 py-2 w-full border rounded-lg resize-none min-h-[100px]"
                                            placeholder="ช่องสำหรับใส่ Description พิมพ์ได้ไม่จำกัดตัวอักษร"
                                            [class.border-error]="(isSubmitted || groupCustomerService.get('detail')?.touched) && f['detail'].errors"></textarea>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupCustomerService.get('detail')?.touched) && f['detail'].errors">
                                            กรุณากรอกรายละเอียด
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">
                            <div #detailRight
                                class="flex-none static md:sticky w-full md:w-[326px] xl:w-[346px] grid grid-cols-1 gap-6"
                                [ngStyle]="{'top': isActiveMenu ? topRight + 'px' : (topRight - 57) + 'px'}">
                                <div class="card-detail"
                                    [ngClass]="{'disable': dataDetail.value.customerServiceStatus.ordering > 0}">
                                    <div class="flex items-center min-h-[80px] border-b p-4">
                                        <div class="flex-1 flex flex-col items-center">
                                            <span class="text-lg font-bold">
                                                {{approveStatus(dataDetail.value.status) ?
                                                'งานร้องเรียนนี้ได้รับการอนุมัติ'
                                                :
                                                'งานร้องเรียนนี้ยังไม่ได้รับการอนุมัติ'}}
                                            </span>
                                            <span class="text-sm font-normal opacity-50 mt-5">
                                                {{dataDetail.value.status ?
                                                'คุณต้องการยกเลิกอนุมัติงานร้องเรียนนี้หรือไม่' :
                                                'คุณต้องการอนุมัติงานร้องเรียนนี้หรือไม่'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="!dataDetail.value.status" class="p-4 grid grid-cols-2 gap-2">
                                        <button
                                            (click)="dataDetail.value.customerServiceStatus.ordering > 0 ? null : clickApprove()"
                                            mat-button class="bg-gray-100 button-cdg">
                                            ไม่อนุมัติ
                                        </button>
                                        <button
                                            (click)="dataDetail.value.customerServiceStatus.ordering > 0 ? null : clickApprove(true)"
                                            mat-button class="bg-bule-light button-cdg">
                                            อนุมัติ
                                        </button>
                                    </div>
                                    <div *ngIf="dataDetail.value.status" class="p-4 grid grid-cols-2 gap-2">
                                        <button
                                            (click)="dataDetail.value.customerServiceStatus.ordering > 0 ? null : clickApprove(false)"
                                            mat-button class="bg-bule-light button-cdg">
                                            {{approveStatus(dataDetail.value.status) ? 'ยกเลิกอนุมัติ' :
                                            'ยกเลิกไม่อนุมัติ'}}
                                        </button>
                                    </div>
                                </div>
                                <button mat-button class="bg-bule-light button-cdg"
                                    [class.disable]="dataDetail.value.isComplete || (dataDetail.value.customerServiceStatus.ordering === 3)"
                                    *ngIf="dataDetail.value.status === 'approved'"
                                    (click)="!dataDetail.value.isComplete && (dataDetail.value.customerServiceStatus.ordering < 3) ? clickCompleteStatus() : null">
                                    ปิดงานร้องเรียน
                                </button>
                                <card-detail *ngIf="approveStatus(dataDetail.value.status)"
                                    [ngClass]="{'disable': dataDetail.value.customerServiceStatus.isComplete}"
                                    [header]="'ดำเนินการ'" [isAciton]="true" [isShow]="true">
                                    <ul body class="divide-y">
                                        <ng-container
                                            *ngFor="let item of customerServiceStatus | sort:'desc':'ordering'">
                                            <li class="px-4 py-2 min-h-[56px] flex items-center cursor-pointer"
                                                (click)="!dataDetail.value.customerServiceStatus.isComplete ? clickUpStatus(item) : null">
                                                <mat-icon
                                                    *ngIf="dataDetail.value.customerServiceStatus.ordering < item.ordering"
                                                    class="flex-none text-xl flex items-center justify-center rounded-full text-black bg-[color:var(--bg-FB)] mr-3">
                                                    close
                                                </mat-icon>
                                                <mat-icon
                                                    *ngIf="dataDetail.value.customerServiceStatus.ordering >= item.ordering"
                                                    class="flex-none text-xl flex items-center justify-center rounded-full text-white bg-[color:var(--blue-light)] mr-3">
                                                    done
                                                </mat-icon>
                                                <div class="flex-1">
                                                    <span
                                                        class="flex-1 text-base font-normal opacity-50 line-clamp-1">{{item.name}}</span>
                                                    <div
                                                        *ngIf="dataDetail.value.customerServiceStatus.ordering >= item.ordering">
                                                        <span
                                                            class="text-sm font-normal opacity-30 mr-2">{{convertDateStringStatus(item._id)}}</span>
                                                        <span
                                                            class="text-sm font-normal opacity-50">{{convertStringStatus(item._id)}}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </card-detail>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'examine'">
                        <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'; else elseTemplateExamine">
                            <ng-container *ngIf="dataForeman.length > 0; else elseTemplateForeman">
                                <div class="w-full md:w-[500px] xl:w-[560px] grid grid-cols-1 gap-6">
                                    <div class="card-detail" *ngFor="let item of dataForeman; let i=index">
                                        <div class="flex items-center min-h-[80px] border-b p-4">
                                            <div class="flex-1 flex flex-col">
                                                <span class="text-lg font-bold">
                                                    {{item?.title}}
                                                </span>
                                                <span class="text-sm font-normal opacity-50 mt-1 flex gap-4">
                                                    <span class="flex-none">
                                                        อัพโหลดเมื่อวันที่ {{dateFormat(item?.createdDate ,
                                                        'DD-MM-YYYY')}}
                                                    </span>
                                                    <span class="flex-1 truncate">
                                                        โดย {{item?.user?.displayName}}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="px-4 py-6">
                                            <div class="break-all">
                                                <read-more [content]="item?.description" [limit]="160"></read-more>
                                            </div>
                                            <div class="mt-6" *ngIf="item?.gallery && item?.gallery.length >= 1">
                                                <card-gallery [image]="item?.gallery"
                                                    (eventClickImage)="clickDetailExamine($event, item, item?.images, true)"></card-gallery>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateForeman>
                                <div>ยังไม่มีการตรวจสอบ</div>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseTemplateExamine>
                            <div>ไม่พบข้อมูลการตรวจสอบ</div>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'assessment'">
                        <ng-container *ngIf="dataDetail && dataDetail.mode==='edit'; else elseTemplateAssessment">
                            <div class="w-full md:w-[500px] xl:w-[560px] grid grid-cols-1 gap-6">
                                <div class="card-detail">
                                    <div class="flex items-center min-h-[80px] border-b p-4">
                                        <div class="flex-1 flex justify-between">
                                            <span class="text-lg font-bold">
                                                การประเมินจากลูกค้า
                                            </span>
                                            <div class="flex items-center gap-1">
                                                <mat-icon class="material-symbols-rounded text-[#ffd700]"
                                                    style="font-variation-settings: 'FILL' 1">
                                                    star
                                                </mat-icon>
                                                <span class="text-2xl font-bold">
                                                    {{countStar}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="px-4 py-6">
                                        <ng-container
                                            *ngIf="dataDetail!.value!.questionnaire && dataDetail!.value!.questionnaire!.questionnaires.length >= 1; else elseTemplateQuestionnaire">
                                            <ng-container
                                                *ngFor="let item of dataDetail!.value!.questionnaire && dataDetail!.value!.questionnaire!.questionnaires; let rating_i=index">
                                                <div class="flex items-center gap-3 p-3">
                                                    <img class="flex-none w-11 h-11"
                                                        [src]="'../../../../assets/images/rating/rating-' + item.point + '.svg'"
                                                        alt="">
                                                    <div class="flex-1">
                                                        <p class="leading-[1.8]">{{item.name}}</p>
                                                        <ul
                                                            class="mt-1 flex divide-x divide-white rounded-md overflow-hidden">
                                                            <li class="w-1/5 h-3 bg-[color:var(--bg-EF)]"
                                                                [ngClass]="{'bg-[color:var(--color-rating-1)]': item.point === 1, 'bg-[color:var(--color-rating-2)]': item.point === 2, 'bg-[color:var(--color-rating-3)]': item.point === 3, 'bg-[color:var(--color-rating-4)]': item.point === 4, 'bg-[color:var(--color-rating-5)]': item.point === 5}">
                                                            </li>
                                                            <li class="w-1/5 h-3 bg-[color:var(--bg-EF)]"
                                                                [ngClass]="{'bg-[color:var(--color-rating-2)]': item.point === 2, 'bg-[color:var(--color-rating-3)]': item.point === 3, 'bg-[color:var(--color-rating-4)]': item.point === 4, 'bg-[color:var(--color-rating-5)]': item.point === 5}">
                                                            </li>
                                                            <li class="w-1/5 h-3 bg-[color:var(--bg-EF)]"
                                                                [ngClass]="{'bg-[color:var(--color-rating-3)]': item.point === 3, 'bg-[color:var(--color-rating-4)]': item.point === 4, 'bg-[color:var(--color-rating-5)]': item.point === 5}">
                                                            </li>
                                                            <li class="w-1/5 h-3 bg-[color:var(--bg-EF)]"
                                                                [ngClass]="{'bg-[color:var(--color-rating-4)]': item.point === 4, 'bg-[color:var(--color-rating-5)]': item.point === 5}">
                                                            </li>
                                                            <li class="w-1/5 h-3 bg-[color:var(--bg-EF)]"
                                                                [ngClass]="{'bg-[color:var(--color-rating-5)]': item.point === 5}">
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elseTemplateQuestionnaire>
                                            <div class="text-center opacity-50 py-3">
                                                ยังไม่มีการประเมินจากลูกค้า
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                                <card-detail [header]="'คำแนะนำจากลูกค้า'" [isAciton]="false" [isShow]="true"
                                    [maxHeight]="'unset'">
                                    <div body class="px-4 py-5">
                                        <p class="leading-[1.8]"
                                            [ngClass]="dataDetail!.value!.questionnaire && dataDetail!.value!.questionnaire!.description ? 'opacity-100' : 'opacity-50 py-5 text-center'">
                                            {{dataDetail!.value!.questionnaire &&
                                            dataDetail!.value!.questionnaire!.description ?
                                            dataDetail!.value!.questionnaire!.description
                                            :
                                            'ยังไม่มีคำแนะนำจากลูกค้า'}}
                                        </p>
                                    </div>
                                </card-detail>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateAssessment>
                            <div>ไม่พบข้อมูลการประเมิน</div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>