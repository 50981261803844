import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class ReadChatFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/chatroom/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public getCount = () => this.get<MethodResponseInf<any>>('check_unread_all', this.getDefaultHeader(), null);
    // public check_unread = (request: Pick<ChatRoomModel, 'type' | 'realesateUnitId' | 'typeId'>) => this.post<MethodResponseInf<ChatRoomModel>>('', this.getDefaultHeader(), null, request);
}