import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { NotificationModel } from "../models/NotificationModel";

@Injectable()
export class NotificationService {
    private _subject = new Subject<NotificationModel>();
    private _idx = 0;

    constructor() { }

    public getObservable(): Observable<NotificationModel> {
        return this._subject.asObservable();
    }

    public showNotification(eventId: string, image: string, message: string, itme: string, timeout = 3000) {
        this._subject.next(new NotificationModel(eventId, this._idx++, image, message, itme, timeout));
    }
}