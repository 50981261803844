import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatSidenav } from "@angular/material/sidenav";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { environment } from "../../../../environments/environment";
import { CUSTOMER_SERVICE_CATEGORY } from '../../../constants/General';
import { UserAdminFacade } from "../../../services/facade/UserAdminFacade.service";
import { Observable, map, startWith } from "rxjs";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfigAdminFacade } from "../../../services/facade/ConfigAdminFacade.service";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
    selector: 'slider-noti-after-sale',
    templateUrl: './SliderNotificationAfterSale.component.html',
})
export class SliderNotificationAfterSale implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'setting-noti-page';
    public isSubmitted: boolean = false;
    public userAdmins: any[] = [];
    private conditions = [
        { isAdmin: true, isSubAdmin: false, isMarketing: false },
        { isAdmin: true, isSubAdmin: true, isMarketing: false },
        { isAdmin: false, isSubAdmin: false, isMarketing: true },
        { isAdmin: true, isSubAdmin: true, isMarketing: true },
        { isAdmin: false, isSubAdmin: false, isMarketing: false, isForeman: true },
    ];
    @ViewChild(MatAutocompleteTrigger) public autocomplete: MatAutocompleteTrigger;
    public filteredBeforeUserAdmins: Observable<any[]>;
    public isErrorBefore: boolean = false;
    @ViewChild('inputBefore', { read: ElementRef, static: false })
    public inputBefore: ElementRef;
    public filteredAfterUserAdmins: Observable<any[]>;
    public isErrorAfter: boolean = false;
    @ViewChild('inputAfter', { read: ElementRef, static: false })
    public inputAfter: ElementRef;
    private offset: number = 0;
    private limit: number = 20;
    // Start --- Scroll load ---
    public throttle = 50;
    public scrollDistance = 1;
    public scrollUpDistance = 2;
    // End --- Scroll load ---

    public environmentApi = environment;
    public dataBeforeUsers: any[] = [];
    public dataAfterUsers: any[] = [];
    public dataNotification: any[] = [];
    public typeCategorys = CUSTOMER_SERVICE_CATEGORY;
    public stateBefore = new FormControl<string | any>('', [Validators.required]);
    public stateAfter = new FormControl<string | any>('', [Validators.required]);
    public days: number[] = Array.from({ length: 31 }, (_, index) => index + 1);
    public times: string[] = Array.from({ length: 24 }, (_, index) => { const hours = index < 10 ? `0${index}` : `${index}`; return `${hours}:00`; });
    public notiTypes: any = [
        {
            text: 'All Push Notification',
            value: 'all'
        },
        {
            text: 'Email',
            value: 'email'
        },
        {
            text: 'Push Notification',
            value: 'noti'
        }
    ];
    public patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    public formGroup: UntypedFormGroup;
    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private userAdminFacade: UserAdminFacade,
        private formBuilder: UntypedFormBuilder,
        private checkTokenService: CheckTokenService,
        private configAdminFacade: ConfigAdminFacade,
    ) {

    }

    public ngOnInit(): void {
        this._formNotification();
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.clickOpen();
                this._getUserAdmin(this.offset, this.limit);
                this._getConfig();
                this._filterAutocomplete('before');
                this._filterAutocomplete('after');
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
    }

    public clickCancel(mode: 'close' | 'reset'): void {
        const before = this.before;
        const after = this.after;
        const dateData = this.dateData;
        let isClose: boolean = false;

        if (this.dataBeforeUsers.length !== before.length || this.dataNotification.length !== dateData.length || this.dataAfterUsers.length !== after.length) {
            isClose = true;
        }

        for (const [i, value] of this.dataBeforeUsers.entries()) {
            if (
                value?.email !== before?.at(i)?.get('email')?.value ||
                value?.typeNoti !== before?.at(i)?.get('typeNoti')?.value
            ) {
                isClose = true;
            }
        }

        for (const [i, value] of this.dataNotification.entries()) {
            if (
                value?.type !== dateData?.at(i)?.get('type')?.value ||
                value?.date !== dateData?.at(i)?.get('date')?.value ||
                value?.time !== dateData?.at(i)?.get('time')?.value
            ) {
                isClose = true;
            }
        }

        for (const [i, value] of this.dataAfterUsers.entries()) {
            if (
                value?.email !== after?.at(i)?.get('email')?.value ||
                value?.typeNoti !== after?.at(i)?.get('typeNoti')?.value
            ) {
                isClose = true;
            }
        }

        if (isClose) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ยกเลิกการเปลี่ยนแปลง' + (mode === 'reset' ? '' : ' และปิดสไลด์'),
            }).then((res: any) => {
                if (res) {
                    this._formNotification();
                    this.stateBefore.markAsUntouched();
                    this.stateAfter.markAsUntouched();
                    this.isErrorBefore = false;
                    this.isErrorAfter = false;

                    if (mode === 'close') {
                        this.dataBeforeUsers = [];
                        this.dataAfterUsers = [];
                        this.dataNotification = [];
                        this.sidenav.close();
                    } else if (mode === 'reset') {
                        for (const item of this.dataBeforeUsers) {
                            this._setDataBeforeUserdataValue(item);
                        }

                        for (const item of this.dataAfterUsers) {
                            this._setDataAfterUserdataValue(item);
                        }

                        this._setDataNotificationConfig(this.dataNotification);
                    }
                }
            });

            return;
        }

        if (mode === 'close') {
            this.sidenav.close();
            this._formNotification();
            this.dataBeforeUsers = [];
            this.dataAfterUsers = [];
            this.dataNotification = [];
        }

        this.stateBefore.markAsUntouched();
        this.stateAfter.markAsUntouched();
        this.isErrorBefore = false;
        this.isErrorAfter = false;
    }

    public async clickSubmit(): Promise<void> {
        const before = this.before;
        const after = this.after;
        const dateData = this.dateData;
        let isCheck: boolean = false;
        await this.checkTokenService.checkStatus();

        if (before.invalid || after.invalid || dateData.invalid) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                cancel: false
            });
            return;
        }

        if (this.dataBeforeUsers.length !== before.length || this.dataNotification.length !== dateData.length || this.dataAfterUsers.length !== after.length) {
            isCheck = true;
        }

        for (const [i, value] of this.dataBeforeUsers.entries()) {
            if (
                value?.email !== before?.at(i)?.get('email')?.value ||
                value?.typeNoti !== before?.at(i)?.get('typeNoti')?.value
            ) {
                isCheck = true;
                break;
            }
        }

        for (const [i, value] of this.dataNotification.entries()) {
            if (
                value?.type !== dateData?.at(i)?.get('type')?.value ||
                value?.date !== dateData?.at(i)?.get('date')?.value ||
                value?.time !== dateData?.at(i)?.get('time')?.value
            ) {
                isCheck = true;
                break;
            }
        }

        for (const [i, value] of this.dataAfterUsers.entries()) {
            if (
                value?.email !== after?.at(i)?.get('email')?.value ||
                value?.typeNoti !== after?.at(i)?.get('typeNoti')?.value
            ) {
                isCheck = true;
                break;
            }
        }

        if (!isCheck) {
            return;
        }

        this.isSubmitted = true;
        // update config ass,utt,cmp time
        await this.configAdminFacade.updateData(this._getFormData('ass').name, this._getFormData('ass'));
        await this.configAdminFacade.updateData(this._getFormData('utt').name, this._getFormData('utt'));
        await this.configAdminFacade.updateData(this._getFormData('cmp').name, this._getFormData('cmp'));
        // setValue Before
        const userId_bf = this._setArrayData('id', 'before');
        const emailUser_bf = this._setArrayData('email', 'before');
        const id_bf: string[] = userId_bf.map(item => item.id);
        const email_bf: string[] = emailUser_bf.map(item => item.email);
        // setValue After
        const userId_at = this._setArrayData('id', 'after');
        const emailUser_at = this._setArrayData('email', 'after');
        const id_at: string[] = userId_at.map(item => item.id);
        const email_at: string[] = emailUser_at.map(item => item.email);

        await this.configAdminFacade.updateData('before.users.id', this._getFormData(null, id_bf, 'id'));
        await this.configAdminFacade.updateData('before.mails', this._getFormData(null, email_bf, 'email'));
        await this.configAdminFacade.updateData('after.users.id', this._getFormData(null, id_at, 'id'));
        await this.configAdminFacade.updateData('after.mails', this._getFormData(null, email_at, 'email'));
        this.dataBeforeUsers = this.before.value;
        this.dataNotification = this.dateData.value;
        this.dataAfterUsers = this.after.value;
        this.isSubmitted = false;
        await this.authenManager.clickDialogAlert({
            header: '',
            content: 'อัพเดทสำเร็จ',
            cancel: false
        });
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    private _getFormData(config?: 'ass' | 'cmp' | 'utt' | null, value?: any, mode?: 'id' | 'email'): any {
        let editObj: any;
        const userId: any[] = [];
        const emailUser: any[] = [];
        this.before.value.forEach((item: any) => {
            if (item.id) {
                userId.push(item);
            } else {
                emailUser.push({ email: item.email });
            }
        });

        let data = this.dateData.value;
        // จัดฟอร​์มให้เป็น CTT,10,11:00
        const transformed = data.map((item: { type: any; date: any; time: any; }) => `${item.type}, ${item.date}, ${item.time}`);
        // ทำ transformed เข้าเป็น object
        const resultConfig = transformed.reduce((acc: { [x: string]: any[]; }, item: string) => {
            const firstPart = item.split(',')[0].trim();
            if (!acc[firstPart]) {
                acc[firstPart] = [];
            }

            acc[firstPart].push(item);
            return acc;
        }, {});

        if (!config) {
            editObj = {
                type: 'string',
                value: !!value ? value : (mode === 'id' ? userId.map(item => item.id) : emailUser.map(item => item.email))
            }
        } else {
            editObj = {
                name: config + '.time',
                type: 'notification',
                value: config === 'ass' ? (!!resultConfig.ASS ? resultConfig.ASS : []) : (config === 'cmp' ? (!!resultConfig.CMP ? resultConfig.CMP : []) : (!!resultConfig.UTT ? resultConfig.UTT : []))
            }
        }

        return editObj;
    }

    private _setArrayData(mode: 'id' | 'email', type: 'before' | 'after') {
        const userId: any[] = [];
        const emailUser: any[] = [];
        let value = {
            userId: userId,
            emailUser: emailUser
        }
        if (type === 'before') {
            this.before.value.forEach((item: any) => {
                if (item.id) {
                    if (item.typeNoti === 'all') {
                        userId.push(item);
                        emailUser.push({ email: item.email });
                    } else if (item.typeNoti === 'email') {
                        emailUser.push({ email: item.email });
                    } else {
                        userId.push(item);
                    }
                } else {
                    emailUser.push({ email: item.email });
                }
            });

            return mode === 'id' ? value.userId : value.emailUser;
        } else {
            this.after.value.forEach((item: any) => {
                if (item.id) {
                    if (item.typeNoti === 'all') {
                        userId.push(item);
                        emailUser.push({ email: item.email });
                    } else if (item.typeNoti === 'email') {
                        emailUser.push({ email: item.email });
                    } else {
                        userId.push(item);
                    }
                } else {
                    emailUser.push({ email: item.email });
                }
            });

            return mode === 'id' ? value.userId : value.emailUser;
        }
    }

    private _formNotification(): void {
        this.formGroup = this.formBuilder.group({
            before: this.formBuilder.array([]),
            after: this.formBuilder.array([]),
            dateData: this.formBuilder.array([])
        });
    }

    get before(): UntypedFormArray {
        return (<UntypedFormArray>this.formGroup.get('before'));
    }

    get after(): UntypedFormArray {
        return (<UntypedFormArray>this.formGroup.get('after'));
    }

    get dateData(): UntypedFormArray {
        return (<UntypedFormArray>this.formGroup.get('dateData'));
    }

    public clickAddDate(): void {
        this.dateData.push(this.formBuilder.group({
            type: ['', [Validators.required]],
            date: ['', [Validators.required]],
            time: ['', [Validators.required]]
        }));
    }

    public clickDeleteDate(index: number): void {
        const data = this.dateData;
        if (data.at(index).get('type')?.value || data.at(index).get('day')?.value || data.at(index).get('time')?.value) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบข้อมูล'
            }).then((res: any) => {
                if (res) {
                    data.removeAt(index);
                }
            });
        } else {
            data.removeAt(index);
        }
    }

    private _getUserAdmin(offset: number, limit: number, text?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let val = {
                offset: offset,
                limit: limit,
                text: (typeof text === 'object' && text !== null ? '' : (typeof text === 'string' && !!text ? text : '')),
                whereConditions: { '$or': this.conditions }
            };

            this.userAdminFacade.searchData(val).then((res: any) => {
                if (res) {
                    this.userAdmins = [...this.userAdmins, ...res.data];
                    resolve(res.data);
                    this._filterAutocomplete('before');
                    this._filterAutocomplete('after');
                }
            }).catch((err: any) => {
                if (err) {
                    reject(err);
                }
            });
        });
    }

    public onScrollUser(): void {
        this.offset += this.limit;
        this._getUserAdmin(this.offset, this.limit);
    }

    private async _getConfig(): Promise<void> {
        this.isSubmitted = true;
        // get before user
        const email_bf = (await this.configAdminFacade.getData('before.mails')).data;
        const id_bf = (await this.configAdminFacade.getData('before.users.id')).data;
        if (id_bf.users.length > 0) this._setUser(id_bf.users, email_bf.value, 'before');
        // get after user
        const email_at = (await this.configAdminFacade.getData('after.mails')).data;
        const id_at = (await this.configAdminFacade.getData('after.users.id')).data;
        if (id_at.users.length > 0) this._setUser(id_at.users, email_at.value, 'after');
        // get config date time
        const ass_time = (await this.configAdminFacade.getData('ass.time')).data;
        const utt_time = (await this.configAdminFacade.getData('utt.time')).data;
        const cmp_time = (await this.configAdminFacade.getData('cmp.time')).data;
        if (!!ass_time) this._setDataNotificationConfig(ass_time.value);
        if (!!utt_time) this._setDataNotificationConfig(utt_time.value);
        if (!!cmp_time) this._setDataNotificationConfig(cmp_time.value);
        this.isSubmitted = false;
    }

    private _setUser(users: any, dataEmail: any, type: 'before' | 'after') {
        users.forEach((user: any) => {
            const matchEmail = dataEmail.findIndex((email: any) => email === user.email);
            if (matchEmail !== -1) {
                user.typeNoti = 'all';
                dataEmail.splice(matchEmail, 1);
            } else {
                user.typeNoti = 'noti';
            }
        });

        const dataValue = dataEmail.map((email: any) => {
            return { email: email, typeNoti: 'email' };
        });

        if (!!dataValue) {
            for (const item of users) {
                if (type === 'before') {
                    this.dataBeforeUsers.push(item);
                    this._setDataBeforeUserdataValue(item);
                }

                if (type === 'after') {
                    this.dataAfterUsers.push(item);
                    this._setDataAfterUserdataValue(item);
                }
            }

            for (const item of dataValue) {
                if (type === 'before') {
                    this.dataBeforeUsers.push({ email: item.email, displayName: item.email, typeNoti: item.typeNoti });
                    this._setDataBeforeUserdataValue({ email: item.email, displayName: item.email, typeNoti: item.typeNoti });
                }

                if (type === 'after') {
                    this.dataAfterUsers.push({ email: item.email, displayName: item.email, typeNoti: item.typeNoti });
                    this._setDataAfterUserdataValue({ email: item.email, displayName: item.email, typeNoti: item.typeNoti });
                }
            }
        }
    }

    private _setDataBeforeUserdataValue(dataValue: any): void {
        this.before.push(this.formBuilder.group({
            banned: !!dataValue['banned'] ? dataValue['banned'] : null,
            coverPosition: !!dataValue['coverPosition'] ? dataValue['coverPosition'] : null,
            coverURL: !!dataValue['coverURL'] ? dataValue['coverURL'] : null,
            displayName: !!dataValue['displayName'] ? dataValue['displayName'] : null,
            email: !!dataValue['email'] ? dataValue['email'] : null,
            firstName: !!dataValue['firstName'] ? dataValue['firstName'] : null,
            gender: !!dataValue['gender'] ? dataValue['gender'] : null,
            id: !!dataValue['id'] ? dataValue['id'] : null,
            imageURL: !!dataValue['imageURL'] ? dataValue['imageURL'] : null,
            isAdmin: !!dataValue['isAdmin'] ? dataValue['isAdmin'] : null,
            isForeman: !!dataValue['isForeman'] ? dataValue['isForeman'] : null,
            isMarketing: !!dataValue['isMarketing'] ? dataValue['isMarketing'] : null,
            isSubAdmin: !!dataValue['isSubAdmin'] ? dataValue['isSubAdmin'] : null,
            lastName: !!dataValue['lastName'] ? dataValue['lastName'] : null,
            tokenFCM: !!dataValue['tokenFCM'] ? dataValue['tokenFCM'] : null,
            typeNoti: [!!dataValue['typeNoti'] ? dataValue['typeNoti'] : null, [Validators.required]],
            username: !!dataValue['username'] ? dataValue['username'] : null
        }));
    }

    private _setDataAfterUserdataValue(dataValue: any): void {
        this.after.push(this.formBuilder.group({
            banned: !!dataValue['banned'] ? dataValue['banned'] : null,
            coverPosition: !!dataValue['coverPosition'] ? dataValue['coverPosition'] : null,
            coverURL: !!dataValue['coverURL'] ? dataValue['coverURL'] : null,
            displayName: !!dataValue['displayName'] ? dataValue['displayName'] : null,
            email: !!dataValue['email'] ? dataValue['email'] : null,
            firstName: !!dataValue['firstName'] ? dataValue['firstName'] : null,
            gender: !!dataValue['gender'] ? dataValue['gender'] : null,
            id: !!dataValue['id'] ? dataValue['id'] : null,
            imageURL: !!dataValue['imageURL'] ? dataValue['imageURL'] : null,
            isAdmin: !!dataValue['isAdmin'] ? dataValue['isAdmin'] : null,
            isForeman: !!dataValue['isForeman'] ? dataValue['isForeman'] : null,
            isMarketing: !!dataValue['isMarketing'] ? dataValue['isMarketing'] : null,
            isSubAdmin: !!dataValue['isSubAdmin'] ? dataValue['isSubAdmin'] : null,
            lastName: !!dataValue['lastName'] ? dataValue['lastName'] : null,
            tokenFCM: !!dataValue['tokenFCM'] ? dataValue['tokenFCM'] : null,
            typeNoti: [!!dataValue['typeNoti'] ? dataValue['typeNoti'] : null, [Validators.required]],
            username: !!dataValue['username'] ? dataValue['username'] : null
        }));
    }

    private _setDataNotificationConfig(dataValue: any) {
        const data = this.dateData;
        for (const obj of (typeof dataValue === 'string' ? [dataValue] : dataValue)) {
            if (typeof obj === 'string') {
                const [type, day, time] = obj.split(',').map((part: string) => part.trim());
                this.dataNotification.push({ type: type, date: parseInt(day, 10), time: time });
                data.push(this.formBuilder.group({
                    type: [type, [Validators.required]],
                    date: [parseInt(day, 10), [Validators.required]],
                    time: [time, [Validators.required]]
                })); // add form group
            } else if (typeof obj === 'object') {
                data.push(this.formBuilder.group({
                    type: [obj.type, [Validators.required]],
                    date: [parseInt(obj.date, 10), [Validators.required]],
                    time: [obj.time, [Validators.required]]
                })); // add form group
            }
        }
    }

    public async onEnter(event: any, type: 'before' | 'after') {
        if (!!event.target.value.match(this.patternEmail)) {
            this.offset = 0;
            const user = this.userAdmins.find((item) => item.email === event.target.value);
            if (!user) {
                this.userAdmins = [];
                await this._getUserAdmin(this.offset, this.limit, event.target.value);
            }

            if (type === 'before') {
                if (this.userAdmins.length >= 1) {
                    this._addUser((!!user ? user : this.userAdmins[0]), false, type);
                } else {
                    this._addUser(event.target.value, true, type);
                }
            } else if (type === 'after') {
                if (this.userAdmins.length >= 1) {
                    this._addUser((!!user ? user : this.userAdmins[0]), false, type);
                } else {
                    this._addUser(event.target.value, true, type);
                }
            }

            this.inputBefore.nativeElement.blur();
            this.inputAfter.nativeElement.blur();
            this.autocomplete.closePanel();
            this.userAdmins = [];
            await this._getUserAdmin(this.offset, this.limit);
        }
    }

    public displayFn(user: any): string {
        return user && user.email ? user.email : '';
    }

    private _filter(email: string): any[] {
        const filterValue = email.toLowerCase();
        return this.userAdmins.filter(res => res.email.toLowerCase().includes(filterValue));
    }

    public onChangeEmail(event: any, type: 'before' | 'after') {
        if (type === 'before') {
            if (!!event.target.value.match(this.patternEmail)) {
                this.isErrorBefore = false;
            } else {
                this.isErrorBefore = true;
            }
        } else if (type === 'after') {
            if (!!event.target.value.match(this.patternEmail)) {
                this.isErrorAfter = false;
            } else {
                this.isErrorAfter = true;
            }
        }
    }

    public clickDeleteUser(index: number, email: string, type: 'before' | 'after') {
        this.authenManager.clickDialogAlert({
            header: '',
            content: 'ต้องการลบ "' + email + '" ออกจากการแจ้งเตือน',
        }).then((res: any) => {
            if (res) {
                if (type === 'before') {
                    this.before.removeAt(index);
                } else if (type === 'after') {
                    this.after.removeAt(index);
                }
            }
        });
    }

    private async _addUser(value: any, isNotUser: boolean, type: 'before' | 'after') {
        if (isNotUser) {
            if (await this._checkDuplicateEmail(value, type)) {
                const objVAlue = {
                    banned: null,
                    coverPosition: null,
                    coverURL: null,
                    displayName: value,
                    email: value,
                    firstName: null,
                    gender: null,
                    id: null,
                    imageURL: null,
                    isAdmin: null,
                    isForeman: null,
                    isMarketing: null,
                    isSubAdmin: null,
                    lastName: null,
                    tokenFCM: null,
                    typeNoti: 'email',
                    username: null
                }

                if (type === 'before') this._setDataBeforeUserdataValue(objVAlue);
                if (type === 'after') this._setDataAfterUserdataValue(objVAlue);
            } else {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'Email: ' + value.email + ' นี้มีอยู่แล้วในลิสต์แจ้งเตือน' + (type === 'before' ? 'ล่วงหน้า' : 'เลยกำหนด') + 'แล้ว',
                    cancel: false
                });
            }
        } else {
            if (await this._checkDuplicateEmail(value.email, type)) {
                if (type === 'before') this._setDataBeforeUserdataValue(value);
                if (type === 'after') this._setDataAfterUserdataValue(value);
            } else {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'Email: ' + value.email + ' นี้มีอยู่แล้วในลิสต์แจ้งเตือน' + (type === 'before' ? 'ล่วงหน้า' : 'เลยกำหนด') + 'แล้ว',
                    cancel: false
                });
            }
        }

        if (type === 'before') {
            this.stateBefore.reset();
            this.inputBefore.nativeElement.blur();
            this.stateBefore.markAsUntouched();
            this.isErrorBefore = false;
        }
        if (type === 'after') {
            this.stateAfter.reset();
            this.inputAfter.nativeElement.blur();
            this.stateAfter.markAsUntouched();
            this.isErrorAfter = false;
        }
    }

    private _checkDuplicateEmail(email: string, type: 'before' | 'after'): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const data = (type === 'before' ? this.before.value.find((item: any) => item.email === email) : this.after.value.find((item: any) => item.email === email));
            if (!!data) {
                resolve(false);
            } else {
                resolve(true);
            }
        });
    }

    public clickAddUser(event: any, type: 'before' | 'after') {
        this._addUser(event, false, type);
    }

    private _filterAutocomplete(type: 'before' | 'after'): void {
        if (type === 'before') {
            this.filteredBeforeUserAdmins = this.stateBefore.valueChanges.pipe(
                startWith(''),
                map(value => {
                    const email = typeof value === 'string' ? value : value?.email;
                    return email ? this._filter(email as string) : this.userAdmins.slice();
                }),
            );
        } else if (type === 'after') {
            this.filteredAfterUserAdmins = this.stateAfter.valueChanges.pipe(
                startWith(''),
                map(value => {
                    const email = typeof value === 'string' ? value : value?.email;
                    return email ? this._filter(email as string) : this.userAdmins.slice();
                }),
            );
        }
    }
}