import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as moment from 'moment';
import { Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";
import { AutocompleteChipsModel } from "../../../models/AutocompleteChipsModel";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { ChatFacade } from "../../../services/facade/ChatFacade.service";
import { ChatRoomAdminFacade } from "../../../services/facade/ChatRoomAdminFacade.service";
import { ChatRoomFacade } from "../../../services/facade/ChatRoomFacade.service";
import { NoteAdminFacade } from "../../../services/facade/NoteAdminFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { environment } from "../../../../environments/environment";
import { AutocompleteChips } from "../../shares/AutocompleteChips.component";
import { DialogDetail } from "../../shares/dialog/DialogDetail.component";
import { DialogUpload } from "../../shares/dialog/DialogUpload.component";

const PAGE_NAME: string = 'chat';

@Component({
  selector: 'chat-page',
  templateUrl: './ChatPage.component.html'
})

export class ChatPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  private destroy = new Subject<void>();
  public environmentApi = environment;
  public paramsId: string;
  public menu: any;
  public filterChatList = [
    {
      text: 'ทั้งหมด',
      tag: 'all'
    },
    {
      text: 'อ่านแล้ว',
      tag: 'read'
    },
    {
      text: 'ยังไม่อ่าน',
      tag: 'not_read'
    },
  ];
  public selected = this.filterChatList[0];

  // Start --- Scroll load ---
  public throttle = 50;
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  // End --- Scroll load ---

  // Start --- chat list ---
  public chatList: any[] = [];
  public isLoadchatList: boolean = true;
  public limitChatList = 30;
  public limitRefaceChatList = this.limitChatList;
  public sumChatList = 30;
  @ViewChild('listchat', { read: ElementRef, static: false })
  private listchat!: ElementRef<HTMLElement>;
  // End --- chat list ---

  // Start --- chat room ---
  public chatRoom: any[] = [];
  public isLoadchatRoom: boolean = false;
  public limitChatRoom = 30;
  public limitRefaceChatRoom = this.limitChatRoom;
  public sumChatRoom = 30;
  public isRoomInvalid: boolean;
  @ViewChild('scrollRoom', { read: ElementRef, static: false })
  private scrollRoom!: ElementRef<HTMLElement>;
  public isScrollLast: boolean;
  // End --- chat room ---

  public myMessage = new UntypedFormControl('', [Validators.pattern('[^a-zA-Z0-9ก-๙|^\.s]+')]);
  @ViewChild('drawer', { read: MatSidenav, static: false })
  public drawer!: MatSidenav;
  public isInfo: boolean;
  public isShowing: boolean;

  // Start --- Note ---
  public groupNote: UntypedFormGroup;
  public isCreate: boolean;
  public noteData: any;
  public myNote = new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9ก-๙ \.(){}t_-]+$')]);
  // End --- Note ---

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private authenManager: AuthenManager,
    private observManager: ObservableManager,
    private chatRoomAdminFacade: ChatRoomAdminFacade,
    private chatRoomFacade: ChatRoomFacade,
    private chatFacade: ChatFacade,
    private noteAdminFacade: NoteAdminFacade,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {
        this.activatedRoute.children.forEach(child => {
          child.params.subscribe(params => {
            this.paramsId = params['id'];
          })
        });
      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });

    this.observManager.subscribe('refaceChat', (res: any) => {
      if (res) {
        this.refreshChat(this.limitRefaceChatList);
        // if (this.paramsId) {
        //   setTimeout(async () => {
        //     await this.getRefaceChatRoom(this.paramsId, 0, this.limitRefaceChatRoom, 'DESC');
        //     await this.checkIsRead(this.paramsId);
        //   }, 100);
        // }
      }
    });
  }

  public ngOnInit(): void {
    this.getAutocompleteChip();
    this._formNoteChat();
    this.getChatList(0, this.limitChatList, false, '', { 'chatMessage.createdDate': -1 }, 'push');
    this._messageTo();
    this.__messageToReaders();
    if (this.paramsId) {
      this.getChatRoom(this.paramsId, 0, this.limitChatRoom, 'DESC');
    }
  }

  public ngAfterViewInit(): void {
    this.myMessage.valueChanges.pipe(debounceTime(400), takeUntil(this.destroy))
      .subscribe((res: any) => {
        if (res) {
          this.checkIsRead(this.paramsId);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete('refaceChat');
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'เลขยูนิต',
        tag: 'absUnitId',
        type: 'input'
      },
      {
        name: 'บ้านเลขที่',
        tag: 'no',
        type: 'input'
      },
      {
        name: 'ชื่อลูกค้า',
        tag: 'customerName',
        type: 'input'
      }
    ];
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    // for (const key in filterResult) {
    //   if (!filterResult[key]) {
    //     continue;
    //   }
    //   const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
    //   whereConditions[key] = regex;
    // }

    const optionalFields: any = {};
    if (filterResult['absUnitId'] !== undefined && filterResult['absUnitId'] !== '') {
      optionalFields.absUnitId = { $regex: '.*' + filterResult['absUnitId'] + '.*', $options: 'si' };
    }
    if (filterResult['no'] !== undefined && filterResult['no'] !== '') {
      optionalFields.unitNo = { $regex: '.*' + filterResult['no'] + '.*', $options: 'si' };
    }
    if (filterResult['customerName'] !== undefined && filterResult['customerName'] !== '') {
      optionalFields.userDisplayName = { $regex: '.*' + filterResult['customerName'] + '.*', $options: 'si' };
    }

    return {
      whereConditions,
      optionalFields
    };
  }

  public refreshChat(data: any) {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;

    // clear chat list
    this.chatList = [];
    this.getChatList(0, data, false, '', { 'chatMessage.createdDate': -1 }, 'push', whereConditions, optionalFields);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshChat(this.limitChatList);
    this._resetChatRoom();
  }

  public filterTextComfirm(event: any) {
    this.refreshChat(this.limitChatList);
    this._resetChatRoom();
  }

  private _resetChatRoom() {
    this.router.navigate(['/chat', '']);
    this.isRoomInvalid = false;
  }

  public clickSelect(value: any) {
    this.selected = value;
    this.listchat.nativeElement.scrollTop = 0;
    this.sumChatList = 30;
    this.chatList = [];
    this.getChatList(0, this.limitChatList, false, '', { 'chatMessage.createdDate': -1 }, 'push');
  }

  public onScrollDownChatList() {
    if (this.chatList.length >= this.sumChatList) {
      const start = this.sumChatList;
      this.sumChatList += this.limitChatList;
      this.limitRefaceChatList = start;
      this.getChatList(start, this.limitChatList, false, '', { 'chatMessage.createdDate': -1 }, 'push');
    }
  }

  public onScrollDownChatRoom() {
    if (this.chatRoom.length >= this.sumChatRoom) {
      const start = this.sumChatRoom;
      this.sumChatRoom += this.limitChatRoom;
      this.limitRefaceChatRoom = start;
      this.getChatRoom(this.paramsId, start, this.limitChatRoom, 'DESC');
    }
  }

  public scrollChatRoomToBottom(): void {
    setTimeout(() => {
      document.querySelector('#chat-bottom')?.scrollIntoView({ behavior: 'auto', block: 'end' });
    }, 500);
  }

  private getChatList(offset?: number | null, limit?: number | null, count?: boolean, chatRoomId?: string, orderBy?: any, arrange?: 'unshift' | 'push', whereConditions?: any, optionalFields?: any) {
    let val: any = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      chatRoomId: chatRoomId,
      whereConditions
    };

    if (optionalFields !== undefined) {
      for (const key in optionalFields) {
        val[key] = optionalFields[key];
      }
    }

    if (!val.chatRoomId) {
      delete val.chatRoomId
    }

    this.chatRoomAdminFacade.searchData(val).then((res: any) => {
      if (res) {
        for (const list of res.data) {
          if (arrange === 'unshift') {
            this.chatList.unshift(list);
          } else {
            this.chatList.push(list);
          }
        }

        this.isLoadchatList = true;
        setTimeout(() => {
          this.isLoadchatList = false;
        }, 3000);
      }
    }).catch((err: any) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public getChatRoom(id: string, offset?: number | null, limit?: number | null, orderBy?: any) {
    let val = {
      offset: offset,
      limit: limit,
      orderBy: orderBy,
    };

    this.chatRoomFacade.getRoomChatMessage(id, val).then((res: any) => {
      if (res) {
        for (const data of res.data) {
          this.chatRoom.unshift(data);
        }

        this.isRoomInvalid = true;
        this.isLoadchatRoom = true;
        this.isLoadchatRoom = false;
        if ((offset === 0 || offset === null) && this.chatRoom.length > 0) {
          this.scrollChatRoomToBottom();
          this.sliderInfo();
        }

        let index = this.chatList.findIndex(res => res.id === id);
        if (index >= 0) this._getNote(this.chatList[index].user.id, this.chatList[index].realesateUnitId);
      }
    }).catch((err: any) => {
      if (err) {
        this.isRoomInvalid = false;
        if (err.error.message === 'roomId is invalid.' && err.error.status === 0) {
          this.router.navigate(['/404']);
        } else {
          console.log(err);
        }
      }
    });
  }

  public clickChatList() {
    const items = this.groupNote.get('items') as UntypedFormArray;
    this.chatRoom = [];
    items.clear();
    this.sumChatRoom = 30;
    this.isScrollLast = false;
    setTimeout(async () => {
      await this.getChatRoom(this.paramsId, 0, this.limitChatRoom, 'DESC');
      await this.checkIsRead(this.paramsId);
      this._setReading();
    }, 100);
  }

  public clikSendMess(message?: string, imageURL?: string) {
    if (message) {
      this.chatFacade.sendChat({
        room: this.paramsId,
        message: message,
      });
      this.myMessage.setValue('');
    } else if (imageURL) {
      this.chatFacade.sendChat({
        room: this.paramsId,
        message: '',
        imageURL: imageURL
      });
    }
  }

  private _setReading() {
    this.chatFacade.readed({
      room: this.paramsId,
      senderRole: 'admin',
      senderId: this.authenManager.getCurrentUser().id
    });
  }

  private _messageTo() {
    this.chatFacade.subChat().subscribe(data => {
      if (data) {
        let mess = {
          chatMessage: {
            createdBy: data.chatMessage?.createdBy,
            createdDate: data.chatMessage?.createdDate,
            deleted: data.chatMessage?.deleted,
            fileId: data.chatMessage?.fileId,
            filePath: data.chatMessage?.filePath,
            id: data.chatMessage?.id,
            isRead: data.chatMessage?.isRead,
            message: data.chatMessage?.message,
            messageType: data.chatMessage?.messageType,
            room: data.chatMessage?.room,
            sender: data.chatMessage?.sender,
            senderName: data.chatMessage?.senderName,
            senderType: data.chatMessage?.senderType,
            videoURL: data.chatMessage?.videoURL,
          },
          senderType: data.chatMessage?.senderType,
          senderName: data.chatMessage?.senderName,
          senderImage: data.senderData?.imageURL,
          senderIsRead: data.chatMessage?.isRead
        }

        if (data.room === this.paramsId) {
          this.chatRoom.push(mess);
          this.scrollChatRoomToBottom();
        }

        let index = this.chatList.findIndex(res => res.chatMessage?.room === data.chatMessage?.room);
        if (index >= 0) {
          this.chatList[index].chatMessage.message = data.chatMessage?.message;
          this.chatList[index].chatMessage.createdDate = data.chatMessage?.createdDate;
          this.chatList[index].chatMessage.readers = data.chatMessage?.readers;
          this.chatList[index].chatMessage.senderType = data.chatMessage?.senderType;
          let list = this.chatList.splice(index, 1);
          this.chatList.unshift(...list);
        } else if (data.chatMessage?.senderType === 'USER') {
          this.getChatList(null, null, false, data.chatMessage?.room, { 'chatMessage.createdDate': -1 }, 'unshift');
        }
      }
    });
  }

  private __messageToReaders() {
    this.chatFacade.subReaded().subscribe(data => {
      if (data) {
        if (data.senderRole === 'user' && data.room === this.paramsId) {
          this.chatRoom.map((res: any) => {
            if (res.chatMessage.readers === null || res.chatMessage.readers === undefined) {
              res.chatMessage.readers = [];
            }

            if (res.chatMessage.readers) res.chatMessage.readers.push({
              sender: data.senderId,
              senderType: data.senderRole
            });
          });
        }
      }
    });
  }

  public checkReaderSenderType(data: any, mode: 'admin' | 'user') {
    let index = data?.findIndex((res: any) => res.senderType === mode);
    if (index >= 0) {
      return false;
    } else {
      return true;
    }
  }

  public onScroll(event: any) {
    if ((Math.ceil(event.target.scrollTop + event.target.clientHeight)) < this.scrollRoom?.nativeElement?.scrollHeight) {
      this.isScrollLast = true;
    } else {
      this.isScrollLast = false;
    }

    // this.checkIsRead(this.paramsId);
  }

  public checkReaders(data: any) {
    let index = data?.findIndex((res: any) => res.sender === this.authenManager.getCurrentUser().id);
    if (index >= 0) {
      return false;
    } else {
      return true;
    }
  }

  public checkIsRead(id: string) {
    for (const [i, list] of this.chatList.entries()) {
      if (list.chatMessage?.room === id) {
        this.checkReaders(list.chatMessage.readers);
        this.chatRoomFacade.updateIsRead(id).then((res: any) => {
          if (res) {
            for (const val of res.data) {
              if (list.chatMessage?.room === val.id || list.chatMessage?.room === val._id) {
                this.chatList[i].chatMessage.readers = val.chatMessage.readers;
                this.observManager.createSubject('chat');
                this.observManager.publish('chat', {
                  data: true
                });
              }
            }
          }
        }).catch((err: any) => {
          if (err) {
            console.log(err);
          }
        });
      }
    }

    this._setReading();
  }

  public clickSliderInfo() {
    if (this.isShowing === true) {
      this.isShowing = false;
    } else {
      if (window.innerWidth > 1024) {
        this.isShowing = true;
      } else {
        this.isShowing = false;
      }
    }
  }

  public onResize(event: any) {
    this.sliderInfo();
  }

  public sliderInfo() {
    if (this.paramsId) {
      if (window.innerWidth > 1024) {
        this.drawer.open();
        this.isInfo = false;
        this.isShowing = true;
      } else {
        if (this.isInfo === false) {
          this.drawer.close();
        }
        this.isInfo = true;
        this.isShowing = false;
      }
    }
  }

  public clickCloseSliderInfo() {
    try {
      // this.drawer.nativeElement.scrollTop = 0;
    } catch (err) {
      console.log(err);
    }
  }

  private _formNoteChat() {
    this.groupNote = new UntypedFormGroup({
      items: new UntypedFormArray([])
    });
  }

  get noteItems(): any {
    return (this.groupNote.get('items') as UntypedFormArray).controls;
  }

  private _getNote(userId: string, realesateUnitId: string) {
    this.noteAdminFacade.searchData({ userId, realesateUnitId }).then((res: any) => {
      if (res) {
        this.noteData = res.data;
        const items = this.groupNote.get('items') as UntypedFormArray;
        if (this.noteData.length > items.value.length) {
          items.reset();
          for (const note of this.noteData) {
            items.push(
              this.formBuilder.group({
                createdDate: moment(note.createdDate).locale('th').add(543, 'year').format('ll HH:mm') + 'น.',
                message: note.message,
                createUser: note.createUser.displayName,
                isEdit: false,
                isReadMore: false
              })
            );
          }
        }
      }
    }).catch((err: any) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickCancelCreateNote() {
    if (this.myNote.invalid && this.myNote.value === '') {
      this.isCreate = false;
    } else {
      this.authenManager.clickDialogAlert({
        header: '',
        content: 'ยกเลิกการสร้างโน๊ต',
        cancel: true
      }).then((res: any) => {
        if (res) {
          this.isCreate = false;
        }
      });
    }
  }

  public clickCreateNote() {
    const items = this.groupNote.get('items') as UntypedFormArray;
    let index = this.chatList.findIndex(res => res.id === this.paramsId);
    if (index >= 0) {
      let val = {
        userId: this.chatList[index].user.id,
        realesateUnitId: this.chatList[index].realesateUnitId,
        message: this.myNote.value
      }

      if (this.myNote.invalid && this.myNote.value === '') {
        return;
      }

      this.noteAdminFacade.createData(val).then((res: any) => {
        if (res) {
          this.noteData.unshift(res.data);
          items.insert(0,
            this.formBuilder.group({
              createdDate: moment(res.data.createdDate).locale('th').add(543, 'year').format('ll HH:mm') + 'น.',
              message: res.data.message,
              createUser: res.data.createUser.displayName,
              isEdit: false,
              isReadMore: false
            })
          );
          this.isCreate = false;
          this.myNote.setValue('');
          this.authenManager.clickDialogAlert({
            header: '',
            content: 'สร้างโน๊ตสำเร็จ',
            cancel: false
          }).then((res: any) => {
            if (res) {

            }
          });
        }
      }).catch((err: any) => {
        if (err) {
          console.log(err);
        }
      });
    }
  }

  public clickEditNote(index: number) {
    const items = this.groupNote.get('items') as UntypedFormArray;
    if (!items.at(index).get('message')?.value || items.at(index).get('message')?.value === this.noteData[index].message) {
      return;
    }

    this.noteAdminFacade.updateData(this.noteData[index]._id || this.noteData[index].id, { message: items.at(index).get('message')?.value }).then((res: any) => {
      if (res) {
        this.noteData[index].message = res.data.message;
        items.at(index).get('message')?.setValue(res.data.message);
        items.at(index).get('isEdit')?.setValue(false);
        items.at(index).get('isReadMore')?.setValue(false);
        this.authenManager.clickDialogAlert({
          header: '',
          content: 'แก้ไขโน๊ตสำเร็จ',
          cancel: false
        }).then((res: any) => {
          if (res) {

          }
        });
      }
    }).catch((err: any) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickDeleteNote(index: number) {
    const items = this.groupNote.get('items') as UntypedFormArray;
    this.authenManager.clickDialogAlert({
      header: '',
      content: 'ต้องการลบโน๊ต',
      cancel: true
    }).then((res: any) => {
      if (res) {
        this.noteAdminFacade.deleteData(this.noteData[index]._id || this.noteData[index].id).then((res: any) => {
          if (res) {
            items.removeAt(index);
            this.noteData.splice(index, 1);
          }
        }).catch((err: any) => {
          if (err) {
            console.log(err);
            this.authenManager.clickDialogAlert({
              header: '',
              content: 'ไม่สามารถลบโน๊ตได้',
              cancel: false
            }).then((res: any) => {
              if (res) {
                items.at(index).get('message')?.setValue(this.noteData[index].message);
                items.at(index).get('isEdit')?.setValue(false);
                items.at(index).get('isReadMore')?.setValue(false);
              }
            });
          }
        });
      }
    });
  }

  public clickEditCancel(index: number) {
    const items = this.groupNote.get('items') as UntypedFormArray;
    if (items.at(index).get('message')?.value === this.noteData[index].message) {
      items.at(index).get('isEdit')?.setValue(false);
      items.at(index).get('isReadMore')?.setValue(false);
      return;
    }

    this.authenManager.clickDialogAlert({
      header: '',
      content: 'ยกเลิกการแก้ไข',
      cancel: true
    }).then((res: any) => {
      if (res) {
        items.at(index).get('message')?.setValue(this.noteData[index].message);
        items.at(index).get('isEdit')?.setValue(false);
        items.at(index).get('isReadMore')?.setValue(false);
      }
    });
  }

  public clickDetailExamine(event: any, detail: any, gallery: any, isContent: boolean) {
    const dialogRef = this.dialog.open(DialogDetail, {
      autoFocus: false,
      restoreFocus: false,
      data: {
        index: event ? event.index : null,
        detail: detail ? detail : null,
        gallery: gallery,
        isContent: isContent
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  public clickAddImage() {
    let val = ['.jpg', '.jpeg'];
    const dialogRef = this.dialog.open(DialogUpload, {
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'อัพโหลดรูปภาพ',
        accept: val,
        type: 'image'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result[0].data.size > ((1024 * 1024) * 4)) {
          this.authenManager.clickDialogAlert({
            header: '',
            content: 'การอัพโหลดล้มเหลว. ขนาดสูงสุดของไฟล์ JPG คือ 4 MB.',
            cancel: false
          });
        } else {
          this.clikSendMess('', result[0].base64);
        }
      }
    });
  }

  private getRefaceChatList(offset?: number | null, limit?: number | null, count?: boolean, chatRoomId?: string, orderBy?: any, whereConditions?: any, optionalFields?: any) {
    let val: any = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      chatRoomId: chatRoomId,
      whereConditions
    };

    if (optionalFields !== undefined) {
      for (const key in optionalFields) {
        val[key] = optionalFields[key];
      }
    }

    if (!val.chatRoomId) {
      delete val.chatRoomId
    }

    this.chatRoomAdminFacade.searchData(val).then((res: any) => {
      if (res) {
        this.chatList = res.data;
      }
    }).catch((err: any) => {
      if (err) {
        console.log(err);
      }
    });
  }

  private getRefaceChatRoom(id: string, offset?: number | null, limit?: number | null, orderBy?: any) {
    let val = {
      offset: offset,
      limit: limit,
      orderBy: orderBy,
    };

    this.chatRoomFacade.getRoomChatMessage(id, val).then((res: any) => {
      if (res) {
        this.chatRoom = [];
        for (const data of res.data) {
          this.chatRoom.unshift(data);
        }

        // this.scrollChatRoomToBottom();
        let index = this.chatList.findIndex(res => res.id === id);
        if (index >= 0) this._getNote(this.chatList[index].user.id, this.chatList[index].realesateUnitId);
      }
    }).catch((err: any) => {
      if (err) {
        this.isRoomInvalid = false;
        if (err.error.message === 'roomId is invalid.' && err.error.status === 0) {
          this.router.navigate(['/404']);
        } else {
          console.log(err);
        }
      }
    });
  }

  public setFormatDate(data: any) {
    return moment(data).add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
  }
}