<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper
            class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose();">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                    <div class="flex-1 flex flex-col">
                        <span class="text-lg font-bold flex items-center">
                            <img class="flex-none w-6 h-6 min-w-6 mr-2"
                                [src]="'../../../../assets/images/app/project.svg'" alt="">
                            <span class="flex-1 line-clamp-1">
                                {{dataJob?.absUnitId ? dataJob?.absUnitId :
                                'หัวเรื่อง'}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="flex-none flex items-center">
                    <div class="grid grid-cols-2 gap-2">
                        <!-- <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                            คืนค่า
                        </button>
                        <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">บันทึก</ng-container>
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'create'">สร้าง</ng-container>
                        </button> -->
                    </div>
                    <!-- <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        class="ml-3 flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
                        [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>ลบข้อมูล</span>
                        </button>
                    </mat-menu> -->
                </div>
            </div>
            <div class="flex-1 flex items-start" [ngClass]="{'items-center text-center': installments.length <= 0}">
                <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
                    <div class="w-full md:max-w-[500px] xl:max-w-[560px] grid grid-cols-1 gap-4">
                        <ng-container *ngIf="installments.length > 0; else elseTemplateInstallments">
                            <card-detail [header]="item ? item.absId : 'รายการผ่อนดาวน์'" [isAciton]="true"
                                [isShow]="true" [maxHeight]="'unset'" *ngFor="let item of installments; let i = index">
                                <ul body role="list" class="divide-y divide-slate-200 px-4 py-6">
                                    <li class="flex items-center py-4 first:pt-0 last:pb-0">
                                        <div class="flex-1">
                                            <div
                                                class="rounded text-sm font-normal {{!item.paymentDate ? 'bg-cyan-600' : 'bg-green-600'}} text-white px-4 py-1 inline-block">
                                                {{!item.paymentDate ? 'รอชำระ' : 'ชำระแล้ว'}}
                                            </div>
                                            <div class="text-sm font-normal mt-1 opacity-50">
                                                {{parseDate(item.paymentDate) ? (setYear(parseDate(item.paymentDate) ,
                                                '543')) : (parseDate(item.dueDate) ? (setYear(parseDate(item.dueDate) ,
                                                '543')) : '-')}}
                                            </div>
                                        </div>
                                        <div class="flex-none text-right">
                                            <div class="text-base font-normal text-[color:var(--bg-69)]">
                                                {{!item.amount ? '-' : item.amount}}
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li class="flex py-4 first:pt-0 last:pb-0">
                                    <div class="flex-1">
                                        <div class="text-base font-normal text-[color:var(--bg-69)]">
                                            ธ.กสิกรไทย
                                        </div>
                                        <div class="text-sm font-normal opacity-50">
                                            เลขบัญชีต้นทาง
                                        </div>
                                        <div class="text-sm font-normal opacity-50">
                                            ช่องทาง
                                        </div>
                                    </div>
                                    <div class="flex-none text-right">
                                        <div class="text-base font-normal text-[color:var(--bg-69)]">
                                            xxx-xx455x-9
                                        </div>
                                        <div class="text-sm font-normal opacity-50">
                                            K Plus
                                        </div>
                                        <div class="text-sm font-normal opacity-50">
                                        </div>
                                    </div>
                                </li> -->
                                </ul>
                            </card-detail>
                        </ng-container>
                        <ng-template #elseTemplateInstallments>
                            <span class="text-lg font-medium">
                                ยังไม่มีข้อมูล
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>