import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'card-notification',
    templateUrl: './CardNotification.component.html',
})
export class CardNotification implements OnInit {
    @Input()
    public class!: string | [string];
    @Input()
    public image: any = '';
    @Input()
    public content: any = 'ตัวอย่างการแจ้งเตือน ยกตัวอย่าง เช่น แจ้งเตื่อนเมื่อปิดงานซ่อม';
    @Input()
    public time: any = '15 นาทีที่แล้ว';
    @Input()
    public read: boolean = false;
    @Input()
    public pulse: boolean = false;
    @Output()
    public event: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    public ngOnInit() {
    }

    public clickEvent(event: any) {
        this.event.emit(event);
    }
}