import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CustomTable } from "../table/CustomTable.component";

@Component({
    selector: 'dialog-table',
    templateUrl: './DialogTable.component.html',
})
export class DialogTable implements OnInit {
    // Start table
    @ViewChild('table') tableRow: CustomTable;
    // End table

    public dataTable: any = {};

    constructor(
        public dialogRef: MatDialogRef<DialogTable>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private observManager: ObservableManager
    ) {
        this.dialogRef.disableClose = true;
    }

    public ngOnInit(): void {
        this.dialogRef.addPanelClass('overlay-dialog-table');
        if (this.data) setTimeout(() => {
            this._setDataTable();
        }, 10);
        this.observManager.subscribe('dialogTable.paginator', (res: any) => {
            if (res) {
                this.data.content.data = res.data;
                this.tableRow.data = res.data;
                this.dataTable['data'] = res.data;
                setTimeout(() => {
                    this.tableRow.isLoadingResults = false;
                }, 1000);
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete('dialogTable.paginator');
    }

    private _setDataTable() {
        this.dataTable['columns'] = this.data.content.columns;
        this.dataTable['data'] = this.data.content.data;
        this.dataTable['lengthDataTable'] = this.data.content.lengthDataTable;
        this.dataTable['limitTable'] = this.data.content.limitTable;
        this.dataTable['isCheckBoxAll'] = this.data.content.isCheckBoxAll;
        this.dataTable['isCheckBox'] = this.data.content.isCheckBox;
        this.dataTable['isShowFirstLastButtons'] = this.data.content.isShowFirstLastButtons;
    }

    public clickClose(): void {
        this.dialogRef.close(false);
        if (this.data !== undefined && this.data !== null && this.data.cancelClickedEvent !== undefined) {
            this.data.cancelClickedEvent.emit(false);
        }
    }

    public clickSubmit(): void {
        this.dialogRef.close(true);
        if (this.data !== undefined && this.data !== null && this.data.confirmClickedEvent !== undefined) {
            this.data.confirmClickedEvent.emit(true);
        }
    }

    public clickPaginator(event: any) {
        let count: number = this.data.content.limitPage * event.pageIndex;
        this.observManager.createSubject('dialogTable.click');
        this.observManager.publish('dialogTable.click', {
            data: count
        });
    }
}