export class DateTimeUtils {
    public static padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }

    public static formatDate(date: Date) {
        return (
            [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
            ].join('-') +
            ' ' +
            [
                this.padTo2Digits(date.getHours()),
                this.padTo2Digits(date.getMinutes()),
            ].join(':')
        );
    }
}