import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { RepairCreateRequest } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class RealesateUnitRepairFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/realesate_unit/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public searchData = (reUnitId: string, repairId: string) => this.get<MethodResponseInf<any>>(`${(reUnitId) + '/repair/'}${(repairId) + '/statusHistory'}`, this.getDefaultHeader(), null);
    public createData = (request: RepairCreateRequest, id: string) => this.post<MethodResponseInf<any>>(`${(id) + '/repair'}`, this.getDefaultHeader(), null, request);
}