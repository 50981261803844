import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { CustomerServiceStatusAdminFacade } from "../../../services/facade/CustomerServiceStatusAdminFacade.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-customer-service-status',
    templateUrl: './SliderCustomerServiceStatus.component.html',
})
export class SliderCustomerServiceStatus implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public isSubmitted: boolean = false;
    public isCompleteItem: string = '1';
    public groupCustomerServiceStatusForm: UntypedFormGroup;
    public dataPage: any[] = [];
    public filteredListPage: any;
    public color: string;

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private formBuilder: UntypedFormBuilder,
        private checkTokenService: CheckTokenService,
        private customerServiceStatusAdminFacade: CustomerServiceStatusAdminFacade,
    ) {
        // this.getSelectPrefix();
        this.formCustomerServiceStatus();
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                if (this.dataDetail.mode === 'edit') {
                    this.setCustomerServiceStatusInfo(res.data.value);
                    setTimeout(() => {
                        this.groupCustomerServiceStatusForm.get('isComplete')?.disable();
                    }, 10);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.groupCustomerServiceStatusForm.get('isComplete')?.enable();
                    }, 10);
                }
            }
        });
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                // this.getSelectPrefix();
                if (this.dataDetail.mode === 'edit') {
                    this.setCustomerServiceStatusInfo(this.dataDetail.value);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            let isComplete = this.dataJob.isComplete ? '1' : '0';
            if (
                this.groupCustomerServiceStatusForm.get('name')?.value !== this.dataJob.name ||
                this.groupCustomerServiceStatusForm.get('colorCode')?.value !== this.dataJob.colorCode ||
                this.groupCustomerServiceStatusForm.get('ordering')?.value !== JSON.stringify(this.dataJob.ordering) ||
                this.groupCustomerServiceStatusForm.get('isComplete')?.value !== isComplete
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.setCustomerServiceStatusInfo(this.dataDetail.value);
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupCustomerServiceStatusForm.get('name')?.value ||
                this.groupCustomerServiceStatusForm.get('colorCode')?.value !== '#000000' ||
                this.groupCustomerServiceStatusForm.get('ordering')?.value !== '0' ||
                this.groupCustomerServiceStatusForm.get('isComplete')?.value !== '0'
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.formCustomerServiceStatus();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            let isComplete = this.dataJob.isComplete ? '1' : '0';
            if (
                this.groupCustomerServiceStatusForm.get('name')?.value !== this.dataJob.name ||
                this.groupCustomerServiceStatusForm.get('colorCode')?.value !== this.dataJob.colorCode ||
                this.groupCustomerServiceStatusForm.get('ordering')?.value !== JSON.stringify(this.dataJob.ordering) ||
                this.groupCustomerServiceStatusForm.get('isComplete')?.value !== isComplete
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.setCustomerServiceStatusInfo(this.dataDetail.value);
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupCustomerServiceStatusForm.get('name')?.value ||
                this.groupCustomerServiceStatusForm.get('colorCode')?.value !== '#000000' ||
                this.groupCustomerServiceStatusForm.get('ordering')?.value !== '0' ||
                this.groupCustomerServiceStatusForm.get('isComplete')?.value !== '0'
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.formCustomerServiceStatus();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.groupCustomerServiceStatusForm.reset();
        this.formCustomerServiceStatus();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    public clearFormData(): void {
        this.groupCustomerServiceStatusForm.reset();
        this.formCustomerServiceStatus();
    }

    private getFormData(): any {
        const name = this.groupCustomerServiceStatusForm.get('name')?.value;
        const colorCode = this.groupCustomerServiceStatusForm.get('colorCode')?.value;
        const orderingString = this.groupCustomerServiceStatusForm.get('ordering')?.value;
        const isCompleteString = this.groupCustomerServiceStatusForm.get('isComplete')?.value;
        let ordering = 0;
        try {
            ordering = parseInt(orderingString);
        } catch (error) {
        }
        let isComplete = false;
        try {
            isComplete = isCompleteString === '1' ? true : false;
        } catch (error) {
        }

        const editObj = {
            name,
            colorCode,
            isComplete,
            ordering
        };

        return editObj;
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'edit') {
            const isInvalid = this.groupCustomerServiceStatusForm.get('name')?.invalid ||
                this.groupCustomerServiceStatusForm.get('colorCode')?.invalid ||
                this.groupCustomerServiceStatusForm.get('ordering')?.invalid ||
                this.groupCustomerServiceStatusForm.get('isComplete')?.invalid;

            if (isInvalid) {
                return;
            }

            const formData = this.getFormData();

            if (formData.pageId === '') {
                return;
            }

            this.isSubmitted = true;
            this.customerServiceStatusAdminFacade.updateData(this.dataDetail.value.id || this.dataDetail.value._id, formData).then((res: any) => {
                if (res) {
                    this.setCustomerServiceStatusInfo(res.data);
                    this.editTableRow(this.dataDetail.index, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            const isInvalid = this.groupCustomerServiceStatusForm.get('name')?.invalid ||
                this.groupCustomerServiceStatusForm.get('colorCode')?.invalid ||
                this.groupCustomerServiceStatusForm.get('ordering')?.invalid ||
                this.groupCustomerServiceStatusForm.get('isComplete')?.invalid;

            if (isInvalid) {
                return;
            }

            const formData = this.getFormData();

            if (formData.pageId === '') {
                return;
            }

            this.isSubmitted = true;
            this.customerServiceStatusAdminFacade.createData(formData).then((res: any) => {
                if (res) {
                    this.createTableRow(res.data, 'create');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    });
                    // clear form
                    this.clearFormData();
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.name + '"'
            }).then((res: any) => {
                if (res) {
                    this.customerServiceStatusAdminFacade.deleteData(this.dataDetail.value.id || this.dataDetail.value._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.formCustomerServiceStatus();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    private setCustomerServiceStatusInfo(data: any) {
        this.dataJob = data;
        this.groupCustomerServiceStatusForm.get('name')?.setValue(this.dataJob.name);
        this.groupCustomerServiceStatusForm.get('colorCode')?.setValue(this.dataJob.colorCode);
        this.groupCustomerServiceStatusForm.get('ordering')?.setValue(this.dataJob.ordering + '');
        this.groupCustomerServiceStatusForm.get('isComplete')?.setValue(this.dataJob.isComplete ? '1' : '0');
    }

    private formCustomerServiceStatus() {
        this.groupCustomerServiceStatusForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
            colorCode: ['#000000', [Validators.pattern('^#(?:[0-9a-fA-F]{3}){1,2}$')]],
            ordering: ['0', [Validators.pattern('^[0-9]{1,2}$')]],
            isComplete: ['0', [Validators.required]],
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupCustomerServiceStatusForm.controls;
    }
}

