import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogsMessageRequest } from '../../request/LogsMessageRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class LogsMessageFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/marketing/logs/');
    }
    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);

    public searchData = (request?: LogsMessageRequest) => this.post<MethodResponseInf<any>>('search', this.getDefaultHeader(), null, request);
    // public createData = (request: MessageRequest) => this.post<MethodResponseInf<any>>('', this.getDefaultHeader(), null, request);
    // public updateData = (id: string, request: MessageRequest) => this.put<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), null, request);
    // public deletePost = (id: string) => this.delete<any>(`${(id)}`, this.getDefaultHeader(), null);
}