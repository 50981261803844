import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";

@Component({
    selector: 'dialog-datepicker',
    templateUrl: './DialogDatepicker.component.html',
})
export class DialogDatepicker implements OnInit {
    public groupTime!: UntypedFormGroup;
    public isReset: boolean = false;
    private timeDefault: any = {
        hh: 0,
        mm: 0
    }

    constructor(
        public dialogRef: MatDialogRef<DialogDatepicker>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: UntypedFormBuilder,
    ) {
        this.dialogRef.disableClose = true;
        this._validatorsTime();
        if (this.data.selectedDate) {
            const date = new Date(this.data.selectedDate);
            this.timeDefault!.hh = parseInt(moment(date).add(543, 'year').format('HH'));
            this.timeDefault!.mm = parseInt(moment(date).add(543, 'year').format('mm'));
            this.groupTime.get('hh')?.setValue(moment(date).add(543, 'year').format('HH'));
            this.groupTime.get('mm')?.setValue(moment(date).add(543, 'year').format('mm'));
        }
    }

    public ngOnInit(): void {
        this.dialogRef.addPanelClass('overlay-dialog-datepicker');
    }

    public clickClose(): void {
        this.dialogRef.close(false);
        if (this.data !== undefined && this.data !== null && this.data.cancelClickedEvent !== undefined) {
            this.data.cancelClickedEvent.emit(false);
        }
    }

    public clickSubmit(): void {
        const groupTime = this.groupTime;
        const date = new Date(this.data.selectedDate);
        let val: any;
        if (this.data.type === 'all' || this.data.type === 'date' || this.data.type === 'all-bypass') {
            date.setHours(groupTime.get('hh')?.value);
            date.setMinutes(groupTime.get('mm')?.value);
            val = date;
        } else if (this.data.type === 'time') {
            val = (groupTime.get('hh')?.value + ':' + groupTime.get('mm')?.value);
        }

        this.dialogRef.close(this.isReset ? null : val);
        if (this.data !== undefined && this.data !== null && this.data.confirmClickedEvent !== undefined) {
            this.data.confirmClickedEvent.emit(true);
        }
    }

    public clickReset() {
        this.data.selectedDate = null;
        this.groupTime.get('hh')?.setValue('00');
        this.groupTime.get('mm')?.setValue('00');
        this.isReset = true;
    }

    public onSelectedChange(event: any) {
        this.data.selectedDate = event;
        this.isReset = false;
        this.clickSubmit();
    }

    private _validatorsTime() {
        this.groupTime = this.formBuilder.group({
            hh: ['', [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-3])?$')]],
            mm: ['', [Validators.required, Validators.pattern('^([0-5]?[0-9]|5[0-9])?$')]],
        });
    }

    public clickTime(position: number = 1 || 2, type: boolean) {
        const groupTime = this.groupTime;
        if (position === 1) {
            if (type) {
                if (this.timeDefault!.hh <= 23) {
                    this.timeDefault!.hh += 1;
                    groupTime.get('hh')?.setValue(this.timeDefault!.hh < 10 ? ('0' + this.timeDefault!.hh) : this.timeDefault!.hh);
                }

                if (this.timeDefault!.hh < 0 || this.timeDefault!.hh === 24) {
                    this.timeDefault!.hh = 0;
                    groupTime.get('hh')?.setValue('00');
                }
            } else {
                if (this.timeDefault!.hh > -1) {
                    this.timeDefault!.hh -= 1;
                    groupTime.get('hh')?.setValue(this.timeDefault!.hh < 10 ? ('0' + this.timeDefault!.hh) : this.timeDefault!.hh);
                }

                if (this.timeDefault!.hh < 0) {
                    this.timeDefault!.hh = 23;
                    groupTime.get('hh')?.setValue(this.timeDefault!.hh);
                }

                if (this.timeDefault!.hh === 0) {
                    this.timeDefault!.hh = 0;
                    groupTime.get('hh')?.setValue('00');
                }
            }
        } else if (position === 2) {
            if (type) {
                if (this.timeDefault!.mm <= 59) {
                    this.timeDefault!.mm += 1;
                    groupTime.get('mm')?.setValue(this.timeDefault!.mm < 10 ? ('0' + this.timeDefault!.mm) : this.timeDefault!.mm);
                }

                if (this.timeDefault!.mm < 0 || this.timeDefault!.mm === 60) {
                    this.timeDefault!.mm = 0;
                    groupTime.get('mm')?.setValue('00');
                }
            } else {
                if (this.timeDefault!.mm > -1) {
                    this.timeDefault!.mm -= 1;
                    groupTime.get('mm')?.setValue(this.timeDefault!.mm < 10 ? ('0' + this.timeDefault!.mm) : this.timeDefault!.mm);
                }

                if (this.timeDefault!.mm < 0) {
                    this.timeDefault!.mm = 59;
                    groupTime.get('mm')?.setValue(this.timeDefault!.mm);
                }

                if (this.timeDefault!.mm === 0) {
                    this.timeDefault!.mm = 0;
                    groupTime.get('mm')?.setValue('00');
                }
            }
        }
    }
}