import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TypingDataInf } from '../interface/interface';
import { ChatFacade, ChatRoomFacade, NotificationFacade } from '../services/services';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

declare var $: any;
const PAGE_NAME: string = 'test';

@Component({
  selector: 'test-main-page',
  templateUrl: './Test.component.html',
})
export class Test implements OnInit {


  public static readonly PAGE_NAME: string = PAGE_NAME;
  private chatFacade: ChatFacade;
  private chatRoomFacade: ChatRoomFacade;
  private notiFacade: NotificationFacade;

  public campaignOne: UntypedFormGroup;
  public campaignTwo: UntypedFormGroup;
  constructor(private title: Title, private meta: Meta, chatFacade: ChatFacade, chatRoomFacade: ChatRoomFacade, notiFacade: NotificationFacade) {
    this.chatFacade = chatFacade;
    this.chatRoomFacade = chatRoomFacade;
    this.notiFacade = notiFacade;
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaignOne = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(year, month, 13)),
      end: new UntypedFormControl(new Date(year, month, 16)),
    });

    this.campaignTwo = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(year, month, 15)),
      end: new UntypedFormControl(new Date(year, month, 19)),
    });
  }

  async ngOnInit() {

    // example!!

    // let isConnected = false;

    // const chatRoom = new ChatRoom();
    // chatRoom.type = 'USER';
    // chatRoom.realesateUnitId = '61e8d99127270309815d410e';
    // chatRoom.typeId = '61ea8337a5875c23d0158742';
    // const createChatRoom = await this.chatRoomFacade.createData(chatRoom);

    // const chatRoomList = await this.chatRoomFacade.getList(0, 2);
    // console.log('chatRoomList; ', chatRoomList.data);

    // const chatMessageList = await this.chatRoomFacade.getRoomChatMessage('62273eee8e38afa610a8ea96');
    // console.log('chatMessageList; ', chatMessageList.data);

    // // create chat message
    // const message = { 'room': '62273eee8e38afa610a8ea96', 'message': 'helo' };

    // // test sendChat
    // setInterval(() => {
    //   try {

    //     // if connecting server
    //     if (isConnected) {
    //       // send!
    //       this.chatFacade.sendChat(message);
    //     }
    //   } catch (error) {
    //     console.log('error: ', error);
    //   }
    // }, 50000);


    // const readed = { 'room': '62273eee8e38afa610a8ea96' };
    // // readed!
    // this.chatFacade.readed(readed);

    // // typing!
    // const typing: Partial<TypingDataInf> = { 'room': '62273eee8e38afa610a8ea96', 'isTyping': true };


    // this.chatFacade.typing(typing);

    // // subscribe chat
    // this.chatFacade.subChat().subscribe(data => {
    //   console.log('chat:', data);
    // });

    // // subscribe typing
    // this.chatFacade.subTyping().subscribe(data => {
    //   console.log('type:', data);
    // });

    // // subscribe notification
    // this.notiFacade.sub().subscribe((data) => {
    //   console.log('noti: ', data);
    // });

    // // subscribe is connect
    // this.chatFacade.subIsConnect().subscribe(data => {
    //   console.log('isConnect: ', data);
    //   isConnected = data;
    // });

    // this.chatFacade.subReaded().subscribe(data => {
    //   console.log('readed: ', data);
    // });

    // this.chatRoomFacade.connectError().subscribe(() => { }, error => {
    //   console.log('hi: ', error);
    // });
    // const x = await this.activateFacade.validate('d');
    // x.data.citizenId
    // const y = await this.authenManager.login(new LoginRequest('treerayuth.o.1994@gmail.com', '123456'));
    // console.log('y: ', y);

    // const x = await this.repairStatusAdminFacade.searchData();
    // console.log('x: ', x);

    // const y = await this.authenManager.logout();


    // const y = await this.authenManager.forgotPassword('hahakozec9@gmail.com');

    // const request = new ChangePasswordRequest();
    // const changePassword = await this.authenManager.changePassword(request);
    // changePassword.data
    // const registerRequest = new RegisterRequest();
    // registerRequest.citizenId = '1199900617719';
    // registerRequest.email = 'hahakozec9@gmail.com'
    // registerRequest.mobileNo = '0818537880';
    // registerRequest.password = 'N@m1982540';
    // registerRequest.displayName = 'plzdontcry';
    // const y = await this.authenManager.register(registerRequest);
    // console.log('y: ', y);
  }
}