<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper
            class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose();">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                    <div class="flex-1 flex flex-col">
                        <span class="text-lg font-bold flex items-center">
                            <img class="flex-none w-6 h-6 min-w-6 mr-2"
                                [src]="'../../../../assets/images/app/project.svg'" alt="">
                            <span class="flex-1 line-clamp-1">
                                {{groupActivateInfo.get('absUnitId')?.value ?
                                groupActivateInfo.get('absUnitId')?.value :
                                'หัวเรื่อง'}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="flex-none flex items-center">
                    <div class="grid grid-cols-2 gap-2">
                        <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                            คืนค่า
                        </button>
                        <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">บันทึก</ng-container>
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'create'">สร้าง</ng-container>
                        </button>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        class="ml-3 flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
                        [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>ลบข้อมูล</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="flex-1 flex items-start">
                <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
                    <div class="w-full md:max-w-[500px] xl:max-w-[560px] grid grid-cols-1 gap-6">
                        <ng-container [formGroup]="groupActivateInfo">
                            <div class="card-detail">
                                <div class="flex items-center min-h-[80px] border-b p-4">
                                    <div class="flex-1 flex flex-col">
                                        <span class="text-lg font-bold">
                                            ข้อมูลบ้าน
                                        </span>
                                        <span class="text-sm font-normal opacity-50 mt-1">
                                            ข้อมูลทั่วไปของบ้านในโครงการ
                                        </span>
                                    </div>
                                </div>
                                <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <div>
                                        <span class="text-base font-bold opacity-50">รหัสโครงการ</span>
                                        <mat-select #select class="select-custom" [disableOptionCentering]="true"
                                            (selectionChange)="selectProject($event)" [placeholder]="'เลือก'"
                                            autocomplete="off" formControlName="prefix"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('prefix')?.touched) && f['prefix'].errors">
                                            <mat-select-filter class="select-filter" [displayMember]="'prefix'"
                                                [array]="dataPage" [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                                                (filteredReturn)="filteredListPage =$event" *ngIf="select.focused">
                                            </mat-select-filter>
                                            <ng-container *ngFor="let item of filteredListPage">
                                                <mat-option class="font-medium" [value]="item.prefix">
                                                    ({{item.prefix}}) {{item.name}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('prefix')?.touched) && f['prefix'].errors">
                                            รหัสโครงการต้องเป็น 3 หลัก
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">รหัสยูนิต</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="absUnitId"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('absUnitId')?.touched) && f['absUnitId'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('absUnitId')?.touched) && f['absUnitId'].errors">
                                            รหัสยูนิตต้องเป็น 11 หลัก
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">บ้านเลขที่</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="no"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('no')?.touched) && f['no'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('no')?.touched) && f['no'].errors">
                                            บ้านเลขที่กรอกอย่างน้อย 1 หลัก
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">ชื่อแบบบ้าน</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="modelName"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('modelName')?.touched) && f['modelName'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('modelName')?.touched) && f['modelName'].errors">
                                            ชื่อแบบบ้านกรอกอย่างน้อย 3 หลัก
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">รหัสติดตามการขาย</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="absLeadId"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('absLeadId')?.touched) && f['absLeadId'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('absLeadId')?.touched) && f['absLeadId'].errors">
                                            รหัสติดตามการขายต้องเป็น 11 หลัก
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">วันที่เริ่มรับประกันบ้าน</span>
                                        <div class="relative flex items-center mt-1" (click)="picker1.open()">
                                            <input class="input-custom mt-0 bg-transparent" type="text" placeholder=""
                                                autocomplete="off" formControlName="guaranteeDate"
                                                [matDatepicker]="picker1" (dateChange)="getProjectData()"
                                                [class.border-error]="(isSubmitted || groupActivateInfo.get('guaranteeDate')?.touched) && f['guaranteeDate'].errors">
                                            <mat-datepicker #picker1 disabled="false" restoreFocus="false">
                                            </mat-datepicker>
                                            <button mat-icon-button
                                                class="absolute right-2 z-[1] flex-none icon-ripper-center"
                                                (click)="picker1.open()">
                                                <mat-icon>event</mat-icon>
                                            </button>
                                        </div>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('guaranteeDate')?.touched) && f['guaranteeDate'].errors">
                                            เลือก วัน/เดือน/ปี
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">วันที่สิ้นสุดรับประกันบ้าน</span>
                                        <div class="relative flex items-center mt-1" (click)="picker2.open()">
                                            <input class="input-custom mt-0 bg-transparent" type="text" placeholder=""
                                                autocomplete="off" formControlName="guaranteeEndDate"
                                                [matDatepicker]="picker2"
                                                [class.border-error]="(isSubmitted || groupActivateInfo.get('guaranteeEndDate')?.touched) && f['guaranteeEndDate'].errors">
                                            <mat-datepicker #picker2 disabled="false" restoreFocus="false">
                                            </mat-datepicker>
                                            <button mat-icon-button
                                                class="absolute right-2 z-[1] flex-none icon-ripper-center"
                                                (click)="picker2.open()">
                                                <mat-icon>event</mat-icon>
                                            </button>
                                        </div>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('guaranteeEndDate')?.touched) && f['guaranteeEndDate'].errors">
                                            เลือก วัน/เดือน/ปี
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">วันโอนกรรมสิทธิ์</span>
                                        <div class="relative flex items-center mt-1" (click)="picker3.open()">
                                            <input class="input-custom mt-0 bg-transparent" type="text" placeholder=""
                                                autocomplete="off" formControlName="transferOwnershipDate"
                                                [matDatepicker]="picker3"
                                                [class.border-error]="(isSubmitted || groupActivateInfo.get('transferOwnershipDate')?.touched) && f['transferOwnershipDate'].errors">
                                            <mat-datepicker #picker3 disabled="false" restoreFocus="false">
                                            </mat-datepicker>
                                            <button mat-icon-button
                                                class="absolute right-2 z-[1] flex-none icon-ripper-center"
                                                (click)="picker3.open()">
                                                <mat-icon>event</mat-icon>
                                            </button>
                                        </div>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('transferOwnershipDate')?.touched) && f['transferOwnershipDate'].errors">
                                            เลือก วัน/เดือน/ปี
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                            <div class="card-detail">
                                <div class="flex items-center min-h-[80px] border-b p-4">
                                    <div class="flex-1 flex flex-col">
                                        <span class="text-lg font-bold">
                                            ข้อมูลยืนยัน
                                        </span>
                                        <span class="text-sm font-normal opacity-50 mt-1">
                                            ข้อมูลสำหรับการเปิดใช้งานแอพพลิเคชั่นของบ้าน
                                        </span>
                                    </div>
                                </div>
                                <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <div>
                                        <span class="text-base font-bold opacity-50">ชื่อเจ้าบ้าน (ชื่อ-นามสกุล)</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="fullName"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('fullName')?.touched) && f['fullName'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('fullName')?.touched) && f['fullName'].errors">
                                            ชื่อเจ้าบ้านกรอกอย่างน้อย 3 หลัก
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">รหัสประจำตัวประชาชน</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="citizenId"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('citizenId')?.touched) && f['citizenId'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('citizenId')?.touched) && f['citizenId'].errors">
                                            กรุณาใส่รหัสบัตรประชาชน
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">หมายเลขโทรศัพท์</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="mobileNo"
                                            [class.border-error]="(isSubmitted || groupActivateInfo.get('mobileNo')?.touched) && f['mobileNo'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupActivateInfo.get('mobileNo')?.touched) && f['mobileNo'].errors">
                                            หมายเลขโทรศัพท์กรอกอย่างน้อย 9 หลัก
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>