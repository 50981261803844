import { SearchFilter } from "./SearchFilterRequest";

export class ChangePasswordRequest {
    public code?: string;
    public email?: string;
    public password?: string;

    constructor(code: string, email: string, password: string) {
        this.code = code;
        this.email = email;
        this.password = password;
    }
}