<div class="main-page flex flex-col" [class.pointer-events-none]="!isFullScreen" (window:resize)="onResize($event)">
    <div class="fixed top-0 left-0 bottom-0 right-0 z-[99999] h-screen flex flex-col items-center justify-center p-4 text-center select-none bg-white"
        *ngIf="!isFullScreen">
        <span class="material-icons-outlined text-[80px] text-red-500">
            screenshot_monitor
        </span>
        <div class="mt-4 text-4xl font-bold">
            Your browser is too small
        </div>
        <div class="mt-4 text-lg font-medium opacity-60">
            Resize your browser to be at least 900px
            <br>
            wide to get back into work mode.
        </div>
    </div>
    <header-page class="main-page-left fixed top-0 bottom-0 left-0 z-[101] grow" (openMenu)="setMenuOpen($event);">
    </header-page>
    <div class="main-page-right shrink-0 min-h-screen bg-[color:var(--bg-F2)]" [class.active]="isMenuOpen">
        <router-outlet></router-outlet>
    </div>
</div>