import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { PageFacade } from "../../../services/facade/PageFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { DocumentManualFacade } from "../../../services/facade/DocumentManualFacade.service";
import { DialogUpload } from "../dialog/DialogUpload.component";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'slider-document-manual',
    templateUrl: './SliderDocumentManual.component.html',
})
export class SliderDocumentManual implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public formGroup: UntypedFormGroup;
    public isSubmitted: boolean = false;
    public dataPage: any[] = [];
    public filteredListPage: any;
    public file: any;

    constructor(
        private http: HttpClient,
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private formBuilder: UntypedFormBuilder,
        private pageFacade: PageFacade,
        private documentManualFacade: DocumentManualFacade,
        private checkTokenService: CheckTokenService,
    ) {
        this.formDocumentManual();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                this.getSelectPage();
                if (res.data.mode === 'edit') {
                    this.getDocumentManual(this.dataDetail.value);
                    setTimeout(() => {
                        this.formGroup.get('pageId')?.disable();
                        this.formGroup.get('pageId')?.clearValidators();
                        this.formGroup.get('pageId')?.updateValueAndValidity();
                    }, 10);
                } else if (res.data && res.data.mode === 'create') {
                    setTimeout(() => {
                        this.formGroup.get('pageId')?.enable();
                        this.formGroup.get('pageId')?.setValidators([Validators.required]);
                        this.formGroup.get('pageId')?.updateValueAndValidity();
                    }, 10);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.formGroup.get('pageId')?.value !== this.dataDetail.value.pageId ||
                this.formGroup.get('title')?.value !== this.dataDetail.value.title ||
                this.formGroup.get('description')?.value !== this.dataDetail.value.description ||
                this.formGroup.get('file')?.value !== this.dataDetail.value.fileName
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this.getDocumentManual(this.dataDetail.value);
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.formGroup.get('pageId')?.value ||
                this.formGroup.get('title')?.value ||
                this.formGroup.get('description')?.value ||
                this.formGroup.get('file')?.value
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.formGroup.reset();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.formGroup.get('pageId')?.value !== this.dataDetail.value.pageId ||
                this.formGroup.get('title')?.value !== this.dataDetail.value.title ||
                this.formGroup.get('description')?.value !== this.dataDetail.value.description
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.getDocumentManual(this.dataDetail.value);
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.formGroup.get('pageId')?.value ||
                this.formGroup.get('title')?.value ||
                this.formGroup.get('description')?.value ||
                this.formGroup.get('file')?.value
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.formGroup.reset();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                    }
                });

                return;
            }
        }

        this.formGroup.reset();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private getDocumentManual(data: any) {
        this.formGroup.get('pageId')?.setValue(data.pageId);
        this.formGroup.get('title')?.setValue(data.title);
        this.formGroup.get('description')?.setValue(data.description);
        this.formGroup.get('file')?.setValue(data.fileName);
    }

    public formDocumentManual() {
        this.formGroup = this.formBuilder.group({
            pageId: [''],
            title: ['', [Validators.required]],
            description: [''],
            file: ['']
        });
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        const formData: any = new FormData();
        if (this.dataDetail.mode === 'create') formData.append('pageId', this.formGroup.get('pageId')?.value);
        if (this.dataDetail.mode === 'create' || this.formGroup.get('file')?.value !== this.dataDetail.value.fileName) formData.append('file', this.file);
        formData.append('title', this.formGroup.get('title')?.value);
        formData.append('description', !!this.formGroup.get('description')?.value ? this.formGroup.get('description')?.value : '');
        if (this.dataDetail.mode === 'edit') {
            if (this.formGroup.invalid || (
                this.formGroup.get('pageId')?.value === this.dataDetail.value.pageId &&
                this.formGroup.get('title')?.value === this.dataDetail.value.title &&
                this.formGroup.get('description')?.value === this.dataDetail.value.description &&
                this.formGroup.get('file')?.value === this.dataDetail.value.fileName)
            ) {
                return;
            }

            this.isSubmitted = true;
            this.documentManualFacade.updateData((this.dataDetail.value.id || this.dataDetail.value._id), formData).then((res: any) => {
                if (res) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    });
                    this.dataDetail.value = res.data;
                    this.dataDetail.value['pageName'] = this.dataDetail.value.page.name;
                    this.getDocumentManual(this.dataDetail.value);
                    this.editTableRow((this.dataDetail.index || 0), this.dataDetail.value, 'edit');
                    this.isSubmitted = false;
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.getDocumentManual(this.dataDetail.value);
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err?.error?.message,
                        cancel: false
                    });
                }
            });
        } else if (this.dataDetail.mode === 'create') {
            if (this.formGroup.invalid) {
                return;
            }

            if (!this.formGroup.get('file')?.value) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'กรุณาเลือกไฟล์คู่มือ',
                    cancel: false
                });

                return;
            }

            this.isSubmitted = true;
            this.documentManualFacade.createData(formData).then((res: any) => {
                if (res) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างสำเร็จ',
                        cancel: false
                    });
                    this.dataDetail.value = res.data;
                    this.dataDetail.value['pageName'] = this.dataDetail.value.page.name;
                    this.createTableRow(this.dataDetail.value, 'create');
                    this.isSubmitted = false;
                    this.formGroup.reset();
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err?.error?.message,
                        cancel: false
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickActionFile() {
        let val = ['.pdf'];
        const dialogRef = this.dialog.open(DialogUpload, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                header: 'อัพโหลดไฟล์คู่มือ',
                accept: val
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.file = result.data;
                this.formGroup.get('file')?.setValue(this.file.name);
            }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataDetail.value.title + '"'
            }).then((res: any) => {
                if (res) {
                    this.documentManualFacade.deleteData(this.dataDetail.value.id || this.dataDetail.value._id).then((res: any) => {
                        if (res) {
                            this.deleteTableRow(this.dataDetail.index, 'delete');
                            this.sidenav.close();
                            this.scrollTop();
                            this.setSubComponent();
                            this.formDocumentManual();
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: 'ลบสำเร็จ',
                                cancel: false
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            this.authenManager.clickDialogAlert({
                                header: '',
                                content: err.error.message,
                                cancel: false
                            }).then((res: any) => {
                                if (res) {
                                    this.isSubmitted = false;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    private getSelectPage() {
        this.pageFacade.searchData({ count: false, orderBy: { "createdDate": -1 } }).then((res: any) => {
            if (res) {
                this.dataPage = res.data;
                this.filteredListPage = this.dataPage.slice();
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.formGroup.controls;
    }
}

