import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { CustomTable } from "../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../models/TableModel";
import { UserAdminFacade } from "../../../../services/facade/UserAdminFacade.service";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { AutocompleteChips } from "../../../shares/AutocompleteChips.component";

const PAGE_NAME: string = 'manage-users';

@Component({
  selector: 'manage-users-page',
  templateUrl: './ManageUsersPage.component.html'
})

export class ManageUsersPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;

  private conditions = [
    { isAdmin: true, isSubAdmin: false, isMarketing: false },
    { isAdmin: true, isSubAdmin: true, isMarketing: false },
    { isAdmin: false, isSubAdmin: false, isMarketing: true },
    { isAdmin: true, isSubAdmin: true, isMarketing: true },
    { isAdmin: false, isSubAdmin: false, isMarketing: false, isForeman: true },
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private userAdminFacade: UserAdminFacade,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    const or = { '$or': this.conditions };
    this._getSearch(null, null, true, { 'createdDate': -1 }, or);
    this._getSearch(0, this.limitPage, false, { 'createdDate': -1 }, or);
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'email',
        tag: 'email',
        type: 'input'
      },
      {
        name: 'รหัสบัตรประชาชน',
        tag: 'citizenId',
        type: 'input'
      },
      {
        name: 'เบอร์มือถือ',
        tag: 'mobileNo',
        type: 'input'
      },
      {
        name: 'ชื่อที่แสดง',
        tag: 'displayName',
        type: 'input'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const whereConditions = this.getFilterWhereCondition();
    this._getSearch(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
      whereConditions['$or'] = this.conditions;
    }

    return whereConditions;
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const whereConditions: any = this.getFilterWhereCondition();
    whereConditions['$or'] = this.conditions;

    // search table
    this._getSearch(null, null, true, { 'createdDate': -1 }, whereConditions);
    this._getSearch(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event
      }
    });
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  private _getSearch(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any) {
    let val = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      whereConditions
    };

    this.userAdminFacade.searchData(val).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'email', header: 'อีเมล', tag: 'text', display: true, cell: (element: any) => `${element.email ? element.email : '-'}` },
            { columnDef: 'citizenId', header: 'รหัสบัตรประชาชน', tag: 'text', display: true, cell: (element: any) => `${element.citizenId ? element.citizenId : '-'}` },
            { columnDef: 'mobileNo', header: 'เบอร์มือถือ', tag: 'text', display: true, cell: (element: any) => `${element.mobileNo ? element.mobileNo.replace('-', '') : '-'}` },
            { columnDef: 'displayName', header: 'ชื่อที่แสดง', tag: 'text', display: true, cell: (element: any) => `${element.displayName ? element.displayName : '-'}` },
            { columnDef: 'isAdmin', header: 'เป็น Root', align: 'center', tag: 'text', display: true, color: 'var(--red-cdg)', cell: (element: any) => `${element.isAdmin ? '●' : '-'}` },
            { columnDef: 'isSubAdmin', header: 'เป็น Sales', align: 'center', tag: 'text', display: true, color: 'var(--red-cdg)', cell: (element: any) => `${element.isSubAdmin ? '●' : '-'}` },
            { columnDef: 'isMarketing', header: 'เป็น Marketing', align: 'center', tag: 'text', display: true, color: 'var(--red-cdg)', cell: (element: any) => `${element.isMarketing ? '●' : '-'}` },
            { columnDef: 'isForeman', header: 'เป็น Foreman', align: 'center', tag: 'text', display: true, color: 'var(--red-cdg)', cell: (element: any) => `${element.isForeman ? '●' : '-'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }

}
