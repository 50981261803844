import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'dialog-upload',
    templateUrl: './DialogUpload.component.html',
})
export class DialogUpload implements OnInit {
    public files: any;
    public images: any[] = [];
    public isCheckUploadFile: boolean;
    public isFileSupport: boolean;
    private type_file_not_support = ['video/mp4', 'video/mp3', 'video/ogg', 'video/webm', 'video/quicktime'];

    constructor(
        private authenManager: AuthenManager,
        public dialogRef: MatDialogRef<DialogUpload>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
    }

    public ngOnInit(): void {
        this.dialogRef.addPanelClass('overlay-dialog-upload');
    }

    public clickClose() {
        if (this.isCheckUploadFile === true && this.dialogRef.disableClose === true) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการออกจากหน้านี้ใช่ไหม'
            }).then((res: any) => {
                if (res) {
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

    public clickCancel() {
        if (this.isCheckUploadFile === true && this.isFileSupport === true) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการคืนค่าเดิม ใช่ หรือ ไม่'
            }).then((res: any) => {
                if (res) {
                    this.isCheckUploadFile = false;
                    this.isFileSupport = false;
                    this.dialogRef.disableClose = false;
                    this.files = '';
                    this.images = [];
                }
            });
        } else if (this.isCheckUploadFile === true && this.isFileSupport === false) {
            this.isCheckUploadFile = false;
            this.isFileSupport = false;
            this.dialogRef.disableClose = false;
        } else {
            this.dialogRef.close(false);
        }
    }

    public clickSubmit(): void {
        if (this.isCheckUploadFile === true && this.isFileSupport === true) {
            if (this.data.type === 'image') {
                this.dialogRef.close(this.images);
            } else {
                this.dialogRef.close(this.files);
            }
        }
    }

    public onFileChange(type: string, file: any) {
        let fileMap: any;
        if (type === 'dragdrop') {
            fileMap = Object.keys(file).map((key: any) => file[key]);
        } else if (type === 'input') {
            fileMap = Object.keys(file.target.files).map((key: any) => file.target.files[key]);
        }

        if (this.data.type === 'image') {
            if (!this.data.multiple && this.checkCountFile(fileMap) === true) {
                return;
            }

            for (const file of fileMap) {
                if ((this.data.accept.indexOf('.' + file.name.split('.')[1]) >= 0) && (this.type_file_not_support.indexOf('.' + file.name.split('.')[1]) < 0)) {
                    this.isCheckUploadFile = true;
                    if (this.isCheckUploadFile === true) {
                        this.isFileSupport = true;
                        this.dialogRef.disableClose = true;
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            let val = {
                                // base64: reader.result?.toString().split(','),
                                base64: reader.result?.toString(),
                                data: file
                            }
                            this.getBaseReader(val);
                        };
                        reader.onerror = function (error) {
                            console.log(error);
                        };
                    }
                } else {
                    this.dialogRef.disableClose = true;
                    this.isCheckUploadFile = false;
                    this.isFileSupport = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'รองรับนามสกุลรูปภาพ ' + this.data.accept,
                        cancel: false
                    });
                }
            }
        } else {
            if ((this.data.accept.indexOf('.' + fileMap[0].name.split('.')[1]) >= 0) && (this.type_file_not_support.indexOf('.' + fileMap[0].name.split('.')[1]) < 0)) {
                if (this.checkCountFile(fileMap) === true) {
                    return;
                }

                this.isCheckUploadFile = true;
                if (this.isCheckUploadFile === true) {
                    this.isFileSupport = true;
                    this.dialogRef.disableClose = true;
                    let reader = new FileReader();
                    reader.readAsDataURL(fileMap[0]);
                    reader.onload = () => {
                        let val = {
                            // base64: reader.result?.toString().split(','),
                            base64: reader.result?.toString(),
                            data: fileMap[0]
                        }
                        this.getBaseReader(val);
                    };
                    reader.onerror = function (error) {
                        console.log(error);
                    };
                }
            } else {
                this.dialogRef.disableClose = true;
                this.isCheckUploadFile = false;
                this.isFileSupport = false;
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'รองรับนามสกุลรูปภาพ ' + this.data.accept,
                    cancel: false
                });
            }
        }
    }

    public getBaseReader(data: any) {
        if (this.data.type === 'image') {
            this.images.push(data);
        } else {
            this.files = data;
        }
    }

    public checkCountFile(data: any): boolean {
        if (data.length > 1) {
            this.isCheckUploadFile = false;
            if (this.isCheckUploadFile === false) {
                this.isFileSupport = false;
                this.getBaseReader(data);
            }
            this.dialogRef.disableClose = true;
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'อัพโหลดได้ทีละ 1ไฟล์',
                cancel: false
            });

            return true;
        } else {
            return false;
        }
    }

    public clickOpenInputImage() {
        document.getElementById("file")?.click();
    }
}