export const environment = {
   production: false,
   socketIoURL: "https://pgm.absolute.co.th:4900/user",
   apiBaseURL: "https://pgm.absolute.co.th:9101/api",
   firebase: {
    apiKey: "AIzaSyBSh7tIrz7wIsMZdQ97h-fd4CDVs73AxxI",
    authDomain: "cdg-homecare-test.firebaseapp.com",
    projectId: "cdg-homecare-test",
    storageBucket: "cdg-homecare-test.appspot.com",
    messagingSenderId: "487349358412",
    appId: "1:487349358412:web:c358209030ba137ea289e9",
    measurementId: "G-69F64RY6CJ",
    vapidKey: "BAX-_Ou9xtOBK8-2oMBpWkO4DmXIget77kyAoYHfylYVAqFZW2PPTf_qWxNRDd_Kp8hxzLHtTMVmvoFU_Z6ZU3c"
   },
   timerReFaceChat: 1
};
