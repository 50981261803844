import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { RepairFacade } from "../../../services/facade/RepairFacade.service";
import * as moment from 'moment';
import { AutocompleteChipsModel } from "../../../models/AutocompleteChipsModel";
import { AutocompleteChips } from "../../shares/AutocompleteChips.component";
import { TableColumnModel } from "../../../models/TableModel";
import { Subject } from "rxjs";
import { RepairRequest } from "../../../request/RepairRequest";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { RepairTypeFacade } from "../../../services/facade/RepairTypeFacade.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { DownloadFileService } from "../../../services/DownloadFile.service";
import { CustomTable } from "../../shares/table/CustomTable.component";
import { AuthenManager } from "../../../services/AuthenManager.service";

const PAGE_NAME: string = 'homecare';

@Component({
  selector: 'repair-base-page',
  templateUrl: './RepairPage.component.html'
})

export class RepairPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;
  public campaign: UntypedFormGroup;

  public paramsId: string;

  private repairRequestval = new RepairRequest;

  public exportDate!: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private authenManager: AuthenManager,
    private formBuilder: UntypedFormBuilder,
    private observManager: ObservableManager,
    private repairFacade: RepairFacade,
    private repairTypeFacade: RepairTypeFacade,
    private checkTokenService: CheckTokenService,
    private downloadFileService: DownloadFileService
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {
        this.activatedRoute.children.forEach(child => {
          child.params.subscribe(params => {
            if (params) {
              this.paramsId = params['id'];
              this._getRepairId(this.paramsId);
            }
          })
        });
      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._campaign();
    this.repairRequestval.startDateTime = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
    this.repairRequestval.endDateTime = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
    this._getRepair(null, null, true);
    this._getRepair(0, this.limitPage, false, { 'createdDate': -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          if (res.data.value.realesateUnit.absUnitId) res.data.value['realesateUnitAbsUnitId'] = res.data.value.realesateUnit.absUnitId;
          if (res.data.value.realesateUnit.no) res.data.value['realesateUnitNo'] = res.data.value.realesateUnit.no;
          if (res.data.value.repairStatus.name) res.data.value['repairStatusName'] = res.data.value.repairStatus.name;
          if (res.data.value.repairType.name) res.data.value['repairTypeName'] = res.data.value.repairType.name;
          if (res.data.value.user.displayName) res.data.value['userDisplayName'] = res.data.value.user.displayName;
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          if (res.data.value.realesateUnit.absUnitId) res.data.value['realesateUnitAbsUnitId'] = res.data.value.realesateUnit.absUnitId;
          if (res.data.value.realesateUnit.no) res.data.value['realesateUnitNo'] = res.data.value.realesateUnit.no;
          if (res.data.value.repairStatus.name) res.data.value['repairStatusName'] = res.data.value.repairStatus.name;
          if (res.data.value.repairType.name) res.data.value['repairTypeName'] = res.data.value.repairType.name;
          if (res.data.value.user.displayName) res.data.value['userDisplayName'] = res.data.value.user.displayName;
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'โครงการบ้าน',
        tag: 'prefix',
        type: 'project_autocomp'
      },
      {
        name: 'รหัสติดตามการขาย',
        tag: 'absLeadId',
        type: 'input'
      },
      {
        name: 'รหัสยูนิต',
        tag: 'absUnitId',
        type: 'input'
      },
      {
        name: 'รหัสงานซ่อม',
        tag: 'name',
        type: 'input'
      },
      {
        name: 'สถานะ',
        tag: 'repairStatus',
        type: 'multi_select'
      },
      {
        name: 'ประเภทการซ่อม',
        tag: 'repairType',
        type: 'input'
      },
      {
        name: 'นอกประกัน',
        tag: 'isOverGuarantee',
        type: 'checkbox'
      },
      {
        name: 'บ้านเลขที่',
        tag: 'no',
        type: 'input'
      },
      {
        name: 'ผู้แจ้งเรือง',
        tag: 'displayName',
        type: 'input'
      },
      {
        name: 'รหัสประจำตัวประชาชน',
        tag: 'citizenId',
        type: 'input'
      },
      {
        name: 'หมายเลขโทรศัพท์',
        tag: 'mobileNo',
        type: 'input'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    this.data.map((res, index) => {
      if (!!res!.realesateUnit!.absUnitId) this.data[index]['realesateUnitAbsUnitId'] = res!.realesateUnit!.absUnitId;
      if (!!res!.realesateUnit!.no) this.data[index]['realesateUnitNo'] = res!.realesateUnit!.no;
      if (!!res!.repairStatus!.name) this.data[index]['repairStatusName'] = res!.repairStatus!.name;
      if (!!res!.repairType!.name) this.data[index]['repairTypeName'] = res!.repairType!.name;
      if (!!res!.user!.displayName) this.data[index]['userDisplayName'] = res!.user!.displayName;
      this.data[index]['questionnaireAvg'] = (!!res!.questionnaireAvg ? res!.questionnaireAvg.toFixed(2) : null);
    });
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;
    this._getRepair(count, this.limitPage, false, { "createdDate": -1 }, whereConditions, optionalFields);
  }

  public async getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      if (key !== 'name') {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    const optionalFields: any = {};
    if (filterResult['prefix'] !== undefined && filterResult['prefix'] !== '') {
      optionalFields.pageIds = [];
      optionalFields.pageIds.push(filterResult['prefix']);
    }
    if (filterResult['repairType'] !== undefined && filterResult['repairType'] !== '') {
      optionalFields.repairType = [];
      let repairType: any[] = await this._getSearchRepairType(filterResult['repairType']);
      optionalFields.repairType = repairType.map((res, index) => {
        if (!!res!.id) return res!.id;
      });
    }
    if (filterResult['repairStatus'] !== undefined && filterResult['repairStatus'] !== '') {
      optionalFields.repairStatus = filterResult['repairStatus'];
    }
    if (filterResult['absUnitId'] !== undefined && filterResult['absUnitId'] !== '') {
      optionalFields.absUnitId = { $regex: '.*' + filterResult['absUnitId'] + '.*', $options: 'si' };
    }
    if (filterResult['absLeadId'] !== undefined && filterResult['absLeadId'] !== '') {
      optionalFields.absLeadId = { $regex: '.*' + filterResult['absLeadId'] + '.*', $options: 'si' };
    }
    if (filterResult['no'] !== undefined && filterResult['no'] !== '') {
      optionalFields.no = filterResult['no'];
    }
    if (filterResult['citizenId'] !== undefined && filterResult['citizenId'] !== '') {
      optionalFields.citizenId = { $regex: '.*' + filterResult['citizenId'] + '.*', $options: 'si' };
    }
    if (filterResult['displayName'] !== undefined && filterResult['displayName'] !== '') {
      optionalFields.displayName = { $regex: '.*' + filterResult['displayName'] + '.*', $options: 'si' };
    }
    if (filterResult['mobileNo'] !== undefined && filterResult['mobileNo'] !== '') {
      optionalFields.mobileNo = { $regex: '.*' + filterResult['mobileNo'] + '.*', $options: 'si' };
    }

    return {
      whereConditions,
      optionalFields
    };
  }

  private _getRepair(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any, optionalFields?: any) {
    this.repairRequestval.count = count;
    this.repairRequestval.limit = limit;
    this.repairRequestval.offset = offset;
    this.repairRequestval.orderBy = orderBy;
    this.repairRequestval.whereConditions = whereConditions;
    optionalFields?.pageIds ? this.repairRequestval.pageIds = optionalFields?.pageIds : delete this.repairRequestval.pageIds;
    optionalFields?.repairType ? this.repairRequestval.repairType = optionalFields?.repairType : delete this.repairRequestval.repairType;
    optionalFields?.repairStatus ? this.repairRequestval.repairStatus = optionalFields?.repairStatus : delete this.repairRequestval.repairStatus;
    optionalFields?.absUnitId ? this.repairRequestval.absUnitId = optionalFields?.absUnitId : delete this.repairRequestval.absUnitId;
    optionalFields?.no ? this.repairRequestval.no = optionalFields?.no : delete this.repairRequestval.no;
    optionalFields?.absLeadId ? this.repairRequestval.absLeadId = optionalFields?.absLeadId : delete this.repairRequestval.absLeadId;
    optionalFields?.citizenId ? this.repairRequestval.citizenId = optionalFields?.citizenId : delete this.repairRequestval.citizenId;
    optionalFields?.mobileNo ? this.repairRequestval.mobileNo = optionalFields?.mobileNo : delete this.repairRequestval.mobileNo;
    optionalFields?.pageId ? this.repairRequestval.pageId = optionalFields?.pageId : delete this.repairRequestval.pageId;
    optionalFields?.displayName ? this.repairRequestval.displayName = optionalFields?.displayName : delete this.repairRequestval.displayName;
    this.repairFacade.searchData(this.repairRequestval).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'realesateUnitAbsUnitId', header: 'รหัสยูนิต', tag: 'text', display: true, cell: (element: any) => `${element.realesateUnitAbsUnitId ? element.realesateUnitAbsUnitId : '-'}` },
            { columnDef: 'realesateUnitNo', header: 'บ้านเลขที่', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.realesateUnitNo ? element.realesateUnitNo : '-'}` },
            { columnDef: 'name', header: 'รหัสงานซ่อม', tag: 'text', display: true, cell: (element: any) => `${element.name ? element.name : '-'}` },
            { columnDef: 'repairStatusName', header: 'สถานะ', tag: 'text', display: true, cell: (element: any) => `${element.status === 'unapproved' ? 'ปฏิเสธ' : (element.repairStatusName ? element.repairStatusName : '-')}` },
            { columnDef: 'repairTypeName', header: 'ประเภทงานซ่อม', tag: 'text', display: true, cell: (element: any) => `${element.repairTypeName ? element.repairTypeName : '-'}` },
            { columnDef: 'approvedDateTime', header: 'วันที่ได้รับอนุมัติ', tag: 'text', display: true, cell: (element: any) => `${element.approvedDateTime ? moment(element.approvedDateTime).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
            { columnDef: 'createdDate', header: 'วันที่แจ้งเรือง', tag: 'text', display: true, cell: (element: any) => `${element.createdDate ? moment(element.createdDate).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
            { columnDef: 'userDisplayName', header: 'ผู้แจ้งเรือง', tag: 'text', display: true, cell: (element: any) => `${(element.createdBy === element.user.id) ? (!!element.userDisplayName ? element.userDisplayName : '-') : 'Admin'}` },
            { columnDef: 'status', header: 'การอนุมัติ', tag: 'text', display: true, cell: (element: any) => `${element.status === 'approved' ? 'ผ่านการอนุมัติ' : element.status === 'unapproved' ? 'ไม่ผ่านการอนุมัติ' : 'รอดำเนินการ'}` },
            { columnDef: 'isOverGuarantee', header: 'นอกประกัน', tag: 'text', display: true, cell: (element: any) => `${element.isOverGuarantee ? 'ใช่' : 'ไม่ใช่'}` },
            { columnDef: 'questionnaireAvg', header: 'คะแนน', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.questionnaireAvg ? element.questionnaireAvg : '-'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  private _getRepairId(id: string) {
    this.repairFacade.searchData({ repairIds: [id] }).then((res: any) => {
      if (res) {
        if (Array.isArray(res.data) && res.data.length === 0) {
          this.router.navigate(['homecare']);
          this.authenManager.clickDialogAlert({
            header: '',
            content: 'ไม่พบรหัสงานแจ้งซ่อม "' + id + '"',
            cancel: false
          });
        } else {
          this.clickTableRow({
            index: null,
            event: res.data[0],
            tag: '',
            id: id
          });
        }
      }
    }).catch((err) => {
      if (err) {
        this.router.navigate(['homecare']);
        this.authenManager.clickDialogAlert({
          header: '',
          content: 'ไม่พบรหัสงานแจ้งซ่อม "' + id + '"',
          cancel: false
        });
      }
    });
  }

  public clickDowload(date: string) {
    if (!date) {
      this.authenManager.clickDialogAlert({
        header: '',
        content: 'ยังไม่ได้เลือกวันที่ออกเอกสาร',
        cancel: false
      });

      return;
    }

    const fullDate = new Date(date);
    const sd = moment(fullDate).format('YYYY-MM-DD');
    const dt: any = sd + 'T00:00:00.000Z';
    this.repairFacade.exportData({ repairDate: dt }).then((res: any) => {
      if (res) {
        // Create a Blob object from the response data
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = sd + '-homecare.pdf';
        link.click();
        this.exportDate = '';
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
        this.authenManager.clickDialogAlert({
          header: '',
          content: 'วันที่ ' + sd + ' ยังไม่มีการอนุมัติงาน',
          cancel: false
        });
      }
    });
  }

  public clickChip(event: any) {

  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  private _campaign() {
    this.campaign = this.formBuilder.group({
      start: new UntypedFormControl(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))),
      end: new UntypedFormControl(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)))
    });
  }

  public async refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const conditionObj: any = await this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;

    // search table
    this._getRepair(null, null, true, { 'createdDate': -1 }, whereConditions, optionalFields);
    this._getRepair(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event,
        id: event.id
      }
    });
  }

  public async searchDate() {
    await this.checkTokenService.checkStatus();
    let startDateTime: any = (this.campaign.get('start')?.value ? moment(this.campaign.get('start')?.value).format('YYYY-MM-DD') : new Date('2019-01-16')) + 'T00:00:00.000Z';
    let endDateTime: any = (this.campaign.get('end')?.value ? moment(this.campaign.get('end')?.value).format('YYYY-MM-DD') : (moment((new Date().getFullYear()) + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate())).format('YYYY-MM-DD'))) + 'T23:59:59.000Z';
    this.repairRequestval.startDateTime = startDateTime;
    this.repairRequestval.endDateTime = endDateTime;
    this.refreshTable();
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true
    }
  }

  private async _getSearchRepairType(repairType: any): Promise<any> {
    let val = {
      count: false,
      whereConditions: {
        name: { $regex: '.*' + repairType + '.*', $options: 'si' }
      }
    }

    const repair = await this.repairTypeFacade.searchData(val);
    if (repair) return repair.data;
  }

  public clickActionTableDataDownload() {
    let rows = [];
    for (const row of this.columns) {
      rows.push(row.columnDef);
    }

    this.downloadFileService.download(PAGE_NAME, this.data, rows);
  }
}