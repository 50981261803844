<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper
            class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose();">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                    <div class="flex-1 flex flex-col">
                        <span class="text-lg font-bold flex items-center">
                            <img class="flex-none w-6 h-6 min-w-6 mr-2"
                                [src]="'../../../../assets/images/app/owner.svg'" alt="">
                            <span class="flex-1 line-clamp-1">
                                {{groupUserAdmin.get('displayName')?.value ? groupUserAdmin.get('displayName')?.value
                                :
                                'หัวเรื่อง'}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="flex-none flex items-center">
                    <div class="grid grid-cols-2 gap-2">
                        <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                            คืนค่า
                        </button>
                        <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">บันทึก</ng-container>
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'create'">สร้าง</ng-container>
                        </button>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        class="ml-3 flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
                        [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>ลบข้อมูล</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="flex-1 flex items-start">
                <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
                    <div class="w-full md:max-w-[500px] xl:max-w-[560px] grid grid-cols-1 gap-6">
                        <ng-container [formGroup]="groupUserAdmin">
                            <div class="card-detail">
                                <div class="flex items-center min-h-[80px] border-b p-4">
                                    <div class="flex-1 flex flex-col">
                                        <span class="text-lg font-bold">
                                            ยูสเซอร์
                                        </span>
                                        <span class="text-sm font-normal opacity-50 mt-1">
                                            ข้อมูลทั่วไปของยูสเซอร์
                                        </span>
                                    </div>
                                </div>
                                <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <div>
                                        <span class="text-base font-bold opacity-50">อีเมล</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="email"
                                            [class.border-error]="(isSubmitted || groupUserAdmin.get('email')?.touched) && f['email'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupUserAdmin.get('email')?.touched) && f['email'].errors">
                                            กรุณาใส่ อีเมล
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">รหัสผ่าน</span>
                                        <div class="relative flex items-center mt-1">
                                            <input class="input-custom mt-0" [type]="hidePassword ? 'text' : 'password'"
                                                placeholder="" autocomplete="new-password" formControlName="password"
                                                [class.border-error]="(isSubmitted || groupUserAdmin.get('password')?.touched) && f['password'].errors">
                                            <button mat-icon-button
                                                class="absolute right-2 z-[1] flex-none icon-ripper-center"
                                                (click)="hidePassword = !hidePassword"
                                                *ngIf="dataDetail && dataDetail.mode === 'create'">
                                                <mat-icon>{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                                            </button>
                                        </div>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupUserAdmin.get('password')?.touched) && f['password'].errors">
                                            กรุณาใส่ รหัสผ่าน อย่างน้อย 6 หลัก
                                        </mat-error>
                                    </div>
                                    <div *ngIf="dataDetail && dataDetail.mode === 'create'">
                                        <span class="text-base font-bold opacity-50">ยืนยันรหัสผ่าน</span>
                                        <div class="relative flex items-center mt-1">
                                            <input class="input-custom mt-0"
                                                [type]="hideConfirmPassword ? 'text' : 'password'" placeholder=""
                                                autocomplete="new-password" formControlName="confirmpassword"
                                                [class.border-error]="(isSubmitted || groupUserAdmin.get('confirmpassword')?.touched) && f['password'].errors || groupUserAdmin.get('confirmpassword')?.value !== groupUserAdmin.get('password')?.value">
                                            <button mat-icon-button
                                                class="absolute right-2 z-[1] flex-none icon-ripper-center"
                                                (click)="hideConfirmPassword = !hideConfirmPassword"
                                                *ngIf="dataDetail && dataDetail.mode === 'create'">
                                                <mat-icon>{{hideConfirmPassword ? 'visibility' : 'visibility_off'}}
                                                </mat-icon>
                                            </button>
                                        </div>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupUserAdmin.get('confirmpassword')?.touched) && f['password'].errors || groupUserAdmin?.get('confirmpassword')?.value !== groupUserAdmin?.get('password')?.value">
                                            กรุณาใส่ ยืนยันรหัสผ่าน ให้ตรงกับ รหัสผ่าน
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">รหัสบัตรประชาชน</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="citizenId"
                                            [class.border-error]="(isSubmitted || groupUserAdmin.get('citizenId')?.touched) && f['citizenId'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupUserAdmin.get('citizenId')?.touched) && f['citizenId'].errors">
                                            กรุณาใส่รหัสบัตรประชาชน
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">เบอร์มือถือ</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="mobileNo"
                                            [class.border-error]="(isSubmitted || groupUserAdmin.get('mobileNo')?.touched) && f['mobileNo'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupUserAdmin.get('mobileNo')?.touched) && f['mobileNo'].errors">
                                            กรุณาใส่เบอร์มือถือ
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">ชื่อแสดง</span>
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="displayName"
                                            [class.border-error]="(isSubmitted || groupUserAdmin.get('displayName')?.touched) && f['displayName'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupUserAdmin.get('displayName')?.touched) && f['displayName'].errors">
                                            กรุณาใส่ชื่อแสดง
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">สิทธิ์การใช้งาน</span>
                                        <br>
                                        <section class="mt-1">
                                            <mat-checkbox class="mr-4" formControlName="isAdmin"
                                                [disabled]="groupUserAdmin.get('isSubAdmin')?.value || groupUserAdmin.get('isMarketing')?.value || (groupUserAdmin.get('isSubAdmin')?.value || groupUserAdmin.get('isMarketing')?.value || groupUserAdmin.get('isForeman')?.value)"
                                                [class.border-error]="(isSubmitted || groupUserAdmin.get('isAdmin')?.touched) && f['isAdmin'].errors">
                                                <span class="text-base font-bold">
                                                    เป็น Root
                                                </span>
                                            </mat-checkbox>
                                            <mat-checkbox class="mr-4" formControlName="isSubAdmin"
                                                [disabled]="(groupUserAdmin.get('isAdmin')?.value || groupUserAdmin.get('isForeman')?.value) && !groupUserAdmin.get('isSubAdmin')?.value && !groupUserAdmin.get('isMarketing')?.value"
                                                [class.border-error]="(isSubmitted || groupUserAdmin.get('isSubAdmin')?.touched) && f['isSubAdmin'].errors">
                                                <span class="text-base font-bold">
                                                    เป็น Sales
                                                </span>
                                            </mat-checkbox>
                                            <mat-checkbox class="mr-4" formControlName="isMarketing"
                                                [disabled]="(groupUserAdmin.get('isAdmin')?.value || groupUserAdmin.get('isForeman')?.value) && !groupUserAdmin.get('isSubAdmin')?.value && !groupUserAdmin.get('isMarketing')?.value"
                                                [class.border-error]="(isSubmitted || groupUserAdmin.get('isMarketing')?.touched) && f['isMarketing'].errors">
                                                <span class="text-base font-bold">
                                                    เป็น Marketing
                                                </span>
                                            </mat-checkbox>
                                            <mat-checkbox class="mr-4" formControlName="isForeman"
                                                [disabled]="(groupUserAdmin.get('isAdmin')?.value || groupUserAdmin.get('isMarketing')?.value || groupUserAdmin.get('isSubAdmin')?.value) && !groupUserAdmin.get('isForeman')?.value"
                                                [class.border-error]="(isSubmitted || groupUserAdmin.get('isForeman')?.touched) && f['isForeman'].errors">
                                                <span class="text-base font-bold">
                                                    เป็น Foreman
                                                </span>
                                            </mat-checkbox>
                                        </section>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="isSubmitted && (!groupUserAdmin?.get('isAdmin')?.value && !groupUserAdmin?.get('isSubAdmin')?.value && !groupUserAdmin?.get('isMarketing')?.value && !groupUserAdmin.get('isForeman')?.value)">
                                            กรุณาเลือกสิทธิ์การใช้งานอย่างน้อย 1 สิทธิ์
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>