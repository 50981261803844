<h2>TEST</h2>
<mat-form-field class="relative flex items-center mt-1">
    <mat-date-range-input class="input-custom mt-0 bg-transparent" #picker [formGroup]="campaignOne"
        [rangePicker]="campaignOnePicker" [comparisonStart]="campaignTwo.value.start"
        [comparisonEnd]="campaignTwo.value.end">
        <input matStartDate formControlName="start">
        <input matEndDate formControlName="end">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
</mat-form-field>