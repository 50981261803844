export class MessageResUtils {
    public static messageRes(text: string): any {
        const absoluteId = 'Existed Absolute UnitId or Absolute LeadId';
        if (!!text) {
            if (text.indexOf(absoluteId) !== -1) {
                return 'absUnitId หรือ absLeadId มีในระบบแล้ว';
            } else {
                return text;
            }
        }
    }
}