<div class="relative dialog-custom dialog-custom-datepicker">
  <button mat-icon-button
    class="absolute -top-6 -right-6 z-[1] icon-ripper-center text-white bg-[color:var(--bg-backdrop)]"
    (click)="clickClose()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-calendar #calendar [(selected)]="data!.selectedDate"
    (selectedChange)="data!.type === 'date' ? onSelectedChange($event) : null"
    *ngIf="data!.type === 'all' || data!.type === 'all-bypass' || data!.type === 'date'"></mat-calendar>
  <div [formGroup]="groupTime" class="wrapper-time flex items-center justify-center gap-2"
    *ngIf="data!.type === 'all' || data!.type === 'all-bypass' || data!.type === 'time'">
    <div>
      <div matRipple [matRippleCentered]="true" class="flex items-center justify-center cursor-pointer hover:bg-black/5"
        (click)="clickTime(1, true)">
        <mat-icon class="text-[30] flex items-center justify-center p-1">arrow_drop_up</mat-icon>
      </div>
      <input
        class="w-[100px] text-center h-20 text-5xl rounded border-2 border-black/25 focus:border-black/25 focus:outline-none"
        type="number" placeholder="00" formControlName="hh">
      <div matRipple [matRippleCentered]="true" class="flex items-center justify-center cursor-pointer hover:bg-black/5"
        (click)="clickTime(1, false)">
        <mat-icon class="text-[30] flex items-center justify-center p-1">arrow_drop_down</mat-icon>
      </div>
    </div>
    <span class="text-3xl">:</span>
    <div>
      <div matRipple [matRippleCentered]="true" class="flex items-center justify-center cursor-pointer hover:bg-black/5"
        (click)="clickTime(2, true)">
        <mat-icon class="text-[30] flex items-center justify-center p-1">arrow_drop_up</mat-icon>
      </div>
      <input
        class="w-[100px] text-center h-20 text-5xl rounded border-2 border-black/25 focus:border-black/25 focus:outline-none"
        type="number" placeholder="00" formControlName="mm">
      <div matRipple [matRippleCentered]="true" class="flex items-center justify-center cursor-pointer hover:bg-black/5"
        (click)="clickTime(2, false)">
        <mat-icon class="text-[30] flex items-center justify-center p-1">arrow_drop_down</mat-icon>
      </div>
    </div>
  </div>
  <div class="px-6 py-4" *ngIf="data!.type === 'all' || data!.type === 'time' || data!.type === 'all-bypass'">
    <button mat-button *ngIf="data!.type === 'all-bypass'" class="bg-gray-200 button-cdg w-full mb-2"
      (click)="clickReset()">
      ล้างค่า
    </button>
    <button mat-button class="bg-bule-light button-cdg w-full"
      [class.disable]="groupTime.invalid && data!.type !== 'all-bypass'"
      (click)="data!.type === 'all-bypass' ? clickSubmit() : (groupTime.invalid ? null : clickSubmit())">
      ยืนยัน
    </button>
  </div>
</div>