import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { CustomTable } from "../../../../shares/table/CustomTable.component";
import { AutocompleteChipsModel } from "../../../../../models/AutocompleteChipsModel";
import { TableColumnModel } from "../../../../../models/TableModel";
import { RepairStatusFacade } from "../../../../../services/facade/RepairStatusFacade.service";
import { ObservableManager } from "../../../../../services/ObservableManager.service";
import { AutocompleteChips } from "../../../../shares/AutocompleteChips.component";

const PAGE_NAME: string = 'repair-status';

@Component({
  selector: 'repair-status-page',
  templateUrl: './RepairStatusPage.component.html'
})

export class RepairStatusPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;

  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private observManager: ObservableManager,
    private repairStatusFacade: RepairStatusFacade,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._getRepairStatusInfo(null, null, true);
    this._getRepairStatusInfo(0, this.limitPage, false, { "createdDate": -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          if (res.data.value.page.prefix) res.data.value['pagePrefix'] = res.data.value.page.prefix;
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          this.tableRow && this.tableRow.createRow(res.data.value);
          if (res.data.value.page.prefix) res.data.value['pagePrefix'] = res.data.value.page.prefix;
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'ชื่อสถานะ',
        tag: 'name',
        type: 'input'
      },
      {
        name: 'รหัสโครงการ',
        tag: 'prefix',
        type: 'project_autocomp'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    this.data.map((res, index) => {
      if (!!res!.page!.prefix) this.data[index]['pagePrefix'] = res!.page!.prefix;
    });
    // if (this.data !== undefined && this.data.length < this.limitPage) {
    //   this.lengthDataTable = this.data ? this.data.length : 0;
    // }
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;
    this._getRepairStatusInfo(count, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      if (key === 'prefix') {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    const optionalFields: any = {};
    if (filterResult['prefix'] !== undefined && filterResult['prefix'] !== '') {
      optionalFields.pageId = filterResult['prefix'];
    }

    return {
      whereConditions,
      optionalFields
    };
  }

  public refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;

    // search table
    this._getRepairStatusInfo(null, null, true, { 'createdDate': -1 }, whereConditions, optionalFields);
    this._getRepairStatusInfo(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public clickChip(event: any) {
  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event
      }
    });
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  private _getRepairStatusInfo(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any, optionalFields?: any) {
    let val: any = {
      offset: offset,
      limit: limit,
      count: count,
      orderBy: orderBy,
      whereConditions
    };

    if (optionalFields !== undefined) {
      for (const key in optionalFields) {
        val[key] = optionalFields[key];
      }
    }

    this.repairStatusFacade.searchData(val).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'name', header: 'ชื่อสถานะ', tag: 'text', display: true, cell: (element: any) => `${element.name ? element.name : '-'}` },
            { columnDef: 'pagePrefix', header: 'รหัสโครงการ', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.pagePrefix ? element.pagePrefix : '-'}` },
            {
              columnDef: 'colorCode', header: 'สี', tag: 'text-color', display: true, cell: (element: any) => {
                return {
                  text: `${element.colorCode ? element.colorCode : '-'}`,
                  color: element.colorCode
                }
              }
            },
            { columnDef: 'ordering', header: 'ลำดับ', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.ordering ? element.ordering : '0'}` },
            { columnDef: 'isComplete', header: 'เป็นสถานะสิ้นสุดงาน', align: 'center', tag: 'text', display: true, cell: (element: any) => `${element.isComplete ? 'ใช่' : 'ไม่ใช่'}` }
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }
}
