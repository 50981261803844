
export class SearchFilter {
    public limit?: number | null;
    public offset?: number | null;
    public select?: any[];
    public relation?: any;
    public whereConditions?: any;
    public isOfficial?: boolean;
    public orderBy?: any;
    public count?: boolean;
}
