import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DownloadFileService {
    constructor() { }

    public download(name: string, data: any[], arr: any[]) {
        const date = new Date().toISOString();
        let fileName = name + '-' + moment(date).format('YYYY-MM-DD') + '-download.csv';
        let columnNames = arr;
        let header = columnNames.join(',');

        let csv = header;
        csv += '\r\n';

        data.map(c => {
            let x = [];
            for (const val of arr) {
                x.push([c[val]]);
            }
            csv += x.join(',');
            csv += '\r\n';
        })

        let blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8;' });

        let link = document.createElement('a');
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}