import { MethodResponseInf } from './../interface/interface';
import { isoRegex } from './../constants/Regex';
import { IMAGE_ASSET_TYPE, TYPE } from '../constants/General';
import { DataUtil } from './DataUtil';

export class ValidateUtil {
    public static validateEmail(email: string): boolean {
        const regex: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    public static validateAndGetBase64Data(str: string): MethodResponseInf<object | undefined> {
        if (typeof str !== TYPE.string) {
            return DataUtil.convertDataToAPI(400, 'str is not string', undefined);
        }
        if (!str.startsWith('data:')) {
            return DataUtil.convertDataToAPI(400, 'Invalid base64 pattern,Must start with data:', undefined);
        }

        if (str.search(';base64,') === -1) {
            return DataUtil.convertDataToAPI(400, 'Invalid base64 pattern,Missing ;base64,', undefined);
        }

        const base64Position: number = str.search(';base64,');
        const mimeType = str.slice(5, base64Position);
        if (!IMAGE_ASSET_TYPE.includes(mimeType)) {
            return DataUtil.convertDataToAPI(400, 'Mime type must be: ' + IMAGE_ASSET_TYPE.toString(), undefined);
        }
        const base64Code: string = str.slice(base64Position + 8);
        return DataUtil.convertDataToAPI(200, 'Sucessfully', { code: base64Code, mimeType });
    }
    public static isIsoDate(str: string): boolean {
        let count = 0;
        for (const regex of isoRegex) {
            if (regex.test(str)) {
                count++;
            }
        }
        if (count === 0) {
            return false;
        }
        return true;
    }
}
