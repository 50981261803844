<div class="min-w-[360px] max-w-[360px] max-h-screen overflow-y-auto">
    <div *ngFor="let notification of notifications"
        class="m-3 rounded-lg overflow-hidden bg-white shadow-[0_0_12px_rgba(0,0,0,0.3)]">
        <ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
    </div>
</div>

<ng-template #notificationTpl let-notification="notification">
    <card-notification [image]="notification.image" [content]="notification.message"
        [time]="setFormatDate(notification.itme) | dateFormat" [read]="true" [pulse]="true"
        (event)="close(notification); clickRouter(notification.eventId)">
    </card-notification>
</ng-template>