import { BaseModel } from "./models";

export class UserModel extends BaseModel {
    public username?: string;
    public password?: string;
    public uniqueId?: string;
    public email?: string;
    public displayName?: string;
    public citizenId?: string;
    public mobileNo?: string;
    public firstName?: string;
    public lastName?: string;
    public birthdate?: Date;
    public gender?: null;
    public customGender?: null;
    public isAdmin?: boolean;
    public isSubAdmin?: boolean;
    public isMarketing?: boolean;
    public isForeman?: boolean;
    public banned?: boolean;
    public imageURL?: string;
}