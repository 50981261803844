<div class="report-page h-screen flex flex-col">
    <div class="bg-white h-[75px] px-6 flex items-center justify-between border-b flex-none">
        <div class="text-xl flex-1">
            {{menu && menu.text}}
        </div>
        <div class="flex gap-2">
            <button mat-stroked-button *ngIf="!!dataTable && dataTable.length > 0"
                class="relative flex-none button-cdg bg-bule-light mr-4" (click)="clickActionTableDataDownload()">
                <span class="material-symbols-outlined">
                    download
                </span>
                ออกเอกสาร
            </button>
            <button mat-stroked-button class="bg-[color:var(--bg-FA)] button-cdg" (click)="clickReset()">
                คืนค่า
            </button>
            <button mat-button class="bg-red-cdg button-cdg" (click)="createTable()">
                สร้างรายงาน
            </button>
        </div>
    </div>
    <div class="flex-1 overflow-auto p-6" [@hide]="state" [formGroup]="groupCustomer">
        <div class="p-6 w-full grid grid-cols-3 gap-3 card-detail"
            [ngClass]="!!dataTable && dataTable.length > 0 ? 'grid-cols-3' : 'grid-cols-1 mx-auto max-w-2xl'">
            <div class="flex flex-col">
                <span class="text-sm opacity-50">โครงการ</span>
                <mat-select #select class="select-custom h-9" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" multiple formControlName="page">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataPage"
                        [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                        (filteredReturn)="filteredListPage = $event">
                    </mat-select-filter>
                    <mat-option class="checkbox-hide" (click)="toggleSelectAll('page')" #selectAllOption>
                        {{ isAllSelectedPage ? 'ยกเลิกเลือกทั้งหมด' : 'เลือกทั้งหมด' }}
                    </mat-option>
                    <ng-container *ngFor="let item of filteredListPage">
                        <mat-option class="font-medium" [value]="item.id">
                            ({{item.prefix}}) {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">สถานะ</span>
                <mat-select #select class="select-custom h-9" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" multiple formControlName="status">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataStatus"
                        [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                        (filteredReturn)="filteredListStatus = $event">
                    </mat-select-filter>
                    <mat-option class="checkbox-hide" (click)="toggleSelectAll('status')" #selectAllOption>
                        {{ isAllSelectedStatus ? 'ยกเลิกเลือกทั้งหมด' : 'เลือกทั้งหมด' }}
                    </mat-option>
                    <ng-container *ngFor="let item of filteredListStatus">
                        <mat-option class="font-medium" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>

            <div class="flex flex-col">
                <span class="text-sm opacity-50">ประเภทการร้องเรียน</span>
                <mat-select #select class="select-custom h-9" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" multiple formControlName="category">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [placeholder]="'ค้นหา...'"
                        [noResultsMessage]="'-'">
                    </mat-select-filter>
                    <ng-container *ngFor="let item of dataCustomerServiceCategory">
                        <mat-option class="font-medium" [value]="item.id" (click)="clickSelect()">
                            {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
            <div *ngIf="!!groupCustomer.get('category').value" class="flex flex-col">
                <span class="text-sm opacity-50">ประเภท</span>
                <mat-select #select class="select-custom h-9" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" multiple formControlName="item">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataItem"
                        [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                        (filteredReturn)="filteredListItem = $event">
                    </mat-select-filter>
                    <mat-option class="checkbox-hide" (click)="toggleSelectAll('item')" #selectAllOption>
                        {{ isAllSelectedItem ? 'ยกเลิกเลือกทั้งหมด' : 'เลือกทั้งหมด' }}
                    </mat-option>
                    <ng-container *ngFor="let item of filteredListItem">
                        <mat-option class="font-medium" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">บ้านเลขที่</span>
                <input class="input-custom h-9" type="text" placeholder="12/23,xx/xx" formControlName="no"
                    autocomplete="off">
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ชื่อเจ้าของบ้าน</span>
                <input class="input-custom h-9" type="text" placeholder="xxxxx" formControlName="userFullName"
                    autocomplete="off">
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ชื่อช่าง</span>
                <input class="input-custom h-9" type="text" placeholder="xxxxx" formControlName="foremanFullName"
                    autocomplete="off">
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ชื่อ admin</span>
                <input class="input-custom h-9" type="text" placeholder="xxxxx" formControlName="adminFullName"
                    autocomplete="off">
            </div>
            <div class="flex flex-col" (click)="picker1.open()">
                <span class="text-sm opacity-50">วันที่ลูกบ้านแจ้ง</span>
                <div class="flex items-center input-custom h-9 cursor-pointer">
                    <mat-date-range-input [rangePicker]="picker1">
                        <input class="pointer-events-none" matStartDate disable formControlName="startDateTime">
                        <input class="pointer-events-none" matEndDate disable formControlName="endDateTime">
                    </mat-date-range-input>
                    <mat-datepicker-toggle class="flex items-center justify-center custom-datepicker-size" matSuffix
                        [for]="picker1"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker1></mat-date-range-picker>
                </div>
            </div>

            <div class="flex flex-col" (click)="picker2.open()">
                <span class="text-sm opacity-50">วันที่ approve / reject</span>
                <div class="flex items-center input-custom h-9 cursor-pointer">
                    <mat-date-range-input [rangePicker]="picker2">
                        <input class="pointer-events-none" matStartDate disable formControlName="startApproveDateTime">
                        <input class="pointer-events-none" matEndDate disable formControlName="endApproveDateTime">
                    </mat-date-range-input>
                    <mat-datepicker-toggle class="flex items-center justify-center custom-datepicker-size" matSuffix
                        [for]="picker2"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker2></mat-date-range-picker>
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-end mt-2" *ngIf="!!dataTable && dataTable.length > 0">
        <div class="rounded-tl-3xl bg-white p-[0px_20px_0px_25px] cursor-pointer" (click)="changeState()">
            <span class="material-symbols-outlined flex" [@arrow]="state">
                stat_2
            </span>
        </div>
    </div>
    <div *ngIf="!!dataTable && dataTable.length > 0" class="overflow-auto" [@heightGrow]="state">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full">

            <!-- No Column -->
            <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> No. </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.no">{{element.header.no}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Page Name Column -->
            <ng-container matColumnDef="pageName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> โครงการ </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.pageName">{{element.header.pageName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- User Owner Name Column -->
            <ng-container matColumnDef="userOwnerName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อเจ้าของบ้าน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.userOwnerName">{{element.header.userOwnerName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- customerService Name Column -->
            <ng-container matColumnDef="customerServiceName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> รหัส </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.customerServiceName">{{element.customerServiceName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- customerService Unit No Column -->
            <ng-container matColumnDef="customerServiceUnitNo">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> บ้านเลขที่ </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.customerServiceUnitNo">{{element.customerServiceUnitNo}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- customerService Status Column -->
            <ng-container matColumnDef="customerServiceStatus">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> สถานะงาน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.customerServiceStatus">{{element.customerServiceStatus}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- customerService Item Column -->
            <ng-container matColumnDef="customerServiceItem">
                <th mat-header-cell *matHeaderCellDef class="min-w-[300px] w-[300px] px-6 py-4"> ประเภทการร้องเรียน
                </th>
                <td mat-cell *matCellDef="let element" class="min-w-[300px] w-[300px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.customerServiceItem">{{element.customerServiceItem}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- customerService Category Column -->
            <ng-container matColumnDef="customerServiceCategory">
                <th mat-header-cell *matHeaderCellDef class="min-w-[300px] w-[300px] px-6 py-4"> ประเภท </th>
                <td mat-cell *matCellDef="let element" class="min-w-[300px] w-[300px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.customerServiceCategory">{{element.customerServiceCategory}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="min-w-[320px] w-[320px] px-6 py-4"> รายละเอียดงานซ่อม </th>
                <td mat-cell *matCellDef="let element" class="min-w-[320px] w-[320px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.description">{{element.description}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Created Date Column -->
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ว/ด/ป ลูกค้าแจ้ง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.createdDate)}}">{{dateAddHours(element.createdDate)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- User Display Name Column -->
            <ng-container matColumnDef="userDisplayName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อผู้แจ้ง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.userDisplayName">{{element.userDisplayName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Customer Service Status Has Date Time Column -->
            <ng-container matColumnDef="customerServiceStatusHasDate">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ว/ด/ป เสร็จงาน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.customerServiceStatusHasDate)}}">{{dateAddHours(element.customerServiceStatusHasDate)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- successCustomerServiceDay Column -->
            <!-- <ng-container matColumnDef="successCustomerServiceDay">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ผลสำเร็จของงาน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.successCustomerServiceDay">{{element.successCustomerServiceDay}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container> -->

            <!-- questionnaireAvg Column -->
            <ng-container matColumnDef="questionnaireAvg">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ผลประเมินความพึงพอใจ
                </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.questionnaireAvg">{{element.questionnaireAvg | number}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- customerServiceForemanPostUser Column -->
            <ng-container matColumnDef="customerServiceForemanPostUser">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อช่าง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.customerServiceForemanPostUser">{{element.customerServiceForemanPostUser}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- approvedDisplayName Column -->
            <ng-container matColumnDef="approvedDisplayName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อ admin ที่
                    approve/reject </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.approvedDisplayName">{{element.approvedDisplayName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- approvedDateTime Date Time Column -->
            <ng-container matColumnDef="approvedDateTime">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> วันที่ approve / reject
                </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.approvedDateTime)}}">{{dateAddHours(element.approvedDateTime)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1">รวมงานทั้งหมด</span>
                </td>
            </ng-container>

            <!-- totalCustomerService Column -->
            <ng-container matColumnDef="totalCustomerService">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> จำนวนงานทั้งหมด </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.totalCustomerService">{{element.header.totalCustomerService}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1">{{getTotalSumCustomerService()}} งาน</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </div>
</div>