import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import { ObservableManager } from "../../../services/ObservableManager.service";
import * as moment from 'moment';
import { AutocompleteChipsModel } from "../../../models/AutocompleteChipsModel";
import { AutocompleteChips } from "../../shares/AutocompleteChips.component";
import { TableColumnModel } from "../../../models/TableModel";
import { Subject } from "rxjs";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { RepairTypeFacade } from "../../../services/facade/RepairTypeFacade.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { CustomerServiceFacade } from "../../../services/facade/CustomerServiceFacade.service";
import { CUSTOMER_SERVICE_CATEGORY } from '../../../constants/General';
import { DownloadFileService } from "../../../services/DownloadFile.service";
import { CustomTable } from "../../shares/table/CustomTable.component";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { CustomerServiceRequest } from "../../../request/CustomerServiceRequest";

const PAGE_NAME: string = 'aftersale';

@Component({
  selector: 'after-sale-page',
  templateUrl: './AfterSalePage.component.html'
})

export class AfterSalePage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  // Start search filter -----------------------
  public autoCompList: AutocompleteChipsModel[] = [];
  @ViewChild('searchText') searchText: AutocompleteChips;
  // End search filter -----------------------

  // Start table
  @ViewChild('table') tableRow: CustomTable;
  public columns: TableColumnModel[];
  public data: any[] = [];
  public limitTable = 100;
  public limitPage = 101;
  public lengthDataTable: number;
  // End table

  private destroy = new Subject<void>();

  private textSliderDetail: string = 'data-table';
  private textSucceedDetail: string = 'succeed-table';

  public menu: any;
  public campaign: UntypedFormGroup;

  public paramsId: string;

  private customerServiceRequestVal = new CustomerServiceRequest;
  private dataCustomerServiceCategory = CUSTOMER_SERVICE_CATEGORY;

  public exportDate!: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private authenManager: AuthenManager,
    private formBuilder: UntypedFormBuilder,
    private observManager: ObservableManager,
    private repairTypeFacade: RepairTypeFacade,
    private checkTokenService: CheckTokenService,
    private customerServiceFacade: CustomerServiceFacade,
    private downloadFileService: DownloadFileService
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {
        this.activatedRoute.children.forEach(child => {
          child.params.subscribe(params => {
            if (params) {
              this.paramsId = params['id'];
              this._getCustomerServiceId(this.paramsId);
            }
          })
        });
      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.getAutocompleteChip();
    this._campaign();
    this.customerServiceRequestVal.startDateTime = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
    this.customerServiceRequestVal.endDateTime = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
    this._getCustomerService(null, null, true);
    this._getCustomerService(0, this.limitPage, false, { 'createdDate': -1 });
  }

  public ngAfterViewInit(): void {
    this.observManager.subscribe(this.textSucceedDetail, (res: any) => {
      if (res) {
        if (res.data.mode === 'edit') {
          if (res.data.value.realesateUnit.absUnitId) res.data.value['realesateUnitAbsUnitId'] = res.data.value.realesateUnit.absUnitId;
          if (res.data.value.realesateUnit.no) res.data.value['realesateUnitNo'] = res.data.value.realesateUnit.no;
          if (res.data.value.customerServiceStatus.name) res.data.value['customerServiceStatusName'] = res.data.value.customerServiceStatus.name;
          if (res.data.value.customerServiceItem.name) res.data.value['customerServiceItemName'] = res.data.value.customerServiceItem.name;
          if (res.data.value.customerServiceItem.category) res.data.value['customerServiceItemCategory'] = res.data.value.customerServiceItem.category;
          if (res.data.value.user.displayName) res.data.value['userDisplayName'] = res.data.value.user.displayName;
          this.tableRow && this.tableRow.editRow(res.data);
        } else if (res.data.mode === 'create') {
          if (res.data.value.realesateUnit.absUnitId) res.data.value['realesateUnitAbsUnitId'] = res.data.value.realesateUnit.absUnitId;
          if (res.data.value.realesateUnit.no) res.data.value['realesateUnitNo'] = res.data.value.realesateUnit.no;
          if (res.data.value.customerServiceStatus.name) res.data.value['customerServiceStatusName'] = res.data.value.customerServiceStatus.name;
          if (res.data.value.customerServiceItem.name) res.data.value['customerServiceItemName'] = res.data.value.customerServiceItem.name;
          if (res.data.value.customerServiceItem.category) res.data.value['customerServiceItemCategory'] = this._matchCategoryCustomer(res.data.value.customerServiceItem.category)?.name;
          if (res.data.value.user.displayName) res.data.value['userDisplayName'] = res.data.value.user.displayName;
          this.tableRow && this.tableRow.createRow(res.data.value);
        } else if (res.data.mode === 'delete') {
          this.tableRow && this.tableRow.deleteRow(res.data.index);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
    this.observManager.complete(this.textSucceedDetail);
  }

  public getAutocompleteChip() {
    this.autoCompList = [
      {
        name: 'โครงการบ้าน',
        tag: 'prefix',
        type: 'project_autocomp'
      },
      {
        name: 'รหัสติดตามการขาย',
        tag: 'absLeadId',
        type: 'input'
      },
      {
        name: 'รหัสยูนิต',
        tag: 'absUnitId',
        type: 'input'
      },
      {
        name: 'รหัสงานร้องเรียน',
        tag: 'name',
        type: 'input'
      },
      {
        name: 'สถานะ',
        tag: 'customerServiceStatus',
        type: 'multi_select'
      },
      {
        name: 'ประเภทการร้องเรียน',
        tag: 'customerServiceItemName',
        type: 'input'
      },
      {
        name: 'บ้านเลขที่',
        tag: 'no',
        type: 'input'
      },
      {
        name: 'ผู้แจ้งเรือง',
        tag: 'displayName',
        type: 'input'
      },
      {
        name: 'รหัสประจำตัวประชาชน',
        tag: 'citizenId',
        type: 'input'
      },
      {
        name: 'หมายเลขโทรศัพท์',
        tag: 'mobileNo',
        type: 'input'
      }
    ];
  }

  public loadData(value: any) {
    this.data = value;
    this.data.map((res, index) => {
      if (!!res!.realesateUnit!.absUnitId) this.data[index]['realesateUnitAbsUnitId'] = res!.realesateUnit!.absUnitId;
      if (!!res!.realesateUnit!.no) this.data[index]['realesateUnitNo'] = res!.realesateUnit!.no;
      if (!!res!.customerServiceStatus!.name) this.data[index]['customerServiceStatusName'] = res!.customerServiceStatus!.name;
      if (!!res!.customerServiceItem!.name) this.data[index]['customerServiceItemName'] = res!.customerServiceItem!.name;
      if (!!res!.customerServiceItem!.category) this.data[index]['customerServiceItemCategory'] = this._matchCategoryCustomer(res!.customerServiceItem!.category)?.name;
      if (!!res!.user!.displayName) this.data[index]['userDisplayName'] = res!.user!.displayName;
      this.data[index]['questionnaireAvg'] = (!!res!.questionnaireAvg ? res!.questionnaireAvg.toFixed(2) : null);
    });
  }

  private _matchCategoryCustomer(customerServiceItemCategory: string) {
    return this.dataCustomerServiceCategory.find((res: any) => res.id === customerServiceItemCategory);
  }

  public clickPaginator(event: any) {
    let count: number = this.limitPage * event.pageIndex;
    const conditionObj: any = this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;
    this._getCustomerService(count, this.limitPage, false, { "createdDate": -1 }, whereConditions, optionalFields);
  }

  public async getFilterWhereCondition() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return {};
    }

    const whereConditions: any = {};
    for (const key in filterResult) {
      if (!filterResult[key]) {
        continue;
      }
      if (key !== 'name') {
        continue;
      }
      const regex = { $regex: '.*' + filterResult[key] + '.*', $options: 'si' };
      whereConditions[key] = regex;
    }

    const optionalFields: any = {};
    if (filterResult['prefix'] !== undefined && filterResult['prefix'] !== '') {
      optionalFields.pageIds = [];
      optionalFields.pageIds.push(filterResult['prefix']);
    }
    if (filterResult['custType'] !== undefined && filterResult['custType'] !== '') {
      optionalFields.custType = [];
      let custType: any[] = await this._getSearchRepairType(filterResult['custType']);
      optionalFields.custType = custType.map((res, index) => {
        if (!!res!.id) return res!.id;
      });
    }
    if (filterResult['customerServiceStatus'] !== undefined && filterResult['customerServiceStatus'] !== '') {
      optionalFields.customerServiceStatus = filterResult['customerServiceStatus'];
    }
    if (filterResult['absUnitId'] !== undefined && filterResult['absUnitId'] !== '') {
      optionalFields.absUnitId = { $regex: '.*' + filterResult['absUnitId'] + '.*', $options: 'si' };
    }
    if (filterResult['absLeadId'] !== undefined && filterResult['absLeadId'] !== '') {
      optionalFields.absLeadId = { $regex: '.*' + filterResult['absLeadId'] + '.*', $options: 'si' };
    }
    if (filterResult['no'] !== undefined && filterResult['no'] !== '') {
      optionalFields.no = filterResult['no'];
    }
    if (filterResult['citizenId'] !== undefined && filterResult['citizenId'] !== '') {
      optionalFields.citizenId = { $regex: '.*' + filterResult['citizenId'] + '.*', $options: 'si' };
    }
    if (filterResult['displayName'] !== undefined && filterResult['displayName'] !== '') {
      optionalFields.displayName = { $regex: '.*' + filterResult['displayName'] + '.*', $options: 'si' };
    }
    if (filterResult['mobileNo'] !== undefined && filterResult['mobileNo'] !== '') {
      optionalFields.mobileNo = { $regex: '.*' + filterResult['mobileNo'] + '.*', $options: 'si' };
    }

    return {
      whereConditions,
      optionalFields
    };
  }

  private _getCustomerService(offset?: number | null, limit?: number | null, count?: boolean, orderBy?: any, whereConditions?: any, optionalFields?: any) {
    this.customerServiceRequestVal.count = count;
    this.customerServiceRequestVal.limit = limit;
    this.customerServiceRequestVal.offset = offset;
    this.customerServiceRequestVal.orderBy = orderBy;
    this.customerServiceRequestVal.whereConditions = whereConditions;
    optionalFields?.pageIds ? this.customerServiceRequestVal.pageIds = optionalFields?.pageIds : delete this.customerServiceRequestVal.pageIds;
    optionalFields?.custType ? this.customerServiceRequestVal.custType = optionalFields?.custType : delete this.customerServiceRequestVal.custType;
    optionalFields?.customerServiceStatus ? this.customerServiceRequestVal.customerServiceStatus = optionalFields?.customerServiceStatus : delete this.customerServiceRequestVal.customerServiceStatus;
    optionalFields?.absUnitId ? this.customerServiceRequestVal.absUnitId = optionalFields?.absUnitId : delete this.customerServiceRequestVal.absUnitId;
    optionalFields?.no ? this.customerServiceRequestVal.no = optionalFields?.no : delete this.customerServiceRequestVal.no;
    optionalFields?.absLeadId ? this.customerServiceRequestVal.absLeadId = optionalFields?.absLeadId : delete this.customerServiceRequestVal.absLeadId;
    optionalFields?.citizenId ? this.customerServiceRequestVal.citizenId = optionalFields?.citizenId : delete this.customerServiceRequestVal.citizenId;
    optionalFields?.mobileNo ? this.customerServiceRequestVal.mobileNo = optionalFields?.mobileNo : delete this.customerServiceRequestVal.mobileNo;
    optionalFields?.pageId ? this.customerServiceRequestVal.pageId = optionalFields?.pageId : delete this.customerServiceRequestVal.pageId;
    optionalFields?.displayName ? this.customerServiceRequestVal.displayName = optionalFields?.displayName : delete this.customerServiceRequestVal.displayName;
    this.customerServiceFacade.searchData(this.customerServiceRequestVal).then((res: any) => {
      if (res) {
        if (count === true) {
          this.lengthDataTable = res.data;
        } else {
          this.loadData(res.data);
          this.columns = [
            { columnDef: 'realesateUnitAbsUnitId', header: 'เลขที่บ้าน', tag: 'text', display: true, cell: (element: any) => `${element.realesateUnit.absUnitId ? element.realesateUnit.absUnitId : '-'}` },
            { columnDef: 'realesateUnitNo', header: 'บ้านเลขที่', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.realesateUnitNo ? element.realesateUnitNo : '-'}` },
            { columnDef: 'name', header: 'รหัสงานร้องเรียน', tag: 'text', display: true, cell: (element: any) => `${element.name ? element.name : '-'}` },
            { columnDef: 'customerServiceStatusName', header: 'สถานะ', tag: 'text', display: true, cell: (element: any) => `${element.customerServiceStatusName ? element.customerServiceStatusName : '-'}` },
            { columnDef: 'customerServiceItemName', header: 'ประเภทการร้องเรียน', tag: 'text', display: true, cell: (element: any) => `${element.customerServiceItemName ? element.customerServiceItemName : '-'}` },
            { columnDef: 'customerServiceItemCategory', header: 'ประเภท', tag: 'text', display: true, cell: (element: any) => `${element.customerServiceItemCategory ? element.customerServiceItemCategory : '-'}` },
            { columnDef: 'approvedDateTime', header: 'วันที่ได้รับอนุมัติ', tag: 'text', display: true, cell: (element: any) => `${element.approvedDateTime ? moment(element.approvedDateTime).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
            { columnDef: 'createdDate', header: 'วันที่แจ้งเรือง', tag: 'text', display: true, cell: (element: any) => `${element.createdDate ? moment(element.createdDate).add(543, 'year').format('DD/MM/YYYY') : '-'}` },
            { columnDef: 'userDisplayName', header: 'ผู้แจ้งเรือง', tag: 'text', display: true, cell: (element: any) => `${(element.createdBy === element.user.id) ? (!!element.userDisplayName ? element.userDisplayName : '-') : 'Admin'}` },
            { columnDef: 'status', header: 'การอนุมัติ', tag: 'text', display: true, cell: (element: any) => `${element.status === 'approved' ? 'ผ่านการอนุมัติ' : element.status === 'unapproved' ? 'ไม่ผ่านการอนุมัติ' : 'รอดำเนินการ'}` },
            { columnDef: 'questionnaireAvg', header: 'คะแนน', tag: 'text', display: true, align: 'center', cell: (element: any) => `${element.questionnaireAvg ? element.questionnaireAvg : '-'}` },
          ];
        }
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  private _getCustomerServiceId(id: string) {
    this.customerServiceFacade.searchData({ custIds: [id] }).then((res: any) => {
      if (res) {
        if (Array.isArray(res.data) && res.data.length === 0) {
          this.router.navigate(['homecare']);
          this.authenManager.clickDialogAlert({
            header: '',
            content: 'ไม่พบรหัสงานร้องเรียน "' + id + '"',
            cancel: false
          });
        } else {
          this.clickTableRow({
            index: null,
            event: res.data[0],
            tag: '',
            id: id
          });
        }
      }
    }).catch((err) => {
      if (err) {
        this.router.navigate(['homecare']);
        this.authenManager.clickDialogAlert({
          header: '',
          content: 'ไม่พบรหัสงานร้องเรียน "' + id + '"',
          cancel: false
        });
      }
    });
  }

  public clickDowload(date: string) {
    if (!date) {
      this.authenManager.clickDialogAlert({
        header: '',
        content: 'ยังไม่ได้เลือกวันที่ออกเอกสาร',
        cancel: false
      });

      return;
    }

    const fullDate = new Date(date);
    const sd = moment(fullDate).format('YYYY-MM-DD');
    const dt: any = sd + 'T00:00:00.000Z';
    this.customerServiceFacade.exportData({ customerServiceDate: dt }).then((res: any) => {
      if (res) {
        // Create a Blob object from the response data
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = sd + '-homecare.pdf';
        link.click();
        this.exportDate = '';
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
        this.authenManager.clickDialogAlert({
          header: '',
          content: 'วันที่ ' + sd + ' ยังไม่มีการอนุมัติงาน',
          cancel: false
        });
      }
    });
  }

  public clickChip(event: any) {

  }

  public closeChip(event: any) {
    this.refreshTable();
  }

  public filterTextComfirm(event: any) {
    this.refreshTable();
  }

  private _campaign() {
    this.campaign = this.formBuilder.group({
      start: new UntypedFormControl(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))),
      end: new UntypedFormControl(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)))
    });
  }

  public async refreshTable() {
    const filterResult = this.searchText.getFilterResults();
    if (!filterResult) {
      return;
    }

    const conditionObj: any = await this.getFilterWhereCondition();
    const whereConditions: any = conditionObj.whereConditions;
    const optionalFields: any = conditionObj.optionalFields;

    this._getCustomerService(null, null, true, { 'createdDate': -1 }, whereConditions, optionalFields);
    this._getCustomerService(0, this.limitPage, false, { 'createdDate': -1 }, whereConditions, optionalFields);
  }

  public clickCreate() {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'create'
      }
    });
  }

  public clickSetting() {
    this.observManager.createSubject('setting-noti-page');
    this.observManager.publish('setting-noti-page', {
      data: {
        mode: 'create'
      }
    });
  }

  public clickTableRow(event: any) {
    this.observManager.createSubject(this.textSliderDetail);
    this.observManager.publish(this.textSliderDetail, {
      data: {
        mode: 'edit',
        index: event.index,
        value: event.event,
        id: event.id
      }
    });
  }

  public async searchDate() {
    await this.checkTokenService.checkStatus();
    let startDateTime: any = (this.campaign.get('start')?.value ? moment(this.campaign.get('start')?.value).format('YYYY-MM-DD') : new Date('2019-01-16')) + 'T00:00:00.000Z';
    let endDateTime: any = (this.campaign.get('end')?.value ? moment(this.campaign.get('end')?.value).format('YYYY-MM-DD') : (moment((new Date().getFullYear()) + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate())).format('YYYY-MM-DD'))) + 'T23:59:59.000Z';
    this.customerServiceRequestVal.startDateTime = startDateTime;
    this.customerServiceRequestVal.endDateTime = endDateTime;
    this.refreshTable();
  }

  public clickDisplayTable(index: number) {
    if (this.columns[index].display === true) {
      this.columns[index].display = false;
    } else {
      this.columns[index].display = true;
    }
  }

  private async _getSearchRepairType(repairType: any): Promise<any> {
    let val = {
      count: false,
      whereConditions: {
        name: { $regex: '.*' + repairType + '.*', $options: 'si' }
      }
    }

    const repair = await this.repairTypeFacade.searchData(val);
    if (repair) return repair.data;
  }

  public clickActionTableDataDownload() {
    let rows = [];
    for (const row of this.columns) {
      rows.push(row.columnDef);
    }

    this.downloadFileService.download(PAGE_NAME, this.data, rows);
  }
}