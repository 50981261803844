import { Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";
import { ObservableManager } from "../../../../services/ObservableManager.service";
import { environment } from "../../../../../environments/environment";
import { FormArray, FormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { PageFacade } from "src/app/services/facade/PageFacade.service";
import { MatTableDataSource } from "@angular/material/table";
import { animate, state, style, transition, trigger } from "@angular/animations";
import * as moment from 'moment';
import { AuthenManager } from "src/app/services/AuthenManager.service";
import { CustomerServiceItemFacade } from "src/app/services/facade/CustomerServiceItemFacade.service";
import { CustomerServiceStatusAdminFacade } from "src/app/services/facade/CustomerServiceStatusAdminFacade.service";
import { ReportCustomerServiceAdminFacade } from "src/app/services/facade/ReportCustomerServiceAdminFacade.service";
import { CUSTOMER_SERVICE_CATEGORY } from '../../../../constants/General';

const PAGE_NAME: string = 'aftersale';
const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'report-customer-service-page',
  templateUrl: './ReportCustomerServicePage.component.html',
  animations: [
    trigger('heightGrow', [
      state('closed', style({
        height: '45%',
      })),
      state('open', style({
        height: '100vh'
      })),
      transition('* => *', animate(300))
    ]),
    trigger('hide', [
      state('closed', style({
        display: 'unset',
      })),
      state('open', style({
        display: 'none',
      })),
      transition('* => *', animate(300))
    ]),
    trigger('arrow', [
      state('closed', style({
        rotate: '0deg',
      })),
      state('open', style({
        rotate: '-180deg',
      })),
      transition('* => *', animate(300))
    ]),
  ]
})

export class ReportCustomerServicePage implements OnInit {

  public static readonly PAGE_NAME: string = PAGE_NAME;

  private destroy = new Subject<void>();

  @Input() dataTable: any[];

  displayedColumns: string[] = [
    'no',
    'pageName',
    'userOwnerName',
    'customerServiceName',
    'customerServiceUnitNo',
    'customerServiceStatus',
    'customerServiceItem',
    'customerServiceCategory',
    'description',
    'createdDate',
    'userDisplayName',
    'customerServiceStatusHasDate',
    // 'successCustomerServiceDay',
    'questionnaireAvg',
    'customerServiceForemanPostUser',
    'approvedDisplayName',
    'approvedDateTime',
    'totalCustomerService'
  ];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  // Page
  public dataPage: any[] = [];
  public filteredListPage: any[] = [];

  // Category
  public dataCategory: any[] = [];
  public filteredCategory: any[] = [];
  public dataCustomerServiceCategory = CUSTOMER_SERVICE_CATEGORY;

  // Item
  public dataItem: any[] = [];
  public filteredListItem: any[] = [];

  // Status
  public dataStatus: any[] = [];
  public filteredListStatus: any[] = [];

  public menu: any;
  public state = "closed";

  public environmentApi = environment;
  public groupCustomer: any;

  public isAllSelectedStatus = false;
  public isAllSelectedPage = false;
  public isAllSelectedItem = false;

  constructor(
    private router: Router,
    private observManager: ObservableManager,
    private customerServiceItemFacade: CustomerServiceItemFacade,
    private pageFacade: PageFacade,
    private formBuilder: UntypedFormBuilder,
    private customerServiceStatusFacade: CustomerServiceStatusAdminFacade,
    private reportCustomerServiceAdminFacade: ReportCustomerServiceAdminFacade,
    private authenManager: AuthenManager
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });

    this.observManager.subscribe('menu', (res: any) => {
      if (res) {
        this.menu = res.data;
      }
    });

    this.formCustomerService();
    this._getPage();
    this._getStatusList();
    this._searchCustomerItem();
  }

  public async ngOnInit(): Promise<void> {
  }

  public transformData(jsonData: any[]): any[] {
    return jsonData.map(data => {
      if (data.type === 'D') {
        return {
          header: {
            no: '',
            pageName: '',
            userOwnerName: '',
            totalCustomerService: ''
          },
          customerServiceName: data.customerService?.name,
          customerServiceUnitNo: data.customerService?.realesateUnit?.no,
          customerServiceStatus: data.customerService?.customerServiceStatus?.name,
          customerServiceItem: data.customerService?.customerServiceItem?.name,
          customerServiceCategory: this._setValueCategory(data.customerService?.customerServiceItem?.category),
          description: data.customerService?.description,
          createdDate: data.customerService?.createdDate,
          userDisplayName: data.customerService?.user?.displayName,
          customerServiceStatusHasDate: data.customerService?.customerServiceStatusHasDate?.createdDate,
          // successCustomerServiceDay: data.successCustomerServiceDay,
          questionnaireAvg: this._setQuestionnaireAvg(data.customerService?.questionnaireAvg),
          customerServiceForemanPostUser: this._setForemanFullName(data.customerService?.customerServiceForemanPostUser),
          approvedDisplayName: data.customerService?.approvedDisplayName,
          approvedDateTime: data.customerService?.approvedDateTime,
          totalSumCustomerService: data.totalSumCustomerService
        };
      } else if (data.type === 'H') {
        return {
          header: {
            no: data?.no,
            pageName: data?.pageName,
            userOwnerName: data?.userOwnerName,
            totalCustomerService: data?.totalCustomerService
          },
          customerServiceName: '',
          customerServiceUnitNo: '',
          customerServiceStatus: '',
          customerServiceItem: '',
          customerServiceCategory: '',
          description: '',
          createdDate: '',
          userDisplayName: '',
          customerServiceStatusHasDate: '',
          // successCustomerServiceDay: '',
          questionnaireAvg: '',
          customerServiceForemanPostUser: '',
          approvedDisplayName: '',
          approvedDateTime: '',
          totalSumCustomerService: ''
        };
      } else {
        return null;
      }
    }).filter(Boolean);
  }

  private _setQuestionnaireAvg(data: number) {
    if (!data) return '';
    return data.toFixed(2);
  }

  private _setForemanFullName(data: any) {
    if (!data || !Array.isArray(data)) {
      return '';
    }
    return data.map((item: any) => item.displayName).join(',');
  }

  public getTotalSumCustomerService() {
    return this.dataSource.data[this.dataSource.data.length - 1]?.totalSumCustomerService || 0;
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.observManager.complete('menu');
  }

  public formCustomerService() {
    this.groupCustomer = this.formBuilder.group({
      page: ['', [Validators.required, Validators.minLength(1)]],
      status: [''],
      category: [''],
      item: [''],
      foremanFullName: [''],
      adminFullName: [''],
      userFullName: [''],
      no: [''],
      startApproveDateTime: [''],
      endApproveDateTime: [''],
      startDateTime: [''],
      endDateTime: [''],
    });
  }

  private _getPage() {
    this.pageFacade.searchData().then((res: any) => {
      if (res) {
        this.dataPage = res.data;
        this.filteredListPage = this.dataPage.slice();
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  private async _searchCustomerItem(): Promise<any> {
    this.customerServiceItemFacade.searchData().then((res: any) => {
      if (res) {
        this.dataItem = res.data;
        this._setCustomerItem(this.dataItem);
      }
    });
  }

  private _setCustomerItem(data: any) {
    let result: any[] = [];

    data.forEach((item: any) => {
      result.push({ category: item.category, name: item.name, id: item._id });
    });

    this.dataItem = result;
    this.filteredListItem = this.dataItem.slice();
  }

  private _getStatusList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerServiceStatusFacade.searchData().then((res: any) => {
        if (res && res.data) {
          const groupedData = res.data.reduce((acc: { [x: string]: any[] }, item: { name: string; _id: any }) => {
            if (!acc[item.name]) {
              acc[item.name] = [];
            }
            acc[item.name].push(item._id);
            return acc;
          }, {});

          const transformedData = Object.keys(groupedData).map(key => ({
            name: key,
            id: groupedData[key]
          }));

          this.dataStatus = transformedData;
          this.filteredListStatus = this.dataStatus.slice();
        }
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public createTable() {
    let data: any = {};
    const adjustDateTime = (dateTimeString: string, type: 'start' | 'end'): string => {
      if (!dateTimeString) {
        return '';
      }
      let date = moment(dateTimeString);
      if (type === 'start') {
        date = date.startOf('day');
      } else if (type === 'end') {
        date = date.endOf('day');
      }
      date = date.add(7, 'hours');
      return date.toISOString();
    };

    const addIfExists = (key: string, value: any, type: 'start' | 'end' | null = null) => {
      if (value !== null && value !== undefined && value !== '') {
        if (type) {
          data[key] = adjustDateTime(value, type);
        } else {
          data[key] = value;
        }
      }
    };

    addIfExists('pageIds', this.groupCustomer.value.page);
    addIfExists('foremanFullName', this.groupCustomer.value.foremanFullName);
    addIfExists('adminFullName', this.groupCustomer.value.adminFullName);
    addIfExists('userFullName', this.groupCustomer.value.userFullName);
    addIfExists('no', this.groupCustomer.value.no);
    addIfExists('startApproveDateTime', this.groupCustomer.value.startApproveDateTime, 'start');
    addIfExists('endApproveDateTime', this.groupCustomer.value.endApproveDateTime, 'end');
    addIfExists('startDateTime', this.groupCustomer.value.startDateTime, 'start');
    addIfExists('endDateTime', this.groupCustomer.value.endDateTime, 'end');

    if (!!this.groupCustomer.value.category) addIfExists('categorys', this.groupCustomer.value.category);
    if (!!this.groupCustomer.value.status) addIfExists('customerServiceStatusIds', this._setStatus(this.groupCustomer.value.status));
    if (!!this.groupCustomer.value.item) addIfExists('customerServiceItemIds', this.groupCustomer.value.item);

    this.reportCustomerServiceAdminFacade.searchData(data).then((res: any) => {
      if (res) {
        this.dataTable = res.data;
        this.dataSource.data = this.transformData(this.dataTable);
      }
    }).catch((err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  private _setStatus(data: any) {
    if (!Array.isArray(data)) {
      return [];
    }
    const flatArray = data.flat();
    return flatArray;
  }

  public changeState(): void {
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
  }

  public clickActionTableDataDownload() {
    // Map the data excluding totalSumCustomerService
    const dataToExport = this.dataSource.data.map(item => ({
      'บ้านเลขที่': item.header?.no || '',
      'โครงการ': item.header?.pageName || '',
      'ชื่อเจ้าของบ้าน': item.header?.userOwnerName || '',
      'รหัส': item.customerServiceName || '',
      'บ้านเลขที่ ': item.customerServiceUnitNo || '',
      'สถานะงาน': item.customerServiceStatus || '',
      'ประเภทการร้องเรียน': item.customerServiceItem || '',
      'ประเภท': item.customerServiceCategory || '',
      'รายละเอียด': item.description || '',
      'ว/ด/ป ที่ลูกค้าแจ้ง': this.dateAddHours(item.createdDate, true) || '',
      'ชื่อผู้แจ้ง': item.userDisplayName || '',
      'ว/ด/ป ที่เสร็จงาน': this.dateAddHours(item.customerServiceStatusHasDate, true) || '',
      // 'ผลสำเร็จของงาน (ใน 30 วัน)': item.successCustomerServiceDay || '',
      'ผลประเมินความพึงพอใจ': item.questionnaireAvg || '',
      'ชื่อช่าง': item.customerServiceForemanPostUser || '',
      'ชื่อ admin ที่ approve/reject': item.approvedDisplayName || '',
      'วันที่ approve/reject': this.dateAddHours(item.approvedDateTime, true) || '',
      'จำนวนงานทั้งหมด': item.header?.totalCustomerService || ''
    }));

    // Find the row with totalSumCustomerService and append it as the last row
    const totalSumCustomerServiceRow = this.dataSource.data.find(item => !!item.totalSumCustomerService);
    if (totalSumCustomerServiceRow) {
      dataToExport.push({
        'บ้านเลขที่': '',
        'โครงการ': '',
        'ชื่อเจ้าของบ้าน': '',
        'รหัส': '',
        'บ้านเลขที่ ': '',
        'สถานะงาน': '',
        'ประเภทการร้องเรียน': '',
        'ประเภท': '',
        'รายละเอียด': '',
        'ว/ด/ป ที่ลูกค้าแจ้ง': '',
        'ชื่อผู้แจ้ง': '',
        'ว/ด/ป ที่เสร็จงาน': '',
        // 'ผลสำเร็จของงาน (ใน 30 วัน)': '',
        'ผลประเมินความพึงพอใจ': '',
        'ชื่อช่าง': '',
        'ชื่อ admin ที่ approve/reject': '',
        'วันที่ approve/reject': 'รวมงาน',
        'จำนวนงานทั้งหมด': totalSumCustomerServiceRow.totalSumCustomerService || '',
      });
    }

    const currentDate = new Date();
    const formattedDate = this._formatDate(currentDate);
    const csvData = this._convertToCsv(dataToExport);
    const blob = new Blob(["\ufeff" + csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `CustomerServiceData-${formattedDate}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private _convertToCsv(data: any[]) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  private _formatDate(date: any) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return `${day}-${month}-${year}`;
  }

  private _setValueCategory(data: string) {
    const category = CUSTOMER_SERVICE_CATEGORY.find(cat => cat.id === data);
    if (category) {
      return category.name;
    }

    return null;
  }


  public dateAddHours(dateTime: string, isCreateCsv?: boolean): any {
    if (!dateTime) {
      return '';
    }

    return moment(dateTime).add(7, 'hours').format('D/M/YYYY');
    // if (isCreateCsv) return moment(dateTime).add(7, 'hours').format('D/M/YYYY');
    // if (!isCreateCsv) return moment(dateTime).add(7, 'hours').toISOString();
  }

  public clickReset() {
    this.authenManager.clickDialogAlert({
      header: '',
      content: 'ต้องการที่จะคืนค่า'
    }).then((res: any) => {
      if (res) {
        this.groupCustomer.reset();
        this.dataSource.data = [];
        this.dataTable = [];
        this.state = "closed";
      }
    });
  }

  public clickSelect() {
    this._filterItemData(this.groupCustomer.get('category').value);
  }

  private _filterItemData(data?: any) {
    if (data) {
      this.isAllSelectedItem = false;
      this.groupCustomer.get('item').setValue('');
      this.filteredListItem = this.dataItem.filter((res: any) => data.includes(res.category));
    }
  }

  public toggleSelectAll(type: string) {
    if (type === 'status') {
      this.isAllSelectedStatus = !this.isAllSelectedStatus;
      if (this.isAllSelectedStatus) {
        const allStatusIds = this.filteredListStatus.map(item => item.id);
        this.groupCustomer.get('status').setValue(allStatusIds);
      } else {
        this.groupCustomer.get('status').setValue([]);
      }
    } else if (type === 'page') {
      this.isAllSelectedPage = !this.isAllSelectedPage;
      if (this.isAllSelectedPage) {
        const allPageIds = this.filteredListPage.map(item => item.id);
        this.groupCustomer.get('page').setValue(allPageIds);
      } else {
        this.groupCustomer.get('page').setValue([]);
      }
    } else {
      this.isAllSelectedItem = !this.isAllSelectedItem;
      if (this.isAllSelectedItem) {
        const allItemIds = this.filteredListItem.map(item => item.id);
        this.groupCustomer.get('item').setValue(allItemIds);
      } else {
        this.groupCustomer.get('item').setValue([]);
      }
    }
  }
}