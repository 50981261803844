import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class RealesateUnitInstallmentFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/realesate_unit/');
    }

    public getInstallments = (reUnitId: string, reLeadId?: string) => this.get<MethodResponseInf<any>>(`${(reUnitId) + '/down_payment' + (reLeadId ? '?reLeadId=' + reLeadId : '')}`, this.getDefaultHeader(), null);
    public completeStatus = (reUnitId: string, repairId: string, description?: string) => this.put<MethodResponseInf<any>>(`${(reUnitId) + '/repair/'}${(repairId) + '/complete/'}`, this.getDefaultHeader(), null, { completeAdminDescription: description });
}