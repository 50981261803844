<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper
            class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose()">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                    <div class="flex-1 flex flex-col">
                        <span class="text-lg font-bold flex items-center">
                            <span class="line-clamp-1">
                                {{dataDetail && dataDetail.mode === 'create' ? 'สร้างข่าวสาร' : 'แก้ไขข่าวสาร'}}
                            </span>
                        </span>
                        <!-- <span class="text-sm font-normal opacity-50 line-clamp-1 mt-1"
                            *ngIf="dataDetail && (dataDetail.type !== 'project' && dataDetail.type !== 'house-registration')">

                        </span> -->
                    </div>
                </div>
                <div class="flex-none flex items-center">
                    <div class="grid grid-cols-2 gap-2">
                        <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                            คืนค่า
                        </button>
                        <button mat-button class="bg-bule-light button-cdg"
                            (click)="isSubmitted ?  null : clickSubmit()">
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">บันทึก</ng-container>
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'create'">สร้าง</ng-container>
                        </button>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        class="ml-3 flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
                        [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>ลบข้อมูล</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="flex-1 flex">
                <div #detailL [formGroup]="groupPage"
                    class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-between gap-6 md:gap-5">
                    <div class="w-full md:w-[500px] xl:w-[560px] grid grid-cols-1 gap-6">
                        <card-detail [header]="'ตั้งค่าการเผยแพร่'" [isAciton]="false" [isShow]="true"
                            [maxHeight]="'unset'">
                            <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                <div>
                                    <span class="text-base font-bold opacity-50">ประเภทเนื้อหา</span>
                                    <mat-select formControlName="type" #select class="select-custom"
                                        (selectionChange)="selectType($event.value)" [disableOptionCentering]="true"
                                        [placeholder]="'เลือก'" autocomplete="off"
                                        [class.border-error]="(isSubmitted || groupPage.get('type')?.touched) && f['type'].errors">
                                        <ng-container *ngFor="let item of contentTypes">
                                            <mat-option class="font-medium" [value]="item.boolean">
                                                {{item.lable}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupPage.get('type')?.touched) && f['type'].errors">
                                        กรุณาเลือกประเภทเนื้อหา
                                    </mat-error>
                                </div>
                                <span class="text-lg font-bold mt-5 pb-2 border-b">ช่วงเวลาเผยแพร่</span>
                                <div>
                                    <span class="text-base font-bold opacity-50">วันที่เริ่มเผยแพร่</span>
                                    <div class="flex gap-4">
                                        <div class="w-full">
                                            <input formControlName="startDate" (click)="pickerStart.open()"
                                                class="input-custom" type="text" placeholder="" autocomplete="off"
                                                [matDatepicker]="pickerStart"
                                                [class.border-error]="(isSubmitted || groupPage.get('startDate')?.touched) && f['startDate'].errors">
                                            <mat-datepicker #pickerStart disabled="false" restoreFocus="false">
                                            </mat-datepicker>
                                        </div>
                                        <input class="input-custom flex-none w-32" formControlName="startDateTime"
                                            type="time" placeholder="00:00">
                                    </div>
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupPage.get('startDate')?.touched) && f['startDate'].errors">
                                        กรุณากำหนดระยะเวลาเริ่มการเผยแพร่
                                    </mat-error>
                                </div>
                                <div *ngIf="groupPage.get('isEndDate')?.value">
                                    <span class="text-base font-bold opacity-50">วันที่สิ้นสุดเผยแพร่</span>
                                    <div class="flex gap-4">
                                        <div class="w-full">
                                            <input formControlName="endDate" (click)="pickerEnd.open()"
                                                class="input-custom" type="text" placeholder="" autocomplete="off"
                                                [matDatepicker]="pickerEnd"
                                                [class.border-error]="(isSubmitted || groupPage.get('endDate')?.touched) && f['endDate'].errors">
                                            <mat-datepicker #pickerEnd disabled="false" restoreFocus="false">
                                            </mat-datepicker>
                                        </div>
                                        <input class="input-custom flex-none w-32" formControlName="endDateTime"
                                            type="time" placeholder="00:00">
                                    </div>
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupPage.get('endDate')?.touched) && f['endDate'].errors">
                                        กรุณากำหนดระยะเวลาสิ้นสุดการเผยแพร่
                                    </mat-error>
                                </div>
                                <div>
                                    <mat-checkbox class="font-bold" formControlName="isEndDate"
                                        (click)="clickisEndDate(groupPage.get('isEndDate')?.value)">
                                        กำหนดเวลาสิ้นสุด
                                    </mat-checkbox>
                                </div>
                            </div>
                        </card-detail>
                        <card-detail [header]="'โครงการที่ต้องการเผยแพร่'" [isAciton]="false" [isShow]="true"
                            [maxHeight]="'unset'" *ngIf="groupPage.get('type')?.value">
                            <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                <div>
                                    <span class="text-base font-bold opacity-50">โครงการ</span>
                                    <mat-select formControlName="page" #select class="select-custom"
                                        [disableOptionCentering]="true" [placeholder]="'เลือก'" autocomplete="off"
                                        [class.border-error]="(isSubmitted || groupPage.get('page')?.touched) && f['page'].errors">
                                        <ng-container *ngFor="let item of pageList">
                                            <mat-option class="font-medium" [value]="item.id"
                                                *ngIf="item.isOfficial === false">
                                                ({{item.prefix}}) {{item.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupPage.get('page')?.touched) && f['page'].errors">
                                        กรุณาเลือกโครงการที่ต้องการเผยแพร่
                                    </mat-error>
                                </div>
                            </div>
                        </card-detail>
                        <card-detail [header]="'ภาพหน้าปก'" [isAciton]="false" [isShow]="true" [maxHeight]="'unset'">
                            <card-upload-image body [isAction]="true"
                                [image]="groupPage.get('coverImage')?.value ? checkCoverImage() : ''"
                                (event)="clickActionTableDataUpload()">
                                <button (click)="clickActionTableDataUpload()" mat-button
                                    class="bg-gray-100 button-cdg">
                                    <div class="flex items-center">
                                        <mat-icon class="mr-2">add_photo_alternate</mat-icon>
                                        เปลี่ยนรูปภาพ
                                    </div>
                                </button>
                            </card-upload-image>
                        </card-detail>
                        <card-detail [header]="'หัวเรื่อง'" [isAciton]="false" [isShow]="true" [maxHeight]="'unset'">
                            <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                <div>
                                    <span class="text-base font-bold opacity-50">รูปแบบการพาดหัว</span>
                                    <div class="mt-4">
                                        <mat-radio-group class="flex gap-4" formControlName="isHeadlinePattern">
                                            <mat-radio-button [value]="true">
                                                แสดงหัวเรื่อง
                                            </mat-radio-button>
                                            <mat-radio-button [value]="false">
                                                ไม่แสดงหัวเรื่อง
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div>
                                    <span class="text-base font-bold opacity-50">ข้อความพาดหัว</span>
                                    <input class="input-custom" maxlength="60" formControlName="title" type="text"
                                        placeholder=""
                                        [class.border-error]="(isSubmitted || groupPage.get('title')?.touched) && f['title'].errors">
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupPage.get('title')?.touched) && f['title'].errors">
                                        กรุณากรอกข้อความพาดหัว
                                    </mat-error>
                                </div>
                            </div>
                        </card-detail>
                        <card-detail [header]="'ข้อความ'" [isAciton]="false" [isShow]="true" [maxHeight]="'unset'">
                            <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                <div>
                                    <div class="editor"
                                        [class.border-error]="(isSubmitted || groupPage.get('story')?.touched) && f['story'].errors">
                                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                        <ngx-editor appDragDrop (filesChangeEmiter)="onFileChange($event)"
                                            formControlName="story" [editor]="editor"
                                            [placeholder]="'ช่องสำหรับใส่เนื้อหา'">
                                        </ngx-editor>
                                    </div>
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupPage.get('story')?.touched) && f['story'].errors">
                                        กรุณากรอกเนื้อหา
                                    </mat-error>
                                </div>
                            </div>
                        </card-detail>
                    </div>
                    <div #detailRight
                        class="flex-none static md:sticky w-full md:w-[326px] xl:w-[346px] grid grid-cols-1 gap-6"
                        [ngStyle]="{'top': topRight + 'px'}">
                        <card-detail [header]="'ตัวอย่างการเผยแพร่'" [isAciton]="false" [isShow]="true"
                            [maxHeight]="'unset'">
                            <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                <div class="grid grid-cols-2 rounded-lg bg-[#DEDEDE]">
                                    <div class="rounded-lg text-center p-2 shadow cursor-pointer border"
                                        [ngClass]="{'bg-white': !isFormContent}" (click)="isFormContent = false">
                                        หน้าปก
                                    </div>
                                    <div class="rounded-lg text-center p-2 shadow cursor-pointer border"
                                        [ngClass]="{'bg-white': isFormContent}" (click)="isFormContent = true">
                                        เนื้อหา
                                    </div>
                                </div>
                                <div class="relative border rounded-lg shadow-xl overflow-hidden">
                                    <span
                                        class="absolute top-4 left-4 z-[1] bg-black/50 text-white rounded-2xl py-1 px-4 backdrop-blur-sm"
                                        *ngIf="!isFormContent && groupPage.get('type')?.value === true">
                                        ประกาศ
                                    </span>
                                    <div class="w-full"
                                        [ngClass]="{'px-6 bg-black/10': !groupPage.get('coverImage')?.value}">
                                        <img class="w-full"
                                            [src]="groupPage.get('coverImage')?.value ? checkCoverImage() : imageDef"
                                            alt="">
                                    </div>
                                    <div [ngClass]="{'p-3': groupPage.get('title')?.value || (isFormContent && groupPage.get('story')?.value && groupPage.get('story')?.value !== '<p></p>')}"
                                        *ngIf="isFormContent || groupPage.get('isHeadlinePattern')?.value === true">
                                        <div class=" text-lg font-bold break-all"
                                            *ngIf="isFormContent || groupPage.get('isHeadlinePattern')?.value === true">
                                            {{groupPage.get('title')?.value}}
                                        </div>
                                        <div class="text-base break-all mt-2"
                                            *ngIf="isFormContent && (isFormContent && groupPage.get('story')?.value && groupPage.get('story')?.value !== '<p></p>')"
                                            [innerHTML]="groupPage.get('story')?.value"></div>
                                    </div>
                                </div>
                            </div>
                        </card-detail>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>