import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { SearchFilter } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class CustomerServiceItemFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/customerservice_item/');
    }

    public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<any[]>>(`search`, this.getDefaultHeader(), null, request);
}