import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'dialog-alert',
    templateUrl: './DialogAlert.component.html',
})
export class DialogAlert implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogAlert>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
    }

    public ngOnInit(): void {
        this.dialogRef.addPanelClass('overlay-dialog-alert');

    }

    public clickClose(): void {
        this.dialogRef.close(false);
        if (this.data !== undefined && this.data !== null && this.data.cancelClickedEvent !== undefined) {
            this.data.cancelClickedEvent.emit(false);
        }
    }

    public clickSubmit(): void {
        this.dialogRef.close(true);
        if (this.data !== undefined && this.data !== null && this.data.confirmClickedEvent !== undefined) {
            this.data.confirmClickedEvent.emit(true);
        }
    }
}