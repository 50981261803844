import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule, APP_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DatePipe } from '@angular/common'
import { ImageCropperModule } from 'ngx-image-cropper';
import { registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';
import localeThExtra from '@angular/common/locales/extra/th';
import { NgxEditorModule } from 'ngx-editor';
import { CommonModule } from '@angular/common';

registerLocaleData(localeTh, 'th-TH', localeThExtra);

import {
  MainPage,
  LoginPage,
  // -------------
  SettingPage,
  ManageUsersPage,
  ConfigPage,
  // -------------
  RepairPage,
  AfterSalePage,
  // -------------
  DataBasePage,
  ProjectPage,
  HouseRegistrationPage,
  ResidentsPage,
  RepairOutletPage,
  RepairStatusPage,
  ReportPage,
  ReportRepairPage,
  ReportCustomerServicePage,
  CustomerOutletPage,
  CustomerServiceStatusPage,
  CustomerServiceItemPage,
  CustomerServiceAssessmentPage,
  ContentPage,
  RepairTypePage,
  AssessmentPage,
  // -------------
  ChatPage,
  NotificationPage,
  DownPaymentPage,
  ErrorPage,
  Test,

  // shares
  Header,
  CardNotification,
  CardListMenu,
  CardDetail,
  CardChat,
  CardUploadImage,
  CardGallery,
  CustomTable,
  AutocompleteChips,
  DialogComplaint,
  DialogAlert,
  DialogDetail,
  DialogDatepicker,
  DialogTable,
  SliderContent,
  SliderDetailRepair,
  SliderAfterSale,
  SliderNotificationAfterSale,
  SliderProject,
  SliderHouseRegistration,
  SliderResidents,
  SliderRepairStatus,
  SliderRepairType,
  SliderManageUsers,
  SliderConfig,
  SliderDownPayment,
  SliderAssessment,
  DialogUpload,
  notification,
  MessagePage,
  EmailMessagePage,
  SliderMessage,
  SmsMessagePage,
  SliderCustomerServiceStatus,
  SliderCustomerServiceItem,
  SliderCustomerServiceAssessment,
  LogsMessagePage,
  ReadMore,
  DocumentManualPage,
  SliderDocumentManual
} from './components/components';
import {
  AuthenManager,
  ObservableManager,
  SocketIoManager,
  // Facade
  ActivateFacade,
  ActivateInfoAdminFacade,
  ChatFacade,
  ChatRoomFacade,
  CustomerServiceAdminFacade,
  CustomerServiceStatusAdminFacade,
  CustomerServiceStatusFacade,
  CustomerServiceItemAdminFacade,
  CustomerServiceItemFacade,
  NotificationFacade,
  RepairStatusAdminFacade,
  RepairTypeAdminFacade,
  RepairTypeFacade,
  RealesateUnitFacade,
  RealesateUnitCustomerServiceFacade,
  ReportRepairAdminFacade,
  ReportCustomerServiceAdminFacade,
  PageFacade,
  PageAdminFacade,
  ChatRoomAdminFacade,
  NoteFacade,
  NoteAdminFacade,
  RepairAdminFacade,
  PostAdminFacade,
  PostFacade,
  RepairFacade,
  RealesateUnitRepairFacade,
  RepairStatusFacade,
  UserAdminFacade,
  ConfigAdminFacade,
  CustomerServiceFacade,
  ReadChatFacade,
  QuestionnaireFacade,
  // Service
  TypeFilterListService,
  NotificationService,
  DownPaymentFacade,
  RealesateUnitInstallmentFacade,
  CheckStatusFacade,
  UploadFileFacade,
  EmailMessageFacade,
  SmsMessageFacade,
  LogsMessageFacade,
  CheckTokenService,
  DocumentManualFacade,
  DownloadFileService,
} from './services/services';
import {
  PipeDatetime, PipeSafeHtml, PipeReadMore, SortPipe
} from './components/shares/pipes/pipes';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { NgOtpInputModule } from 'ng-otp-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { appDragDropeDirective } from './services/directive/drag-and-drop';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InterceptorLoaderService } from './services/InterceptorLoaderService.service';

export const BOOSTRAP_CLASSES: any[] = [AppComponent];

const COMPONENTS: any[] = [
  // Bootstrap Classes
  AppComponent,
  // Pages
  MainPage,
  LoginPage,
  // -------------
  SettingPage,
  ManageUsersPage,
  ConfigPage,
  // -------------
  RepairPage,
  AfterSalePage,
  ContentPage,
  // -------------
  DataBasePage,
  ProjectPage,
  HouseRegistrationPage,
  ResidentsPage,
  RepairOutletPage,
  RepairStatusPage,
  ReportPage,
  ReportRepairPage,
  ReportCustomerServicePage,
  CustomerOutletPage,
  CustomerServiceStatusPage,
  CustomerServiceItemPage,
  CustomerServiceAssessmentPage,
  RepairTypePage,
  AssessmentPage,
  DocumentManualPage,
  // -------------
  MessagePage,
  EmailMessagePage,
  SmsMessagePage,
  LogsMessagePage,
  ReadMore,
  // -------------
  NotificationPage,
  DownPaymentPage,
  ChatPage,
  ErrorPage,
  Test,

  // shares
  Header,
  CardNotification,
  CardListMenu,
  CardDetail,
  CardChat,
  CardUploadImage,
  CardGallery,
  CustomTable,
  AutocompleteChips,
  DialogComplaint,
  DialogAlert,
  SliderContent,
  DialogDetail,
  DialogDatepicker,
  DialogTable,
  SliderDetailRepair,
  SliderAfterSale,
  SliderNotificationAfterSale,
  SliderProject,
  SliderHouseRegistration,
  SliderResidents,
  SliderRepairStatus,
  SliderRepairType,
  SliderManageUsers,
  SliderConfig,
  SliderDownPayment,
  SliderAssessment,
  SliderMessage,
  SliderCustomerServiceStatus,
  SliderCustomerServiceItem,
  SliderCustomerServiceAssessment,
  SliderDocumentManual,
  DialogUpload,
  notification
];

const PIPE_CLASSES: any[] = [
  PipeDatetime,
  PipeSafeHtml,
  SortPipe,
  PipeReadMore
];

const DIRECTIVE_CLASSES: any[] = [
  appDragDropeDirective
];

const SERVICE_CLASSES: any[] = [
  AuthGuard,
  AuthenManager,
  ObservableManager,
  SocketIoManager,
  DatePipe,
  // Facade
  ActivateFacade,
  ActivateInfoAdminFacade,
  ChatFacade,
  ChatRoomFacade,
  NotificationFacade,
  RepairStatusAdminFacade,
  RepairTypeAdminFacade,
  RepairTypeFacade,
  RealesateUnitFacade,
  RealesateUnitCustomerServiceFacade,
  ReportRepairAdminFacade,
  ReportCustomerServiceAdminFacade,
  PostFacade,
  PostAdminFacade,
  PageFacade,
  PageAdminFacade,
  ChatRoomAdminFacade,
  NoteFacade,
  NoteAdminFacade,
  RepairAdminFacade,
  RepairFacade,
  RealesateUnitRepairFacade,
  RepairStatusFacade,
  UserAdminFacade,
  CustomerServiceFacade,
  ConfigAdminFacade,
  CustomerServiceAdminFacade,
  CustomerServiceStatusAdminFacade,
  CustomerServiceStatusFacade,
  CustomerServiceItemAdminFacade,
  CustomerServiceItemFacade,
  ReadChatFacade,
  QuestionnaireFacade,
  DownPaymentFacade,
  RealesateUnitInstallmentFacade,
  CheckStatusFacade,
  UploadFileFacade,
  EmailMessageFacade,
  SmsMessageFacade,
  LogsMessageFacade,
  DocumentManualFacade,
  // Service
  TypeFilterListService,
  NotificationService,
  CheckTokenService,
  DownloadFileService,
  { provide: MAT_DATE_LOCALE, useValue: 'th-TH' },
  { provide: LOCALE_ID, useValue: "th-TH" },
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorLoaderService, multi: true },
];

initializeApp(environment.firebase);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectFilterModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatRippleModule,
    MatNativeDateModule,
    ClipboardModule,
    A11yModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatTreeModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ImageCropperModule,
    NgOtpInputModule,
    InfiniteScrollModule,
    NgxEditorModule,
    CommonModule,
  ],
  providers: [SERVICE_CLASSES, PIPE_CLASSES],
  bootstrap: BOOSTRAP_CLASSES,
  declarations: [COMPONENTS, PIPE_CLASSES, DIRECTIVE_CLASSES]
})
export class AppModule {

}
