// import { API_ROOT_PATH } from '../../constants/constants';
import { DataUtil, GenerateUUIDUtil } from '../../utils/utils';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AuthenManager } from '../services';
import { CookieUtil } from '../../utils/CookieUtil';
import { empty } from '../../type/type';
import { environment } from '../../../environments/environment';

const UUID: string = 'UUID'
export abstract class AbstractBaseFacade<ERROR, RETURN_ERROR> {
    public static timeout = 20;
    protected http: HttpClient;
    protected authMgr: AuthenManager;
    protected routPath: string = environment.apiBaseURL;
    protected subPath: string = '';

    constructor(http: HttpClient, authMgr: AuthenManager, subPath: string, timeout?: number) {
        this.http = http;
        this.authMgr = authMgr;
        if (!this._validateSubPath(subPath)) {
            throw new Error('Invalid sub path!!');
        }
        if (!DataUtil.isEmpty(timeout)) {
            timeout = timeout;
        }
        if (DataUtil.isEmpty(this.routPath)) {
            throw new Error('Missing routPath!!');
        }
    }

    protected abstract handleApiError(error: HttpErrorResponse): RETURN_ERROR;
    protected abstract handleAnyError(error: any): RETURN_ERROR;
    public async request<T>(config: HttpRequest<T>): Promise<T | any> {
        return new Promise((resolve, reject) => {
            this.http.request<any>(config).toPromise().then((response: any) => {
                resolve(response.body);
            }).catch((error: any) => {
                if (error instanceof HttpErrorResponse) {
                    this.handleApiError(error);
                } else {
                    this.handleAnyError(error);
                }
                reject(error);
            });
        });
    }

    public getDefaultHeader(): HttpHeaders {

        //getCookie UUID
        let uuid: any = CookieUtil.getCookie(UUID);

        if (uuid === null || uuid === undefined) {
            uuid = GenerateUUIDUtil.getUUID();
            CookieUtil.setCookie(UUID, uuid);
        }

        const headers = new HttpHeaders().append('authorization', 'Bearer ' + this.authMgr.getUserToken());

        return headers;
    }

    public getHttp(): HttpClient {
        return this.http;
    }

    public getBaseURL(): string {
        return this.routPath;
    }

    public getUserToken(): string | empty {
        return this.authMgr.getUserToken();
    }

    private _validateSubPath(subPath: string): boolean {

        if (DataUtil.isEmpty(subPath)) {
            return false;
        }

        if (subPath.length === 0) {
            return false;
        }

        if (subPath.slice(-1) !== '/') {
            return false;
        }

        this.subPath = subPath;
        return true;
    }
}
