<div class="card-upload-image group" [ngClass]="class">
    <ng-container *ngIf="image && image !== ''  && image.length > 0; else elseTemplate">
        <div class="relative">
            <img [src]="image" alt="" class="w-full" *ngIf="!checkTypeof(image)">
            <div class="relative grid grid-cols-3 gap-2" *ngIf="checkTypeof(image)">
                <ng-container *ngFor="let item of image; let i = index">
                    <div class="relative">
                        <mat-icon
                            class="absolute top-1 right-1 z-[1] bg-white rounded-full shadow-md text-sm flex items-center justify-center cursor-pointer"
                            *ngIf="isDelete" (click)="isDelete ? clickEventDelete(i , item) : ''">
                            close
                        </mat-icon>
                        <div matRipple>
                            <img [src]="item" alt=""
                                class="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                                (click)="clickEventImage(i , item)">
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="absolute left-0 bottom-0 right-0 p-2 z-[1] flex gap-2 items-center justify-center bg-black/10 group-hover:bg-black/20"
                [ngClass]="{'top-0 flex-col': !isDelete , 'bg-black/20': isDelete}" *ngIf="isAction">
                <ng-content></ng-content>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div matRipple
            class="group relative flex items-center justify-center border rounded-md cursor-pointer overflow-hidden select-none h-80 bg-gray-100 hover:bg-gray-200 flex-col"
            (click)="clickEvent($event)">
            <img src="../../../../assets/images/image-gallery.svg" alt="" class="w-[90px] h-[90px]">
            <span class="text-lg font-medium">
                อัพโหลดรูปภาพ
            </span>
        </div>
    </ng-template>
    <div class="mt-0.5 mb-2 text-right px-2">
        <span class="text-sm font-normal">ขนาดสูงสุดของไฟล์ JPG คือ 4 MB.</span>
    </div>
</div>