import { Component, OnInit } from "@angular/core";

const PAGE_NAME: string = 'repair';

@Component({
  selector: 'repair-outlet-page',
  templateUrl: './RepairOutletPage.component.html'
})

export class RepairOutletPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;

  constructor() {

  }

  public ngOnInit(): void {

  }
}
