<button mat-button #button class="card-list-menu relative min-w-0 flex items-center select-none flex-none"
    [ngClass]="{'justify-center px-0 rounded-full': !isAciton , 'active w-full mx-auto' : isAciton ,'bg-[color:var(--blue-choose)]' : isActive && isTabActive}"
    [matTooltip]="tooltip" [matTooltipPosition]="'after'" [matTooltipDisabled]="isAciton ? true : false"
    [matTooltipClass]="'text-sm'" (click)="clickEvent($event)">
    <div class="flex items-center">
        <div class="absolute top-0 left-0 bottom-0 w-1.5 bg-[color:var(--blue-light)]" *ngIf="isActive && isTabActive">
        </div>
        <div class="flex items-center flex-1 overflow-hidden">
            <div class="flex items-center justify-center">
                <img *ngIf="!isSubMenu" [src]="image" alt="" class="shrink-0 w-6 h-6 min-w-6"
                    [ngClass]="isActive ? classAcitonImage : ''">
            </div>
            <span class="ml-3 truncate text-base font-medium" *ngIf="isAciton" [ngClass]="{'px-6': isSubMenu}">
                {{content}}
            </span>
        </div>
        <ng-container *ngIf="isCount">
            <span class="menu-notification bg-[color:var(--red-cdg)] rounded-full text-white text-base px-2.5 flex-none"
                *ngIf="isAciton && count && count !== 0">
                <ng-container *ngIf="count > 99; else elseTemplateCount">
                    99+
                </ng-container>
                <ng-template #elseTemplateCount>
                    {{count}}
                </ng-template>
            </span>
        </ng-container>
    </div>
    <ng-container *ngIf="isCount">
        <span class="menu-notification absolute top-2 right-1 z-10 bg-[color:var(--red-cdg)] w-2 h-2 rounded-full"
            *ngIf="!isAciton && count && count !== 0"></span>
    </ng-container>
</button>