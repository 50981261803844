<div class="card-detail">
    <div class="flex items-center min-h-[80px] border-b p-4">
        <div class="flex-1">
            <span class="text-lg font-bold">
                {{header}}
            </span>
        </div>
        <button mat-icon-button class="group ml-3 flex-none icon-ripper-center" *ngIf="isAciton" (click)="clickShow()">
            <mat-icon class="opacity-50 group-hover:opacity-100">{{isShowActive ? 'expand_less' : 'expand_more'}}
            </mat-icon>
        </button>
    </div>
    <div #detail class="detail-content overflow-y-auto" [ngClass]="{'max-h-0': !isShowActive}"
        [ngStyle]="{'max-height': maxHeight ? maxHeight + 'px' : ''}">
        <ng-content select="[body]"></ng-content>
    </div>
    <div class="grid min-h-[45px] border-t select-none" *ngIf="isFooter">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>