<div class="custom-table flex-1 flex flex-col overflow-hidden relative">
    <div #wrapper class="flex-1 overflow-auto">
        <div class="absolute top-0 left-0 bottom-0 right-0 z-10 flex items-center justify-center bg-stone-900/[.03]"
            *ngIf="isLoadingResults">
            <mat-spinner></mat-spinner>
        </div>
        <table mat-table [dataSource]="dataSource" matSort class="w-full">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <ng-container *ngIf="isCheckBox">
                    <mat-header-cell *matHeaderCellDef class="px-6 w-16 min-w-16 flex-none">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                            *ngIf="isCheckBoxAll"></mat-checkbox>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row" class="px-6 w-16 min-w-16 flex-none">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)"></mat-checkbox>
                    </mat-cell>
                </ng-container>
            </ng-container>

            <!-- Column -->
            <ng-container *ngFor="let column of columns">
                <ng-container matColumnDef="{{column.columnDef}}">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        class="min-w-[160px] w-[160px] px-8 text-ellipsis overflow-hidden"
                        [ngClass]="{'min-w-[220px]' : column.tag !== 'image' , 'flex-none' : column.tag === 'image'}"
                        [ngStyle]="{'justify-content': column.align , 'text-align': column.align , 'display' : column.display === false ? 'none' : ''}">
                        <span class="text-base font-bold text-black line-clamp-1" [matTooltip]="column.header">
                            {{ column.header }}
                        </span>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row; let i = index" class="min-w-[160px] w-[160px] px-8 py-4"
                        [ngClass]="{'min-w-[220px]' : column.tag !== 'image' , 'flex-none' : column.tag === 'image'}"
                        [ngStyle]="{'justify-content': column.align , 'text-align': column.align , 'color' : column.color , 'display' : column.display === false ? 'none' : ''}">
                        <span class="text-base font-normal break-all"
                            [ngClass]="column.align === 'center' ? 'mr-[calc(12px+6px)]' : ''">
                            <ng-container *ngIf="column.tag === 'image'">
                                <ng-container
                                    *ngIf="column.cell(row) !== '' && column.cell(row) !== null && column.cell(row) !== undefined; else elseTemplateImage">
                                    <img [src]="column.cell(row)" class="h-[56px] w-[56px] object-cover rounded">
                                </ng-container>
                                <ng-template #elseTemplateImage>
                                    <span
                                        class="material-icons-outlined h-[56px] w-[56px] text-[56px] flex items-center justify-center rounded">
                                        image
                                    </span>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="column.tag === 'text-color'">
                                <div class="flex items-center">
                                    <div class="w-4 h-4 mr-2 rounded-full"
                                        [ngStyle]="{'background-color': column.cell(row).color}"></div>
                                    <span [ngClass]="column.color ? 'opacity-100' : 'opacity-50'">
                                        {{column.cell(row).text}}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="column.tag === 'text'">
                                <span [ngClass]="column.color ? 'opacity-100' : 'opacity-50'">
                                    {{column.cell(row)}}
                                </span>
                            </ng-container>
                        </span>
                    </mat-cell>
                </ng-container>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
            <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" (click)="clickEvent(i,tag ,row)"
                class="cursor-pointer hover:bg-[color:var(--blue-choose)]">
            </mat-row>
            <div class="absolute top-0 left-0 bottom-[72px] right-0 z-10 mt-4 flex items-center justify-center p-6 text-lg font-normal"
                *matNoDataRow>
                {{ textNoData }}
            </div>
        </table>
    </div>
    <mat-paginator class="border-t" [showFirstLastButtons]="isShowFirstLastButtons" [hidePageSize]="isHidePageSize"
        [pageSizeOptions]="pagination" [pageSize]="pageSize" [length]="length" (page)="clickPaginator($event)">
    </mat-paginator>
</div>