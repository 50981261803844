import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ObservableManager } from "../../../services/ObservableManager.service";

const PAGE_NAME: string = '404';

@Component({
  selector: 'error-page',
  templateUrl: './ErrorPage.component.html'
})

export class ErrorPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  private destroy = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {

      });
  }

  public ngOnInit(): void {

  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}