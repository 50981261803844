import { BaseModel } from "./BaseModel";

export class ActivateInfoModel extends BaseModel {
    public prefix?: string;
    public absLeadId?: string;
    public absUnitId?: string;
    public activateInfo?: any;
    public address?: string;
    public createdDate?: string;
    public guaranteeDate?: Date;
    public modelName?: string;
    public name?: string;
    public no?: string;
    public page?: any;
}