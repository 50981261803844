import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ChangePasswordRequest } from "../../../request/ChangePasswordRequest";
import { LoginRequest } from "../../../request/LoginRequest";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { environment } from "../../../../environments/environment";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const PAGE_NAME: string = 'login';

@Component({
  selector: 'login-page',
  templateUrl: './LoginPage.component.html'
})

export class LoginPage implements OnInit {
  public static readonly PAGE_NAME: string = PAGE_NAME;
  public isHidePass: boolean = true;
  public isHideConPass: boolean = true;
  public isMemorizePassword: boolean;
  public workMode: 'Login' | 'Forgot' | 'Change' | 'Succ' = 'Login';
  public groupLogin!: UntypedFormGroup;
  public isSubmitted: boolean = false;

  //TokenFirebase !!!!!
  public currentTokenFirebase: any;

  @ViewChild('ngOtpInput', { static: false })
  public ngOtpInput: any;
  public otpInputConfig: any = {
    length: 6,
    allowNumbersOnly: false,
    letterCase: 'Upper'
  };
  private destroy = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private observManager: ObservableManager,
    private formBuilder: UntypedFormBuilder,
    private authenManager: AuthenManager
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {
        this.requestPermission();
      });
  }

  public ngOnInit(): void {
    this.validatorsLogin();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public clickMemorizePassword() {
    this.isMemorizePassword = !this.isMemorizePassword;
  }

  public clickMode(item: any) {
    this.workMode = item;
    this.validatorsLogin();
    this.isHidePass = true;
    this.isHideConPass = true;
    this.isSubmitted = false;
  }

  public validatorsLogin() {
    this.groupLogin = this.formBuilder.group({
      email: ['', [Validators.email]],
      password: ['', [Validators.minLength(6)]],
      conpassword: ['', [Validators.minLength(6)]],
      otp: [''],
      message: ['']
    });
  }

  get f() {
    return this.groupLogin.controls;
  }

  public resetMessage() {
    this.groupLogin.get('message')?.reset();
  }

  public clickLogin() {
    this.isSubmitted = true;
    if (this.groupLogin.get('email')?.value && this.groupLogin.get('password')?.value) {
      this.authenManager.login(new LoginRequest((this.groupLogin.get('email')?.value).trim(), this.groupLogin.get('password')?.value), this.isMemorizePassword, this.currentTokenFirebase).then((res: any) => {
        if (res) {
          this.isSubmitted = false;
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitted = false;
          if (err.status === 400) {
            this.groupLogin.get('message')?.setValue(err.error.message);
          } else {
            console.log(err);
          }
        }
      });
    }
  }

  public clickForgotPassword() {
    this.isSubmitted = true;
    if (this.groupLogin.get('email')?.value) {
      this.authenManager.forgotPassword((this.groupLogin.get('email')?.value).trim()).then((res: any) => {
        if (res) {
          this.workMode = 'Change';
          this.isSubmitted = false;
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitted = false;
          if (err.status === 400) {
            this.groupLogin.get('message')?.setValue(err.error.message);
          } else {
            console.log(err);
          }
        }
      });
    }
  }

  public onOtpChange(event: any) {
    this.groupLogin.get('otp')?.setValue(event);
  }

  public clickChangePassword() {
    this.isSubmitted = true;
    this.ngOtpInput.otpForm.disable();
    if (this.groupLogin.get('password')?.value && this.groupLogin.get('conpassword')?.value && this.groupLogin.get('otp')?.value.length === 6 && (this.groupLogin.get('password')?.value === this.groupLogin.get('conpassword')?.value)) {
      this.authenManager.changePassword(new ChangePasswordRequest(this.groupLogin.get('otp')?.value, this.groupLogin.get('email')?.value, this.groupLogin.get('password')?.value)).then((res: any) => {
        if (res) {
          this.workMode = 'Succ';
          this.isSubmitted = false;
          this.groupLogin.get('message')?.setValue(res.message);
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitted = false;
          this.ngOtpInput.otpForm.enable();
          if (err.status === 400) {
            this.groupLogin.get('message')?.setValue(err.error.message);
          } else {
            console.log(err);
          }
        }
      });
    }
  }


  // FIREBASE NOTI
  private async requestPermission() {
    if (await isSupported()) {
      const messaging = getMessaging();
      getToken(messaging,
        { vapidKey: environment.firebase.vapidKey }).then(
          (currentToken) => {
            this.currentTokenFirebase = currentToken;
          }).catch((err) => {
            console.log(err);
          });
    }
  }
}