import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { RealesateUnitFacade } from "../../../services/facade/ReslesateUnitFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { ActivateInfoAdminFacade } from "../../../services/facade/ActivateInfoAdminFacade.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-residents',
    templateUrl: './SliderResidents.component.html',
})
export class SliderResidents implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public groupResidents: UntypedFormGroup;
    public isSubmitted: boolean = false;
    public activeTab: string = '';

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private realesateUnitFacade: RealesateUnitFacade,
        private activateInfoAdminFacade: ActivateInfoAdminFacade,
        private formBuilder: UntypedFormBuilder,
        private checkTokenService: CheckTokenService,
    ) {
        this.formResidents();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                this.formResidents();
                this.getResidents(this.dataDetail.value);
                this.activeTab = this.dataDetail?.value.absUnitId;
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public clickCancel(): void {
        if (
            this.groupResidents.get('absLeadId')?.value !== this.dataJob.absLeadId ||
            this.groupResidents.get('no')?.value !== this.dataJob.no ||
            this.groupResidents.get('absUnitId')?.value !== this.dataJob.absUnitId ||
            this.groupResidents.get('fullName')?.value !== this.dataJob.activateInfo.fullName ||
            this.groupResidents.get('citizenId')?.value !== this.dataJob.activateInfo.citizenId ||
            this.groupResidents.get('mobileNo')?.value !== (this.dataJob.activateInfo.mobileNo ? this.dataJob.activateInfo.mobileNo.replace('-', '') : this.dataJob.activateInfo.mobileNo)
        ) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ยกเลิกการเปลี่ยนแปลง'
            }).then((res: any) => {
                if (res) {
                    this.getResidents(this.dataDetail.value);
                    this.isSubmitted = false;
                }
            });

            return;
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        this.groupResidents.reset();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private getResidents(data: any) {
        this.dataJob = data;
        this.groupResidents.get('absLeadId')?.setValue(this.dataJob.absLeadId);
        this.groupResidents.get('no')?.setValue(this.dataJob.no);
        this.groupResidents.get('absUnitId')?.setValue(this.dataJob.absUnitId);
        this.groupResidents.get('fullName')?.setValue(this.dataJob.activateInfo.fullName);
        this.groupResidents.get('citizenId')?.setValue(this.dataJob.activateInfo.citizenId);
        this.groupResidents.get('mobileNo')?.setValue(this.dataJob.activateInfo.mobileNo ? this.dataJob.activateInfo.mobileNo.replace('-', '') : this.dataJob.activateInfo.mobileNo);
        this.groupResidents.get('absLeadId')?.disable();
        this.groupResidents.get('no')?.disable();
        this.groupResidents.get('absUnitId')?.disable();
        this.getRealesateUnit(data);
    }

    private formResidents() {
        this.groupResidents = this.formBuilder.group({
            absLeadId: [],
            no: [],
            absUnitId: [],
            fullName: [],
            citizenId: [],
            mobileNo: [],
            items: this.formBuilder.array([])
        });
    }

    get residentsCon(): any {
        return (this.groupResidents?.get('items') as UntypedFormArray)?.controls;
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (
            this.groupResidents.get('absLeadId')?.value !== this.dataJob.absLeadId ||
            this.groupResidents.get('no')?.value !== this.dataJob.no ||
            this.groupResidents.get('absUnitId')?.value !== this.dataJob.absUnitId ||
            this.groupResidents.get('fullName')?.value !== this.dataJob.activateInfo.fullName ||
            this.groupResidents.get('citizenId')?.value !== this.dataJob.activateInfo.citizenId ||
            this.groupResidents.get('mobileNo')?.value !== (this.dataJob.activateInfo.mobileNo ? this.dataJob.activateInfo.mobileNo.replace('-', '') : this.dataJob.activateInfo.mobileNo)
        ) {
            this.isSubmitted = true;
            this.activateInfoAdminFacade.updateData({
                activateInfoId: this.dataJob.activateInfo.id,
                fullName: this.groupResidents.get('fullName')?.value,
                citizenId: this.groupResidents.get('citizenId')?.value,
                mobileNo: this.groupResidents.get('mobileNo')?.value,
            }).then((res: any) => {
                if (res) {
                    this.dataJob.activateInfo = res.data.activateInfo;
                    this.getResidents(this.dataJob);
                    this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'แก้ไขสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                }
            }).catch((err) => {
                if (err) {
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: err.error.message,
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                        }
                    });
                }
            });
        }
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        this.authenManager.clickDialogAlert({
            header: '',
            content: 'ต้องการลบ "' + this.dataDetail.value.absUnitId + '"',
        }).then((res: any) => {
            if (res) {
                this.realesateUnitFacade.deleteData(this.dataDetail.value.id).then((res: any) => {
                    if (res) {
                        this.getRealesateUnit(this.dataJob);
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: 'ลบสำเร็จ',
                            cancel: false
                        }).then((res: any) => {
                            if (res) {

                            }
                        });
                    }
                }).catch((err) => {
                    if (err) {
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: err.error.message,
                            cancel: false
                        }).then((res: any) => {
                            if (res) {
                                this.isSubmitted = false;
                            }
                        });
                    }
                });
            }
        });
    }

    private getRealesateUnit(data: any) {
        this.realesateUnitFacade.getData(data.id).then((res: any) => {
            if (res) {
                this.dataJob['userItems'] = res.data;
                const arrayForm = this.groupResidents.get("items") as UntypedFormArray;
                if (this.dataJob['userItems'].length > 0) {
                    this.groupResidents.get('fullName')?.disable();
                    this.groupResidents.get('citizenId')?.disable();
                    this.groupResidents.get('mobileNo')?.disable();
                } else {
                    this.groupResidents.get('fullName')?.enable();
                    this.groupResidents.get('citizenId')?.enable();
                    this.groupResidents.get('mobileNo')?.enable();
                }

                this.dataJob['userItems'].forEach((item: any) => {
                    arrayForm.push(this.formBuilder.group({
                        id: [item.id || item._id],
                        fullName: [(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' ' +
                            item.user.lastName : '') : '') || (item.user.lastName ? (item.user.firstName ?
                                item.user.firstName + ' ' : '') + item.user.lastName : '') ||
                            (item.user.firstName
                                && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName :
                                (item.user.displayName ? item.user.displayName : '-'))],
                        username: [item && item.user.username ? item.user.username : '-'],
                        password: ['●●●●●●●●●●'],
                        mobileNo: [item && item.user.mobileNo ? item.user.mobileNo : '-']
                    }));
                });

                arrayForm.disable();
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    public clickTab(event: string) {
        this.activeTab = event;
    }
}

