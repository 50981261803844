<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper
            class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose()">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                    <div class="flex-1 flex flex-col">
                        <span class="text-lg font-bold flex items-center">
                            <img class="flex-none w-6 h-6 min-w-6 mr-2"
                                [src]="'../../../../assets/images/app/project.svg'" alt="">
                            <span class="flex-none line-clamp-1">
                                {{dataDetail && dataDetail.value.absUnitId ? dataDetail &&
                                dataDetail.value.absUnitId :
                                'หัวเรื่อง'}}
                            </span>
                            <ng-container *ngIf="dataJob?.userItems && dataJob?.userItems.length >= 0">
                                <ng-container *ngFor="let item of dataJob?.userItems; let i = index">
                                    <ng-container *ngIf="item.id === activeTab">
                                        <span class="material-icons-outlined flex-none mx-3">
                                            chevron_right
                                        </span>
                                        <img class="flex-none w-6 h-6 min-w-6 mr-2"
                                            [src]="item.roles === 'OWNER' ? '../../../../assets/images/app/owner.svg' : '../../../../assets/images/app/residents.svg'"
                                            alt="">
                                        <span class="flex-1 line-clamp-1">
                                            {{(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' '
                                            +
                                            item.user.lastName : '') : '') || (item.user.lastName ?
                                            (item.user.firstName
                                            ?
                                            item.user.firstName + ' ' : '') + item.user.lastName : '') ||
                                            (item.user.firstName
                                            && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName :
                                            (item.user.displayName ? item.user.displayName : '-'))}}
                                        </span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </span>
                    </div>
                </div>
                <div class="flex-none flex items-center">
                    <ng-container *ngIf="dataJob?.userItems && dataJob?.userItems.length > 0; else elseTemplate">
                        <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                            class="flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
                            [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #beforeMenu="matMenu" xPosition="before">
                            <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
                                <mat-icon>delete</mat-icon>
                                <span>ลบข้อมูล</span>
                            </button>
                        </mat-menu>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="grid grid-cols-2 gap-2">
                            <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                                คืนค่า
                            </button>
                            <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                                <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">บันทึก</ng-container>
                                <ng-container *ngIf="dataDetail && dataDetail.mode === 'create'">สร้าง</ng-container>
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="flex-1 flex items-start">
                <div
                    class="flex-none sticky top-[80px] z-20 h-[calc(100vh-80px)] w-[260px] lg:w-[312px] bg-[color:var(--bg-F9)] overflow-y-auto">
                    <card-list-menu [image]="'../../../../assets/images/app/project.svg'" [isTabActive]="true"
                        [isActive]="true" [classAcitonImage]="'image-filter-blue'"
                        [content]="dataDetail && dataDetail?.value?.absUnitId" [count]="1"
                        [tooltip]="dataDetail?.value?.absUnitId" [isCount]="false" [isAciton]="true"
                        (event)="clickTab(dataDetail && dataDetail?.value?.absUnitId)"
                        [class.active-Tab]="dataDetail && dataDetail?.value?.absUnitId !== activeTab">
                    </card-list-menu>
                    <ng-container *ngIf="dataJob?.userItems && dataJob?.userItems.length > 0">
                        <ng-container *ngFor="let item of dataJob?.userItems; let i = index">
                            <card-list-menu class="list-residents"
                                [image]="item.roles === 'OWNER' ? '../../../../assets/images/app/owner.svg' : '../../../../assets/images/app/residents.svg'"
                                [isTabActive]="item.id === activeTab" [isActive]="item.id === activeTab"
                                [classAcitonImage]="'image-filter-blue'" [content]="(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' ' +
                                item.user.lastName : '') : '') || (item.user.lastName ? (item.user.firstName ?
                                item.user.firstName + ' ' : '') + item.user.lastName : '') ||
                                (item.user.firstName
                                && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName :
                                (item.user.displayName ? item.user.displayName : '-'))" [count]="1" [tooltip]="(item.user.firstName ? item.user.firstName + (item.user.lastName ? ' ' +
                                item.user.lastName : '') : '') || (item.user.lastName ? (item.user.firstName ?
                                item.user.firstName + ' ' : '') + item.user.lastName : '') ||
                                (item.user.firstName
                                && item.user.lastName ? item.user.firstName + ' ' + item.user.lastName :
                                (item.user.displayName ? item.user.displayName : '-'))" [isCount]="false"
                                [isAciton]="true" (event)="clickTab(item.id || item._id)">
                            </card-list-menu>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
                    <div class="w-full md:max-w-[500px] xl:max-w-[560px] grid grid-cols-1 gap-6">
                        <ng-container [formGroup]="groupResidents" [ngSwitch]="activeTab">
                            <ng-container *ngSwitchCase="groupResidents?.get('absUnitId')?.value">
                                <div class="card-detail">
                                    <div class="flex items-center min-h-[80px] border-b p-4">
                                        <div class="flex-1 flex flex-col">
                                            <span class="text-lg font-bold">
                                                ข้อมูลบ้าน
                                            </span>
                                            <span class="text-sm font-normal opacity-50 mt-1">
                                                ข้อมูลทั่วไปของบ้านในโครงการ
                                            </span>
                                        </div>
                                    </div>
                                    <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                        <div>
                                            <span class="text-base font-bold opacity-50">รหัสติดตามการขาย</span>
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="absLeadId" [class.border-error]="''">
                                        </div>
                                        <div>
                                            <span class="text-base font-bold opacity-50">บ้านเลขที่</span>
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="no" [class.border-error]="''">
                                        </div>
                                        <div>
                                            <span class="text-base font-bold opacity-50">รหัสยูนิต</span>
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="absUnitId" [class.border-error]="''">
                                        </div>
                                    </div>
                                </div>
                                <div class="card-detail">
                                    <div class="flex items-center min-h-[80px] border-b p-4">
                                        <div class="flex-1 flex flex-col">
                                            <span class="text-lg font-bold">
                                                ข้อมูลยืนยัน
                                            </span>
                                            <span class="text-sm font-normal opacity-50 mt-1">
                                                ข้อมูลสำหรับการเปิดใช้งานแอพพลิเคชั่นของบ้าน
                                            </span>
                                        </div>
                                    </div>
                                    <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                        <div>
                                            <span class="text-base font-bold opacity-50">ชื่อเจ้าบ้าน
                                                (ชื่อ-นามสกุล)</span>
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="fullName" [class.border-error]="''">
                                        </div>
                                        <div>
                                            <span class="text-base font-bold opacity-50">รหัสประจำตัวประชาชน</span>
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="citizenId" [class.border-error]="''">
                                        </div>
                                        <div>
                                            <span class="text-base font-bold opacity-50">หมายเลขโทรศัพท์</span>
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="mobileNo" [class.border-error]="''">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container formArrayName="items">
                                <ng-container *ngFor="let item of residentsCon; let i = index">
                                    <div class=" card-detail" [formGroupName]="i"
                                        *ngSwitchCase="item?.get('id')?.value">
                                        <div class="flex items-center min-h-[80px] border-b p-4">
                                            <div class="flex-1 flex flex-col">
                                                <span class="text-lg font-bold">
                                                    ข้อมูลบัญชีผู้ใช้
                                                </span>
                                                <span class="text-sm font-normal opacity-50 mt-1">
                                                    ข้อมูลการเชื่อต่อกับ Third party
                                                </span>
                                            </div>
                                        </div>
                                        <div class="px-4 py-6 grid grid-cols-1 gap-4">
                                            <div>
                                                <span class="text-base font-bold opacity-50">ชื่อผู้ใช้</span>
                                                <input class="input-custom" type="text" placeholder=""
                                                    autocomplete="off" formControlName="fullName"
                                                    [class.border-error]="''">
                                            </div>
                                            <div>
                                                <span class="text-base font-bold opacity-50">ยูสเซอร์เนม</span>
                                                <input class="input-custom" type="text" placeholder=""
                                                    autocomplete="off" formControlName="username"
                                                    [class.border-error]="''">
                                            </div>
                                            <div>
                                                <span class="text-base font-bold opacity-50">รหัสผ่าน</span>
                                                <input class="input-custom" type="password" placeholder=""
                                                    autocomplete="off" formControlName="password"
                                                    [class.border-error]="''">
                                            </div>
                                            <div>
                                                <span class="text-base font-bold opacity-50">หมายเลขโทรศัพท์</span>
                                                <input class="input-custom" type="text" placeholder=""
                                                    autocomplete="off" formControlName="mobileNo"
                                                    [class.border-error]="''">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>