import { HttpClient } from '@angular/common/http';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

export class BaseFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/', 10);
    }
    // public login = (requst: LoginRequest) => this.post<MethodResponseInf<LoginResponse>, login>('login', { mode: requst.mode }, null, requst);
    // public register = (requst: RegisterRequest) => this.post<MethodResponseInf<any>>('register', null, null, requst);
    // public forgotPassword = (email: string) => this.post<MethodResponseInf<any>>('forgot', {
    //     'Access-Control-Allow-Origin': 'http://localhost:4300',
    //     'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    //     // 'Access-Control-Request-Method': 'GET,PUT,OPTIONS,POST',
    //     // 'mode': request.mode
    // }, null, { username: email });

}