import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { RealesateUnitRequest, SearchFilter } from '../../request/request';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class RealesateUnitFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/realesate_unit/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);

    public searchData = (request?: SearchFilter) => this.post<MethodResponseInf<any>>(`search`, this.getDefaultHeader(), null, request);
    // public createData = (request: ReslesateUnitModel) => this.post<MethodResponseInf<ReslesateUnitModel>>(``, this.getDefaultHeader(), null, request);
    // public updateData = (id: string, name: string) => this.put<MethodResponseInf<ReslesateUnitModel>>(`${(id)}`, this.getDefaultHeader(), null, { name });
    public searchDataRequest = (request?: RealesateUnitRequest) => this.post<MethodResponseInf<any>>(`search`, this.getDefaultHeader(), null, request);
    public getData = (id: string) => this.get<MethodResponseInf<any>>(`${(id)}/users`, this.getDefaultHeader(), null);
    public searchUserDataById = (id: string) => this.get<MethodResponseInf<any>>(`${(id)}/users`, this.getDefaultHeader(), null);
    public doweStatus = (id: string, repairId: string) => this.put<MethodResponseInf<any>>(`${(id) + '/repair/'}${(repairId) + '/status_cancel/'}`, this.getDefaultHeader(), null, null);
    public getInstallments = (id: string) => this.put<MethodResponseInf<any>>(`${(id) + '/down_payment/'}`, this.getDefaultHeader(), null, null);
    public deleteData = (id: string) => this.delete<MethodResponseInf<any>>(`${(id)}/user`, this.getDefaultHeader(), null);
}