import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import * as moment from 'moment';
import { RealesateUnitFacade } from "../../../services/facade/ReslesateUnitFacade.service";
import { PageFacade } from "../../../services/facade/PageFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { environment } from "../../../../environments/environment";
import { DialogUpload } from "../dialog/DialogUpload.component";
import { DialogComplaint } from "../dialog/DialogComplaint.component";
import { Router } from "@angular/router";
import { DialogDatepicker } from "../dialog/DialogDatepicker.component";
import { TableColumnModel } from "../../../models/TableModel";
import { RepairFacade } from "../../../services/facade/RepairFacade.service";
import { DialogDetail } from "../dialog/DialogDetail.component";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { CustomerServiceItemFacade } from "../../../services/facade/CustomerServiceItemFacade.service";
import { CustomerServiceAdminFacade } from "../../../services/facade/CustomerServiceAdminFacade.service";
import { CustomerServiceFacade } from "../../../services/facade/CustomerServiceFacade.service";
import { RealesateUnitCustomerServiceFacade } from "../../../services/facade/RealesateUnitCustomerServiceFacade.service";
import { CustomerServiceStatusAdminFacade } from "../../../services/facade/CustomerServiceStatusAdminFacade.service";
import { AuthenManager } from "../../../services/AuthenManager.service";
import { CUSTOMER_SERVICE_CATEGORY } from '../../../constants/General';

@Component({
    selector: 'slider-after-sale',
    templateUrl: './SliderAfterSale.component.html',
})
export class SliderAfterSale implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper: ElementRef;
    @ViewChild('content', { read: ElementRef, static: false })
    public content: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public groupCustomerService: UntypedFormGroup;
    public isSubmitted: boolean = false;
    public isDelete: boolean;

    // Page
    public dataPage: any[] = [];
    public filteredListPage: any[] = [];
    // Unit
    public realesateUnit: any[] = [];
    public filteredListUnit: any[] = [];
    private _unit!: any;
    // User
    public realesateUser: any[] = [];
    public filteredListUser: any[] = [];
    private _user!: any;
    // Type
    public customerItemType: any[] = [];
    public filteredListType: any[] = [];
    private _type!: any;
    private _category!: any;
    // HistoryList
    private statusHistoryList: any[] = [];

    public images: any[] = [];
    public isImageChange = false;
    private environment = environment;
    public customerServiceStatus: any;
    public completeStatusId: string;
    public dataCustomerServiceCategory = CUSTOMER_SERVICE_CATEGORY;
    public menuTab: any = [
        {
            text: 'แจ้งเรื่อง',
            icon: '../../../../assets/images/repair-information',
            tab: 'information'
        },
        {
            text: 'ตรวจสอบ',
            icon: '../../../../assets/images/examine',
            tab: 'examine'
        },
        {
            text: 'การประเมิน',
            icon: '../../../../assets/images/assessment',
            tab: 'assessment'
        }
    ];
    private scroll_position: number = 0;
    public isActiveMenu: boolean = true;
    public tabActive: 'information' | 'examine' | 'assessment' = 'information';
    public dataForeman: any[] = [];
    public environmentApi = environment;
    public countStar: any;
    public columns: TableColumnModel[];
    public data: any[] = [];
    public limitTable = 20;
    public limitPage = 21;
    public lengthDataTable: number;

    constructor(
        private router: Router,
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private realesateUnitCustomerServiceFacade: RealesateUnitCustomerServiceFacade,
        private pageFacade: PageFacade,
        private formBuilder: UntypedFormBuilder,
        private realesateUnitFacade: RealesateUnitFacade,
        private repairFacade: RepairFacade,
        private customerServiceFacade: CustomerServiceFacade,
        private customerServiceItemFacade: CustomerServiceItemFacade,
        private customerServiceAdminFacade: CustomerServiceAdminFacade,
        private customerServiceStatusAdminFacade: CustomerServiceStatusAdminFacade,
        private checkTokenService: CheckTokenService,
    ) {
        this.formCustomerService();
    }

    public ngOnInit(): void {
        this.observManager.subscribe(this.textSliderDetail, (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                if (this.dataDetail.mode === 'edit') {
                    this._getCustomer(this.dataDetail.value);
                    this._getForeman();
                    this._setCountStar();
                    this._getIdCompleteStatus(this.dataDetail.value.pageId);
                    if (!!this.dataDetail?.value?.coverURLs) {
                        for (const cover of this.dataDetail.value.coverURLs) {
                            this.images.push(this.image(cover));
                        }
                    }

                    setTimeout(() => {
                        this.groupCustomerService.get('name')?.disable();
                        this.groupCustomerService.get('realesateUnitName')?.disable();
                        this.groupCustomerService.get('page')?.disable();
                        this.groupCustomerService.get('unit')?.disable();
                        this.groupCustomerService.get('user')?.disable();
                        this.groupCustomerService.get('type')?.disable();
                        this.groupCustomerService.get('category')?.disable();
                        this.groupCustomerService.get('detail')?.disable();
                    }, 10);

                    this._unitCustomerServiceHistory();
                    this._getIdCompleteStatus(this.dataDetail.value.pageId);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.groupCustomerService.get('name')?.enable();
                        this.groupCustomerService.get('realesateUnitName')?.enable();
                        this.groupCustomerService.get('page')?.enable();
                        this.groupCustomerService.get('unit')?.enable();
                        this.groupCustomerService.get('user')?.enable();
                        this.groupCustomerService.get('type')?.enable();
                        this.groupCustomerService.get('category')?.enable();
                        this.groupCustomerService.get('detail')?.enable();
                    }, 10);
                }
            }
        });

        this.observManager.subscribe('dialogTable.click', async (res: any) => {
            if (res) {
                this.observManager.createSubject('dialogTable.paginator');
                this.observManager.publish('dialogTable.paginator', {
                    data: this.data
                });
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
        this.observManager.complete('dialogTable.click');
    }

    public ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.topRight = this.detailRight.nativeElement.offsetTop;
        // }, 100);
    }

    public scrollingTo(event: any) {
        let st = this.wrapper!.nativeElement!.scrollTop;
        if (st > this.scroll_position) {
            this.isActiveMenu = false;
        } else {
            this.isActiveMenu = true;
        } // else was horizontal scroll
        this.scroll_position = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
        this._getPage();
        this.tabActive = 'information';
        setTimeout(() => {
            if (this.detailRight && this.detailRight.nativeElement) {
                this.topRight = this.detailRight.nativeElement.offsetTop;
            }
        }, 100);
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    public formCustomerService() {
        this.groupCustomerService = this.formBuilder.group({
            name: [''],
            realesateUnitName: [''],
            page: ['', [Validators.required, Validators.minLength(1)]],
            unit: ['', [Validators.required, Validators.minLength(1)]],
            user: ['', [Validators.required, Validators.minLength(1)]],
            type: ['', [Validators.required, Validators.minLength(1)]],
            category: ['', [Validators.required, Validators.minLength(1)]],
            detail: ['', [Validators.required, Validators.minLength(3)]],
            repairDateTime: [''],
            walkinDateTime: [''],
            guaranteeDate: ['']
        });
    }

    private _getCustomer(data: any) {
        this.dataJob = data;
        this.groupCustomerService.get('repairDateTime')?.setValue(this.dataJob.repairDateTime ? moment(this.dataJob.repairDateTime).locale('th').add(543, 'year').format('DD/MM/YYYY เวลา HH:mm น.') : '-');
        this.groupCustomerService.get('walkinDateTime')?.setValue(this.dataJob.walkinDateTime ? moment(this.dataJob.walkinDateTime).locale('th').add(543, 'year').format('DD/MM/YYYY เวลา HH:mm น.') : '-');
        this.groupCustomerService.get('guaranteeDate')?.setValue(this.dataJob.guaranteeDate ? moment(this.dataJob.guaranteeDate).locale('th').add(543, 'year').format('DD/MM/YYYY เวลา HH:mm น.') : '-');
        this.groupCustomerService.get('name')?.setValue(this.dataJob.name);
        this.groupCustomerService.get('realesateUnitName')?.setValue(this.dataJob.realesateUnit.absUnitId ? this.dataJob.realesateUnit.absUnitId : 'ไม่พบประเภทการร้องเรียน');
        this.groupCustomerService.get('page')?.setValue(this.dataJob.page.prefix);
        this.groupCustomerService.get('unit')?.setValue(this.dataJob.realesateUnit.no);
        this.groupCustomerService.get('user')?.setValue(this.dataJob.user.displayName ? this.dataJob.user.displayName : (this.dataJob.user.firstName ? this.dataJob.user.firstName : (this.dataJob.user.email ? this.dataJob.user.email : this.dataJob.user.username)));
        this.groupCustomerService.get('detail')?.setValue(this.dataJob.description ? this.dataJob.description : '');
        this.groupCustomerService.get('type')?.setValue(this.dataJob.customerServiceItem ? this.dataJob.customerServiceItem.id : 'ไม่พบประเภทการร้องเรียน');
        this.groupCustomerService.get('category')?.setValue(this.dataJob.customerServiceItem.category ? this.dataJob.customerServiceItem.category : 'ไม่พบประเภท');
    }

    public clickCancel(): void {
        if (this.dataDetail.mode === 'edit') {
            if (
                this.groupCustomerService.get('name')?.value !== this.dataJob.name ||
                this.groupCustomerService.get('realesateUnitName')?.value !== this.dataJob.realesateUnit.absUnitId ||
                this.groupCustomerService.get('page')?.value !== this.dataJob.page.prefix ||
                this.groupCustomerService.get('unit')?.value !== this.dataJob.realesateUnit.no ||
                this.groupCustomerService.get('user')?.value !== (this.dataJob.user.displayName ? this.dataJob.user.displayName : (this.dataJob.user.firstName ? this.dataJob.user.firstName : (this.dataJob.user.email ? this.dataJob.user.email : this.dataJob.user.username))) ||
                this.groupCustomerService.get('type')?.value !== this.dataJob.customerServiceItem.name ||
                this.groupCustomerService.get('category')?.value !== this.dataJob.customerServiceItem.category ||
                this.groupCustomerService.get('detail')?.value !== this.dataJob.description ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง'
                }).then((res: any) => {
                    if (res) {
                        this._getCustomer(this.dataDetail.value);
                        this.isSubmitted = false;
                        this.isImageChange = false;
                        this.images = [];
                        this.setDefault();
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupCustomerService.get('name')?.value ||
                this.groupCustomerService.get('realesateUnitName')?.value ||
                this.groupCustomerService.get('page')?.value ||
                this.groupCustomerService.get('unit')?.value ||
                this.groupCustomerService.get('user')?.value ||
                this.groupCustomerService.get('type')?.value ||
                this.groupCustomerService.get('category')?.value ||
                this.groupCustomerService.get('detail')?.value ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง'
                }).then((res: any) => {
                    if (res) {
                        this.groupCustomerService.reset();
                        this.isSubmitted = false;
                        this.isImageChange = false;
                        this.images = [];
                        this.setDefault();
                    }
                });

                return;
            }
        }

        this.isSubmitted = false;
        this.isImageChange = false;
        this.setDefault();
    }

    public clickClose(): void {
        if (this.dataDetail.mode === 'edit') {
            this._getCustomer(this.dataDetail.value);
            if (
                this.groupCustomerService.get('name')?.value !== this.dataJob.name ||
                this.groupCustomerService.get('realesateUnitName')?.value !== this.dataJob.realesateUnit.absUnitId ||
                this.groupCustomerService.get('page')?.value !== this.dataJob.page.prefix ||
                this.groupCustomerService.get('unit')?.value !== this.dataJob.realesateUnit.no ||
                this.groupCustomerService.get('user')?.value !== (this.dataJob.user.displayName ? this.dataJob.user.displayName : (this.dataJob.user.firstName ? this.dataJob.user.firstName : (this.dataJob.user.email ? this.dataJob.user.email : this.dataJob.user.username))) ||
                this.groupCustomerService.get('category')?.value !== this.dataJob.customerServiceItem.category ||
                this.groupCustomerService.get('detail')?.value !== this.dataJob.description ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                        this.clearFormData();
                        this.setDefault();
                    }
                });

                return;
            }
        } else if (this.dataDetail.mode === 'create') {
            if (
                this.groupCustomerService.get('name')?.value ||
                this.groupCustomerService.get('realesateUnitName')?.value ||
                this.groupCustomerService.get('page')?.value ||
                this.groupCustomerService.get('unit')?.value ||
                this.groupCustomerService.get('user')?.value ||
                this.groupCustomerService.get('type')?.value ||
                this.groupCustomerService.get('category')?.value ||
                this.groupCustomerService.get('detail')?.value ||
                this.isImageChange
            ) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ยกเลิกการสร้าง และปิดสไลด์'
                }).then((res: any) => {
                    if (res) {
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                        this.isSubmitted = false;
                        this.clearFormData();
                        this.setDefault();
                    }
                });

                return;
            }
        }

        if (this.dataDetail.id) {
            this.router.navigate(['/homecare']);
        }
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.isSubmitted = false;
        this.clearFormData();
        this.setDefault();
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.dataDetail.mode === 'create') {
            if (
                this.groupCustomerService.invalid ||
                (
                    !this.groupCustomerService.get('page')?.value &&
                    !this.groupCustomerService.get('unit')?.value &&
                    !this.groupCustomerService.get('user')?.value &&
                    !this.groupCustomerService.get('type')?.value &&
                    !this.groupCustomerService.get('category')?.value &&
                    !this.groupCustomerService.get('detail')?.value
                ) ||
                !this.isImageChange) {
                return;
            }

            this.isSubmitted = true;
            let image = undefined;
            if (this.images.length > 0) {
                image = this.images[0];
            }

            const data: any = {};
            data.ownerUser = this._user ? this._user.userId : '';
            let coverImageDesciptions: any[] = [];
            for (let img of this.images) {
                coverImageDesciptions.push(img);
            }
            data.coverImages = coverImageDesciptions;
            data.description = this.groupCustomerService.get('detail')?.value
            data.customerServiceItemId = this._type ? this._type._id : '';

            this.realesateUnitCustomerServiceFacade.createData(data, this._unit.id ? this._unit.id : this._unit.userId).then((res: any) => {
                if (res) {
                    this.dataJob = res.data[0];
                    this.createTableRow(this.dataJob, 'create');
                    this.setDefault();
                    this.isSubmitted = false;
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'สร้างรายการสำเร็จ',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {

                        }
                    });
                    // clear form
                    this.clearFormData();
                }
            }).catch((err) => {
                if (err) {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'เกิดข้อผิดพลาด' + err.error.message ? err.error.message : '',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                            this.clearFormData();
                            this.setDefault();
                            this.isSubmitted = false;
                        }
                    });
                }
            });
        }
    }

    private _filterPageId() {
        if (this.dataDetail.mode === 'edit') {
            if (this.groupCustomerService.get('page')?.value) {
                const page = this.dataPage.filter((res: any) => res.prefix === this.groupCustomerService.get('page')?.value);
                this.getRealesateUnitByPage(page[0].id);
                this.getCustomerItemType(page[0].id);
            }
        }
    }

    private _getPage() {
        this.pageFacade.searchData().then((res: any) => {
            if (res) {
                this.dataPage = res.data;
                this.filteredListPage = this.dataPage.slice();
                this._filterPageId();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    public _getIdCompleteStatus(pageId: string) {
        this.customerServiceStatusAdminFacade.searchData({ pageId: pageId }).then((res: any) => {
            if (res) {
                this.customerServiceStatus = res.data;
                this.completeStatusId = res.data.filter((data: any) => data.isComplete === true)[0]._id;
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    private _filterUnitByPageId() {
        if (this.dataDetail.mode === 'edit') {
            if (this.groupCustomerService.get('unit')?.value) {
                const page = this.realesateUnit.filter((res: any) => res.no === this.groupCustomerService.get('unit')?.value);
                if (page.length > 0) this.getRealesateUserByUnit(page[0].id);
            }
        }
    }

    public getRealesateUnitByPage(id?: string) {
        this.realesateUnitFacade.searchDataRequest({ pageIds: id ? [id] : [] }).then((res: any) => {
            if (res) {
                this.realesateUnit = res.data;
                this.filteredListUnit = this.realesateUnit.slice();
                this._filterUnitByPageId();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    public getRealesateUserByUnit(id?: string) {
        this.realesateUnitFacade.searchUserDataById(id!).then((res: any) => {
            if (res) {
                this.realesateUser = res.data;
                this.filteredListUser = this.realesateUser.slice();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    public getCustomerItemType(id: string) {
        let val: any = {
            offset: 0,
            limit: 100,
            count: false,
            orderBy: { "createdDate": -1 },
        };
        this.customerServiceItemFacade.searchData(val).then((res: any) => {
            if (res) {
                this.customerItemType = res.data;
                this.filteredListType = this.customerItemType.slice();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    public clickActionTableDataUpload() {
        let val = ['.jpg', '.jpeg'];
        if (this.dialogAlertUploadImage(this.images, 5, 'แนบรูปภาพสูงสุดไม่เกิน 6 รูปเท่านั้น')) {
            return;
        }

        const dialogRef = this.dialog.open(DialogUpload, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                header: 'อัพโหลดรูปภาพ',
                accept: val,
                type: 'image',
                multiple: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                for (const [i, data] of result.entries()) {
                    if (this.dialogAlertUploadImage(this.images, 5, 'แนบรูปภาพสูงสุดไม่เกิน 6 รูปเท่านั้น')) {
                        return;
                    }

                    if (val.indexOf('.' + data.data.name.split('.')[1]) >= 0) {
                        this.images.push(data.base64);
                        this.isImageChange = true;
                    }
                }
            }
        });
    }

    private dialogAlertUploadImage(data: any, count: number, content: string): boolean {
        if (data.length > count) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: content
            });

            return true;
        } else {
            return false;
        }
    }

    public clickRemoveImage(index: number, val?: any) {
        this.authenManager.clickDialogAlert({
            header: '',
            content: 'ต้องการลบรูปภาพที่ ' + (index + 1)
        }).then((res: any) => {
            if (res) {
                this.images.splice(index, 1);
                if (this.images.length <= 0) {
                    this.isImageChange = false;
                }
            }
        });
    }

    public getIndexNull(value: any): boolean {
        if (value.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public image(data: string): string {
        return (this.environment.apiBaseURL + data + '/image').toString();
    }

    public clearFormData(): void {
        this.images = [];
        this.isImageChange = false;
        this.groupCustomerService.reset();
        this.formCustomerService();
        this.isDelete = false;
    }

    public clickSelect(data: any, tag: string) {
        if (tag === 'page') {
            this.setDefault();
            this.getRealesateUnitByPage(data.id);
            this.getCustomerItemType(data.id);
        } else if (tag === 'unit') {
            this.realesateUser = [];
            this.filteredListUser = [];
            this.groupCustomerService.get('user')?.reset();
            this.getRealesateUserByUnit(data.id);
            this._unit = data;
        } else if (tag === 'user') {
            this._user = data;
        } else if (tag === 'type') {
            this._type = data;
        } else if (tag === 'category') {
            this._category = data;
            this._filterTypeData(this._category);
        }
    }

    private _filterTypeData(data?: any) {
        if (data) {
            this.filteredListType = this.customerItemType.filter((res: any) => res.category === data.id);
        }
    }

    private setDefault() {
        this.realesateUnit = [];
        this.filteredListUnit = [];
        this.realesateUser = [];
        this.filteredListUser = [];
        this.customerItemType = [];
        this.filteredListType = [];
        this._unit = null;
        this._user = null;
        this._type = null;
        this._category = null;
    }

    public approveStatus(Approve: String): boolean {
        if (Approve === 'approved') {
            return true;
        } else {
            return false;
        }
    }

    public async clickApprove(Approve?: boolean): Promise<void> {
        await this.checkTokenService.checkStatus();
        if (Approve !== undefined) {
            const dialogRef = this.dialog.open(DialogComplaint, {
                autoFocus: false,
                restoreFocus: false,
                data: {
                    header: Approve ? ('ยืนยันอนุมัติงานร้องเรียน' + this.dataDetail.value.name) : this.dataDetail.value.status === 'approved' ? ('ยกเลิกอนุมัติงานร้องเรียน ' + this.dataDetail.value.name) : ('ยกเลิกไม่อนุมัติงานร้องเรียน ' + this.dataDetail.value.name),
                    placeholder: 'หมายเหตุ...',
                    type: 'input',
                    submit: 'ยืนยัน'
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result && result.action === 'confirm') {
                    if (!!result.value) {
                        if (Approve) {
                            this.customerServiceAdminFacade.approve(this.dataDetail.value.id ? this.dataDetail.value.id : this.dataDetail.value._id, { description: result.value }).then((res: any) => {
                                this.dataDetail.value.status = res.data.status;
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            this.customerServiceAdminFacade.cancelapprove(this.dataDetail.value.id ? this.dataDetail.value.id : this.dataDetail.value._id, { description: result.value }).then((res: any) => {
                                this.dataDetail.value.status = res.data.status;
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                    } else {
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: 'ต้องใส่หมายเหตุด้วยทุกครั้ง',
                            cancel: false
                        }).then((res: any) => {
                            if (res) {
                            }
                        });
                    }
                }
            });
        } else {
            const dialogRef = this.dialog.open(DialogComplaint, {
                autoFocus: false,
                restoreFocus: false,
                data: {
                    header: 'ไม่อนุมัติงานร้องเรียน ' + this.dataDetail.value.name,
                    placeholder: 'หมายเหตุ...',
                    type: 'input',
                    submit: 'ยืนยัน'
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result && result.action === 'confirm') {
                    if (!!result.value) {
                        this.customerServiceAdminFacade.unapprove(this.dataDetail.value.id ? this.dataDetail.value.id : this.dataDetail.value._id, { description: result.value }).then((res: any) => {
                            this.dataDetail.value.status = res.data.status;
                        }).catch((err) => {
                            console.log(err);
                        });
                    } else {
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: 'ต้องใส่หมายเหตุด้วยทุกครั้ง',
                            cancel: false
                        }).then((res: any) => {
                            if (res) {
                            }
                        });
                    }
                }
            });
        }
    }

    public async clickUpStatus(item: any) {
        await this.checkTokenService.checkStatus();
        if (item.ordering <= this.dataDetail.value.customerServiceStatus.ordering) {
            return;
        }

        if (item.ordering > (this.dataDetail.value.customerServiceStatus.ordering + 1) || item.ordering < (this.dataDetail.value.customerServiceStatus.ordering - 1)) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'คุณสามารถอัพเดทสถานะหรือลดได้ตามลำดับเท่านั้น',
                cancel: false
            });
            return;
        }

        this.authenManager.clickDialogAlert({
            header: '',
            content: 'ยืนยันปรับสถานะ',
        }).then(async (res: any) => {
            if (res) {
                if (item.ordering > (this.dataDetail.value.customerServiceStatus.ordering)) await this.customerServiceAdminFacade.upDateStatus(this.dataDetail.value.id, item._id);
                if (item.ordering < (this.dataDetail.value.customerServiceStatus.ordering)) await this.realesateUnitFacade.doweStatus(this.dataDetail.value.realesateUnit.id, this.dataDetail.value.id);
                this._unitCustomerServiceHistory();
                this.dataDetail.value.customerServiceStatus = item;
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    private _unitCustomerServiceHistory() {
        this.realesateUnitCustomerServiceFacade.searchData(this.dataDetail.value.realesateUnit.id, this.dataDetail.value.id).then((res: any) => {
            if (res) {
                this.statusHistoryList = res.data;
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    public convertDateStringStatus(data: string): string {
        return moment(this.statusHistoryList[this.statusHistoryList.map(function (e: { customerServiceStatusId: any; }) { return e.customerServiceStatusId; }).indexOf(data)] ? this.statusHistoryList[this.statusHistoryList.map(function (e: { customerServiceStatusId: any; }) { return e.customerServiceStatusId; }).indexOf(data)].createdDate : new Date()).add(543, 'year').format('DD/MM/YYYY');
    }

    public convertStringStatus(data: string): string {
        return this.statusHistoryList[this.statusHistoryList.map(function (e: { customerServiceStatusId: any; }) { return e.customerServiceStatusId; }).indexOf(data)] ? this.statusHistoryList[this.statusHistoryList.map(function (e: { customerServiceStatusId: any; }) { return e.customerServiceStatusId; }).indexOf(data)].fuser.displayName : '-';
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupCustomerService.controls;
    }

    private _setCountStar() {
        let count: number = 0;
        if (!!this.dataDetail!.value!.questionnaire && !!this.dataDetail!.value!.questionnaire!.questionnaires) {
            for (const questionnaires of this.dataDetail!.value!.questionnaire!.questionnaires) {
                count += questionnaires.point;
            }

            if (count >= 0) {
                this.countStar = (count / this.dataDetail!.value!.questionnaire!.questionnaires.length).toFixed(2);
                return;
            }
        }

        this.countStar = count;
    }

    public async clickDatepicker(mode: 'REPAIR' | 'WALKIN' | 'GUARANTEEDATE', type: 'all' | 'date' | 'time', date: string) {
        await this.checkTokenService.checkStatus();
        const dialogRef = this.dialog.open(DialogDatepicker, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                type: type,
                selectedDate: date
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (mode !== 'GUARANTEEDATE') {
                    this._clickSehedule(mode, result);
                } else {

                }
            }
        });
    }

    private _clickSehedule(mode: 'REPAIR' | 'WALKIN', value: any) {
        const dialogRef = this.dialog.open(DialogComplaint, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                header: 'หมายเหตุนัด / เลื่อนนัด',
                placeholder: 'หมายเหตุ...',
                type: 'textarea',
                submit: 'ยืนยัน'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (!!result!.value) {

                } else {
                    this.authenManager.clickDialogAlert({
                        header: '',
                        content: 'ต้องกรอกหมายเหตุนัด / เลื่อนนัด',
                        cancel: false
                    }).then((res: any) => {
                        if (res) {
                            this._clickSehedule(mode, value);
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            }
        });
    }

    public clickCompleteStatus() {
        this.authenManager.clickDialogAlert({
            header: '',
            content: 'ยืนยันการปิดงานร้องเรียน'
        }).then((res: any) => {
            if (res) {
                this._upDateStatus();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    private async _upDateStatus() {
        await this.checkTokenService.checkStatus();
        this.customerServiceAdminFacade.completeStatus(this.dataDetail.value.id, this.completeStatusId).then((resStatus: any) => {
            if (resStatus) {
                this._getCustomerServiceId();
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'ปิดงานร้องเรียนสำเร็จ',
                    cancel: false
                }).then((res: any) => {
                    if (res) {

                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'การปิดงานร้องเรียนเกิดข้อผิดพลาด กรุณาตรวจสอบใหม่อีกครั้ง...',
                    cancel: false
                }).then((res: any) => {
                    if (res) {

                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        });
    }

    private _getCustomerServiceId() {
        this.customerServiceFacade.getCustomerService(this.dataDetail.value.id).then((res: any) => {
            if (res) {
                this.dataDetail.value = res.data;
                this.dataJob = res.data;
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    private _getForeman() {
        this.customerServiceFacade.searchForemanData(this.dataDetail.value.id).then((res: any) => {
            if (res) {
                this.dataForeman = res.data;
                for (const foreman of this.dataForeman) {
                    let gallery: any[] = [];
                    for (const images of foreman.images) {
                        images['coverURLs'] = this.environmentApi.apiBaseURL + images.coverURLs + '/image';
                        gallery.push(images.coverURLs);
                    }

                    foreman['gallery'] = gallery;
                }
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    public dateFormat(data: any, format: string) {
        return moment(data).add(543, 'year').format(format);
    }

    public clickDetailExamine(event: any, detail: any, gallery: any, isContent: boolean) {
        const dialogRef = this.dialog.open(DialogDetail, {
            autoFocus: false,
            restoreFocus: false,
            data: {
                index: event ? event.index : null,
                detail: detail ? detail : null,
                gallery: gallery,
                isContent: isContent
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    public clickDowload() {
        this.repairFacade.exportDataById(this.dataDetail.value.id).then((res: any) => {
            if (res) {
                // Create a Blob object from the response data
                const blob = new Blob([res], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = this.dataDetail.value.name + '-homecare.pdf';
                link.click();
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: this.dataDetail.value.name + ' ยังไม่มีการอนุมัติงาน',
                    cancel: false
                });
            }
        });
    }

    public clickCheckStatus(mode: "REPAIR" | "WALKIN" | "GUARANTEEDATE", type: "time" | "all" | "date", date: string) {
        if (!this.dataDetail.value.status) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องอนุมัติงานซ่อมก่อน',
                cancel: false
            });

            return;
        }

        this.clickDatepicker(mode, type, date);
    }

    public clickRefresh(activeTab: 'information' | 'examine' | 'assessment') {
        if (activeTab !== 'examine') {
            this._getCustomerServiceId();
        }

        if (activeTab === 'information') {
            this._getCustomer(this.dataDetail.value);
            if (!!this.dataDetail?.value?.coverURLs) {
                for (const cover of this.dataDetail.value.coverURLs) {
                    this.images.push(this.image(cover));
                }
            }

            setTimeout(() => {
                this.groupCustomerService.get('name')?.disable();
                this.groupCustomerService.get('realesateUnitName')?.disable();
                this.groupCustomerService.get('page')?.disable();
                this.groupCustomerService.get('unit')?.disable();
                this.groupCustomerService.get('user')?.disable();
                this.groupCustomerService.get('type')?.disable();
                this.groupCustomerService.get('category')?.disable();
                this.groupCustomerService.get('detail')?.disable();
            }, 10);

            this._unitCustomerServiceHistory();
            this._getIdCompleteStatus(this.dataDetail.value.pageId);
        } else if (activeTab === 'examine') {
            this._getForeman();
        } else if (activeTab === 'assessment') {
            this._setCountStar();
        }
    }
}

