export * from './SliderDetailRepair.component';
export * from './SliderProject.component';
export * from './SliderHouseRegistration.component';
export * from './SliderResidents.component';
export * from './SliderRepairStatus.component';
export * from './SliderRepairType.component';
export * from './SliderContent.component';
export * from './SliderManageUsers.component';
export * from './SliderConfig.component';
export * from './SliderDownPayment.component';
export * from './SliderMessage.component';
export * from './SliderAssessment.component';
export * from './SliderCustomerServiceStatus.component';
export * from './SliderCustomerServiceItem.component';
export * from './SliderCustomerServiceAssessment.component';
export * from './SliderAfterSale.component';
export * from './SliderNotificationAfterSale.component';
export * from './SliderDocumentManual.component';