export const MAX_SEARCH_ROWS = 200;
export const ONE_HUNDRED = 100;
export const ONE_MILLION = 1000000;
export const TWO_HUNDRED_FIFTY_FIVE = 255;
export const TYPE = {
    string: 'string',
    boolean: 'boolean',
    number: 'number',
    object: 'object'
};
export const IMAGE_ASSET_TYPE: string[] = ['image/jpeg', 'image/gif', 'image/png'];

export const WEEKDAY: string[] = new Array(7);
WEEKDAY[0] = 'Sunday';
WEEKDAY[1] = 'Monday';
WEEKDAY[2] = 'Tuesday';
WEEKDAY[3] = 'Wednesday';
WEEKDAY[4] = 'Thursday';
WEEKDAY[5] = 'Friday';
WEEKDAY[6] = 'Saturday';

export const CUSTOMER_SERVICE_CATEGORY = [
    { id: 'UTT', name: 'สาธารณูปโภค', key: 'utt.time', day: 15 },
    { id: 'ASS', name: 'งานบริการหลังการขาย', key: 'ass.time', day: 15 },
    { id: 'CMP', name: 'เรื่องร้องเรียน', key: 'cmp.time', day: 7 }
]
