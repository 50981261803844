<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper
            class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose();">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                    <div class="flex-1 flex flex-col">
                        <span class="text-lg font-bold flex items-center">
                            <img class="flex-none w-6 h-6 min-w-[24px] mr-2"
                                [src]="'../../../../assets/images/app/config.svg'" alt="">
                            <span class="flex-1 line-clamp-1">
                                {{groupConfigForm.get('name')?.value ? groupConfigForm.get('name')?.value :
                                'สร้างค่าระบบ'}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="flex-none flex items-center">
                    <div class="grid grid-cols-2 gap-2">
                        <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                            คืนค่า
                        </button>
                        <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                            <ng-container *ngIf=" dataDetail && dataDetail.mode==='edit'">บันทึก</ng-container>
                            <ng-container *ngIf=" dataDetail && dataDetail.mode==='create'">สร้าง</ng-container>
                        </button>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]=" beforeMenu"
                        class="ml-3 flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
                        [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>ลบข้อมูล</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="flex-1 flex items-start">
                <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
                    <div class="w-full md:max-w-[500px] xl:max-w-[560px] grid grid-cols-1 gap-6">
                        <div class="card-detail">
                            <div class="flex items-center min-h-[80px] border-b p-4">
                                <div class="flex-1 flex flex-col">
                                    <span class="text-lg font-bold">
                                        ข้อมูลค่าระบบ
                                    </span>
                                    <span class="text-sm font-normal opacity-50 mt-1">
                                        ข้อมูลทั่วไปของค่าระบบ
                                    </span>
                                </div>
                            </div>
                            <div class="px-4 py-6 grid grid-cols-1 gap-4" [formGroup]="groupConfigForm">
                                <div>
                                    <span class="text-base font-bold opacity-50">ชื่อค่าระบบ</span>
                                    <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                        formControlName="name"
                                        [class.border-error]="(isSubmitted || groupConfigForm.get('name')?.touched) && f['name'].errors">
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupConfigForm.get('name')?.touched) && f['name'].errors">
                                        กรุณากรอกชื่อค่าระบบอย่างน้อย 1 หลัก
                                    </mat-error>
                                </div>
                                <div>
                                    <span class="text-base font-bold opacity-50">ประเภท</span>
                                    <mat-select #select class="select-custom" [disableOptionCentering]="true"
                                        [placeholder]="'เลือก'" autocomplete="off" formControlName="type"
                                        [class.border-error]="(isSubmitted || groupConfigForm.get('type')?.touched) && f['type'].errors"
                                        (selectionChange)="selectChange($event)">
                                        <mat-select-filter class="select-filter" [displayMember]="'label'"
                                            [array]="dataPage" [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                                            (filteredReturn)="filteredListPage =$event" *ngIf="select.focused">
                                        </mat-select-filter>
                                        <ng-container *ngFor="let item of filteredListPage">
                                            <mat-option class="font-medium" [value]="item.type">
                                                {{item.label}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="text-sm font-medium"
                                        *ngIf="(isSubmitted || groupConfigForm.get('type')?.touched) && f['type'].errors">
                                        กรุณาเลือกประเภทค่าระบบ
                                    </mat-error>
                                </div>
                                <div *ngIf="groupConfigForm.get('type')?.value">
                                    <ng-container
                                        *ngIf="groupConfigForm.get('type')?.value === 'notification'; else elseDateConfig">
                                        <span class="text-base font-bold opacity-50">ค่าระบบ</span>
                                        <div class="flex flex-col gap-y-2 mt-1">
                                            <ng-container formArrayName="configDateData">
                                                <ng-container
                                                    *ngFor="let control of configData.controls; let i = index">
                                                    <div class="flex gap-4 items-center" [formGroupName]="i">
                                                        <div class="flex gap-4 items-center w-full">
                                                            <mat-select #select class="select-custom mt-0"
                                                                formControlName="type" [disableOptionCentering]="true"
                                                                [placeholder]="'เลือก'">
                                                                <ng-container *ngFor="let item of typeCategory">
                                                                    <mat-option class="font-medium" [value]="item.id">
                                                                        {{item.id}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-select #select class="select-custom mt-0"
                                                                formControlName="day" [disableOptionCentering]="true"
                                                                [placeholder]="'เลือก'">
                                                                <ng-container
                                                                    *ngFor="let item of control.get('type')?.value === 'UTT' ? days_utt : (control.get('type')?.value === 'ASS' ? days_ass : days_cmp)">
                                                                    <mat-option class="font-medium" [value]="item">
                                                                        {{item}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-select #select class="select-custom mt-0"
                                                                formControlName="time" [disableOptionCentering]="true"
                                                                [placeholder]="'เลือก'">
                                                                <ng-container *ngFor="let item of times">
                                                                    <mat-option class="font-medium" [value]="item">
                                                                        {{item}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                        </div>
                                                        <button mat-icon-button
                                                            class="flex-none group icon-ripper-center"
                                                            (click)="clickRemove(i)">
                                                            <mat-icon
                                                                class="opacity-50 group-hover:opacity-100">close</mat-icon>
                                                        </button>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <button mat-button class="button-cdg mt-1 w-[100%]"
                                                [ngClass]="isMaxConfig() ? 'bg-gray-100' : 'bg-bule-light'"
                                                [disabled]="isMaxConfig()"
                                                (click)="!isMaxConfig() ? clickAdd() : null">+ เพิ่มวัน</button>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseDateConfig>
                                        <span class="text-base font-bold opacity-50">ค่าระบบ</span>
                                        <ng-container
                                            *ngIf="groupConfigForm.get('type')?.value === 'string' || groupConfigForm.get('type')?.value === 'number'; else elseTemplateType">
                                            <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                                formControlName="value"
                                                [class.border-error]="(isSubmitted || groupConfigForm.get('value')?.touched) && f['value'].errors">
                                        </ng-container>
                                        <ng-template #elseTemplateType>
                                            <div class="mt-1">
                                                <mat-slide-toggle formControlName="value">
                                                    {{groupConfigForm.get('value')?.value ? 'เปิด' : 'ปิด'}}
                                                </mat-slide-toggle>
                                            </div>
                                        </ng-template>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupConfigForm.get('value')?.touched) && f['value'].errors">
                                            กรุณากรอกค่าให้ตรงกับประเภทค่าระบบ
                                        </mat-error>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>