<div class="report-page h-screen flex flex-col">
    <div class="bg-white h-[75px] px-6 flex items-center justify-between border-b flex-none">
        <div class="text-xl flex-1">
            {{menu && menu.text}}
        </div>
        <div class="flex gap-2">
            <button mat-stroked-button *ngIf="!!dataTable && dataTable.length > 0"
                class="relative flex-none button-cdg bg-bule-light mr-4" (click)="clickActionTableDataDownload()">
                <span class="material-symbols-outlined">
                    download
                </span>
                ออกเอกสาร
            </button>
            <button mat-stroked-button class="bg-[color:var(--bg-FA)] button-cdg" (click)="clickReset()">
                คืนค่า
            </button>
            <button mat-button class="bg-red-cdg button-cdg" (click)="createTable()">
                สร้างรายงาน
            </button>
        </div>
    </div>
    <div class="flex-1 overflow-auto p-6" [@hide]="state" [formGroup]="groupRepair">
        <div class="p-6 w-full grid grid-cols-3 gap-3 card-detail"
            [ngClass]="!!dataTable && dataTable.length > 0 ? 'grid-cols-3' : 'grid-cols-1 mx-auto max-w-2xl'">
            <div class="flex flex-col">
                <span class="text-sm opacity-50">โครงการ</span>
                <mat-select #select class="select-custom h-9" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" multiple formControlName="page">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataPage"
                        [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                        (filteredReturn)="filteredListPage = $event">
                    </mat-select-filter>
                    <mat-option class="checkbox-hide" (click)="toggleSelectAll('page')" #selectAllOption>
                        {{ isAllSelectedPage ? 'ยกเลิกเลือกทั้งหมด' : 'เลือกทั้งหมด' }}
                    </mat-option>
                    <ng-container *ngFor="let item of filteredListPage">
                        <mat-option class="font-medium" [value]="item.id">
                            ({{item.prefix}}) {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">สถานะ</span>
                <mat-select #select class="select-custom h-9" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" multiple formControlName="status">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataStatus"
                        [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                        (filteredReturn)="filteredListStatus = $event">
                    </mat-select-filter>
                    <mat-option class="checkbox-hide" (click)="toggleSelectAll('status')" #selectAllOption>
                        {{ isAllSelectedStatus ? 'ยกเลิกเลือกทั้งหมด' : 'เลือกทั้งหมด' }}
                    </mat-option>
                    <ng-container *ngFor="let item of filteredListStatus">
                        <mat-option class="font-medium" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ประเภทงาน</span>
                <mat-select #select class="select-custom h-9" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" multiple formControlName="type">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataType"
                        [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'" (filteredReturn)="filteredType = $event">
                    </mat-select-filter>
                    <mat-option class="checkbox-hide" (click)="toggleSelectAll('type')" #selectAllOption>
                        {{ isAllSelectedType ? 'ยกเลิกเลือกทั้งหมด' : 'เลือกทั้งหมด' }}
                    </mat-option>
                    <ng-container *ngFor="let item of filteredType">
                        <mat-option class="font-medium" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">บ้านเลขที่</span>
                <input class="input-custom h-9" type="text" placeholder="12/23,xx/xx" formControlName="no"
                    autocomplete="off">
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ชื่อเจ้าของบ้าน</span>
                <input class="input-custom h-9" type="text" placeholder="xxxxx" formControlName="userFullName"
                    autocomplete="off">
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ชื่อช่าง</span>
                <input class="input-custom h-9" type="text" placeholder="xxxxx" formControlName="foremanFullName"
                    autocomplete="off">
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ชื่อ admin</span>
                <input class="input-custom h-9" type="text" placeholder="xxxxx" formControlName="adminFullName"
                    autocomplete="off">
            </div>
            <div class="flex flex-col" (click)="picker1.open()">
                <span class="text-sm opacity-50">วันที่ลูกบ้านแจ้ง</span>
                <div class="flex items-center input-custom h-9 cursor-pointer">
                    <mat-date-range-input [rangePicker]="picker1">
                        <input class="pointer-events-none" matStartDate disable formControlName="startDateTime">
                        <input class="pointer-events-none" matEndDate disable formControlName="endDateTime">
                    </mat-date-range-input>
                    <mat-datepicker-toggle class="flex items-center justify-center custom-datepicker-size" matSuffix
                        [for]="picker1"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker1></mat-date-range-picker>
                </div>
            </div>

            <div class="flex flex-col" (click)="picker2.open()">
                <span class="text-sm opacity-50">วันที่ช่างประเมิน</span>
                <div class="flex items-center input-custom h-9 cursor-pointer">
                    <mat-date-range-input [rangePicker]="picker2">
                        <input class="pointer-events-none" matStartDate disable formControlName="startWalkinDateTime">
                        <input class="pointer-events-none" matEndDate disable formControlName="endWalkinDateTime">
                    </mat-date-range-input>
                    <mat-datepicker-toggle class="flex items-center justify-center custom-datepicker-size" matSuffix
                        [for]="picker2"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker2></mat-date-range-picker>
                </div>
            </div>

            <div class="flex flex-col" (click)="picker3.open()">
                <span class="text-sm opacity-50">วันที่ช่างเข้าซ่อม</span>
                <div class="flex items-center input-custom h-9 cursor-pointer">
                    <mat-date-range-input [rangePicker]="picker3">
                        <input class="pointer-events-none" matStartDate disable formControlName="startRepairDateTime">
                        <input class="pointer-events-none" matEndDate disable formControlName="endRepairDateTime">
                    </mat-date-range-input>
                    <mat-datepicker-toggle class="flex items-center justify-center custom-datepicker-size" matSuffix
                        [for]="picker3"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker3></mat-date-range-picker>
                </div>
            </div>

            <div class="flex flex-col" (click)="picker4.open()">
                <span class="text-sm opacity-50">วันที่ approve / reject</span>
                <div class="flex items-center input-custom h-9 cursor-pointer">
                    <mat-date-range-input [rangePicker]="picker4">
                        <input class="pointer-events-none" matStartDate disable formControlName="startApproveDateTime">
                        <input class="pointer-events-none" matEndDate disable formControlName="endApproveDateTime">
                    </mat-date-range-input>
                    <mat-datepicker-toggle class="flex items-center justify-center custom-datepicker-size" matSuffix
                        [for]="picker4"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker4></mat-date-range-picker>
                </div>
            </div>
            <div class="flex flex-col">
                <span class="text-sm opacity-50">ประกัน</span>
                <div class="flex h-full justify-around items-center">
                    <mat-checkbox [(ngModel)]="isGuarantee" [ngModelOptions]="{standalone: true}"
                        (change)="checkGuarantee(true, $event)">อยู่ในประกัน</mat-checkbox>
                    <mat-checkbox [(ngModel)]="isNotGuarantee" [ngModelOptions]="{standalone: true}"
                        (change)="checkGuarantee(false, $event)">อยู่นอกประกัน</mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-end mt-2" *ngIf="!!dataTable && dataTable.length > 0">
        <div class="rounded-tl-3xl bg-white p-[0px_20px_0px_25px] cursor-pointer" (click)="changeState()">
            <span class="material-symbols-outlined flex" [@arrow]="state">
                stat_2
            </span>
        </div>
    </div>
    <div *ngIf="!!dataTable && dataTable.length > 0" class="overflow-auto" [@heightGrow]="state">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full">

            <!-- No Column -->
            <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> No. </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.no">{{element.header.no}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Page Name Column -->
            <ng-container matColumnDef="pageName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> โครงการ </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.pageName">{{element.header.pageName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- User Owner Name Column -->
            <ng-container matColumnDef="userOwnerName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อเจ้าของบ้าน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.userOwnerName">{{element.header.userOwnerName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Repair Name Column -->
            <ng-container matColumnDef="repairName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> รหัส </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.repairName">{{element.repairName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Repair Unit No Column -->
            <ng-container matColumnDef="repairUnitNo">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> บ้านเลขที่ </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.repairUnitNo">{{element.repairUnitNo}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Repair Type Column -->
            <ng-container matColumnDef="repairType">
                <th mat-header-cell *matHeaderCellDef class="min-w-[300px] w-[300px] px-6 py-4"> ประเภทงาน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[300px] w-[300px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.repairType">{{element.repairType}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Repair Status Column -->
            <ng-container matColumnDef="repairStatus">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> สถานะงาน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.repairStatus">{{element.repairStatus}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="min-w-[320px] w-[320px] px-6 py-4"> รายละเอียดงานซ่อม </th>
                <td mat-cell *matCellDef="let element" class="min-w-[320px] w-[320px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.description">{{element.description}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Created Date Column -->
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ว/ด/ป ลูกค้าแจ้ง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1" matTooltip="{{dateAddHours(element.createdDate)
                   }}">{{dateAddHours(element.createdDate)
                        }}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- User Display Name Column -->
            <ng-container matColumnDef="userDisplayName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อผู้แจ้ง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.userDisplayName">{{element.userDisplayName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Walkin Date Time Column -->
            <ng-container matColumnDef="walkinDateTime">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> นัดประเมิน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.walkinDateTime)}}">{{dateAddHours(element.walkinDateTime)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Repair Date Time Column -->
            <ng-container matColumnDef="repairDateTime">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> นัดเข้าซ่อม </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.repairDateTime)}}">{{dateAddHours(element.repairDateTime)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- repairForemanDateTime Date Time Column -->
            <ng-container matColumnDef="repairForemanDateTime">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> วันเข้าซ่อมจริง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.repairForemanDateTime)}}">{{dateAddHours(element.repairForemanDateTime)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- Repair Status Has Date Time Column -->
            <ng-container matColumnDef="repairStatusHasDate">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ว/ด/ป เสร็จงาน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.repairStatusHasDate)}}">{{dateAddHours(element.repairStatusHasDate)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- slaDay Date Time Column -->
            <!-- <ng-container matColumnDef="slaDay">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ระยะเวลาซ่อม (SLA)
                </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.slaDay">{{element.slaDay}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container> -->

            <!-- bookingDateTime Column -->
            <ng-container matColumnDef="bookingDateTime">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4">
                    วันที่ลูกค้าสะดวกให้เข้าซ่อม </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.bookingDateTime)}}">{{dateAddHours(element.bookingDateTime)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- successRepairForemanDateTime Column -->
            <ng-container matColumnDef="successRepairForemanDateTime">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> เสร็จงานจริง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.successRepairForemanDateTime)}}">{{dateAddHours(element.successRepairForemanDateTime)
                        }}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- foremanRepairCustomerDay Column -->
            <ng-container matColumnDef="foremanRepairCustomerDay">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> รวมวันนัดคิว </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.foremanRepairCustomerDay">{{element.foremanRepairCustomerDay}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- successRepairDay Column -->
            <!-- <ng-container matColumnDef="successRepairDay">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ผลสำเร็จของงาน </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.successRepairDay">{{element.successRepairDay}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container> -->

            <!-- sinceCreatetoSuccessDay Column -->
            <ng-container matColumnDef="sinceCreatetoSuccessDay">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4">
                    รวมวันตั้งแต่แจ้งซ่อมถึงงานเสร็จสิ้น </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.sinceCreatetoSuccessDay">{{element.sinceCreatetoSuccessDay}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- foremanRepairAllDay Column -->
            <ng-container matColumnDef="foremanRepairAllDay">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> รวมวันซ่อม </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.foremanRepairAllDay">{{element.foremanRepairAllDay}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- questionnaireAvg Column -->
            <ng-container matColumnDef="questionnaireAvg">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ผลประเมินความพึงพอใจ
                </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.questionnaireAvg">{{element.questionnaireAvg | number}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- repairForemanPostUser Column -->
            <ng-container matColumnDef="repairForemanPostUser">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อช่าง </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.repairForemanPostUser">{{element.repairForemanPostUser}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- approvedDisplayName Column -->
            <ng-container matColumnDef="approvedDisplayName">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> ชื่อ admin ที่
                    approve/reject </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.approvedDisplayName">{{element.approvedDisplayName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4"></td>
            </ng-container>

            <!-- approvedDateTime Date Time Column -->
            <ng-container matColumnDef="approvedDateTime">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> วันที่ approve / reject
                </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        matTooltip="{{dateAddHours(element.approvedDateTime)}}">{{dateAddHours(element.approvedDateTime)}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1">รวมงานทั้งหมด</span>
                </td>
            </ng-container>

            <!-- TotalRepair Column -->
            <ng-container matColumnDef="totalRepair">
                <th mat-header-cell *matHeaderCellDef class="min-w-[160px] w-[160px] px-6 py-4"> จำนวนงานทั้งหมด </th>
                <td mat-cell *matCellDef="let element" class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1"
                        [matTooltip]="element.header.totalRepair">{{element.header.totalRepair}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef class="min-w-[160px] w-[160px] px-6 py-4">
                    <span class="text-sm text-black !line-clamp-1">{{getTotalSumRepair()}} งาน</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </div>
</div>