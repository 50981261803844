import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageAdminModel } from '../../models/PageAdminModel';
import { PageAdminStatusRequest } from '../../request/PageAdminStatusRequest';
import { PostAdminStatusRequest } from '../../request/PostAdminStatusRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class PageAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/page/');
    }
    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);

    public createData = (request: PageAdminStatusRequest) => this.post<PageAdminModel>('', this.getDefaultHeader(), null, request);
    public updateData = (id: string, request: PageAdminStatusRequest) => this.put<PageAdminModel>(`${(id)}`, this.getDefaultHeader(), null, request);
    public deleteData = (id: string) => this.delete<MethodResponseInf>(`${(id)}`, this.getDefaultHeader(), null);
    public createPost = (pageId: string, request: PostAdminStatusRequest) => this.post<any>(`${(pageId)}/post`, this.getDefaultHeader(), null, request);
    public updatePost = (pageId: string, postId: string, request: PostAdminStatusRequest) => this.put<any>(`${(pageId)}/post/${(postId)}`, this.getDefaultHeader(), null, request);
    public deletePost = (pageId: string, postId: string) => this.delete<any>(`${(pageId)}/post/${(postId)}`, this.getDefaultHeader(), null);
}