<div class="dialog-custom dialog-custom-complaint">
    <div class="mat-dialog-title flex items-center">
        <span class="text-base font-bold flex-1">
            {{data && data.header ? data.header : 'แจ้งเตือน !'}}
        </span>
        <button mat-icon-button class="flex-none icon-ripper-center" (click)="clickClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="overflow-x-hidden break-all border-y max-h-[24rem] p-[24px]"
        [class.p-0]="data && data.content">
        <mat-list class="pt-0" *ngIf="data && data.type === 'checkbox'">
            <ng-container *ngFor="let item of data && data.content; let i = index">
                <mat-list-item>
                    <mat-checkbox class="flex items-center content-center"></mat-checkbox>
                    <span class="ml-4 truncate">
                        {{item.text}}
                    </span>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
        <ng-container [formGroup]="dialogFormGroup">
            <div class="" *ngIf="data && data?.type === 'input'">
                <input #filterInput [placeholder]="data.placeholder ? data.placeholder : 'ค้นหา' + data.header + '...'"
                    formControlName="value"
                    class="placeholder:italic placeholder:text-slate-400 text-base font-medium bg-white h-[48px] w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    type="text" autocomplete="off" (keyup.enter)="clickSubmit()" />
            </div>
            <div class="" *ngIf="data && data?.type === 'textarea'">
                <textarea #filterInput rows="8"
                    [placeholder]="data.placeholder ? data.placeholder : 'ค้นหา' + data.header + '...'"
                    formControlName="value"
                    class="placeholder:italic placeholder:text-slate-400 text-base font-medium bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm resize-none"
                    type="text" autocomplete="off" (keyup.enter)="clickSubmit()"></textarea>
            </div>
            <div class="" *ngIf="data && data?.type === 'project_autocomp'">
                <mat-select #select class="select-custom" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" formControlName="value" (keyup.enter)="clickSubmit()">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataPage"
                        [placeholder]="data.placeholder ? data.placeholder : 'ค้นหา' + data.header + '...'"
                        [noResultsMessage]="'-'" (filteredReturn)="filteredListPage =$event" *ngIf="select.focused">
                    </mat-select-filter>
                    <ng-container *ngFor="let item of filteredListPage">
                        <mat-option class="font-medium" [value]="item.id">
                            ({{item.prefix}}) {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
            <div class="" *ngIf="data && data?.type === 'multi_select'">
                <!-- <mat-select #select class="select-custom mb-[10px]" [disableOptionCentering]="true"
                    [placeholder]="'เลือก'" autocomplete="off" formControlName="value" (click)="checkSelectValue()"
                    (keyup.enter)="clickSubmit()">
                    <mat-select-filter class="select-filter" [displayMember]="'prefix'" [array]="dataPage"
                        [placeholder]="data.placeholder ? data.placeholder : 'ค้นหา' + data.header + '...'"
                        [noResultsMessage]="'-'" (filteredReturn)="filteredListPage =$event" *ngIf="select.focused">
                    </mat-select-filter>
                    <ng-container *ngFor="let item of filteredListPage">
                        <mat-option class="font-medium" [value]="item" (click)="getStatus(item)">
                            ({{item.prefix}}) {{item.name}}
                        </mat-option>
                    </ng-container>
                </mat-select> -->
                <mat-select #select class="select-custom mb-[10px]" [disableOptionCentering]="true"
                    [placeholder]="'เลือก'" autocomplete="off" formControlName="status" multiple>
                    <mat-select-filter class="select-filter" [displayMember]="'name'" [array]="dataPageStatus"
                        [placeholder]="data.placeholder ? data.placeholder : 'ค้นหา' + data.header + '...'"
                        [noResultsMessage]="'-'" (filteredReturn)="filteredListPageStatus = $event"
                        *ngIf="select.focused">
                    </mat-select-filter>
                    <ng-container *ngFor="let item of filteredListPageStatus">
                        <mat-option class="font-medium" [value]="item">
                            {{ item.name }}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <!-- <ng-container *ngIf="!!dialogFormGroup.get('value')?.value">
                </ng-container>
                <ng-container *ngIf="!!dialogFormGroup.get('status')?.value">
                    <button mat-button class="button-cdg mt-1 w-[100%] bg-bule-light mb-[10px]"
                        [disabled]="dialogFormGroup.get('value')?.value.length === 0 || dialogFormGroup.get('value')?.value === ''"
                        (click)="clickAdd()">+
                        เพิ่มโครงการ</button>
                </ng-container> -->
                <ng-container *ngIf="groupDataStatus.length > 0">
                    <div class="flex flex-wrap gap-x-2">
                        <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip *ngFor="let item of groupDataStatus;let index = index"
                                (removed)="removeStatus(item,index)">
                                <ng-container *ngIf="!!item.value; else elseValue">
                                    <span>{{'(' + item.value.prefix + ')' + ' ' + item.status.label}}</span>
                                </ng-container>
                                <ng-template #elseValue>
                                    <span>{{item.name}}</span>
                                </ng-template>
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-list>
                        <!-- <ng-container *ngFor="let item of groupDataStatus">
                            <ng-container *ngIf="!!item.value; else elseValue">
                                <span class="text-sm font-normal py-[6px] px-3 rounded-full bg-gray-400 mt-2">{{'(' +
                                    item.value.prefix + ')' + ' '
                                    + item.status.label}}</span>
                            </ng-container>
                            <ng-template #elseValue>
                                <span class="text-sm font-normal py-[6px] px-3 rounded-full bg-gray-400 mt-2">
                                    {{item.name}}
                                </span>
                            </ng-template>
                        </ng-container> -->
                    </div>
                </ng-container>
            </div>
            <div class="" *ngIf="data && data?.type === 'select' && data?.list">
                <mat-select #select class="select-custom" [disableOptionCentering]="true" [placeholder]="'เลือก'"
                    autocomplete="off" formControlName="value">
                    <mat-select-filter class="select-filter" [displayMember]="'lable'" [array]="dataSelect"
                        [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                        (filteredReturn)="filteredListSelect =$event" *ngIf="select.focused">
                    </mat-select-filter>
                    <ng-container *ngFor="let item of filteredListSelect">
                        <mat-option class="font-medium" [value]="item.value">
                            {{item.label}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button class="bg-gray-100 button-cdg" (click)="clickClose()">
            ยกเลิก
        </button>
        <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
            {{data.submit ? data.submit : 'ค้นหา'}}
        </button>
    </mat-dialog-actions>
</div>