import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatRoomAdminRequest } from '../../request/ChatRoomAdminRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class ChatRoomAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/chatroom/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);

    // public createData = (request: PageAdminStatusRequest) => this.post<PageAdminModel>('', this.getDefaultHeader(), null, request);
    // public updateData = (id: string, request: PageAdminStatusRequest) => this.put<PageAdminModel>(`${(id)}`, this.getDefaultHeader(), null, request);
    // public deleteData = (id: string) => this.delete<MethodResponseInf>(`${(id)}`, this.getDefaultHeader(), null);
    public searchData = (request?: ChatRoomAdminRequest) => this.post<MethodResponseInf<any>>('search', this.getDefaultHeader(), null, request);
}