<ng-container *ngIf="pulse; else elseTemplateData">
    <div class="card-notification group flex py-2 px-4 cursor-pointer hover:bg-gray-100" [ngClass]="class"
        (click)="clickEvent($event)">
        <ng-container *ngIf="image; else elseTemplateImg">
            <img class="h-12 w-12 object-cover mt-1 rounded-full flex-none" [src]="image" alt="" />
        </ng-container>
        <ng-template #elseTemplateImg>
            <div class="h-12 w-12 object-cover mt-1 rounded-full bg-gray-300 flex-none"></div>
        </ng-template>
        <div class="ml-3 overflow-hidden flex-1">
            <p class="text-sm font-normal text-gray-400 group-hover:text-gray-500">
                {{content}}
            </p>
            <div class="flex items-center justify-between mt-1">
                <p class="text-sm text-gray-400 leading-6" [class.text-[color:var(--blue-light)]]="read">
                    {{time}}
                </p>
                <span class="h-2 w-2 bg-[color:var(--blue-light)] rounded-full overflow-hidden" *ngIf="read"></span>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplateData>
    <div class="card-notification animate-pulse flex items-center py-2 px-4 cursor-pointer hover:bg-gray-100"
        [ngClass]="class">
        <div class="h-12 w-12 object-cover rounded-full bg-gray-300 flex-none"></div>
        <div class="ml-3 overflow-hidden flex-1 space-y-2">
            <p class="col-span-1 h-2 bg-gray-300 rounded"></p>
            <div class="grid grid-cols-3 gap-4">
                <p class="col-span-2 h-2 bg-gray-300 rounded"></p>
                <p class="col-span-1 h-2 bg-gray-300 rounded"></p>
            </div>
            <p class="col-span-1 h-2 bg-gray-300 rounded"></p>
        </div>
    </div>
</ng-template>