import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';
import { CustomerServiceModel } from '../../models/CustomerServiceModel';
import { CustomerServiceRequest } from '../../request/CustomerServiceRequest';
import { CustomerServiceStatusAdminRequest } from '../../request/CustomerServiceStatusAdminRequest';

@Injectable()
export class CustomerServiceStatusAdminFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/customerservice_status/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public createData = (request: CustomerServiceRequest) => this.post<MethodResponseInf<CustomerServiceModel>>('', this.getDefaultHeader(), null, request);
    public updateData = (id: string, request: CustomerServiceRequest) => this.put<MethodResponseInf<CustomerServiceModel>>(`${(id)}`, this.getDefaultHeader(), null, request);
    public deleteData = (id: string) => this.delete<MethodResponseInf>(`${(id)}`, this.getDefaultHeader(), null);
    public searchData = (request?: CustomerServiceStatusAdminRequest) => this.post<MethodResponseInf<any[]>>(`search`, this.getDefaultHeader(), null, request);
}