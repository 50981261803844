<!-- <mat-progress-bar mode="indeterminate" class="fixed top-0 left-0 right-0 z-20" *ngIf="isLoading"></mat-progress-bar>
<router-outlet></router-outlet> -->
<!-- <div class="fixed bottom-3 right-3 z-[9999] grid gap-2" *ngIf="Notis.length > 0">
    <div class="p-4 w-64 bg-white rounded-xl shadow-lg flex items-center space-x-4 border border-black/5 text-base"
        *ngFor="let n of Notis; let i = index">
        {{i}} {{n.notification.body}}
    </div>
</div> -->

<ng-container *ngIf="isLoading; else elseTemplate">
    <mat-progress-bar mode="indeterminate" class="fixed top-0 left-0 right-0 z-20"></mat-progress-bar>
</ng-container>
<ng-template #elseTemplate>
    <mat-progress-bar mode="indeterminate" class="fixed top-0 left-0 right-0 z-20"
        *ngIf="loaderService?.isLoading | async"></mat-progress-bar>
</ng-template>
<router-outlet></router-outlet>