import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataQuestionnaireRequest, SearchQuestionnaireRequest } from '../../request/QuestionnaireRequest';
import { SearchFilter } from '../../request/SearchFilterRequest';
import { MethodResponseInf } from '../../interface/interface';
import { AuthenManager } from '../services';
import { MethodFacade } from './MethodFacade';

@Injectable()
export class QuestionnaireFacade extends MethodFacade {

    constructor(http: HttpClient, authMgr: AuthenManager) {
        super(http, authMgr, '/admin/questionnaire/template/');
    }

    // วิธีเพิ่ม method
    // public methodName = (param) => this.post<response>(path, header, queryParam, body);
    public searchData = (request?: SearchQuestionnaireRequest) => this.post<MethodResponseInf<any>>('search', this.getDefaultHeader(), null, request);
    public createData = (request: DataQuestionnaireRequest) => this.post<MethodResponseInf<any>>('', this.getDefaultHeader(), null, request);
    public getData = (id: string, request?: SearchFilter) => this.get<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), request);
    public updateData = (id: string, request: DataQuestionnaireRequest) => this.put<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), null, request);
    public deleteData = (id: string) => this.delete<MethodResponseInf<any>>(`${(id)}`, this.getDefaultHeader(), null);
}