<mat-sidenav-container class="slider-detail z-[auto]" [hasBackdrop]="hasBackdrop" [ngClass]="class">
    <mat-sidenav #sidenav class="slider-detail-content z-[101] w-full bg-transparent" autoFocus="false" [mode]="mode"
        [position]="position" [fixedInViewport]="fixedInViewport" [fixedTopGap]="fixedTopGap"
        [fixedBottomGap]="fixedBottomGap" disableClose="true">
        <div class="fixed z-[21] top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-black/10"
            *ngIf="isSubmitted">
            <mat-spinner></mat-spinner>
        </div>
        <div #wrapper
            class="wrapper-slider-detail flex flex-col bg-[color:var(--bg-F2)] h-full ml-auto overflow-y-auto">
            <div class="flex-none sticky top-0 z-20 flex items-center bg-white h-[80px] px-6 shadow-md">
                <div class="flex-1 flex items-center mr-4">
                    <button mat-icon-button class="group mr-3 flex-none icon-ripper-center" (click)="clickClose();">
                        <mat-icon class="opacity-50 group-hover:opacity-100">close</mat-icon>
                    </button>
                    <div class="flex-1 flex flex-col">
                        <span class="text-lg font-bold flex items-center">
                            <img class="flex-none w-6 h-6 min-w-6 mr-2"
                                [src]="dataDetail?.type === 'sms' ? '../../../../assets/images/app/message_sms.svg' : '../../../../assets/images/app/message_email.svg'"
                                alt="">
                            <span class="flex-1 line-clamp-1">
                                {{dataDetail && dataDetail.value?.projectId ?
                                dataDetail.value.projectId :
                                'หัวเรื่อง'}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="flex-none flex items-center">
                    <div class="grid grid-cols-2 gap-2">
                        <button mat-button class="bg-gray-100 button-cdg" (click)="clickCancel()">
                            คืนค่า
                        </button>
                        <button mat-button class="bg-bule-light button-cdg" (click)="clickSubmit()">
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'edit'">บันทึก</ng-container>
                            <ng-container *ngIf="dataDetail && dataDetail.mode === 'create'">สร้าง</ng-container>
                        </button>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                        class="ml-3 flex-none icon-ripper-center hover:bg-gray-200" #menu="matMenuTrigger"
                        [class.bg-gray-200]="menu.menuOpen" *ngIf="dataDetail && dataDetail.mode === 'edit'">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button mat-menu-item class="text-base font-medium" (click)="clickDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>ลบข้อมูล</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="flex-1 flex items-start">
                <div class="flex-1 p-8 flex-wrap md:flex-nowrap flex items-start justify-center gap-6 md:gap-5">
                    <div class="w-full md:max-w-[500px] xl:max-w-[560px] grid grid-cols-1 gap-6">
                        <ng-container [formGroup]="groupMessage">
                            <card-detail [header]="'ตั้งค่าโครงการ'" [isAciton]="false" [isShow]="true"
                                [maxHeight]="'unset'">
                                <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <div>
                                        <span class="text-base font-bold opacity-50">รหัสโครงการ</span>
                                        <mat-select #select class="select-custom" [disableOptionCentering]="true"
                                            [placeholder]="'เลือก'" autocomplete="off"
                                            (selectionChange)="$event.value === 'OTHER' ? groupMessage.get('projectId')?.setValue('') : groupMessage.get('projectId')?.setValue($event.value)"
                                            formControlName="projectIdOther"
                                            [class.border-error]="(isSubmitted || groupMessage.get('projectIdOther')?.touched) && f['projectIdOther'].errors">
                                            <mat-select-filter class="select-filter" [displayMember]="'prefix'"
                                                [array]="dataPage" [placeholder]="'ค้นหา...'" [noResultsMessage]="'-'"
                                                (filteredReturn)="filteredListPage =$event" *ngIf="select.focused">
                                            </mat-select-filter>
                                            <ng-container *ngFor="let item of filteredListPage">
                                                <mat-option class="font-medium" [value]="item.prefix">
                                                    {{item.prefix !== 'OTHER' ? '(' + item.prefix + ')' : ''}}
                                                    {{item.name}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupMessage.get('projectIdOther')?.touched) && f['projectIdOther'].errors">
                                            รหัสโครงการต้องเป็น 3 หลัก
                                        </mat-error>
                                    </div>
                                    <div *ngIf="groupMessage.get('projectIdOther')?.value === 'OTHER'">
                                        <input class="input-custom" type="text" placeholder="" autocomplete="off"
                                            formControlName="projectId"
                                            [class.border-error]="(isSubmitted || groupMessage.get('projectId')?.touched) && f['projectId'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupMessage.get('projectId')?.touched) && f['projectId'].errors">
                                            รหัสโครงการอย่างน้อยต้องเป็น 3 หลัก
                                        </mat-error>
                                    </div>
                                </div>
                            </card-detail>

                            <card-detail [header]="'ข้อความ'" [isAciton]="false" [isShow]="true" [maxHeight]="'unset'">
                                <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <div *ngIf="dataDetail?.type === 'email'">
                                        <span class=" text-base font-bold opacity-50">เรื่อง</span>
                                        <input class="input-custom" formControlName="subject" type="text" placeholder=""
                                            [class.border-error]="(isSubmitted || groupMessage.get('subject')?.touched) && f['subject'].errors">
                                        <mat-error class="text-sm font-medium"
                                            *ngIf="(isSubmitted || groupMessage.get('subject')?.touched) && f['subject'].errors">
                                            กรุณากรอกข้อความพาดหัว
                                        </mat-error>
                                    </div>
                                    <div>
                                        <span class="text-base font-bold opacity-50">เนื้อหาข้อความ</span>
                                        <div class="text-sm font-medium opacity-30">
                                            - แท็กพิเศษ: {{'{'}}fullname{{'}'}} , {{'{'}}email{{'}'}} ,
                                            {{'{'}}mobile_no{{'}'}} ,
                                            {{'{'}}project_id{{'}'}}
                                        </div>
                                        <div class="text-sm font-medium opacity-30">- วิธีการใช้ เช่น ขอบคุณ
                                            {{'{'}}fullname{{'}'}} สำหรับการลงทะเบียนโครงการ {{'{'}}project_id{{'}'}}
                                        </div>
                                        <div class="mt-2">
                                            <ng-container *ngIf="dataDetail?.type === 'email'; else elseTemplateType">
                                                <div class="editor"
                                                    [class.border-error]="(isSubmitted || groupMessage.get('text')?.touched) && f['text'].errors">
                                                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                                                    </ngx-editor-menu>
                                                    <ngx-editor (filesChangeEmiter)="onFileChange($event)"
                                                        formControlName="text" [editor]="editor"
                                                        [placeholder]="'ช่องสำหรับใส่ข้อความ'">
                                                    </ngx-editor>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateType>
                                                <textarea
                                                    class="resize-none text-base font-bold w-full border rounded-lg p-4 focus:outline-none"
                                                    rows="8" formControlName="text"
                                                    [placeholder]="'ช่องสำหรับใส่ข้อความ'"
                                                    [class.border-error]="(isSubmitted || groupMessage.get('text')?.touched) && f['text'].errors"></textarea>
                                            </ng-template>
                                        </div>
                                        <div class="flex items-center justify-between">
                                            <span class="flex-1">
                                                <mat-error class="text-sm font-medium"
                                                    *ngIf="(isSubmitted || groupMessage.get('text')?.touched) && f['text'].errors">
                                                    กรุณากรอกข้อความ
                                                </mat-error>
                                            </span>
                                            <span class="flex-none">
                                                <span
                                                    [class.text-[#f44336]]="(groupMessage.get('text')?.value).length > 1000">
                                                    {{(groupMessage.get('text')?.value).length}}
                                                </span>
                                                / 1000
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </card-detail>

                            <card-detail [header]="'สถานะ'" [isAciton]="false" [isShow]="true" [maxHeight]="'unset'">
                                <div body class="px-4 py-6 grid grid-cols-1 gap-4">
                                    <div>
                                        <span class="text-base font-bold opacity-50">แจ้งสถานะการลงทะเบียน</span>
                                    </div>
                                    <div>
                                        <mat-slide-toggle formControlName="isOpen">
                                            {{groupMessage.get('isOpen')?.value ? 'เผยแพร่' : 'ไม่เผยแพร่'}}
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </card-detail>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>