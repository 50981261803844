import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { LoaderService } from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class InterceptorLoaderService implements HttpInterceptor {
    private count = 0;

    constructor(
        public loaderService: LoaderService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.isLoading.next(true);
        this.count++;
        return next.handle(req).pipe(
            finalize(
                () => {
                    this.count--;
                    if (this.count === 0) {
                        this.loaderService.isLoading.next(false);
                    }
                }
            ),
        );
    }
}