import { TYPE } from '../constants/constants';
import { MethodResponseInf } from '../interface/interface';

export class DataUtil {
    public static isEmpty(data: any): boolean {
        if (typeof data === TYPE.string) {
            data = data.trim();
        }
        return data === null || data === undefined || data === '';
    }
    public static isNotEmpty(data: any): boolean {

        if (data === null || data === undefined) {
            return false;
        }
        if (typeof data === TYPE.string) {
            data = data.trim();
        }
        return data !== null && data !== undefined && data !== '';
    }
    public static arrayEquals(a: any, b: any): boolean {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }
    public static euclidianDistance3d(rgbColor: any[]): number {
        const r = rgbColor[0];
        const g = rgbColor[1];
        const b = rgbColor[2];
        return Math.sqrt(Math.pow(r, 2) + Math.pow(g, 2) + Math.pow(b, 2));
    }
    public static capitalizeFirstLetter(string: string): string | undefined {
        if (typeof string !== TYPE.string) {
            return undefined;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public static colorTemperatureToRGB(kelvin: number): object {
        const temp = kelvin / 100;
        let red;
        let green;
        let blue;
        if (temp <= 66) {
            red = 255;
            green = temp;
            green = 99.4708025861 * Math.log(green) - 161.1195681661;
            if (temp <= 19) {
                blue = 0;
            } else {
                blue = temp - 10;
                blue = 138.5177312231 * Math.log(blue) - 305.0447927307;

            }
        } else {
            red = temp - 60;
            red = 329.698727446 * Math.pow(red, -0.1332047592);
            green = temp - 60;
            green = 288.1221695283 * Math.pow(green, -0.0755148492);
            blue = 255;
        }
        return {
            r: this._clamp(red, 0, 255),
            g: this._clamp(green, 0, 255),
            b: this._clamp(blue, 0, 255),
        };
    }
    public static convertDataToAPI<T = any>(status: number, message: string, data: any): MethodResponseInf<T> {
        return { status, message, data };
    }

    public static objCovertToArray(obj: any): MethodResponseInf<[]> {
        try {
            const responseArray: any[] = [];
            if (this.isEmpty(obj)) {
                return this.convertDataToAPI(400, 'objToArray: Must input obj', undefined);
            }

            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const element = obj[key];
                    responseArray.push(element);
                }
            }
            return this.convertDataToAPI(200, 'Create Array data successfully', responseArray);
        } catch (error: any) {
            return this.convertDataToAPI(400, 'Error : ' + error.message, undefined);
        }
    }

    public static stringIsEnglish(str: string): boolean | any {
        if (this.isEmpty(str)) {
            return undefined;
        }
        const englishRegExp: RegExp = /^[A-Za-z0-9]*$/;
        for (const char of str) {
            if (!englishRegExp.test(char)) {
                return false;
            }
        }
        return true;
    }
    public static objIsEmpty(obj: object): boolean {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }
    public static convertTZ(date: any, tzString: string): Date {
        return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
    }
    private static _clamp(x: number, min: number, max: number): number {
        if (x < min) { return min; }
        if (x > max) { return max; }
        return x;
    }

}