import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { PageFacade } from "../../../services/facade/PageFacade.service";
import { ObservableManager } from "../../../services/ObservableManager.service";
import { Editor, toDoc, Toolbar } from "ngx-editor";
import { EmailMessageFacade } from "../../../services/facade/EmailMessageFacade.service";
import { SmsMessageFacade } from "../../../services/facade/SmsMessageFacade.service";
import { CheckTokenService } from "../../../services/CheckToken.service";
import { AuthenManager } from "../../../services/AuthenManager.service";

@Component({
    selector: 'slider-message',
    templateUrl: './SliderMessage.component.html',
})
export class SliderMessage implements OnInit {
    @Input()
    public hasBackdrop: boolean;
    @Input()
    public fixedInViewport: boolean;
    @Input()
    public fixedTopGap: number = 0;
    @Input()
    public fixedBottomGap: number = 0;
    @Input()
    public mode: 'side' | 'over' | 'push' = 'over';
    @Input()
    public position: 'start' | 'end' = 'start';
    @Input()
    public class: string | [string];
    @ViewChild('wrapper', { read: ElementRef, static: false })
    public wrapper!: ElementRef;
    @ViewChild('sidenav', { read: MatSidenav, static: false })
    public sidenav!: MatSidenav;
    @ViewChild('detailRight', { read: ElementRef, static: false })
    public detailRight!: ElementRef;
    public topRight: number = 0;
    private textSub: string = 'card-detail';
    private textSliderDetail: string = 'data-table';
    private textSucceedDetail: string = 'succeed-table';
    public dataDetail: any;
    public dataJob: any;
    public groupMessage: UntypedFormGroup;
    public dataPage: any[] = [];
    public filteredListPage: any;
    public isSubmitted: boolean = false;

    public editor: Editor;
    public toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['text_color', 'background_color'],
        ['link', 'image'],
        ['blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    constructor(
        private observManager: ObservableManager,
        private dialog: MatDialog,
        private authenManager: AuthenManager,
        private pageFacade: PageFacade,
        private formBuilder: UntypedFormBuilder,
        private emailMessageFacade: EmailMessageFacade,
        private smsMessageFacade: SmsMessageFacade,
        private checkTokenService: CheckTokenService,
    ) {
        this.formMessage();
    }

    public ngOnInit(): void {
        this.editor = new Editor();
        this.observManager.subscribe(this.textSliderDetail, async (res: any) => {
            if (res) {
                this.dataDetail = res.data;
                this.clickOpen();
                this.getSelectPrefix();
                if (this.dataDetail.type === 'sms') {
                    this.groupMessage.get('subject')?.clearValidators();
                } else if (this.dataDetail.type === 'email') {
                    this.groupMessage.get('subject')?.setValidators([Validators.required, Validators.minLength(1)]);
                }

                this.groupMessage.get('subject')?.updateValueAndValidity();
                if (this.dataDetail.mode === 'edit') {
                    setTimeout(() => {
                        this.groupMessage.get('projectIdOther')?.disable();
                        this.groupMessage.get('projectId')?.disable();
                    }, 10);
                } else if (this.dataDetail && this.dataDetail.mode === 'create') {
                    setTimeout(() => {
                        this.groupMessage.get('projectIdOther')?.enable();
                        this.groupMessage.get('projectId')?.enable();
                    }, 10);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.observManager.complete(this.textSliderDetail);
        this.observManager.complete(this.textSub);
        this.editor?.destroy();
    }

    public ngAfterViewInit(): void {

    }

    public clickOpen() {
        this.sidenav.open();
        this.scrollTop();
        this.setSubComponent();
    }

    private _checkValue(mode: 'create' | 'edit', type: 'sms' | 'email'): boolean {
        let isCheck: boolean = false;
        if (mode === 'create') {
            if (type === 'sms') {
                if (
                    this.groupMessage.get('projectId')?.value ||
                    this.groupMessage.get('text')?.value ||
                    this.groupMessage.get('isOpen')?.value
                ) {
                    isCheck = true;
                }
            } else {
                if (
                    this.groupMessage.get('projectId')?.value ||
                    this.groupMessage.get('subject')?.value ||
                    this.groupMessage.get('text')?.value ||
                    this.groupMessage.get('isOpen')?.value
                ) {
                    isCheck = true;
                }
            }
        } else {
            if (type === 'sms') {
                if (
                    this.groupMessage.get('projectId')?.value !== this.dataJob.projectId ||
                    this.groupMessage.get('text')?.value !== this.dataJob.text ||
                    this.groupMessage.get('isOpen')?.value !== this.dataJob.open
                ) {
                    isCheck = true;
                }
            } else {
                if (
                    this.groupMessage.get('projectId')?.value !== this.dataJob.projectId ||
                    this.groupMessage.get('subject')?.value !== this.dataJob.subject ||
                    this.groupMessage.get('text')?.value !== this.dataJob.text ||
                    this.groupMessage.get('isOpen')?.value !== this.dataJob.open
                ) {
                    isCheck = true;
                }
            }
        }

        return isCheck;
    }

    public clickCancel(): void {
        if (this._checkValue(this.dataDetail.mode, this.dataDetail.type)) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: (this.dataDetail.mode === 'create' ? 'ยกเลิกการสร้าง' : 'ยกเลิกการเปลี่ยนแปลง')
            }).then((res: any) => {
                if (res) {
                    if (this.dataDetail.mode === 'create') {
                        this.groupMessage.reset();
                        this.formMessage();
                    } else {
                        this.getMessage(this.dataDetail.value);
                    }

                    this.isSubmitted = false;
                }
            });

            return;
        }

        this.isSubmitted = false;
    }

    public clickClose(): void {
        if (this._checkValue(this.dataDetail.mode, this.dataDetail.type)) {
            this.authenManager.clickDialogAlert({
                header: '',
                content: (this.dataDetail.mode === 'create' ? 'ยกเลิกการสร้าง และปิดสไลด์' : 'ยกเลิกการเปลี่ยนแปลง และปิดสไลด์')
            }).then((res: any) => {
                if (res) {
                    if (this.dataDetail.mode === 'create') {
                        this.groupMessage.reset();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                    } else {
                        this.groupMessage.reset();
                        this.sidenav.close();
                        this.scrollTop();
                        this.setSubComponent();
                    }

                    this.isSubmitted = false;
                    this.formMessage();
                }
            });

            return;
        }

        this.groupMessage.reset();
        this.sidenav.close();
        this.scrollTop();
        this.setSubComponent();
        this.formMessage();
        this.isSubmitted = false;
    }

    public clickSubmit(): void {
        this.clickSubmitPage();
    }

    public scrollTop() {
        this.wrapper.nativeElement.scrollTop = 0;
    }

    public setSubComponent() {
        this.observManager.createSubject(this.textSub);
        this.observManager.publish(this.textSub, {
            data: true
        });
    }

    private getMessage(data: any) {
        this.dataJob = data;
        if (this.dataDetail.type === 'email') {
            this.groupMessage.get('subject')?.setValue(this.dataJob.subject);
        }

        const page = this.dataPage.find(element => element.prefix === this.dataJob.projectId);
        if (page) {
            this.groupMessage.get('projectIdOther')?.setValue(this.dataJob.projectId);
        } else {
            this.groupMessage.get('projectIdOther')?.setValue('OTHER');
        }

        this.groupMessage.get('projectId')?.setValue(this.dataJob.projectId);
        this.groupMessage.get('text')?.setValue(this.dataJob.text);
        this.groupMessage.get('isOpen')?.setValue(this.dataJob.open);
    }

    private formMessage() {
        this.groupMessage = this.formBuilder.group({
            projectIdOther: ['', [Validators.required, Validators.pattern('[A-Z0-9_]+$'), Validators.minLength(3)]],
            projectId: ['', [Validators.required, Validators.pattern('[A-Z0-9_]+$'), Validators.minLength(3)]],
            subject: [''],
            text: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1000)]],
            isOpen: false
        });
    }

    public checkSelectProject(project: any) {
        if (project) {

        }
    }

    private async clickSubmitPage() {
        await this.checkTokenService.checkStatus();
        if (this.groupMessage.invalid) {
            return;
        }

        if (!this._checkValue(this.dataDetail.mode, this.dataDetail.type)) {
            return;
        }

        this.isSubmitted = true;
        let data: any = {
            projectId: this.groupMessage.get('projectId')?.value,
            text: this.groupMessage.get('text')?.value,
            open: this.groupMessage.get('isOpen')?.value
        };

        if (this.dataDetail.type === 'email') {
            data['subject'] = this.groupMessage.get('subject')?.value
        }

        if (this.dataDetail.mode === 'edit') {
            let isCheck: boolean = false;
            if (this.dataDetail.type === 'sms') {
                await this.smsMessageFacade.updateData(this.dataDetail.value._id || this.dataDetail.value.id, data).then((res: any) => {
                    if (res) {
                        this.getMessage(res.data);
                        isCheck = true;
                    }
                }).catch((err) => {
                    if (err) {
                        this.formMessage();
                        this.isSubmitted = false;
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: err.error.message,
                            cancel: false
                        }).then((res: any) => {
                            if (res) {
                            }
                        });
                    }
                });
            } else if (this.dataDetail.type === 'email') {
                await this.emailMessageFacade.updateData(this.dataDetail.value._id || this.dataDetail.value.id, data).then((res: any) => {
                    if (res) {
                        this.getMessage(res.data);
                        isCheck = true;
                    }
                }).catch((err) => {
                    if (err) {
                        this.formMessage();
                        this.isSubmitted = false;
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: err.error.message,
                            cancel: false
                        }).then((res: any) => {
                            if (res) {
                            }
                        });
                    }
                });
            }

            if (isCheck) {
                this.editTableRow(this.dataDetail.index || 0, this.dataJob, 'edit');
                this.isSubmitted = false;
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'แก้ไขสำเร็จ',
                    cancel: false
                }).then((res: any) => {
                    if (res) {

                    }
                });
            }

        } else if (this.dataDetail.mode === 'create') {
            let isCheck: boolean = false;
            if (this.dataDetail.type === 'sms') {
                await this.smsMessageFacade.createData(data).then((res: any) => {
                    if (res) {
                        this.dataJob = res.data;
                        isCheck = true;
                    }
                }).catch((err) => {
                    if (err) {
                        this.isSubmitted = false;
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: err.error.message,
                            cancel: false
                        }).then((res: any) => {
                            if (res) {
                            }
                        });
                    }
                });
            } else if (this.dataDetail.type === 'email') {
                await this.emailMessageFacade.createData(data).then((res: any) => {
                    if (res) {
                        this.dataJob = res.data;
                        isCheck = true;
                    }
                }).catch((err) => {
                    if (err) {
                        this.isSubmitted = false;
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: err.error.message,
                            cancel: false
                        }).then((res: any) => {
                            if (res) {
                            }
                        });
                    }
                });
            }

            if (isCheck) {
                this.createTableRow(this.dataJob, 'create');
                this.formMessage();
                this.isSubmitted = false;
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'สร้างสำเร็จ',
                    cancel: false
                }).then((res: any) => {
                    if (res) {

                    }
                });
            }
        }
    }

    get f(): { [key: string]: AbstractControl } {
        return this.groupMessage?.controls;
    }

    public editTableRow(index: number, value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, value, mode }
        });
    }

    public createTableRow(value: any, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { value, mode }
        });
    }

    public deleteTableRow(index: number, mode: any) {
        this.observManager.createSubject(this.textSucceedDetail);
        this.observManager.publish(this.textSucceedDetail, {
            data: { index, mode }
        });
    }

    public clickDelete() {
        if (this.dataDetail.mode === 'edit') {
            this.authenManager.clickDialogAlert({
                header: '',
                content: 'ต้องการลบ "' + this.dataJob.projectId + '"'
            }).then(async (res: any) => {
                if (res) {
                    let isCheck: boolean = false;
                    if (this.dataDetail.type === 'sms') {
                        await this.smsMessageFacade.deleteData(this.dataDetail.value._id || this.dataDetail.value.id).then((res: any) => {
                            if (res) {
                                isCheck = true;
                            }
                        }).catch((err) => {
                            if (err) {
                                this.authenManager.clickDialogAlert({
                                    header: '',
                                    content: err.error.message,
                                    cancel: false
                                }).then((res: any) => {
                                    if (res) {
                                        this.isSubmitted = false;
                                    }
                                });
                            }
                        });
                    } else if (this.dataDetail.type === 'email') {
                        await this.emailMessageFacade.deleteData(this.dataDetail.value._id || this.dataDetail.value.id).then((res: any) => {
                            if (res) {
                                isCheck = true;
                            }
                        }).catch((err) => {
                            if (err) {
                                this.authenManager.clickDialogAlert({
                                    header: '',
                                    content: err.error.message,
                                    cancel: false
                                }).then((res: any) => {
                                    if (res) {
                                        this.isSubmitted = false;
                                    }
                                });
                            }
                        });
                    }

                    if (isCheck) {
                        this.deleteTableRow(this.dataDetail.index, 'delete');
                        this.sidenav.close();
                        this.groupMessage.reset();
                        this.formMessage();
                        this.scrollTop();
                        this.setSubComponent();
                        this.authenManager.clickDialogAlert({
                            header: '',
                            content: 'ลบสำเร็จ',
                            cancel: false
                        }).then((res: any) => {
                            if (res) {

                            }
                        });
                    }
                }
            });
        }
    }

    private getSelectPrefix() {
        this.pageFacade.searchData({ count: false, orderBy: { "createdDate": -1 } }).then((res: any) => {
            if (res) {
                this.dataPage = res.data;
                this.dataPage.push({
                    prefix: 'OTHER',
                    name: 'อื่นๆ'
                });
                this.filteredListPage = this.dataPage.slice();
                if (this.dataDetail.mode === 'edit') {
                    this.getMessage(this.dataDetail.value);
                }
            }
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    public onFileChange(file: any) {
        let page = toDoc(this.groupMessage.get('text')?.value);
        let fileMap = Object.keys(file).map((key: any) => file[key]);
        for (const imageList of fileMap) {
            if (imageList.type.indexOf('image') !== 0 && imageList.size > ((1024 * 1024) * 4)) {
                this.authenManager.clickDialogAlert({
                    header: '',
                    content: 'การอัพโหลดล้มเหลว. ขนาดสูงสุดของไฟล์ JPG คือ 4 MB.',
                    cancel: false
                }).then((res: any) => {
                    if (res) {

                    }
                });

                return;
            }

            let reader = new FileReader();
            reader.readAsDataURL(imageList);
            reader.onload = () => {
                let list = {
                    type: "heading",
                    attrs: {
                        level: 3,
                        align: null
                    },
                    content: [
                        {
                            type: "image",
                            attrs: {
                                src: reader.result?.toString(),
                                width: "100%",
                            }
                        }
                    ]
                }

                page['content'].push(list);
                this.groupMessage.get('text')?.setValue(page);
            };

            reader.onerror = function (error) {
                console.log(error);
            };
        }
    }
}

